import React from 'react';
import PropTypes from 'prop-types';
import { TextField, SingleSelect, Button, Alert, DatePicker } from 'emerald-ui';
import './AddLicense.css';
import { joinLicense, availableLicense, saveExternalLicense } from '../../api/licenses';
import { forceAuthenticate } from '../../utils';
import moment from 'moment';
import config from '../../config';

const addLicenseSection = config.addLicenseSection;
class AddLicense extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateSelected: null,
      professionSelected: null,
      addLicenseStatus: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      loadingStates: true,
    });
    this.props.fetchStatesProfessions();
  }

  onSelectState = async (stateId) => {
    const { states } = this.props;
    const selectedStateObj = states ? states.find((s) => s.id === stateId) : {};
    this.setState({
      stateSelected: stateId,
      professionSelected: null,
      errorMessage: '',
      displayCustomFields: selectedStateObj.code === 'NBCOT',
      stateSelectedCode: selectedStateObj.code,
      licenseNumber: null,
      dateOfBirth: null,
      lastName: null,
    });
  };

  onSelectProfession = (item) => {
    this.setState({
      professionSelected: item,
      errorMessage: '',
    });
  };

  onChangeLicenseNumber = (e) => {
    const value = e.target.value;
    this.setState({
      licenseNumber: value,
    });
  };

  validateAddLicense = () => {
    const { professionSelected, stateSelected, licenseNumber, dateOfBirth, lastName, displayCustomFields } = this.state;
    const addLicenseFields = displayCustomFields ? dateOfBirth && lastName : true;
    return licenseNumber && stateSelected && professionSelected && addLicenseFields;
  };

  onClickAddLicense = async () => {
    const {
      licenseNumber,
      stateSelected,
      professionSelected,
      dateOfBirth,
      lastName,
      stateSelectedCode,
      displayCustomFields,
    } = this.state;
    const SECONDS = 2;
    const { user, authenticatedUser, loggedOnBehalf, onHideAddRecord, addNotification, subscriptionType } = this.props;
    this.setState({ addLicenseStatus: 'loading', errorMessage: '' });

    const isValid = this.validateAddLicense();
    if (isValid) {
      const userOnBehalfId = loggedOnBehalf ? authenticatedUser.id : 0;

      try {
        const { dateOfBirthField, usePollingStrategy } = addLicenseSection;
        const dateFormat = dateOfBirthField ? dateOfBirthField.format : 'DD/MM/YYYY';
        const dateOfBirthDelimiter = dateFormat.includes('/') ? '/' : '-';
        if (!displayCustomFields || !usePollingStrategy) {
          if (displayCustomFields) {
            const fieldsValues = {
              certification_number: licenseNumber,
              dob: this.getDateOfBirthFormatted(dateOfBirth, dateOfBirthDelimiter, dateFormat),
              last_name: lastName,
            };
            await saveExternalLicense(fieldsValues);
          }
          const joinLicenseResponse = await joinLicense({
            userId: user.id,
            licenseNumber,
            stateId: stateSelected,
            professionCode: professionSelected,
            userOnBehalfId,
          });
          if (joinLicenseResponse) {
            addNotification({
              id: Math.random(),
              message: `License pending verification.`,
              timeout: 2000,
            });
            onHideAddRecord();
            setTimeout(() => {
              forceAuthenticate(user.id, loggedOnBehalf);
            }, SECONDS * 1000);
          }
        } else {
          const fieldsValues = {
            certification_number: licenseNumber,
            dob: this.getDateOfBirthFormatted(dateOfBirth, dateOfBirthDelimiter, dateFormat),
            last_name: lastName,
          };

          await saveExternalLicense(fieldsValues);
          const response = await availableLicense({
            subscriptionType,
            licenseNumber,
            state: stateSelectedCode,
          });
          if (response) {
            const joinLicenseResponse = await joinLicense({
              userId: user.id,
              licenseNumber,
              stateId: stateSelected,
              professionCode: professionSelected,
              userOnBehalfId,
            });
            if (joinLicenseResponse) {
              addNotification({
                id: Math.random(),
                message: `License pending verification.`,
                timeout: 2000,
              });
              onHideAddRecord();
              setTimeout(() => {
                forceAuthenticate(user.id, loggedOnBehalf);
              }, SECONDS * 1000);
            }
          }
        }
      } catch (error) {
        const { data } = error.response;
        let { message } = data;
        if (typeof message !== 'string') {
          message = 'There was an error trying to join the license requested. Please try again later.';
        }
        this.setState({
          addLicenseStatus: 'failure',
          errorMessage:
            error.response && error.response.status === 404 && displayCustomFields
              ? 'We are unable to verify your records with NBCOT. Please ensure the information you are entering is correct'
              : message,
        });
      }
    }
  };

  onDismissErrorMessage = () => {
    this.setState({ errorMessage: '' });
  };

  onChangeDateOfBirth = (value) => {
    this.setDateOfBirth(value);
  };

  onKeyDown = (event) => {
    const isValid = moment(event.target.value).isValid();
    if (isValid) {
      this.setDateOfBirth(moment(event.target.value));
    }
  };

  setDateOfBirth = (value) => {
    this.setState({
      dateOfBirth: value,
    });
  };

  onChangeLastName = (e) => {
    const value = e.target.value;
    this.setState({
      lastName: value,
    });
  };

  getDateOfBirthFormatted(getDateWithoutFormat, formatSeparator, formatDate) {
    const date = new Date(getDateWithoutFormat);
    const month = Number(`0${date.getMonth() + 1}`.slice(-2));
    const day = Number(`0${date.getDate()}`.slice(-2));
    const year = Number(date.getFullYear());
    const fullDate = [];
    formatDate.split(formatSeparator).forEach((element) => {
      if (element.toLowerCase() === 'mm') {
        fullDate.push(month);
      } else if (element.toLowerCase() === 'dd') {
        fullDate.push(day);
      } else {
        fullDate.push(year);
      }
    });
    return fullDate.join(formatSeparator);
  }
  render() {
    const {
      stateSelected,
      professionSelected,
      licenseNumber = '',
      lastName = '',
      addLicenseStatus,
      errorMessage,
      dateOfBirth,
      displayCustomFields,
    } = this.state;

    const { dateOfBirthField, lastNameField, licenseNumberField } = addLicenseSection;
    const { states, professions, currentFetchStatus } = this.props;

    const validAddLicense = this.validateAddLicense();
    const selectedStateObj = states.find((s) => s.id === stateSelected);

    let professionsToDisplay = [];
    let licenseNumberPlaceholder = 'ex: RN99999999';
    if (selectedStateObj) {
      switch (selectedStateObj.code) {
        case 'OH':
          licenseNumberPlaceholder = 'ex: A.9999';
          break;
        case 'WY':
          licenseNumberPlaceholder = 'ex: 999';
          break;
        case 'AL':
          licenseNumberPlaceholder = 'ex: 9999';
          break;
        default:
          licenseNumberPlaceholder = 'ex: RN99999999';
      }
      professionsToDisplay = professions[selectedStateObj.code];
    }
    const validStates = states.filter((s) => !s.isNationalBoard);
    const nationalBoardEntities = states.filter((s) => s.isNationalBoard);

    const statesOptions = validStates.map((s) => (
      <option key={s.id} value={s.id} selected={stateSelected === s.id}>
        {s.name}
      </option>
    ));

    const nationalBoardOptions = nationalBoardEntities.map((a) => (
      <option key={a.id} value={a.id} selected={stateSelected === a.id}>
        {a.name}
      </option>
    ));

    const loadingComponents = ['loading', 'failed', ''].includes(currentFetchStatus);

    const nowDate = new Date();
    return (
      <div id="add-license" className="add-record-content">
        <div className="add-license-content">
          <div className="add-record-group">
            <SingleSelect
              label="Location"
              id="location"
              className="add-record-input"
              onSelect={this.onSelectState}
              placeholder={loadingComponents ? 'Loading...' : 'Select a location...'}
            >
              {statesOptions.length > 0 ? <optgroup label="STATES">{statesOptions}</optgroup> : []}

              {nationalBoardOptions.length > 0 ? (
                <optgroup label="NATIONAL OR CERTIFYING BOARD">{nationalBoardOptions}</optgroup>
              ) : (
                []
              )}
            </SingleSelect>
          </div>
          <div className="add-record-group">
            <SingleSelect
              label="Profession"
              id="profession"
              className="add-record-input"
              disabled={!selectedStateObj || professionsToDisplay.length === 0}
              onSelect={this.onSelectProfession}
            >
              <option disabled>
                {!selectedStateObj
                  ? 'Please, select first a state.'
                  : loadingComponents
                  ? 'Loading...'
                  : 'Select a profession...'}
              </option>
              {professionsToDisplay.map((p) => {
                return (
                  <option key={p.id} value={p.code} selected={professionSelected === p.code}>
                    {p.name}
                  </option>
                );
              })}
            </SingleSelect>
          </div>
          <div className="add-record-group text-field-group add-license-input custom-fields">
            <TextField
              label={
                licenseNumberField && displayCustomFields ? licenseNumberField.label : 'License/Certificate number'
              }
              placeholder={
                licenseNumberField && displayCustomFields ? licenseNumberField.placeHolder : licenseNumberPlaceholder
              }
              value={licenseNumber}
              className="add-record-input"
              maxLength={25}
              onChange={this.onChangeLicenseNumber}
            />
          </div>
          {displayCustomFields && (
            <div className="custom-fields">
              <div className="add-record-group text-field-group">
                <DatePicker
                  label={dateOfBirthField ? dateOfBirthField.label : ''}
                  className="custom-fields"
                  onChange={this.onChangeDateOfBirth}
                  selected={dateOfBirth}
                  maxDate={nowDate}
                  placeholderText={dateOfBirthField ? dateOfBirthField.placeHolder : ''}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat={dateOfBirthField ? dateOfBirthField.formatDatePicker : ''}
                  onKeyDown={this.onKeyDown}
                />
              </div>
              <div className="add-record-group text-field-group last-name">
                <TextField
                  label={lastNameField ? lastNameField.label : ''}
                  placeholder={lastNameField ? lastNameField.placeHolder : ''}
                  value={lastName}
                  maxLength={25}
                  onChange={this.onChangeLastName}
                />
              </div>
            </div>
          )}
          {errorMessage && (
            <Alert dismissible onDismiss={this.onDismissErrorMessage} color="danger">
              {errorMessage}
            </Alert>
          )}
          <Button
            color="info"
            className="add-record-button"
            onClick={this.onClickAddLicense}
            disabled={!validAddLicense || addLicenseStatus === 'loading'}
            loading={addLicenseStatus === 'loading'}
          >
            Add license/certificate
          </Button>
        </div>
      </div>
    );
  }
}

AddLicense.propTypes = {
  user: PropTypes.object,
  authenticatedUser: PropTypes.object,
  loggedOnBehalf: PropTypes.bool,
  states: PropTypes.array,
  professions: PropTypes.object,
  currentFetchStatus: PropTypes.string,
  onHideAddRecord: PropTypes.func,
  addNotification: PropTypes.func,
  fetchStatesProfessions: PropTypes.func,
  subscriptionType: PropTypes.string,
};

AddLicense.defaultProps = {
  user: {},
  authenticatedUser: {},
  loggedOnBehalf: false,
  states: [],
  professions: {},
  currentFetchStatus: '',
  subscriptionType: '',
  onHideAddRecord: () => {},
  addNotification: () => {},
  fetchStatesProfessions: () => {},
};

export default AddLicense;
