import { combineReducers } from 'redux';
import at from '../types';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return action.entities.items ? action.entities.items : state;
    }
    case at.DELETE_HEARTCARD_SUCCESS: {
      const newState = state;
      delete newState[action.heartcardId];
      return newState;
    }
    case at.ADD_ATTACHMENT_SUCCESS: {
      const { newFile, reduxId, localFileId } = action;
      newFile.localFileId = localFileId;
      const heartcard = state[reduxId];
      heartcard.attachments.push(newFile);
      return { ...state, [reduxId]: { ...heartcard } };
    }
    case at.DELETE_ATTACHMENT_SUCCESS: {
      const { fileId, reduxId } = action;
      const heartcard = state[reduxId];
      heartcard.attachments = heartcard.attachments.filter((f) => f.id.toString() !== fileId.toString());
      return { ...state, [reduxId]: { ...heartcard } };
    }
    case at.ADD_HEARTCARD_SUCCESS: {
      const { heartcard } = action;
      return { ...state, [`${heartcard.id}-${heartcard.type}`]: heartcard };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const all = (state = [], action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return action.result ? action.result : state;
    }
    case at.ADD_HEARTCARD_SUCCESS: {
      const { heartcard } = action;
      return [...state, `${heartcard.id}-${heartcard.type}`];
    }
    case at.DELETE_HEARTCARD_SUCCESS: {
      const newState = state.filter((item) => {
        return item !== action.heartcard.reduxId;
      });
      return newState;
    }
    default:
      return state;
  }
};

export const fetchStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_REQUEST: {
      return 'loading';
    }
    case at.FETCH_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const deleteHeartcardStatus = (state = '', action) => {
  switch (action.type) {
    case at.DELETE_HEARTCARD_SUCCESS: {
      return 'deleted';
    }
    case at.DELETE_HEARTCARD_REQUEST: {
      return 'deleting';
    }
    case at.DELETE_HEARTCARD_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const addHeartcardStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return '';
    }
    case at.ADD_HEARTCARD_SUCCESS: {
      return 'added';
    }
    case at.ADD_HEARTCARD_REQUEST: {
      return 'adding';
    }
    case at.ADD_HEARTCARD_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  all,
  fetchStatus,
  deleteHeartcardStatus,
  addHeartcardStatus,
});
