import axios from 'axios';
import config from '../config';

const { licenseesApiInternal } = config;

export const fetchRequirementsLink = (boardId) => {
  return axios.request({
    method: 'get',
    url: `${licenseesApiInternal}/boards/${boardId}/requirements-link`,
  });
};

export const fetchBoardLogo = (boardId) => {
  return axios.request({
    method: 'get',
    url: `${licenseesApiInternal}/boards/${boardId}/logo`,
  });
};
