import { connect } from 'react-redux';
import * as authenticationStore from '../store/authentication';
import TranscriptRouter from '../components/TranscriptRouter';

function mapStateToProps(state) {
  const authenticationSelectors = authenticationStore.selectors(state);
  const licenseeAccount = authenticationSelectors.getLicenseeAccount();
  const { subscriptions = [] } = licenseeAccount;
  let subscriptionType = '';
  if (subscriptions.length > 0) {
    subscriptionType = subscriptions[0].code;
  }
  return {
    subscriptionType,
  };
}

export default connect(mapStateToProps, {})(TranscriptRouter);
