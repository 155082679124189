import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { Popover, IconButton, FileUploadList, Spinner } from 'emerald-ui/lib';
import Overlay from 'react-bootstrap/lib/Overlay';

class AttachmentsColumn extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showOverlay: false,
      overlayTimeout: {},
    };
  }

  getOverlayTarget = () => {
    return ReactDOM.findDOMNode(this.target); // eslint-disable-line
  };

  handleOverlay = (showOverlay) => {
    clearTimeout(this.state.overlayTimeout);
    const overlayTimeout = setTimeout(
      () => {
        this.setState({ showOverlay });
      },
      showOverlay ? 500 : 300
    );
    this.setState({ overlayTimeout });
  };

  showOverlay = () => {
    this.handleOverlay(true);
  };

  hideOverlay = () => {
    this.handleOverlay(false);
  };

  render() {
    const { files, attachmentsFetchStatus, onShowAttachments } = this.props;
    return (
      <div>
        <IconButton
          icon="attach_file"
          className="table-icons"
          onClick={(e) => {
            e.stopPropagation();
            const showOverlay = !this.state.showOverlay;
            this.setState({ showOverlay });
          }}
          ref={(button) => {
            this.target = button;
          }}
          onMouseOver={this.showOverlay}
          onMouseLeave={this.hideOverlay}
        />
        <Overlay
          show={this.state.showOverlay}
          placement="bottom"
          delayShow={500}
          delayHide={300}
          onEntering={() => {
            if (attachmentsFetchStatus !== 'loaded') {
              onShowAttachments();
            }
          }}
          target={this.getOverlayTarget}
        >
          <Popover
            placement="bottom"
            id="filterForm"
            className="list-attachments-popover"
            style={{ width: '300px' }}
            onMouseOver={this.showOverlay}
            onMouseLeave={this.hideOverlay}
          >
            {attachmentsFetchStatus !== 'loaded' && (
              <div className="course-history-attachments-loader">
                <Spinner size="sm" style={{ marginRight: '10px' }} />
                <span>Loading attachments...</span>
              </div>
            )}
            {attachmentsFetchStatus === 'loaded' && <FileUploadList files={files} />}
          </Popover>
        </Overlay>
      </div>
    );
  }
}

AttachmentsColumn.propTypes = {
  attachmentsFetchStatus: PropTypes.string,
  files: PropTypes.array,
  onShowAttachments: PropTypes.func,
};

AttachmentsColumn.defaultProps = {
  attachmentsFetchStatus: '',
  files: [],
  onShowAttachments: () => {},
};

export default AttachmentsColumn;
