import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ReactivateSubscription from '../components/ReactivateSubscription';
import * as subscription from '../store/subscription';
import * as subscriptionsStore from '../store/subscriptions';
import * as cartStore from '../store/cart';

import * as utils from '../utils';

import config from '../config';
const { secureCookie } = config;

function mapStateToProps(state) {
  const secureToken = utils.getCookie(secureCookie);
  const subscriptionsSelectors = subscriptionsStore.selectors(state);
  const plansRequestStatus = subscriptionsSelectors.getSubscriptionPlansStatus();
  const subscriptionPlans = subscriptionsSelectors.getSubscriptionPlans();
  const cartSelectors = cartStore.selectors(state);

  const {
    authentication: { licenseeAccount, status },
    subscription,
  } = state;
  let userId = 0,
    currentSubscriptionType = '';

  if (Object.keys(licenseeAccount).length !== 0) {
    userId = licenseeAccount.user.id;
    currentSubscriptionType = licenseeAccount.subscriptions[0].code;
  }

  const saveCartItemStatus = cartSelectors.saveCartItemStatus();

  const params = {
    userId,
    currentSubscriptionType,
    status,
  };

  return {
    params,
    subscription,
    secureToken,
    subscriptionPlans,
    plansRequestStatus,
    saveCartItemStatus,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchLastSubscription: subscription.actions.fetchLastSubscription,
    getSubscriptionPlans: subscriptionsStore.actions.getSubscriptionPlans,
    saveCartItem: cartStore.actions.saveCartItem,
  })(ReactivateSubscription)
);
