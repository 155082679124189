import { connect } from 'react-redux';
import Toaster from '../components/Toaster';

import * as notificationsStore from '../store/notifications';

function mapStateToProps(state) {
  const notifications = notificationsStore.selectors(state).getGeneralNotification();

  return {
    notifications,
  };
}

export default connect(mapStateToProps, {
  addNotification: notificationsStore.actions.add,
})(Toaster);
