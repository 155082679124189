import React from 'react';

import './TableNoResultsState.css';
import { Table } from 'emerald-ui';

class TableNoResultsState extends React.Component {
  render() {
    return (
      <Table>
        <tbody>
          <tr>
            <td className="course-history-no-results">
              <span className="text-lighter" style={{ fontSize: '16px' }}>
                No results found. Try searching with another keyword or changing the filter parameters.
              </span>
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default TableNoResultsState;
