export default function (globalState) {
  const state = globalState.paymentHistory;

  const getEntityById = (id) => state.entities[id] || {};

  const getEntitiesByIds = (ids) => ids.map(getEntityById);

  const getEntitiesByPage = (page) => getEntitiesByIds(state.pages[page] || []);

  const getPageFetchStatus = (page) => state.pagesFetchStatus[page] || '';

  const getEntitiesFetchStatus = () => state.entitiesFetchStatus;

  const getTotalCount = () => state.totalCount;

  return {
    getEntityById,
    getEntitiesByIds,
    getEntitiesByPage,
    getPageFetchStatus,
    getEntitiesFetchStatus,
    getTotalCount,
  };
}
