import { put, call, select } from 'redux-saga/effects';

import * as api from '../../../api/dashboardCards';
import at from '../types';

export default function* deleteAttachment({ fileId, heartcardId, reduxId }) {
  try {
    const state = yield select();
    const userId = state.authentication.licenseeAccount.user.id;
    yield call(api.deleteAttachment, fileId, heartcardId, userId);
    yield put({
      type: at.DELETE_ATTACHMENT_SUCCESS,
      fileId,
      reduxId,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    yield put({ type: at.DELETE_ATTACHMENT_FAILURE, fileId, reduxId });
  }
}
