import axios from 'axios';
import config from '../config';

export function authenticate(token, tokenType, userId, allowInactiveUser) {
  const params = { token, tokenType };
  if (userId) {
    params.userId = userId;
  }
  if (allowInactiveUser) {
    params.allowInactiveUser = allowInactiveUser;
  }
  return axios({
    method: 'get',
    url: `${config.licenseesApiInternal}/authentication`,
    headers: {
      Pragma: 'no-cache',
    },
    params,
  });
}
