import { put, call } from 'redux-saga/effects';
import * as api from '../../../api/selfSubmissions';
import at from '../types';

export default function* fetchSelfSubmission({ selfSubmissionId, feeId, meta = {} }) {
  try {
    const { data } = yield call(api.fetchSelfSubmission, selfSubmissionId);
    yield put({
      type: at.FETCH_SELF_SUBMISSION_SUCCESS,
      selfSubmissionId,
      reduxId: feeId,
      ...data,
      meta,
    });
  } catch (error) {
    console.error(error); //eslint-disable-line
    yield put({
      type: at.FETCH_SELF_SUBMISSION_FAILURE,
      selfSubmissionId,
      reduxId: feeId,
      meta,
    });
  } finally {
    //eslint-disable-line
  }
}
