import at from './types';

export const fetchCart = () => ({
  type: at.FETCH_CART,
});

export const saveCartItem = ({ courses, subscription, meta }) => ({
  type: at.SAVE_CART_ITEM,
  courses,
  subscription,
  meta,
});

export const removeCartItem = (courseId, meta) => ({
  type: at.DELETE_CART_ITEM,
  courseId,
  meta,
});

export const clearStatusCart = () => ({
  type: at.CLEAR_SAVE_CART_ITEM_STATUS,
});
