import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { Pager } from 'emerald-ui/lib';
import './ListPager.css';

class ListPager extends React.Component {
  updateUrl = (pathname, params) => {
    const {
      history: { replace },
    } = this.props;
    replace(`${pathname}?${queryString.stringify(params)}`);
  };

  updateUrlParams = (params) => {
    const {
      location: { pathname },
    } = this.props;
    this.updateUrl(pathname, params);
  };

  handlePageChange = (action) => {
    const { params, fetch, clearRowsSelected, pageAttribute } = this.props;
    let page = params[pageAttribute];
    const oldPage = page;
    page = action === 'prev' ? page - 1 : page + 1;
    const newParams = { ...params, [pageAttribute]: page, oldPage };
    fetch(newParams, { pageAttribute });
    this.updateUrlParams(newParams);
    if (clearRowsSelected) {
      clearRowsSelected();
    }
    window.scrollTo(0, 0);
  };

  handleLimitChage = (limit) => {
    const { params, fetch, sizeAttribute } = this.props;
    const newParams = { ...params, [sizeAttribute]: limit };
    fetch(newParams, { clearEntities: true, sizeAttribute });
    this.updateUrlParams(newParams);
  };

  render() {
    const { params, totalCount, pageAttribute, sizeAttribute } = this.props;
    const page = params[pageAttribute];
    const size = params[sizeAttribute];
    return (
      <Pager
        id="list-pager"
        offset={(page - 1) * size}
        limit={size}
        total={totalCount}
        onPageChange={this.handlePageChange}
        onLimitChange={this.handleLimitChage}
      />
    );
  }
}

ListPager.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  params: PropTypes.object,
  fetch: PropTypes.func,
  totalCount: PropTypes.number,
  pageAttribute: PropTypes.string,
  sizeAttribute: PropTypes.string,
  clearRowsSelected: PropTypes.func,
};

ListPager.defaultValues = {
  pageAttribute: 'page',
  sizeAttribute: 'size',
};

export default withRouter(ListPager);
