import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'emerald-ui';
import './styles.css';

const DeleteConfirmationModal = ({
  confirmationMessage,
  title,
  show,
  onHide,
  onDelete,
  cancelButtonText,
  deleteButtonText,
}) => {
  return (
    <Modal
      show={show}
      size="sm"
      onHide={onHide}
      centered={true}
      hideOnOutsideClick={true}
      id="delete-confirmation-modal"
      style={{ zIndex: 20000 }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-light">{confirmationMessage}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button color="default" onClick={onHide}>
          {cancelButtonText}
        </Button>
        <Button color="danger" onClick={onDelete}>
          {deleteButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onDelete: PropTypes.func,
  confirmationMessage: PropTypes.string,
  title: PropTypes.string,
  cancelButtonText: PropTypes.string,
  deleteButtonText: PropTypes.string,
};

DeleteConfirmationModal.defaultProps = {
  cancelButtonText: 'CANCEL',
  deleteButtonText: 'DELETE',
};

export default DeleteConfirmationModal;
