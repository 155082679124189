import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as authenticationStore from '../store/authentication';
import UpgradeAd from '../components/UpgradeAd';
import { saveEvent } from '../api/statistics';

function mapStateToProps(state, ownProps) {
  const { match } = ownProps;
  const { pkLicense } = match.params;
  const authenticationSelectors = authenticationStore.selectors(state);
  const licenseeAccount = authenticationSelectors.getLicenseeAccount();
  const { user, subscriptions, licenses } = licenseeAccount;
  const selectedLicense = licenses.find((l) => parseInt(l.id, 10) === parseInt(pkLicense, 10));
  let boardId = 0;
  if (selectedLicense) {
    boardId = selectedLicense.profession.board.id;
  }
  let isFTOAvailable = false,
    subscriptionType = '';
  if (subscriptions.length > 0) {
    subscriptionType = subscriptions[0].code;
    isFTOAvailable = subscriptions[0].isftoAvailable;
  }
  return {
    user,
    isFTOAvailable,
    saveEvent,
    boardId,
    subscriptionType,
  };
}

export default withRouter(connect(mapStateToProps, {})(UpgradeAd));
