import { put, call, select } from 'redux-saga/effects';

import * as api from '../../../api/dashboardCards';
import at from '../types';

export default function* addAttachment({ file, heartcardId, reduxId, localFileId }) {
  const state = yield select();
  const userId = state.authentication.licenseeAccount.user.id;
  try {
    const result = yield call(api.addAttachment, file, heartcardId, userId);
    yield put({
      type: at.ADD_ATTACHMENT_SUCCESS,
      newFile: result.data,
      heartcardId,
      reduxId,
      localFileId,
    });
  } catch (error) {
    console.error(error); //eslint-disable-line
    yield put({
      type: at.ADD_ATTACHMENT_FAILURE,
      file,
      heartcardId,
      reduxId,
      localFileId,
    });
  } finally {
    //eslint-disable-line
  }
}
