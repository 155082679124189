import React from 'react';
import { Row, Col } from 'emerald-ui';

import './CourseHistoryDetailSkeleton.css';

class CourseHistoryDetailSkeleton extends React.Component {
  render() {
    return (
      <Row className="course-detail-loading">
        <Col xs={12} sm={12}>
          <Row className="section-row course-detail-skeleton">
            <Col xs={12}>
              <Row>
                <Col xs={3} className="section-col">
                  <p className="course-detail-subheader skeleton-stripe title-skeleton" />
                </Col>
                <Col xs={9} className="section-col">
                  <p className="course-detail-subheader skeleton-stripe subtitle-skeleton" />
                </Col>
              </Row>
              <Row>
                <Col xs={3} className="section-col">
                  <p className="course-detail-subheader skeleton-stripe title-skeleton" />
                </Col>
                <Col xs={9} className="section-col">
                  <p className="course-detail-subheader skeleton-stripe subtitle-long-skeleton" />
                </Col>
              </Row>
              <Row>
                <Col xs={3} className="section-col">
                  <p className="course-detail-subheader skeleton-stripe title-skeleton" />
                </Col>
                <Col xs={9} className="section-col">
                  <p className="course-detail-subheader skeleton-stripe subtitle-skeleton" />
                </Col>
              </Row>
              <Row>
                <Col xs={3} className="section-col">
                  <p className="course-detail-subheader skeleton-stripe title-skeleton" />
                </Col>
                <Col xs={9} className="section-col">
                  <p className="course-detail-subheader skeleton-stripe subtitle-detail-skeleton" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={12}>
          <Row className="section-row course-detail-skeleton">
            <Col xs={12} className="section-col">
              <p className="course-detail-subheader skeleton-stripe attachments-skeleton" />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default CourseHistoryDetailSkeleton;
