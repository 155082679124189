import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectors as transcriptSelectors } from '../store/transcript';
import queryString from 'query-string';
import CourseHistory from '../components/TranscriptPage/CourseHistory';

function mapStateToProps(state, ownProps) {
  const transcript = transcriptSelectors(state);
  const { match, location } = ownProps;
  const query = queryString.parse(location.search);
  const { pkLicense } = match.params;
  const { cycle: cycleId = 0 } = query;
  const transcriptDetail = transcript.getEntityById(`${pkLicense}-${cycleId}`).transcriptDetail;
  return {
    showCourseTypeColumn: transcriptDetail.isShowCreditCourseType,
    scenarioType: transcriptDetail.licensePeriod.scenario.type,
    isViewOnlyCompleted: transcriptDetail.isViewOnlyCompleted,
  };
}

export default withRouter(connect(mapStateToProps, {})(CourseHistory));
