import { put, call, select } from 'redux-saga/effects';
import selectors from '../selectors';
import * as api from '../../../api/dashboardCards';
import at from '../types';

export default function* deleteHeartcard({ heartcardId, params = {} }) {
  try {
    yield call(api.deleteHeartcard, heartcardId, params);
    const state = yield select();
    const heartcardSelector = selectors(state);
    const heartcard = heartcardSelector.getEntityByHeartcardId(heartcardId);
    yield put({
      type: at.DELETE_HEARTCARD_SUCCESS,
      heartcardId,
      heartcard,
      params,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    yield put({ type: at.DELETE_HEARTCARD_FAILURE, heartcardId, params });
  }
}
