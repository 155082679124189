export default {
  FETCH_REQUEST: 'communications/FETCH_REQUEST',

  //FETCH REQUEST TYPE
  FETCH_REQUEST_SUCCESS: 'communications/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_FAILURE: 'communications/FETCH_REQUEST_FAILURE',

  //FETCH RESPONSE TYPE
  FETCH_RESPONSE_SUCCESS: 'communications/FETCH_RESPONSE_SUCCESS',
  FETCH_RESPONSE_FAILURE: 'communications/FETCH_RESPONSE_FAILURE',

  // FETCH DETAIL
  FETCH_DETAIL_REQUEST: 'communications/FETCH_DETAIL_REQUEST',
  FETCH_DETAIL_SUCCESS: 'communications/FETCH_DETAIL_SUCCESS',
  FETCH_DETAIL_FAILURE: 'communications/FETCH_DETAIL_FAILURE',

  // SAVE_REQUEST_RESPONSE
  SAVE_REQUEST_RESPONSE_REQUEST: 'communications/SAVE_REQUEST_RESPONSE_REQUEST',
  SAVE_REQUEST_RESPONSE_SUCCESS: 'communications/SAVE_REQUEST_RESPONSE_SUCCESS',
  SAVE_REQUEST_RESPONSE_FAILURE: 'communications/SAVE_REQUEST_RESPONSE_FAILURE',

  FETCH_AUDIT_REQUEST: 'communications/FETCH_AUDIT_REQUEST',

  //FETCH AUDIT REQUEST TYPE
  FETCH_AUDIT_REQUEST_SUCCESS: 'communications/FETCH_AUDIT_REQUEST_SUCCESS',
  FETCH_AUDIT_REQUEST_FAILURE: 'communications/FETCH_AUDIT_REQUEST_FAILURE',

  //FETCH AUDIT RESPONSE TYPE
  FETCH_AUDIT_RESPONSE_SUCCESS: 'communications/FETCH_AUDIT_RESPONSE_SUCCESS',
  FETCH_AUDIT_RESPONSE_FAILURE: 'communications/FETCH_AUDIT_RESPONSE_FAILURE',

  // SAVE AUDIT RESPONSE
  SAVE_AUDIT_RESPONSE_REQUEST: 'communications/SAVE_AUDIT_RESPONSE_REQUEST',
  SAVE_AUDIT_RESPONSE_SUCCESS: 'communications/SAVE_AUDIT_RESPONSE_SUCCESS',
  SAVE_AUDIT_RESPONSE_FAILURE: 'communications/SAVE_AUDIT_RESPONSE_FAILURE',

  CLEAR_AUDIT_ENTITIES: 'communications/CLEAR_AUDIT_ENTITIES',

  // OTHER TYPES
  SET_PAGE_FETCH_STATUS: 'communications/SET_PAGE_FETCH_STATUS',
  CLEAR_ENTITIES: 'communications/CLEAR_ENTITIES',
};
