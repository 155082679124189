import { takeLatest } from 'redux-saga/effects';

import at from '../types';
import fetch from './fetch';
import fetchEmailSettings from './fetchEmailSettings';
import update from './update';
import updateEmailSettings from './updateEmailSettings';
import fetchConciergeManager from './fetchConciergeManager';
import fetchCommunicationSettings from './fetchCommunicationSettings';
import updateCommunicationSettings from './updateCommunicationSettings';
import updateUsername from './updateUsername';
import updatePassword from './updatePassword';
import fetchUserFlag from './fetchUserFlag';

export default function* root() {
  yield takeLatest(at.FETCH_REQUEST, fetch);
  yield takeLatest(at.UPDATE_REQUEST, update);
  yield takeLatest(at.FETCH_EMAIL_SETTINGS_REQUEST, fetchEmailSettings);
  yield takeLatest(at.UPDATE_EMAIL_SETTINGS_REQUEST, updateEmailSettings);
  yield takeLatest(at.FETCH_CONCIERGE_MANAGER_REQUEST, fetchConciergeManager);
  yield takeLatest(at.FETCH_COMMUNICATION_SETTINGS_REQUEST, fetchCommunicationSettings);
  yield takeLatest(at.UPDATE_COMMUNICATION_SETTINGS_REQUEST, updateCommunicationSettings);
  yield takeLatest(at.UPDATE_USERNAME_REQUEST, updateUsername);
  yield takeLatest(at.UPDATE_PASSWORD_REQUEST, updatePassword);
  yield takeLatest(at.FETCH_USER_FLAG_REQUEST, fetchUserFlag);
}
