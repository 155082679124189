import React from 'react';
import { Progressbar } from 'emerald-ui/lib';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.css';

const ComplianceProgress = ({ progress, noCERequired, ...rest }) => {
  let newProgress = progress;
  if (noCERequired) {
    newProgress = null;
  }

  return (
    <div
      {...rest}
      className={classNames('compliance-progress', {
        'compliance-progress-complete': newProgress === 100,
        'compliance-progress-no-ce': noCERequired,
      })}
    >
      {newProgress || newProgress === 0 ? (
        <span className="label">{newProgress === 100 ? 'COMPLETE' : 'NOT COMPLETE'}</span>
      ) : (
        ''
      )}
      {newProgress || newProgress === 0 ? <Progressbar progress={newProgress} /> : ''}
      {newProgress || newProgress === 0 ? <span className="percentage">{`${newProgress}%`}</span> : ''}
    </div>
  );
};

ComplianceProgress.propTypes = {
  progress: PropTypes.number,
  noCERequired: PropTypes.bool,
};

ComplianceProgress.defaultProps = {
  progress: null,
};

export default ComplianceProgress;
