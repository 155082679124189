import React from 'react';
import PropTypes from 'prop-types';
import { Popover, OverlayTrigger } from 'emerald-ui/lib';
import { titleCase } from '../../utils';
import classnames from 'classnames';

const NameColumn = ({ processError, name, additionalItemMessage, dateCompleted, showWarning }) => (
  <div className="course-history-name-wrapper">
    {processError.length > 0 ? (
      <OverlayTrigger
        delay={300}
        triggerType="hover"
        placement="right"
        overlay={
          <Popover id="filterForm" className="course-history-list-warning-popover">
            {processError.map((e, i) => (
              <p key={`process-error-${i}`}>{e}</p>
            ))}
          </Popover>
        }
      >
        <i className="material-icons text-danger warning-icon">warning</i>
      </OverlayTrigger>
    ) : (
      <span className={classnames({ 'show-warning': showWarning, 'no-warning': !showWarning })} />
    )}
    <div className="course-history-submitted-wrapper">
      <p className="course-history-name text-light">
        {titleCase(name)}
        {additionalItemMessage && <em>{additionalItemMessage}</em>}
      </p>
      <span className="course-history-date-completed text-lighter">Completed {dateCompleted}</span>
    </div>
  </div>
);

NameColumn.propTypes = {
  processError: PropTypes.array,
  name: PropTypes.string,
  additionalItemMessage: PropTypes.string,
  dateCompleted: PropTypes.string,
  showWarning: PropTypes.bool,
};

export default NameColumn;
