import { put, call, select } from 'redux-saga/effects';

import * as api from '../../../api/cart';
import at from '../types';
import { getShoppingCartData, deleteCookie } from '../../../utils';

const { REACT_APP_SHOPPING_CART_COOKIE_KEY: shoppingCartCookieKey } = process.env;

export function* fetchCart() {
  const state = yield select();
  const {
    authentication: { licenseeAccount },
  } = state;
  const { user = {}, autoRenewSettings } = licenseeAccount;
  const { ownerId, id: userId } = user;
  yield put({ type: at.FETCH_CART_REQUEST });

  try {
    const { courses = [], sessionUuid: currentSessionUuid = null } = getShoppingCartData(shoppingCartCookieKey);
    const { customerId: stripeCustomerId } = autoRenewSettings;
    const {
      data: { courses: cart, sessionUuid, totalItems: currentTotalItems, subscription: currentSubscriptionItemData },
    } = yield call(api.updateCartByUser, userId, { sessionUuid: currentSessionUuid, ownerId, stripeCustomerId });

    let currentCourses = cart;
    let totalItems = currentTotalItems;
    let subscription = currentSubscriptionItemData;
    let actionType = at.FETCH_CART_SUCCESS;
    const shoppingCartCourses = courses.map(({ added, ...course }) => course);

    if (shoppingCartCourses && shoppingCartCourses.length) {
      const {
        data: { courses: updatedCourses, totalItems: newTotalItems, subscription: subscriptionItemData },
      } = yield call(api.saveCartItem, { userId, data: { courses: shoppingCartCourses, sessionUuid } });
      currentCourses = updatedCourses;
      totalItems = newTotalItems;
      subscription = subscriptionItemData;
      actionType = at.SAVE_CART_ITEM_SUCCESS;
      deleteCookie(shoppingCartCookieKey, 'cebroker.com');
    }

    yield put({
      type: actionType,
      userId,
      entity: currentCourses,
      sessionUuid,
      totalItems,
      subscription,
    });
  } catch (error) {
    yield put({
      type: at.FETCH_CART_FAILURE,
      userId,
      error: error.message,
    });
  }
}
