import React from 'react';
import Panel from 'emerald-ui/lib/Panel';
import Sun from './Sun.svg';
import './styles.css';

const NoCERequired = () => {
  return (
    <div className="container no-ce-required">
      <Panel className="no-ce-required-panel">
        <div className="img">
          <img src={Sun} alt="No CE Required" />
        </div>
        <div className="content">
          <div className="title">
            <h3>No CE requirements</h3>
          </div>
          <div className="text">
            <p>You have no continuing education requirements for this reporting period.</p>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default NoCERequired;
