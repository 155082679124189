import { takeEvery } from 'redux-saga/effects';

import at from '../types';
import { fetchCart } from './fetchCart';
import { saveCartItem } from './saveCartItem';
import { removeCartItem } from './removeCartItem';

export default function* root() {
  yield takeEvery(at.FETCH_CART, fetchCart);
  yield takeEvery(at.SAVE_CART_ITEM, saveCartItem);
  yield takeEvery(at.DELETE_CART_ITEM, removeCartItem);
}
