import at from './types';

export const fetch = () => ({
  type: at.FETCH_REQUEST,
});

export const changeVisibily = (params) => ({
  type: at.CHANGE_BANNER_VISIBILITY,
  params,
});
