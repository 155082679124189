import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RenewalRequirementBanner from './RenewalRequirementBanner';
import * as authenticationStore from '../../store/authentication';
import * as transcriptStore from '../../store/transcript';
import queryString from 'query-string';
import { removeUrlProtocol } from '../../utils';

function mapStateToProps(state, ownProps) {
  const authenticationSelectors = authenticationStore.selectors(state);
  const transcriptSelectors = transcriptStore.selectors(state);
  const { match, location } = ownProps;
  const query = queryString.parse(location.search);
  const { pkLicense } = match.params;
  const licenseAccount = authenticationSelectors.getLicenseeAccount();
  const { user = {}, licenses = [], subscriptions = [], requestedLicenses = [] } = licenseAccount;
  const license = licenses.find((l) => l.id === parseInt(pkLicense, 10)) || {};
  const { profession = {} } = license;
  const { board = {} } = profession;
  let { renewalRequirementsLink = '' } = board;
  if (renewalRequirementsLink && typeof renewalRequirementsLink === 'string') {
    renewalRequirementsLink = removeUrlProtocol(renewalRequirementsLink);
  }
  const { cycle: cycleId = 0 } = query;
  const currentId = `${pkLicense}-${cycleId}`;
  const transcript = transcriptSelectors.getEntityById(currentId);
  const { transcriptDetail = {} } = transcript;
  const { status: complianceStatus } = transcriptDetail;
  const transcriptStatus = transcriptSelectors.getStatusById(currentId);

  const isFTOAvailable = subscriptions[0] && subscriptions[0].isftoAvailable ? subscriptions[0].isftoAvailable : false;
  const upgradeLink = isFTOAvailable ? `/upgrade/payment?upgradeTo=fto&user_id=${user.id}` : '/upgrade';

  const selectedRequestedLicense = requestedLicenses.find((l) => l.id.toString() === pkLicense);
  const isRequestedLicense = !!selectedRequestedLicense;

  return {
    isFTOAvailable,
    renewalRequirementsLink,
    complianceStatus,
    transcriptStatus,
    upgradeLink,
    licenseLabel: isRequestedLicense ? selectedRequestedLicense.profession.licenseLabel : profession.licenseLabel,
  };
}

export default withRouter(connect(mapStateToProps)(RenewalRequirementBanner));
