// URLs
export const legacyUrl = 'http://dev.secure.cebroker.com';
export const storageUrl = 'https://test.storage.cebroker.com';
export const launchpadUrl = 'http://dev.launchpad.cebroker.com';
export const urlBase = '/';

export const licenseesApiInternal = 'http://licensees.dev.cebroker.com:3001/api';

export const licenseesUrl = 'http://licensees.dev.cebroker.com:3000';

export const cebHome = 'http://dev.cebroker.com';
export const conditionsUrl = 'https://cebroker.com/terms';
export const privacyUrl = 'https://cebroker.com/privacy';

export const courseSearchUrl = 'http://courses.dev.cebroker.com:4000';
export const courseSearchApiUrl = 'http://courses.dev.cebroker.com:4001/api';

export const signInUrl = 'http://dev.launchpad.cebroker.com';
export const lmsUrl = 'https://learning.test.cebroker.com';

export const callToll = '1-877-434-6323';

export const lmsUrlAcademyImplementation = 'https://google.com';

export const helpWidgetUrl = 'https://help.cebroker.com';

// Cookies
export const secureCookie = 'dev_cebtoken';
export const licenseeTokenCookie = 'dev_licensee_token';
export const licenseeAuthInfoCookie = 'dev_licensee_auth_info';

// Export all
export default {
  legacyUrl,
  storageUrl,
  launchpadUrl,
  urlBase,
  licenseesApiInternal,
  secureCookie,
  courseSearchUrl,
  courseSearchApiUrl,
  licenseesUrl,
  cebHome,
  helpWidgetUrl,
  conditionsUrl,
  privacyUrl,
  signInUrl,
  lmsUrl,
  callToll,
  lmsUrlAcademyImplementation,
  licenseeTokenCookie,
  licenseeAuthInfoCookie,
};
