import axios from 'axios';
import config from '../config';
const { licenseesApiInternal } = config;

export const fetch = (userId, filters) => {
  const { page, size, ...rest } = filters;
  const query = {
    pageNumber: page,
    pageSize: size,
    ...rest,
  };

  return axios.request({
    url: `${licenseesApiInternal}/communications/${userId}/notifications`,
    params: query,
    headers: {},
    method: 'GET',
  });
};

export const archive = (userLogonId, inboxIds) => {
  return axios.request({
    url: `${licenseesApiInternal}/communications/${userLogonId}/notifications`,
    headers: {},
    method: 'PUT',
    data: {
      inboxIds,
    },
  });
};

export const deleteNotifications = (userLogonId, inboxIds, inboxType) => {
  return axios.request({
    url: `${licenseesApiInternal}/communications/${userLogonId}/notifications`,
    headers: {},
    method: 'DELETE',
    data: {
      inboxIds,
      inboxType,
    },
  });
};
