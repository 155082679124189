export default {
  FETCH_PAYMENT_INFO_REQUEST: 'subscription/FETCH_PAYMENT_INFO_REQUEST',
  FETCH_PAYMENT_INFO_SUCCESS: 'subscription/FETCH_PAYMENT_INFO_SUCCESS',
  FETCH_PAYMENT_INFO_FAILURE: 'subscription/FETCH_PAYMENT_INFO_FAILURE',
  UPDATE_PAYMENT_INFO_REQUEST: 'subscription/UPDATE_PAYMENT_INFO_REQUEST',
  UPDATE_PAYMENT_INFO_SUCCESS: 'subscription/UPDATE_PAYMENT_INFO_SUCCESS',
  UPDATE_PAYMENT_INFO_FAILURE: 'subscription/UPDATE_PAYMENT_INFO_FAILURE',
  FETCH_RENEW_FEE_REQUEST: 'subscription/FETCH_RENEW_FEE_REQUEST',
  FETCH_RENEW_FEE_SUCCESS: 'subscription/FETCH_RENEW_FEE_SUCCESS',
  FETCH_RENEW_FEE_FAILURE: 'subscription/FETCH_RENEW_FEE_FAILURE',
  FETCH_ACTIVE_SUBSCRIPTION_REQUEST: 'subscription/FETCH_ACTIVE_SUBSCRIPTION_REQUEST',
  FETCH_ACTIVE_SUBSCRIPTION_SUCCESS: 'subscription/FETCH_ACTIVE_SUBSCRIPTION_SUCCESS',
  FETCH_ACTIVE_SUBSCRIPTION_FAILURE: 'subscription/FETCH_ACTIVE_SUBSCRIPTION_FAILURE',
  CANCEL_SUBSCRIPTION_REQUEST: 'subscription/CANCEL_SUBSCRIPTION_REQUEST',
  CANCEL_SUBSCRIPTION_SUCCESS: 'subscription/CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_FAILURE: 'subscription/CANCEL_SUBSCRIPTION_FAILURE',
  DOWNGRADE_ACCOUNT_REQUEST: 'subscription/DOWNGRADE_ACCOUNT_REQUEST',
  DOWNGRADE_ACCOUNT_SUCCESS: 'subscription/DOWNGRADE_ACCOUNT_SUCCESS',
  DOWNGRADE_ACCOUNT_FAILURE: 'subscription/DOWNGRADE_ACCOUNT_FAILURE',
  FETCH_LAST_SUBSCRIPTION_REQUEST: 'subscription/FETCH_LAST_SUBSCRIPTION_REQUEST',
  FETCH_LAST_SUBSCRIPTION_SUCCESS: 'subscription/FETCH_LAST_SUBSCRIPTION_SUCCESS',
  FETCH_LAST_SUBSCRIPTION_FAILURE: 'subscription/FETCH_LAST_SUBSCRIPTION_FAILURE',
};
