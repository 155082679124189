import at from './types';

export const fetch = (params, meta) => ({
  type: params.courseStatus === 'progress' ? at.FETCH_IN_PROGRESS_REQUEST : at.FETCH_COMPLETED_REQUEST,
  params,
  meta,
});

export const fetchInProgressCourses = (params) => ({
  type: at.FETCH_IN_PROGRESS_LIST_REQUEST,
  params,
});
