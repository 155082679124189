const queryString = require('query-string');

const { REACT_APP_ENV = 'development' } = process.env;
const config = require(`./${REACT_APP_ENV}.config.js`);
const {
  legacyUrl,
  storageUrl,
  launchpadUrl,
  urlBase,
  licenseesApiInternal,
  secureCookie,
  courseSearchUrl,
  courseSearchApiUrl,
  licenseesUrl,
  cebHome,
  helpWidgetUrl,
  conditionsUrl,
  privacyUrl,
  signInUrl,
  lmsUrl,
  callToll,
  lmsUrlAcademyImplementation,
  licenseeTokenCookie,
  licenseeAuthInfoCookie,
} = config;

const getCourseSearchUrl = (params = {}) => {
  const query = queryString.stringify(params);
  return `${courseSearchUrl}/login?${query}`;
};

const getLogoutUrl = () => {
  return `${legacyUrl}/public/pb_auth_gateway.aspx?action=logout`;
};

const legacyAccountSettingsUrl = (accountType) => {
  if (accountType === 'BAS') {
    return `${legacyUrl}/limited/lm_my_profile_dtl.aspx`;
  }
  return `${legacyUrl}/licensee/lc_my_profile_dtl.aspx`;
};

const legacyDashboardUrl = (accountType) => {
  if (accountType === 'BAS') {
    return `${legacyUrl}/limited/lm_dashboard.aspx`;
  }
  return `${legacyUrl}/licensee/lc_dashboard.aspx`;
};

const legacyReportCEUrl = (accountType, pkLicense = 0) => {
  const licParam = pkLicense > 0 ? `?lic=${pkLicense}` : '';
  if (accountType === 'BAS') {
    return `${legacyUrl}/limited/lm_ce_request_lst_v2.aspx${licParam}`;
  }
  return `${legacyUrl}/licensee/lc_ce_request_lst_v2.aspx${licParam}`;
};

const openDownloadCertificate = (certificateId) => {
  const features = `left=0,top=0,width=200,height=300,copyhistory=0,location=0,menubar=0,directories=0,resizable=0,scrollbars=0,status=0,toolbar=0,hotkeys=0`;
  const dURL = `${legacyUrl}/common/c_download_certificate_popup.asp`;
  const requestURL = `${legacyUrl}/licensee/lc_generate_certificate.aspx`;
  const URL = `${dURL}?hGenerate=1?hRequestURL=${requestURL}?hIdSequenceCertificate=${certificateId}`;
  window.open(URL, 'dCertificate', features);
};

const supportData = {
  phone: '1-877-434-6323',
  email: 'support@cebroker.com',
  url: 'https://help.cebroker.com/',
};

const INPUT_FORMAT_DATE = 'YYYY-MM-DD';

const baseLicenseeCookie = 'licensee_token';
const baselicenseeAuthCookie = 'licensee_auth_info';

const licenseesCookies = [
  `dev_${baseLicenseeCookie}`,
  `dev_${baselicenseeAuthCookie}`,
  `test_${baseLicenseeCookie}`,
  `test_${baselicenseeAuthCookie}`,
  `demo_${baseLicenseeCookie}`,
  `demo_${baselicenseeAuthCookie}`,
  baseLicenseeCookie,
  baselicenseeAuthCookie,
];

const downloadPdfStatuses = {
  initial: 'PENDING',
  processing: 'PROCESSING',
  finished: 'DONE',
  downloaded: 'COMPLETED',
};

const AnalyticsTrackingEntities = {
  categories: {
    upgrade: 'Upgrade_cta',
  },
  actions: {
    sectionTopNavUpgrade: 'Top_nav_upgrade',
    sectionBannerAdUpgrade: 'Banner_ad_upgrade',
    sectionComplianceStatusUpgrade: 'Compliance_status_upgrade',
    sectionBoardTextLink: 'Board_text_link',
    sectionUpgradeTextLink: 'Upgrade_text_link',
    sectionTranscriptWidgetUpgrade: 'Transcript_widget_upgrade',
    sectionTranscriptUpgrade: 'Transcript_upgrade',
    sectionRenewSubsTopNav: 'Renew_subscription_top_nav',
    sectionUpgradeLinkMenu: 'Settings_dropdown_upgrade',

    accountOptionPRO: 'Account_option_professional',
    accountOptionCON: 'Account_option_concierge',
    accountOptionPRO_PLUS: 'Account_option_professional_plus',
    accountOptionCON_PLUS: 'Account_option_concierge_plus',

    usePromoCodeApplied: 'Promo_apply',
    usePromoCodeFailed: 'Promo_fail',

    clickUpgrade: 'Checkout_click',
    upgradeSuccess: 'Checkout_success',
    upgradeFailed: 'Checkout_fail',
  },
  labels: {
    basicWithFreeTrial: 'Bas_withfto',
    basicNoFreeTrial: 'Bas_nofto',
    FreeTrial: 'Fto',
    Professional: 'Pro',
    Concierge: 'Con',
    Employee: 'Emp',
    ProfessionalPlus: 'Pro_Plus',
    ConciergePlus: 'Con_Plus',
  },
};

const carouselSettings = {
  itemPerSlide: 4,
  breakPoints: [
    { width: 1, itemsToShow: 1.5, itemsToScroll: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 850, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1450, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1750, itemsToShow: 4, itemsToScroll: 4 },
  ],
  loaderItems: [1, 2, 3, 4],
};

const discoverInitialStates = {
  settingsCE: {
    sections: [],
    settingsCEStatus: 'INITIAL',
  },
  licenseToggleId: '',
  spotlight: {
    spotlightData: [],
    spotlightStatus: 'INITIAL',
  },
  popularCE: {
    popularCECourses: [],
    popularCEStatus: 'INITIAL',
  },
  keepLearningCE: {
    keepLearningCECourses: [],
    keepLearningCEStatus: 'INITIAL',
  },
  recentlyAddedCE: {
    recentlyAddedCECourses: [],
    recentlyAddedCEStatus: 'INITIAL',
  },
};

const addLicenseSection = {
  dateOfBirthField: {
    label: 'Date of Birth',
    placeHolder: 'MM/DD/YYYY',
    format: 'MM/DD/YYYY',
    formatDatePicker: 'MM/dd/yyyy',
  },
  lastNameField: {
    label: 'Last Name',
    placeHolder: 'Last Name',
  },
  licenseNumberField: {
    label: 'NBCOT Member number',
    placeHolder: 'ex: 999999',
  },
  usePollingStrategy: true,
};

const Tabs = Object.freeze({
  COURSES: 'courses',
  EXEMPTIONS: 'exemptions',
  REMOVED: 'removed',
});

const defaultSubscriptionPrices = {
  pro: '39.99',
  con: '124.99',
};

const USCountryOption = {
  name: 'United States',
  code: 'US',
  id: 1,
};

const OutUSCountryOption = {
  name: 'Outside of United States',
  code: 'XX',
  id: 0,
};

function isValidZipCode(code) {
  const zipCodeRegex = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$');
  return zipCodeRegex.test(code);
}

const GTM_EVENTS = {
  COURSE_PURCHASES: 'course-purchases',
  LICENSEE_UPGRADE: 'licensee-upgrade',
  MKT_START_COURSE_POPOVER: 'mkt_start_course_popover',
  MKT_ADD_TO_CART_POPOVER: 'mkt_add_to_cart_popover',
  MKT_ADD_TO_FAVORITES_POPOVER: 'mkt_add_to_favorites_popover',
};

const SUBSCRIPTION_TYPES = {
  BAS: 'BAS',
  FTO: 'FTO',
  PRO: 'PRO',
  EMP: 'EMP',
  PRO_PLUS: 'PRO_PLUS',
  CON: 'CON',
  CON_PLUS: 'CON_PLUS',
};

export {
  supportData,
  legacyUrl,
  storageUrl,
  launchpadUrl,
  urlBase,
  licenseesApiInternal,
  secureCookie,
  courseSearchUrl,
  courseSearchApiUrl,
  licenseesUrl,
  cebHome,
  helpWidgetUrl,
  conditionsUrl,
  privacyUrl,
  signInUrl,
  lmsUrl,
  callToll,
  lmsUrlAcademyImplementation,
  licenseeTokenCookie,
  licenseeAuthInfoCookie,
  legacyAccountSettingsUrl,
  legacyDashboardUrl,
  legacyReportCEUrl,
  getCourseSearchUrl,
  getLogoutUrl,
  openDownloadCertificate,
  INPUT_FORMAT_DATE,
  licenseesCookies,
  downloadPdfStatuses,
  AnalyticsTrackingEntities,
  carouselSettings,
  discoverInitialStates,
  addLicenseSection,
  Tabs,
  defaultSubscriptionPrices,
  USCountryOption,
  OutUSCountryOption,
  isValidZipCode,
  GTM_EVENTS,
  SUBSCRIPTION_TYPES,
};

export default {
  supportData,
  legacyUrl,
  storageUrl,
  launchpadUrl,
  urlBase,
  licenseesApiInternal,
  secureCookie,
  courseSearchUrl,
  courseSearchApiUrl,
  licenseesUrl,
  cebHome,
  helpWidgetUrl,
  conditionsUrl,
  privacyUrl,
  signInUrl,
  lmsUrl,
  callToll,
  lmsUrlAcademyImplementation,
  licenseeTokenCookie,
  licenseeAuthInfoCookie,
  legacyAccountSettingsUrl,
  legacyDashboardUrl,
  legacyReportCEUrl,
  getCourseSearchUrl,
  getLogoutUrl,
  openDownloadCertificate,
  INPUT_FORMAT_DATE,
  licenseesCookies,
  downloadPdfStatuses,
  AnalyticsTrackingEntities,
  carouselSettings,
  discoverInitialStates,
  addLicenseSection,
  Tabs,
  defaultSubscriptionPrices,
  USCountryOption,
  OutUSCountryOption,
  isValidZipCode,
  GTM_EVENTS,
  SUBSCRIPTION_TYPES,
};
