import { put, call, select } from 'redux-saga/effects';
import * as api from '../../../api/payments';
import at from '../types';

export default function* fetchHasActiveSubscription({ meta = {} }) {
  const state = yield select();
  const user = state.authentication.licenseeAccount.user;
  try {
    const { data } = yield call(api.getHasActiveSubscription, user.id);
    yield put({
      type: at.FETCH_ACTIVE_SUBSCRIPTION_SUCCESS,
      userId: user.id,
      data,
      meta,
    });
  } catch (error) {
    console.error(error); //eslint-disable-line
    yield put({
      type: at.FETCH_ACTIVE_SUBSCRIPTION_FAILURE,
      userId: user.id,
      meta,
    });
  } finally {
    //eslint-disable-line
  }
}
