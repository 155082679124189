import { call, put, select } from 'redux-saga/effects';

import * as api from '../../../api/transcript';
import at from '../actions/types';

export default function* fetch({ params }) {
  const state = yield select();
  const { licenseeAccount, token } = state.authentication;
  params.authToken = token.token;
  const { user, licenses } = licenseeAccount;
  // We started using the licensee userId instead of the loggedUserId
  params.userId = user.id;
  const cycleId = params.cycleId ? params.cycleId : 0;

  const license = licenses.find((l) => l.id === parseInt(params.pkLicense, 10));

  if (!params.licenseNumber) {
    params.licenseNumber = license.number;
  }

  try {
    const transcript = yield call(api.fetchTranscript, params);
    if (!transcript.data) {
      yield put({ type: at.FETCH_FAILURE, params, cycleId });
    }
    yield put({
      type: at.FETCH_SUCCESS,
      data: transcript.data,
      cycleId,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
    if (error.response.status === 401) {
      yield fetch();
    } else {
      yield put({ type: at.FETCH_FAILURE, params, cycleId });
    }
  }
}
