import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'emerald-ui/lib';

const QuestionSection = ({ questions = [] }) => {
  return (
    <Row id="ce-questions" className="section-row">
      <Col xs={12}>
        <h4 className="course-history-detail-header">Questions</h4>
        {questions.map((q) => (
          <Row key={q.id}>
            <Col xs={12} className="section-col">
              <p className="course-detail-subheader">
                <span className="text-semibold">Q:</span>
                <span>{q.question}</span>
              </p>
              <p className="course-detail-answers">
                <span className="text-semibold">A:</span>
                <span>{q.answerText}</span>
              </p>
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  );
};

QuestionSection.propTypes = {
  questions: PropTypes.array,
};

export default QuestionSection;
