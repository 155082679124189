import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SettingsHeader from '../SettingsHeader/SettingsHeaderLoadingContainer';
import SettingsProfile from '../SettingsProfile/SettingsProfileLoading';
import { Route } from 'react-router-dom';
import './SettingsPage.css';

class SettingsPage extends Component {
  render() {
    const { subscriptionType, professionBrandColor } = this.props;
    return (
      <div id="settings-page">
        <SettingsHeader subscriptionType={subscriptionType} professionBrandColor={professionBrandColor} />
        <div className="container">
          <Route path="/settings/profile" component={SettingsProfile} />
        </div>
      </div>
    );
  }
}

SettingsPage.propTypes = {
  subscriptionType: PropTypes.string,
  professionBrandColor: PropTypes.string,
};

SettingsPage.defaultProps = {
  subscriptionType: 'BAS',
};

export default SettingsPage;
