import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AddHeartcard from '../components/AddHeartcard';
import * as store from '../store/dashboardCards';
import * as authenticationStore from '../store/authentication';

function mapStateToProps(state) {
  const authenticationSelectors = authenticationStore.selectors(state);
  const licenseeAccount = authenticationSelectors.getLicenseeAccount();
  const { user, subscriptions } = licenseeAccount;
  const addHeartcardSelectors = store.selectors(state);
  const authenticatedAccount = authenticationSelectors.getAuthenticatedAccount();
  const loggedOnBehalf = authenticatedAccount.role === 'helpdesk';
  let subscriptionType = '';
  let isftoAvailable = false;
  if (subscriptions && subscriptions.length > 0) {
    subscriptionType = subscriptions[0].type;
    isftoAvailable = subscriptions[0].isftoAvailable;
  }
  const addHeartcardStatus = addHeartcardSelectors.getAddHeartcardStatus();
  return {
    user,
    authenticatedUser: authenticatedAccount,
    loggedOnBehalf,
    subscriptionType,
    isftoAvailable,
    addHeartcardStatus,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    addHeartcard: store.actions.addHeartcard,
    fetchHeartCards: store.actions.fetch,
  })(AddHeartcard)
);
