import { connect } from 'react-redux';
import AssociationReturnButton from './AssociationReturnButton';

import * as authenticationStore from '../../store/authentication';

function mapStateToProps(state) {
  const authenticationSelectors = authenticationStore.selectors(state);
  const licenseeAccount = authenticationSelectors.getLicenseeAccount();
  const authenticatedAccount = authenticationSelectors.getAuthenticatedAccount();

  const { associationUsersInfo = {}, licenses = [] } = licenseeAccount;

  const licenseWithBrandColor = licenses.find((l) => l.profession.color);
  const professionBrandColor = licenseWithBrandColor ? licenseWithBrandColor.profession.color : '';

  return {
    associationUsersInfo,
    professionBrandColor,
    loggedOnBehalf: authenticatedAccount.role === 'helpdesk',
  };
}

export default connect(mapStateToProps, {
  logout: authenticationStore.actions.logout,
})(AssociationReturnButton);
