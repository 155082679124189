import axios from 'axios';

import config from '../config';
import { retryAsyncFunction } from '../utils';

const { licenseesApiInternal } = config;

export const joinLicense = (params) => {
  return axios.request({
    method: 'post',
    url: `${licenseesApiInternal}/licenses/join`,
    data: params,
  });
};

export const availableLicense = ({ subscriptionType, licenseNumber, state }) => {
  const evaluateSearchLicense = async () => {
    return await axios({
      method: 'get',
      url: `${licenseesApiInternal}/licenses/available?type=${subscriptionType}&licenseNumber=${licenseNumber}&state=${state}`,
    });
  };
  return retryAsyncFunction(evaluateSearchLicense);
};

export const saveExternalLicense = async (fieldsValues) => {
  return axios.request({
    method: 'post',
    url: `${licenseesApiInternal}/licenses/save-license`,
    data: fieldsValues,
  });
};
