import './ProPlusBadge.css';
import { OverlayTrigger, Popover } from 'emerald-ui-3';

const ProPlusBadge = ({ showCompleteText = false, showPopover }) => {
  return (
    <OverlayTrigger
      className="pro-plus-overlay-trigger"
      placement="bottom"
      overlay={
        <Popover style={{ maxWidth: '211px' }}>
          <p>Courses with this badge are included with a Pro+ subscription.</p>
          <a href="/upgrade" className="text-semibold">
            Learn more
          </a>
        </Popover>
      }
    >
      {showCompleteText ? (
        <span className={`badge-wrapper ${showPopover ? 'clickable' : ''}`}>
          {'Included with a '}
          <span className="badge-wrapper-logo">
            {'Pro'}
            <span>{'+'}</span>
          </span>
          {'  subscription'}
        </span>
      ) : (
        <span className={`badge-wrapper-short ${showPopover ? 'clickable' : ''}`}>
          {'Included with '}
          <span className="badge-wrapper-logo">
            {'Pro'}
            <span>{'+'}</span>
          </span>
        </span>
      )}
    </OverlayTrigger>
  );
};

export default ProPlusBadge;
