export default {
  CLEAR: 'authentication/CLEAR',
  SET_TOKEN: 'authentication/SET_TOKEN',
  SET_LEGACY_TOKEN: 'authentication/SET_LEGACY_TOKEN',
  SET_STATUS: 'authentication/SET_STATUS',
  SET_AUTHENTICATED_ACCOUNT: 'authentication/SET_AUTHENTICATED_ACCOUNT',
  SET_LICENSEE_ACCOUNT: 'authentication/SET_LICENSEE_ACCOUNT',

  LOGOUT: 'authentication/LOGOUT',
};
