// URLs
export const legacyUrl = 'https://demo.secure.cebroker.com';
export const storageUrl = 'https://test.storage.cebroker.com';
export const launchpadUrl = 'https://demo.launchpad.cebroker.com';
export const urlBase = '/';
export const licenseesApiInternal = 'https://licensees.demo.cebroker.com/api';

export const licenseesUrl = 'https://licensees.demo.cebroker.com';

export const cebHome = 'https://demo.cebroker.com';
export const conditionsUrl = 'https://demo.cebroker.com/terms';
export const privacyUrl = 'https://demo.cebroker.com/privacy';

export const courseSearchUrl = 'https://courses.demo.cebroker.com';
export const courseSearchApiUrl = 'https://courses.demo.cebroker.com/api';
export const signInUrl = 'https://demo.launchpad.cebroker.com/login';
export const lmsUrl = 'https://learning.demo.cebroker.com/';
export const callToll = '1-877-434-6323';
export const lmsUrlAcademyImplementation = 'https://google.com';

export const helpWidgetUrl = 'https://help.cebroker.com';

// Cookies
export const secureCookie = 'demo_cebtoken';
export const licenseeTokenCookie = 'demo_licensee_token';
export const licenseeAuthInfoCookie = 'demo_licensee_auth_info';

// Export all
export default {
  legacyUrl,
  storageUrl,
  launchpadUrl,
  urlBase,
  licenseesApiInternal,
  secureCookie,
  courseSearchUrl,
  courseSearchApiUrl,
  licenseesUrl,
  cebHome,
  helpWidgetUrl,
  conditionsUrl,
  privacyUrl,
  signInUrl,
  lmsUrl,
  callToll,
  lmsUrlAcademyImplementation,
  licenseeTokenCookie,
  licenseeAuthInfoCookie,
};
