import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Alert from 'emerald-ui/lib/Alert';

import BasicInfo from './BasicInfo';
import RequirementsList from './RequirementsList';
import NoCERequired from './NoCERequired';
import LoadingState from './LoadingState';
import CycleSelector from './CycleSelector';

import './TranscriptPage.css';

import docDefinition from './docDefinition';

const dashboardUrl = '/dashboard';

class TranscriptPage extends Component {
  state = {
    selectedPeriod: {
      text: '...loading',
      id: null,
    },
    licenseInfo: {
      number: '',
      stateCode: '',
      professionId: 0,
    },
  };

  componentDidMount() {
    const {
      fetchTranscript,
      location = {},
      match = {},
      subscriptionType,
      transcriptStatus,
      transcript: { licenseCycles: licensePeriodItems = [] } = {},
      currentLicense,
      isAuditTranscript,
    } = this.props;
    if ((!subscriptionType || subscriptionType === 'BAS') && !isAuditTranscript) {
      window.location.href = dashboardUrl;
      return;
    }

    const { params } = match;
    const query = queryString.parse(location.search);

    const selected = Array.isArray(licensePeriodItems)
      ? licensePeriodItems.find((p) => {
          const {
            transcriptFields: { isDefault },
          } = p;
          return isDefault;
        })
      : null;

    if (selected) {
      this.setState({
        selectedPeriod: {
          text: selected.transcriptFields.name,
          id: selected.id,
        },
      });
    }

    if (!(fetchTranscript && params.pkLicense && transcriptStatus !== 'loaded')) {
      return;
    }

    const { cycle = 0 } = query;
    const fetchParams = {
      pkLicense: params.pkLicense,
      cycleId: cycle,
    };
    if (currentLicense.isDualTranscript) {
      fetchParams.licenseNumber = currentLicense.number;
      fetchParams.stateCode = currentLicense.profession.board.state.code;
      fetchParams.professionId = currentLicense.profession.id;
    }
    fetchTranscript(fetchParams);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      transcript: { licenseCycles: licensePeriodItems = [], ownerLicenses = [] } = {},
      match: { params = {} } = {},
    } = nextProps;
    const selected = Array.isArray(licensePeriodItems)
      ? licensePeriodItems.find((p) => {
          const {
            transcriptFields: { isDefault },
          } = p;
          return isDefault;
        })
      : null;
    let { licenseInfo } = this.state;

    const license = ownerLicenses.find((lic) => {
      return lic.id === parseInt(params.pkLicense, 10);
    });

    if (license) {
      licenseInfo = {
        number: license.number,
        stateCode: license.profession.board.state.code,
        professionId: license.profession.id,
      };
    }

    if (selected) {
      this.setState({
        selectedPeriod: {
          text: selected.transcriptFields.name,
          id: selected.id,
        },
        licenseInfo,
      });
    }
  }

  getIsCompleted(item) {
    const { children = [] } = item;
    let { requiredHours, appliedHours } = item;
    const requiredChildren = [];
    children.forEach((child) => {
      if (!child.isExemptionDependent) {
        requiredHours += child.requiredHours;
      } else {
        requiredChildren.push(child);
      }
      appliedHours += child.appliedHours;
    });

    const requiredChildrenPending = requiredChildren.find((child) => {
      if (child.requiredHours - child.appliedHours > 0) {
        return true;
      }
      return false;
    });

    const hoursNeeded = requiredHours - appliedHours;

    return hoursNeeded <= 0 && !requiredChildrenPending;
  }

  getNewPdfInstance = () => {
    const { selectedPeriod = {} } = this.state;
    const { transcript = {}, match = {}, currentLicense = {} } = this.props;
    const { params = {} } = match;
    const selectedLicense = params.pkLicense;

    const { licenseLabel = 'license' } = currentLicense.profession || {};

    const { transcriptDetail = {} } = transcript;

    transcriptDetail.licenseLabel = licenseLabel;

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const docDef = docDefinition(transcriptDetail, transcript.ownerLicenses, selectedLicense, selectedPeriod.text);
    return pdfMake.createPdf(docDef);
  };

  handleDownloadPdf = () => {
    const pdf = this.getNewPdfInstance();
    return pdf.download(`transcript.pdf`);
  };

  handlePrintPdf = () => {
    const pdf = this.getNewPdfInstance();

    // I overrided the openPdf function to support edge and IE11
    // eslint-disable-next-line no-proto
    pdf.__proto__._openPdf = function (options) {
      this.getBlob(function (result) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(result);
        } else {
          const urlCreator = window.URL || window.webkitURL;
          const pdfUrl = urlCreator.createObjectURL(result);
          const i = window.open(pdfUrl);

          const ua = navigator.userAgent.toLowerCase();
          if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
            setTimeout(() => {
              try {
                i.print();
              } catch (e) {
                // console.log(e);
              }
            }, 1500);
          }
        }
      }, options);
    };

    return pdf.print();
  };

  render() {
    const {
      transcript = {},
      transcriptStatus,
      match = {},
      user = {},
      subscriptionType,
      loggedOnBehalf,
      fetchTranscript,
      currentLicense,
      isAuditTranscript,
    } = this.props;
    const { params = {} } = match;

    const { REACT_APP_ENABLE_HOTJAR } = process.env;
    if (REACT_APP_ENABLE_HOTJAR === 'true' && subscriptionType !== 'BAS') {
      window.hj =
        window.hj ||
        function () {
          (hj.q = hj.q || []).push(arguments); //eslint-disable-line
        };
      window.hj('trigger', 'transcript-incoming-feedback'); //eslint-disable-line
      setTimeout(() => {
        window.hj('trigger', 'transcript-incoming-feedback');
      }, 500);
    }

    if ((!subscriptionType || subscriptionType === 'BAS') && !isAuditTranscript) {
      return (
        <div className="container">
          <p className="unauthorized-message">
            {"You can't see this page. We're redirecting you to "}
            <a href="/dashboard">your dashboard</a>.
          </p>
        </div>
      );
    }
    let { transcriptDetail = {} } = transcript;
    transcriptDetail = transcriptDetail ? transcriptDetail : {};

    const { selectedPeriod = {} } = this.state;

    const { licenseCycles: licensePeriodItems = [] } = transcript;

    const {
      licensePeriod: {
        scenario: { scenarioMessage = '' } = {},
        percentageCompleted = 0,
        license: { profession: { id: professionId = '' } = {}, id: pkLicense = '' } = {},
        id: licensePeriodId = '',
      } = {},
    } = transcriptDetail;

    const transcriptBasicInfo = {
      daysRemaining: transcriptDetail.daysRemaining,
      totalHoursRequired: transcriptDetail.totalHoursRequired,
      totalHoursPosted: transcriptDetail.totalHoursPosted,
      totalHoursNeeded: transcriptDetail.totalHoursNeeded,
      totalHoursApplied: transcriptDetail.totalHoursApplied,
      status: transcriptDetail.status,
      inAudit: transcriptDetail.inAudit,
      isProcessedByAudit: transcriptDetail.isProcessedByAudit,
      isEmployer: transcriptDetail.isEmployer,
      isRecommended: transcriptDetail.isRecommended,
      specialty: transcriptDetail.specialty,
      completedPercentage: percentageCompleted,
      completionDate: transcriptDetail.completionDate,
      licenseExpirationDate: transcriptDetail.licenseExpirationDate,
      /*pathLogoBoard: transcriptDetail.pathLogoBoard,
      pathLogoEmployer: transcriptDetail.pathLogoEmployer,*/
      boardLogo: transcriptDetail.boardLogo,
      licenses: transcript.ownerLicenses, //transcriptDetail.licenses,
      licensePeriod: transcriptDetail.licensePeriod,
      isShowCreditCourseType: transcriptDetail.isShowCreditCourseType,
      messageScenario: scenarioMessage,
      isBoardWithoutLisensingTranscript: transcriptDetail.isBoardWithoutLisensingTranscript,
      isEMTorPMDProfession: transcriptDetail.isemTorPMDProfession,
    };

    const selectedLicense = params.pkLicense;

    const { licensePeriod: { scenario: { type: scenarioType = '' } = {} } = {} } = transcript;

    let { items: requirements = [] } = transcriptDetail;

    const { exemptions = [] } = transcriptDetail;

    requirements = requirements.filter((r) => r.isVisible);

    requirements = requirements.map((r) => {
      if (r.isFlag) {
        r.isCompleted = r.isPosted;
      } else {
        r.isCompleted = this.getIsCompleted(r);
        r.isNotRequired = r.requiredHours === 0;
      }
      return r;
    });

    const hourRequirements = requirements.filter((item) => !item.isFlag);
    const flagRequirements = requirements.filter((item) => item.isFlag);

    const uncompletedHourRequirements = hourRequirements.filter((item) => !item.isCompleted || item.isNotRequired);
    const completedHourRequirements = hourRequirements.filter((item) => item.isCompleted && !item.isNotRequired);

    const uncompletedFlagRequirements = flagRequirements.filter((item) => !item.isCompleted);
    const completedFlagRequirements = flagRequirements.filter((item) => item.isCompleted);

    const isLoading = transcriptStatus === 'loading';
    if (isLoading) {
      return <LoadingState />;
    }

    return (
      <div className="transcript">
        <div className="container" style={{ marginTop: '27px' }}>
          {transcriptStatus === 'failed' && (
            <Alert color="danger">
              <div>There was an error fetching your transcript information. Try again later.</div>
            </Alert>
          )}

          {!isAuditTranscript && (
            <div className="basic-info-item mobile-only" style={{ display: 'flex' }}>
              <CycleSelector
                {...{
                  licensePeriods: licensePeriodItems,
                  currentLicense: currentLicense,
                  selectedPeriodId: selectedPeriod.id,
                  fetchTranscript,
                  onDownloadPdf: this.handleDownloadPdf,
                  onPrintPdf: this.handlePrintPdf,
                }}
              />
            </div>
          )}

          <BasicInfo
            transcriptBasicInfo={transcriptBasicInfo}
            selectedLicense={selectedLicense}
            user={user}
            licensePeriods={licensePeriodItems}
            selectedPeriodId={selectedPeriod.id}
            onDownloadPdf={this.handleDownloadPdf}
            onPrintPdf={this.handlePrintPdf}
            fetchTranscript={fetchTranscript}
            exemptions={exemptions}
            currentLicense={currentLicense}
            isAuditTranscript={isAuditTranscript}
          />
        </div>
        {transcriptDetail.status === 'NO_CE_REQ' ? (
          <NoCERequired />
        ) : (
          <div>
            <div className="container hour-requirements">
              {hourRequirements.length > 0 && (
                <RequirementsList
                  user={user}
                  title="Hour based requirements"
                  items={[...uncompletedHourRequirements, ...completedHourRequirements]}
                  showCourseTypeColumn={transcriptBasicInfo.isShowCreditCourseType}
                  scenarioType={scenarioType}
                  isViewOnlyCompleted={transcript.isViewOnlyCompleted}
                  professionId={professionId}
                  pkLicense={pkLicense}
                  licensePeriodId={licensePeriodId}
                  loggedOnBehalf={loggedOnBehalf}
                  isAuditTranscript={isAuditTranscript}
                />
              )}
            </div>
            <div className="container flag-requirements">
              {flagRequirements.length > 0 && (
                <RequirementsList
                  title="Completion based subject areas"
                  items={[...uncompletedFlagRequirements, ...completedFlagRequirements]}
                  showCourseTypeColumn={transcriptBasicInfo.isShowCreditCourseType}
                  scenarioType={scenarioType}
                  isViewOnlyCompleted={transcript.isViewOnlyCompleted}
                  professionId={professionId}
                  pkLicense={pkLicense}
                  licensePeriodId={licensePeriodId}
                  user={user}
                  loggedOnBehalf={loggedOnBehalf}
                  isAuditTranscript={isAuditTranscript}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

TranscriptPage.propTypes = {
  subscriptionType: PropTypes.string,
  user: PropTypes.object,
  transcript: PropTypes.object,
  history: PropTypes.object,
  transcriptStatus: PropTypes.string,
  fetchTranscript: PropTypes.func,
  location: PropTypes.object,
  match: PropTypes.object,
  loggedOnBehalf: PropTypes.bool,
  currentLicense: PropTypes.object,
  isAuditTranscript: PropTypes.bool,
};

TranscriptPage.defaultProps = {
  transcript: {},
  user: {},
  loggedOnBehalf: false,
  isAuditTranscript: false,
  fetchTranscript: () => {},
};

export default TranscriptPage;
