export default function (globalState) {
  const state = globalState.communications;

  const getEntityById = (id) => state.entities[id] || {};

  const getEntitiesByIds = (ids) => ids.map(getEntityById);

  const getEntitiesByPage = (page, communicationsType) => {
    return communicationsType === 'REQUEST'
      ? getEntitiesByIds(state.requestPages[page] || [])
      : getEntitiesByIds(state.responsePages[page] || []);
  };

  const getPageFetchStatus = (page, communicationsType) => {
    return communicationsType === 'REQUEST'
      ? state.requestPageStatus[page] || ''
      : state.responsePageStatus[page] || '';
  };

  const getEntitiesFetchStatus = () => state.entitiesFetchStatus;

  const getTotalCount = (communicationsType) =>
    communicationsType === 'REQUEST' ? state.totalRequestCount : state.totalResponseCount;

  const getDeleteStatus = () => state.deleteStatus;

  const getArchiveStatus = () => state.archiveStatus;

  const getFetchDetailStatus = (sectionCode) => {
    if (sectionCode === 'REQUEST') {
      return state.requestDetailStatus;
    }
    if (sectionCode === 'RESPONSE') {
      return state.responseDetailStatus;
    }
  };

  const getAuditEntities = (actionType) => {
    return (actionType === 'REQUEST' ? state.auditRequestEntities : state.auditResponseEntities) || [];
  };

  const getAuditEntitiesStatus = (actionType) => {
    return (actionType === 'REQUEST' ? state.auditRequestStatus : state.auditResponseStatus) || '';
  };

  const getTotalAuditCount = (actionType) => {
    return (actionType === 'REQUEST' ? state.totalAuditRequestCount : state.totalAuditResponseCount) || 0;
  };

  const getAuditResponseStatus = () => state.saveAuditResponseStatus;

  const getSaveRequestResponseStatus = () => state.saveRequestResponseStatus;

  return {
    getEntityById,
    getEntitiesByIds,
    getEntitiesByPage,
    getPageFetchStatus,
    getEntitiesFetchStatus,
    getTotalCount,
    getDeleteStatus,
    getArchiveStatus,
    getFetchDetailStatus,
    getAuditEntities,
    getAuditEntitiesStatus,
    getTotalAuditCount,
    getAuditResponseStatus,
    getSaveRequestResponseStatus,
  };
}
