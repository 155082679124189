import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'emerald-ui/lib/Button/Button';

import './FavoriteButton.css';
import { isFavoriteCourse } from 'utils';

function FavoriteButton({ className, show, savedOfferingsList, handleSaveOffering, courseId }) {
  const [isCourseSaved, setIsCourseSaved] = useState(false);

  useEffect(() => {
    const isFavorite = isFavoriteCourse({ items: savedOfferingsList, courseId });
    setIsCourseSaved(isFavorite);
  }, [courseId, savedOfferingsList]);

  if (!show) {
    return <></>;
  }

  const beatClass = isCourseSaved ? 'beat' : '';
  const classNameStyle = `${className}  material-icons ${beatClass}`;

  const savedContent = (
    <>
      <i className={`${classNameStyle} save-icon-filled`}>{'favorite'}</i>
      <p className="functional-label-button">{'Saved'}</p>
    </>
  );

  const notSavedContent = (
    <>
      <i className={`${classNameStyle} save-icon-empty`}>{'favorite_border'}</i>
      <p className="functional-label-button">{'Save'}</p>
    </>
  );

  return (
    <Button
      className="favorite-course-button"
      onClick={() => {
        handleSaveOffering(isCourseSaved);
      }}
    >
      {isCourseSaved ? savedContent : notSavedContent}
    </Button>
  );
}

FavoriteButton.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  show: PropTypes.bool,
  savedOfferingsList: PropTypes.array,
  handleSaveOffering: PropTypes.func,
  savingStatuses: PropTypes.object,
};

export default FavoriteButton;
