import { combineReducers } from 'redux';
import at from '../types';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return { ...state, ...action.entities.items };
    }
    case at.DELETE_SUCCESS: {
      const { inboxIds } = action;
      for (const inboxId of inboxIds) {
        delete state[inboxId];
      }
      return { ...state };
    }
    case at.ARCHIVE_SUCCESS: {
      const { inboxIds } = action;
      for (const inboxId of inboxIds) {
        delete state[inboxId];
      }
      return { ...state };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const pages = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { filters, result } = action;
      if (!filters.isArchived) {
        return { ...state, [filters.page]: result };
      }
      return state;
    }
    case at.ARCHIVE_SUCCESS: {
      const { inboxIds, page } = action;
      const newStatePage = state[page].filter(function (el) {
        return !inboxIds.includes(el);
      });
      return { ...state, [page]: newStatePage };
    }
    case at.DELETE_SUCCESS: {
      const { inboxIds, inboxType, page } = action;
      if (inboxType === 'MESSAGE_BOX') {
        const newStatePage = state[page].filter(function (el) {
          return !inboxIds.includes(el);
        });
        return { ...state, [page]: newStatePage };
      }
      return state;
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const archivedPages = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { filters, result } = action;
      if (filters.isArchived) {
        return { ...state, [filters.page]: result };
      }
      return state;
    }
    case at.DELETE_SUCCESS: {
      const { inboxIds, page, inboxType } = action;
      if (inboxType === 'MESSAGE_ARCHIVE') {
        const newStatePage = state[page].filter(function (el) {
          return !inboxIds.includes(el);
        });
        return { ...state, [page]: newStatePage };
      }
      return state;
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const pagesFetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { filters } = action;
      return { ...state, [filters.page]: 'loaded' };
    }
    case at.FETCH_FAILURE: {
      const { filters } = action;
      return { ...state, [filters.page]: 'failed' };
    }
    case at.SET_PAGE_FETCH_STATUS: {
      const { filters, status } = action;
      return { ...state, [filters.page]: status };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const archivedPagesFetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { filters } = action;
      if (filters.isArchived) {
        return { ...state, [filters.page]: 'loaded' };
      }
      return state;
    }
    case at.FETCH_FAILURE: {
      const { filters } = action;
      if (filters.isArchived) {
        return { ...state, [filters.page]: 'failed' };
      }
      return state;
    }
    case at.SET_PAGE_FETCH_STATUS: {
      const { status, filters = {} } = action;
      if (filters.isArchived) {
        return { ...state, [filters.page]: status };
      }
      return state;
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const totalCount = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { options } = action;
      return options.totalRecords;
    }
    case at.CLEAR_ENTITIES: {
      return 0;
    }
    default:
      return state;
  }
};

export const archivedTotalCount = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { options } = action;
      return options.totalRecords;
    }
    case at.CLEAR_ENTITIES: {
      return 0;
    }
    default:
      return state;
  }
};

export const deleteStatus = (state = '', action) => {
  switch (action.type) {
    case at.DELETE_REQUEST: {
      return 'loading';
    }
    case at.DELETE_FAILURE: {
      return 'failed';
    }
    case at.DELETE_SUCCESS: {
      return 'loaded';
    }
    default:
      return state;
  }
};

export const archiveStatus = (state = '', action) => {
  switch (action.type) {
    case at.ARCHIVE_REQUEST: {
      return 'loading';
    }
    case at.ARCHIVE_FAILURE: {
      return 'failed';
    }
    case at.ARCHIVE_SUCCESS: {
      return 'loaded';
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  pages,
  archivedPages,
  pagesFetchStatus,
  archivedPagesFetchStatus,
  totalCount,
  deleteStatus,
  archiveStatus,
});
