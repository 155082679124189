import axios from 'axios';
import config from '../config';

export const createCheckoutSession = (data) => {
  return axios.request({
    method: 'post',
    url: `${config.licenseesApiInternal}/checkout/session`,
    data,
  });
};

export const getCheckout = (checkoutId) => {
  return axios.request({
    method: 'get',
    url: `${config.licenseesApiInternal}/checkout/${checkoutId}`,
  });
};

export const updateTalonOneProfile = async (userId, { customerId, ownerId = 0 }) => {
  const data = {
    ownerId: ownerId.toString(),
  };

  if (customerId) {
    data.stripeCustomerId = customerId;
  }
  try {
    await axios.request({
      method: 'PUT',
      url: `${config.licenseesApiInternal}/talon-one/profile/${userId}`,
      data,
    });
  } catch (error) {
    console.error(
      `Error trying to update TalonOne Profile for ${userId}`,
      error && error.response && error.response.data
    );
  }
};
