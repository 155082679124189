export default function (globalState) {
  const state = globalState.courseHistory;

  const getEntityById = (id) => state.entities[id] || {};

  const getEntitiesByIds = (ids) => ids.map(getEntityById);

  const getEntitiesByPage = (page) => getEntitiesByIds(state.pages[page] || []);
  const getEntitiesDeletedByPage = (page) => getEntitiesByIds(state.deletedPages[page] || []);

  const getPageFetchStatus = (page) => state.pagesFetchStatus[page] || '';
  const getDeletedPageFetchStatus = (page) => state.deletedPagesFetchStatus[page] || '';

  const getEntitiesFetchStatus = () => state.entitiesFetchStatus;

  const getTotalCount = () => state.totalCount;
  const getDeletedTotalCount = () => state.deletedTotalCount;

  const getAttachmentStatusById = (id) => state.attachmentsFetchStatus[id] || 'notLoaded';

  const getDeleteAttachmentStatus = (id) => state.attachmentsDeleteStatus[id] || 'notDeleted';

  const getSubjectAreasByProfession = (professionId) => state.subjectAreasFilter[professionId] || [];

  return {
    getEntityById,
    getEntitiesByIds,
    getEntitiesByPage,
    getEntitiesDeletedByPage,
    getPageFetchStatus,
    getDeletedPageFetchStatus,
    getEntitiesFetchStatus,
    getTotalCount,
    getDeletedTotalCount,
    getAttachmentStatusById,
    getDeleteAttachmentStatus,
    getSubjectAreasByProfession,
  };
}
