import axios from 'axios';
import config from '../config';

const { licenseesApiInternal } = config;

export const fetch = ({ userId, courseStatus, term, offset, limit, courseId = false, professionIds = '' }) => {
  const params = { term, offset, limit, professionIds };
  if (courseId) {
    params.courseId = courseId;
  }

  if (professionIds) {
    params.professionIds = professionIds;
  }

  return axios.request({
    method: 'GET',
    url: `${licenseesApiInternal}/now-courses/${userId}/${courseStatus}`,
    params,
  });
};
