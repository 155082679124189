import { put, call } from 'redux-saga/effects';
import * as api from '../../../api/nowCourses';
import at from '../types';

export default function* fetch({ params }) {
  try {
    const { data } = yield call(api.fetch, params);
    yield put({
      type: at.FETCH_IN_PROGRESS_LIST_SUCCESS,
      data: data.items,
    });
  } catch (_error) {
    yield put({ type: at.FETCH_IN_PROGRESS_LIST_FAILURE });
  }
}
