export default {
  FETCH_PAYMENT_HISTORY_REQUEST: 'paymentHistory/FETCH_PAYMENT_HISTORY_REQUEST',
  FETCH_PAYMENT_HISTORY_SUCCESS: 'paymentHistory/FETCH_PAYMENT_HISTORY_SUCCESS',
  FETCH_PAYMENT_HISTORY_FAILURE: 'paymentHistory/FETCH_PAYMENT_HISTORY_FAILURE',
  FETCH_PAYMENT_HISTORY_DETAIL_REQUEST: 'paymentHistory/FETCH_PAYMENT_HISTORY_DETAIL_REQUEST',
  FETCH_PAYMENT_HISTORY_DETAIL_SUCCESS: 'paymentHistory/FETCH_PAYMENT_HISTORY_DETAIL_SUCCESS',
  FETCH_PAYMENT_HISTORY_DETAIL_FAILURE: 'paymentHistory/FETCH_PAYMENT_HISTORY_DETAIL_FAILURE',
  FETCH_SELF_SUBMISSION_REQUEST: 'paymentHistory/FETCH_SELF_SUBMISSION_REQUEST',
  FETCH_SELF_SUBMISSION_SUCCESS: 'paymentHistory/FETCH_SELF_SUBMISSION_SUCCESS',
  FETCH_SELF_SUBMISSION_FAILURE: 'paymentHistory/FETCH_SELF_SUBMISSION_FAILURE',
  SET_PAGE_FETCH_STATUS: 'paymentHistory/SET_PAGE_FETCH_STATUS',
  CLEAR_ENTITIES: 'paymentHistory/CLEAR_ENTITIES',
};
