import React from 'react';
import PropTypes from 'prop-types';
import { Button, Panel } from 'emerald-ui';
import config from '../../config';
import utils from '../../utils';
import transcriptImg1x from './transcript-img-1x.jpg';
import transcriptImg2x from './transcript-img-2x.jpg';
import './UpgradeAd.css';

class UpgradeAd extends React.Component {
  onClickUpgrade = (e) => {
    e.preventDefault();
    const { saveEvent, user, history, isFTOAvailable, subscriptionType } = this.props;
    const statisticCode = 'UPGRADE';
    const type = 'TRANSCRIPT_AD';
    const id = user.id;
    saveEvent(id, statisticCode, type);
    const {
      AnalyticsTrackingEntities: { categories, actions },
    } = config;

    utils.sendAnalyticsEvent({
      category: categories.upgrade,
      action: actions.sectionTranscriptUpgrade,
      label: utils.getAccountTypeLabel(subscriptionType, isFTOAvailable),
    });
    history.push('/upgrade', { comeFrom: 'transcriptTab' });
  };

  render() {
    const { isFTOAvailable } = this.props;

    const isFTOAvailableCaption = isFTOAvailable ? 'Activate free trial' : 'Renew subscription';

    const transcriptBtnColor = isFTOAvailable ? 'danger' : 'info';

    return (
      <Panel className="container restricted-panel" id="restricted-panel-transcript">
        <Panel.Body className="restricted-panel-body">
          <div className="restricted-access-panel">
            <img src={transcriptImg1x} srcSet={`${transcriptImg1x} 1x, ${transcriptImg2x} 2x`} alt="" />
            <div>
              <p className="small">View your Official CE Compliance Transcript</p>
              <h3>Restricted Access</h3>
              <p>
                Our most highly valued feature. CE Broker’s transcripts break down your hours by subject area and
                required hours, applying completed CE to your requirements in real-time so you know exactly what you
                have left to complete. Our transcripts offer clarity and peace of mind. Upgrade your account to try it
                out!
              </p>
              <Button color={transcriptBtnColor} onClick={this.onClickUpgrade}>
                {isFTOAvailableCaption}
              </Button>
            </div>
          </div>
        </Panel.Body>
      </Panel>
    );
  }
}

UpgradeAd.propTypes = {
  user: PropTypes.object,
  saveEvent: PropTypes.func,
  fetchRequirementsLink: PropTypes.func,
  history: PropTypes.object,
  isFTOAvailable: PropTypes.bool,
  subscriptionType: PropTypes.string,
};

UpgradeAd.defaultProps = {
  user: {},
  saveEvent: () => {},
  history: {},
  isFTOAvailable: false,
};

export default UpgradeAd;
