import { put, call } from 'redux-saga/effects';

import * as api from '../../../api/dashboardCards';
import at from '../types';
import notificationsAt from '../../notifications/types';

export default function* addHeartcard({ heartcard, loggedUserInfo, isEditing }) {
  try {
    yield call(!isEditing ? api.addHeartcard : api.editHeartcard, heartcard, loggedUserInfo);
    yield put({
      type: at.ADD_HEARTCARD_SUCCESS,
      heartcard,
      loggedUserInfo,
    });
    yield put({
      type: notificationsAt.ADD,
      notification: {
        id: Math.random(),
        message: `Heartcard ${isEditing ? 'edited' : 'added'} successfully.`,
        timeout: 6000,
      },
    });
  } catch (error) {
    console.error(error); //eslint-disable-line
    yield put({ type: at.ADD_HEARTCARD_FAILURE, heartcard, loggedUserInfo });
  }
}
