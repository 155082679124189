import { put, call } from 'redux-saga/effects';
import at from '../types';
import * as api from '../../../api/subscriptions';

export default function* fetchLastSubscription({ userId }) {
  try {
    const { data } = yield call(api.getLastSubscription, userId);
    yield put({ type: at.FETCH_LAST_SUBSCRIPTION_SUCCESS, data });
  } catch (error) {
    console.log(error); //eslint-disable-line
    yield put({ type: at.FETCH_LAST_SUBSCRIPTION_FAILURE });
  }
}
