import { takeEvery, takeLatest } from 'redux-saga/effects';

import at from '../types';
import fetch from './fetch';
import updateFlag from './updateFlag';
import submitTask from './submitTask';

export default function* root() {
  yield takeEvery(at.FETCH_REQUEST, fetch);
  yield takeEvery(at.FETCH_REQUEST_FLAG, updateFlag);
  yield takeLatest(at.SUBMIT_REQUEST, submitTask);
}
