import { combineReducers } from 'redux';
import at from '../types';

export const ad = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { data } = action;
      const adData = data[0];
      if (!adData) {
        return state;
      }
      const registerUrlInfo = adData.urls.filter((item) => item.alias === 'ButtonURL')[0];
      const websiteUrlInfo = adData.urls.filter((item) => item.alias === 'ProviderURL')[0];
      return {
        imageUrl: adData.img,
        title: adData.title,
        tagline: adData.tagline,
        description: adData.description,
        userType: action.userType,
        mediaCode: action.mediaCode,
        registerUrl: {
          url: registerUrlInfo ? registerUrlInfo.link : '',
          alias: registerUrlInfo ? registerUrlInfo.alias : 'ButtonURL',
        },
        websiteUrl: {
          url: websiteUrlInfo ? websiteUrlInfo.link : '',
          alias: websiteUrlInfo ? websiteUrlInfo.link : 'ProviderURL',
        },
        providerId: adData.providerId,
        courseId: adData.courseId,
      };
    }
    case at.FETCH_DETAIL_SUCCESS: {
      const { data } = action;
      data.course.displayRegistrationWebsite = data.course.registrationWebsite;
      if (state.registerUrl.url) {
        data.course.registrationWebsite = state.registerUrl.url;
      }
      if (state.websiteUrl.url) {
        data.course.provider.adWebsite = state.websiteUrl.url;
      }
      return {
        ...state,
        ...data,
      };
    }
    default:
      return state;
  }
};

export const adStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      return 'loading';
    }
    case at.FETCH_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const adDetailStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_DETAIL_REQUEST: {
      return 'loading';
    }
    case at.FETCH_DETAIL_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_DETAIL_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export default combineReducers({
  ad,
  adStatus,
  adDetailStatus,
});
