import React, { Component } from 'react';
import Icon from 'emerald-ui/lib/Icon';
import propTypes from 'prop-types';
import { convertHexToRGB } from '../../utils';
import './AssociationReturnButton.css';

export default class AssociationReturnButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false,
    };
  }

  handleHoverToggle = () => {
    this.setState((prevState) => ({
      isHover: !prevState.isHover,
    }));
  };

  handleClick = (e) => {
    e.preventDefault();
    const { logout, associationUsersInfo, loggedOnBehalf } = this.props;
    logout({ ...associationUsersInfo, loggedOnBehalf });
  };

  render() {
    const { isHover } = this.state;
    const { associationUsersInfo, professionBrandColor } = this.props;
    const { isAssociationUser, associationReturnURL } = associationUsersInfo;
    if (!isAssociationUser || !associationReturnURL) {
      return <React.Fragment />;
    }

    const style = {
      textDecoration: 'none',
      background: isHover ? convertHexToRGB(professionBrandColor, 50) : `#${professionBrandColor}`,
    };

    return (
      <a
        id="association-return-button"
        className="breadcrumb"
        onMouseEnter={this.handleHoverToggle}
        onMouseLeave={this.handleHoverToggle}
        onClick={this.handleClick}
        href={associationUsersInfo.associationReturnURL}
        style={style}
      >
        <span className="go-back-assoc-button">
          <Icon name="arrow_back_ios" />
          <b>Return to {associationUsersInfo.associationCode} portal</b>
        </span>
      </a>
    );
  }
}

AssociationReturnButton.propTypes = {
  associationUsersInfo: propTypes.object,
  professionBrandColor: propTypes.string,
  loggedOnBehalf: propTypes.bool,
  logout: propTypes.func,
};
