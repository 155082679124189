import axios from 'axios';
import config from '../config';
const { licenseesApiInternal } = config;

export const fetchSelfSubmission = (selfSubmissionId) => {
  return axios.request({
    url: `${licenseesApiInternal}/self-submissions/${selfSubmissionId}`,
    method: 'GET',
    headers: {
      // Authorization: `Bearer ${authToken}`
    },
  });
};

export const deleteSelfSubmission = (selfSubmissionId, userId) => {
  return axios.request({
    url: `${licenseesApiInternal}/self-submissions/${selfSubmissionId}`,
    method: 'DELETE',
    headers: {
      // Authorization: `Bearer ${authToken}`
    },
    data: {
      userId,
    },
  });
};
