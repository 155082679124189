import axios from 'axios';
import config from '../config';

const { licenseesApiInternal } = config;

export const updateCartByUser = (userId, body) => {
  return axios.request({
    method: 'put',
    url: `${licenseesApiInternal}/cart/user/${userId}`,
    data: body,
  });
};

export const saveCartItem = ({ userId, data }) => {
  return axios.request({
    method: 'patch',
    url: `${licenseesApiInternal}/cart/user/${userId}/courses`,
    data,
  });
};

export const removeCartItem = ({ userId, courseId }) => {
  return axios.request({
    method: 'delete',
    url: `${licenseesApiInternal}/cart/user/${userId}/courses/${courseId}`,
  });
};

export const removeSubscriptionFromCart = ({ userId, couponCodes, isCalledFromLicenseeSuite = true }) => {
  return axios.request({
    method: 'delete',
    url: `${licenseesApiInternal}/cart/user/${userId}/subscription`,
    data: { couponCodes, isCalledFromLicenseeSuite },
  });
};
