import React from 'react';
import PropTypes from 'prop-types';
import Responsive from '../../Responsive';
import { Table, Row, Col } from 'emerald-ui/lib';

const subjectAreaContent = (sa, isMustFind) => {
  return (
    <React.Fragment key={sa.name}>
      <Responsive size="phoneOnly">
        <tr>
          <td className="subject-area-name">{sa.name}</td>
          <td className="subject-area-hours">
            <span className="approved-hours">{sa.approvedHours}</span>
            <span>hours</span>
          </td>
        </tr>
      </Responsive>
      <Responsive size="tabletPortraitUp">
        <tr>
          <td className="subject-area-name">{sa.name}</td>
          {isMustFind && <td className="subject-area-hours course-hours">{sa.requestedHours}</td>}
          <td className="subject-area-hours">
            <span className="approved-hours">{sa.approvedHours}</span>
            <span>{`hour${sa.approvedHours === 1 ? '' : 's'}`}</span>
          </td>
        </tr>
      </Responsive>
    </React.Fragment>
  );
};

const SubjectAreasSection = ({ isMustFind, components = [] }) => {
  const subjectAreas = {};
  components.forEach((component) => {
    const sa = {
      ...component.subjectArea,
      approvedHours: component.approvedHours,
      requestedHours: component.requestedHours,
    };
    if (!subjectAreas[component.profession.name]) {
      subjectAreas[component.profession.name] = [sa];
    } else {
      subjectAreas[component.profession.name].push(sa);
    }
  });
  return (
    <Row id="subject-area-section" className="section-row">
      <Col className="section-col">
        <h4 className="course-history-detail-header" style={{ paddingLeft: '16px' }}>
          CE Credit
        </h4>
        {Object.keys(subjectAreas).length > 0 && (
          <Table condensed className="subject-area-list">
            <tbody>
              {Object.keys(subjectAreas).map((key) => {
                const trs = [
                  <tr className="profession-name" key={key}>
                    <td>{key}</td>
                    {isMustFind && (
                      <Responsive size="tabletPortraitUp">
                        <td />
                      </Responsive>
                    )}
                    <td />
                  </tr>,
                ];

                subjectAreas[key].forEach((sa) => {
                  trs.push(subjectAreaContent(sa, isMustFind));
                });

                return trs;
              })}
            </tbody>
          </Table>
        )}
      </Col>
    </Row>
  );
};

SubjectAreasSection.propTypes = {
  isMustFind: PropTypes.bool,
  rosterCode: PropTypes.string,
  components: PropTypes.array,
};

export default SubjectAreasSection;
