export default function (globalState) {
  const state = globalState.cart;

  const getCartEntities = () => {
    return state.entities.courses || [];
  };

  const getCartStatus = () => state.fetchCart.fetchStatuses || '';

  const getCartErrors = () => state.fetchCart.fetchErrors || '';

  const saveCartItemStatus = () => state.saveCartItem.fetchStatuses || '';

  const saveCartItemErrors = () => state.saveCartItem.fetchErrors || '';

  const removeCartItemStatus = () => state.removeCartItem.fetchStatuses || '';

  const removeCartItemErrors = () => state.removeCartItem.fetchErrors || '';

  const getCartTotalItems = () => state.entities.totalItems;

  const getCartSubscriptionData = () => state.entities.subscription || {};

  return {
    getCartEntities,
    getCartStatus,
    getCartErrors,
    saveCartItemStatus,
    saveCartItemErrors,
    removeCartItemStatus,
    removeCartItemErrors,
    getCartTotalItems,
    getCartSubscriptionData,
  };
}
