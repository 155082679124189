import { takeEvery } from 'redux-saga/effects';
import at from '../types';

import fetchSpotlight from './fetchSpotlight';
import fetchPopularCECourses from './fetchPopularCE';
import fetchKeepLearningCECourses from './fetchKeepLearning';
import fetchRecentlyAddedCECourses from './fetchRecentlyAdded';

export default function* root() {
  yield takeEvery(at.SPOTLIGHT_INFO_FETCH, fetchSpotlight);
  yield takeEvery(at.POPULAR_COURSES_FETCH, fetchPopularCECourses);
  yield takeEvery(at.KEEP_LEARNING_COURSES_FETCH, fetchKeepLearningCECourses);
  yield takeEvery(at.RECENTLY_ADDED_COURSES_FETCH, fetchRecentlyAddedCECourses);
}
