import { put, call, select } from 'redux-saga/effects';
import * as api from '../../../api/payments';
import at from '../types';

export default function* fetchPaymentHistory({ feeId, invoiceId, meta = {} }) {
  const state = yield select();
  const user = state.authentication.licenseeAccount.user;
  try {
    const { data } = yield call(api.fetchPaymentHistoryDetail, user.id, invoiceId);
    yield put({
      type: at.FETCH_PAYMENT_HISTORY_DETAIL_SUCCESS,
      userId: user.id,
      ...data,
      reduxId: feeId,
      meta,
    });
  } catch (error) {
    console.error(error); //eslint-disable-line
    yield put({
      type: at.FETCH_PAYMENT_HISTORY_DETAIL_FAILURE,
      userId: user.id,
      reduxId: feeId,
      meta,
    });
  } finally {
    //eslint-disable-line
  }
}
