import { put, call } from 'redux-saga/effects';

import * as api from '../../../api/subscriptions';
import at from '../types';

export function* getSubscriptionPrices() {
  try {
    const result = yield call(api.getSubscriptionPrices);
    yield put({
      type: at.FETCH_SUBSCRIPTION_PRICES_SUCCESS,
      data: result.data,
    });
  } catch (error) {
    console.log(error); //eslint-disable-line
    yield put({
      type: at.FETCH_SUBSCRIPTION_PRICES_FAILURE,
      error: error && error.response && error.response.data,
    });
  }
}
