/* eslint-disable no-undefined */
import React from 'react';
import PropTypes from 'prop-types';

import Toast from 'emerald-ui/lib/Toast';

import './Toaster.css';

class Toaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { notifications } = nextProps;
    notifications.forEach((n) => {
      if (this.state[n.id] === undefined) {
        this.setState({ [n.id]: false });
        setTimeout(() => this.setState({ [n.id]: true }), 1);
        if (n.timeout) {
          setTimeout(() => this.setState({ [n.id]: false }), n.timeout);
        }
      }
    });
  }

  render() {
    const { notifications } = this.props;

    let bottom = 0;
    const notificationStack = notifications.map((n) => {
      const visible = this.state[n.id];
      bottom += bottom === 0 ? 25 : visible ? 60 : 0;
      return (
        <Toast
          key={n.id}
          style={{ bottom }}
          message={n.message}
          visible={this.state[n.id]}
          actionText={n.actionText === undefined ? 'Undo' : n.actionText}
          onActionClick={() => {
            this.setState({ [n.id]: false });
            n.onActionClick();
          }}
          className={`toaster-courses ${n.actionText === null || n.actionText === '' ? 'toast-hidden-opt' : ''}`}
        />
      );
    });
    return <div style={{ zIndex: 10000 }}>{notificationStack}</div>;
  }
}

Toaster.propTypes = {
  notifications: PropTypes.array,
};

export default Toaster;
