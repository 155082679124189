import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import { TableHeader, Icon, ExpansionTableRow, ExpansionTableRowGroup, IconButton } from 'emerald-ui/lib';
import reportedExemptionIcon from './re-icon.svg';
import TableNoResultsState from '../TableNoResultsState';
import moment from 'moment';

import './ReportedExemptionsList.css';
import ReportedExemptionDetail from '../../containers/ReportedExemptionDetail';
import ListPager from '../ListPager';
import ListToolbar from '../ListToolbar';
import ReportedExemptionFiltersModal from '../../containers/ReportedExemptionFiltersModal';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import { titleCase } from '../../utils';

class ReportedExemptionsList extends React.Component {
  state = {
    showConfirmationModal: false,
    lpExemptionIdToDelete: null,
  };

  UNSAFE_componentWillMount() {
    this.fetchReportedExemptions();
  }

  fetchReportedExemptions = () => {
    const { fetchReportedExemptions, params, pageStatus, pkLicense } = this.props;
    const filters = {
      page: params.page,
      size: params.size,
      status: params.status,
      license: pkLicense,
      period: params.period,
      q: params.q,
      sortBy: params.sortBy,
      sortDirection: params.sortDirection,
    };
    if (pageStatus !== 'loaded') {
      fetchReportedExemptions(filters);
    }
  };

  handleClickRevokeIcon = (lpExemptionId) => {
    this.setState({
      showConfirmationModal: true,
      lpExemptionIdToDelete: lpExemptionId,
    });
  };

  handleCloseConfirmationModal = () => {
    this.setState({
      showConfirmationModal: false,
      lpExemptionIdToDelete: null,
    });
  };

  handleRevokeExemption = () => {
    const { revokeExemption, pkLicense, params } = this.props;
    const { lpExemptionIdToDelete } = this.state;
    const { page, size, status, period, q, sortBy, sortDirection } = params;
    const filters = {
      page,
      size,
      status,
      license: pkLicense,
      period,
      q,
      sortBy,
      sortDirection,
    };
    revokeExemption(lpExemptionIdToDelete, parseInt(pkLicense, 10), {
      filters,
    });
    this.setState({
      showConfirmationModal: false,
      lpExemptionIdToDelete: null,
    });
  };

  render() {
    const {
      fetchReportedExemptions,
      params,
      reportedExemptions = [],
      licenses = [],
      pageStatus,
      totalCount,
      pkLicense,
      revokeReqStatus,
    } = this.props;
    params.license = pkLicense;
    const dateFormat = 'MM/DD/YYYY';
    return (
      <div id="reported-exemptions">
        <div className="table-wrapper">
          <ListToolbar
            filters={['status', 'period']}
            params={params}
            fetch={fetchReportedExemptions}
            FiltersModal={ReportedExemptionFiltersModal}
          />
          {pageStatus === 'loading' && <Loader color="info" />}
          {pageStatus !== 'loading' && reportedExemptions.length > 0 && (
            <ExpansionTableRowGroup hover id="exemptions" preventHorizontalGrowth>
              <colgroup>
                <col width="0%" />
                <col width="100%" />
                <col width="0%" />
                <col width="0%" />
              </colgroup>
              <TableHeader useCheckbox={false}>
                <th />
                <th>Name</th>
                <th className="reported-exemptions-period-header">Period</th>
                <th className="reported-exemptions-status-header">Status</th>
              </TableHeader>
              {reportedExemptions.map((exemption) => {
                const licenseNumber = licenses.filter((l) => l.id === exemption.pkLicense)[0].number;
                exemption.licenseNumber = licenseNumber;
                const canRevokeExemption = exemption.status !== 'REVOKE';
                return (
                  <ExpansionTableRow
                    useCheckbox={false}
                    key={exemption.lpExemptionId}
                    customId={exemption.lpExemptionId}
                  >
                    <ExpansionTableRow.Summary>
                      <td className="icons-column">
                        <Icon name="keyboard_arrow_down" className="text-lightest" />
                        <img src={reportedExemptionIcon} alt="" />
                      </td>
                      <td className="reported-exemptions-name-column">
                        <div className="reported-exemption-wrapper">
                          <p className="exemption-name">{exemption.exemptionName}</p>
                          <span>Submitted {moment(exemption.dateAdded).format(dateFormat)}</span>
                        </div>
                      </td>
                      <td className="reported-exemptions-period-column">
                        {moment(exemption.renewalStartDate).format(dateFormat)} -{' '}
                        {moment(exemption.renewalEndDate).format(dateFormat)}
                      </td>
                      <td className="reported-exemptions-status-column">
                        {exemption.status === 'REVOKE' ? 'REVOKED' : exemption.status}
                      </td>
                    </ExpansionTableRow.Summary>
                    <ExpansionTableRow.AlternativeSummary>
                      <div className="detail-title">
                        <div className="title-wrapper">
                          <Icon name="keyboard_arrow_up" className="text-lightest" />
                          <img
                            src={reportedExemptionIcon}
                            alt="Course history icon"
                            className="reported-exemptions-icon"
                          />
                          <div className="detail-title-text">
                            <h3>{titleCase(exemption.exemptionName)}</h3>
                            <span className="reported-exemptions-date-completed">
                              Submitted {moment(exemption.dateAdded).format(dateFormat)}
                            </span>
                          </div>
                        </div>
                        {canRevokeExemption && (
                          <IconButton
                            icon="delete"
                            className="table-icons"
                            title="Revoke Exemption"
                            disabled={revokeReqStatus === 'loading'}
                            onClick={(e) => {
                              e.stopPropagation();
                              this.handleClickRevokeIcon(exemption.lpExemptionId);
                            }}
                          />
                        )}
                      </div>
                    </ExpansionTableRow.AlternativeSummary>
                    <ExpansionTableRow.Content>
                      <ReportedExemptionDetail exemption={exemption} />
                    </ExpansionTableRow.Content>
                  </ExpansionTableRow>
                );
              })}
            </ExpansionTableRowGroup>
          )}
          {pageStatus !== 'loading' && reportedExemptions.length === 0 && <TableNoResultsState />}
          <ListPager
            fetch={fetchReportedExemptions}
            params={params}
            pageAttribute="page"
            sizeAttribute="size"
            totalCount={totalCount}
          />
        </div>
        <DeleteConfirmationModal
          {...{
            confirmationMessage: 'Are you sure you want to revoke this exemption?',
            title: 'Revoke exemption',
            show: this.state.showConfirmationModal,
            deleteButtonText: 'REVOKE',
            onHide: this.handleCloseConfirmationModal,
            onDelete: this.handleRevokeExemption,
          }}
        />
      </div>
    );
  }
}

ReportedExemptionsList.propTypes = {
  fetchReportedExemptions: PropTypes.func,
  pageStatus: PropTypes.string,
  params: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  reportedExemptions: PropTypes.array,
  licenses: PropTypes.array,
  totalCount: PropTypes.number,
  pkLicense: PropTypes.string,
  revokeReqStatus: PropTypes.string,
  revokeExemption: PropTypes.func,
};

ReportedExemptionsList.defaultProps = {
  fetchReportedExemptions: () => {},
  pageStatus: '',
  params: {},
  history: {},
  location: {},
  reportedExemptions: [],
  licenses: [],
  totalCount: 0,
};

export default ReportedExemptionsList;
