import { put, call, select, cancelled } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';

import * as api from '../../../api/reportedExemptions';
import * as actions from '../actions';
import at from '../types';
import * as schema from '../schema';

import * as authenticationStore from '../../authentication';

export function* fetch({ filters }) {
  yield put(actions.setPageFetchStatus(filters.page, 'loading'));
  try {
    yield delay(400);
    const state = yield select();
    const authenticationSelectors = authenticationStore.selectors(state);
    const licenseeAccount = authenticationSelectors.getLicenseeAccount();

    const { user } = licenseeAccount;
    const { data } = yield call(api.fetch, user.id, filters);

    const normalizedData = normalize(data.items, schema.reportedExemptionsArray);
    yield put({
      type: at.FETCH_SUCCESS,
      filters,
      ...normalizedData,
      options: data.options,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    yield put({ type: at.FETCH_FAILURE, filters });
  } finally {
    if (yield cancelled()) {
      yield put(actions.setPageFetchStatus(filters.page, 'cancelled'));
    }
  }
}
