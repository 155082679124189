import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { PageHeader, Button, Icon, Nav, SkeletonLoader } from 'emerald-ui';
import SelectLicense from '../../containers/SelectLicense';
import AssociationReturnButton from '../AssociationReturnButton';
import * as eventsApi from '../../api/events';
import config from '../../config';
import qs from 'query-string';
import './OverviewAppHeader.css';
import { professionColorStyles, addNewStyle, convertHexToRGB, setCookie } from '../../utils';
import classnames from 'classnames';

const { legacyReportCEUrl } = config;
class OverviewAppHeader extends React.Component {
  componentDidMount() {
    addNewStyle(
      `.page-header-container {background-color: ${convertHexToRGB(this.props.professionBrandColor, 100)} !important}`
    );
  }

  handleRedirectToCEReport = () => {
    setCookie({
      name: 'CAME_FROM_REPORTCE',
      value: window.location.href,
      domain: 'cebroker.com',
    });
    const { pkLicense = 0, user = {}, subscriptionType } = this.props;
    const statisticCode = 'REPORTCE';
    const type = 'TRANSCRIPT_PRINCIPAL';
    const entityId = pkLicense;
    eventsApi.saveEvent(user.id, statisticCode, type, entityId);
    window.location.href = legacyReportCEUrl(subscriptionType, pkLicense || 0);
  };

  render() {
    const {
      professionName,
      pkLicense,
      location,
      boardLogo,
      transcriptStatus,
      resetCoursesTab,
      professionBrandColor,
      isRequestedLicense,
      history,
    } = this.props;
    const { pathname, search } = location;
    const params = qs.parse(search);
    const { cycle } = params;
    const brandingStyles = professionColorStyles(professionBrandColor);
    return (
      <div id="overview-app-header">
        {
          <PageHeader className="page-header-container" style={brandingStyles}>
            <AssociationReturnButton />
            <div className="page-header-title-container">
              <div className="page-header-license-info">
                {transcriptStatus === 'loading' || !boardLogo ? (
                  <SkeletonLoader width="63px" height="63px" className="board-logo" loading />
                ) : (
                  <img src={boardLogo} alt="Board Logo" className="board-logo" />
                )}
                <div>
                  <h2 className="text-left page-header-title">{professionName}</h2>
                  <SelectLicense
                    resetCoursesTab={resetCoursesTab}
                    theme={'dark'}
                    shape={'default'}
                    label={'License'}
                    showActions={true}
                  />
                </div>
              </div>
              {!isRequestedLicense && (
                <Button
                  id="report-ce-button"
                  size="small"
                  onClick={this.handleRedirectToCEReport}
                  className="report-overview-ce-button"
                >
                  <Icon name="add_circle_outline" /> <span>Report CE</span>
                </Button>
              )}
            </div>
            {!isRequestedLicense && (
              <Nav style={{ background: professionBrandColor ? 'none' : '' }}>
                <React.Fragment>
                  <a
                    href={`/license/${pkLicense}/overview?cycle=${cycle}`}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`/license/${pkLicense}/overview?cycle=${cycle}`);
                    }}
                    className={classnames({
                      active: pathname.includes('overview'),
                    })}
                  >
                    OVERVIEW
                  </a>
                </React.Fragment>

                <React.Fragment>
                  <a
                    href={`/license/${pkLicense}/transcript?cycle=${cycle}`}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`/license/${pkLicense}/transcript?cycle=${cycle}`);
                    }}
                    className={classnames({
                      active: pathname.includes('transcript'),
                    })}
                  >
                    TRANSCRIPT
                  </a>
                </React.Fragment>
              </Nav>
            )}
          </PageHeader>
        }
      </div>
    );
  }
}

OverviewAppHeader.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  pkLicense: PropTypes.number,
  professionName: PropTypes.string,
  licensePeriodItems: PropTypes.array,
  location: PropTypes.object,
  boardLogo: PropTypes.string,
  transcriptStatus: PropTypes.string,
  subscriptionType: PropTypes.string,
  canSeeReportedExemptions: PropTypes.bool,
  resetCoursesTab: PropTypes.func,
  professionBrandColor: PropTypes.string,
  isRequestedLicense: PropTypes.bool,
};

export default withRouter(OverviewAppHeader);
