import { combineReducers } from 'redux';

import at from '../actions/types';

const defaultState = {};

export const data = (state = defaultState, action) => {
  switch (action.type) {
    case at.FETCH_FAILURE: {
      if (state) {
        return {
          ownerLicenses: state.ownerLicenses,
          licenseCycles: state.licenseCycles,
        };
      }
      return defaultState;
    }
    case at.FETCH_SUCCESS: {
      const value = action.data;
      const cycle = action.cycleId;
      const id = `${value.transcriptDetail.licensePeriod.license.id}-${cycle}`;
      return { ...state, [id]: value };
    }
    case at.FETCH_BOARD_LOGO_SUCCESS: {
      const { boardId } = action;
      return {
        ...state,
        ...{
          transcriptDetail: {
            boardLogo: action.data,
          },
          boardLogos: {
            ...state.boardLogos,
            [boardId]: action.data,
          },
        },
      };
    }
    default:
      return state;
  }
};

export const fetchStatus = (state = [], action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      const cycle = action.cycleId;
      const id = `${action.params.pkLicense}-${cycle}`;
      return { ...state, [id]: 'loading' };
    }
    case at.FETCH_SUCCESS: {
      const value = action.data;
      const cycle = action.cycleId;
      const id = `${value.transcriptDetail.licensePeriod.license.id}-${cycle}`;
      return { ...state, [id]: 'loaded' };
    }
    case at.FETCH_FAILURE: {
      const cycle = action.cycleId;
      const id = `${action.params.pkLicense}-${cycle}`;
      return { ...state, [id]: 'failed' };
    }
    default:
      return state;
  }
};

export default combineReducers({ data, fetchStatus });
