import { put, call, select } from 'redux-saga/effects';
import * as api from '../../../api/reportedExemptions';
import at from '../types';
import * as authenticationStore from '../../authentication';
import { types as transcriptTypes } from '../../transcript';
import { types as notificationTypes } from '../../notifications';

export default function* revokeExemption({ lpExemptionId, pkLicense, meta }) {
  try {
    const state = yield select();
    const authenticationSelectors = authenticationStore.selectors(state);
    const authenticatedAccount = authenticationSelectors.getAuthenticatedAccount();
    const userLogon = authenticatedAccount.id;
    const { licenses = [] } = authenticationSelectors.getLicenseeAccount();
    const currentLicense = licenses.filter((l) => l.id === pkLicense)[0];
    const { ownerId } = currentLicense;

    yield call(api.revokeExemption, lpExemptionId, pkLicense, userLogon, ownerId);

    yield put({
      type: at.REVOKE_EXEMPTION_SUCCESS,
      lpExemptionId,
    });

    yield put({
      type: at.FETCH_REQUEST,
      filters: meta.filters,
    });

    const { currentCycleId } = currentLicense;

    yield put({
      type: transcriptTypes.FETCH_REQUEST,
      params: { pkLicense, cycleId: currentCycleId },
      cycleId: currentCycleId || 0,
    });
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put({
      type: at.REVOKE_EXEMPTION_FAILURE,
      lpExemptionId,
    });
    yield put({
      type: notificationTypes.ADD,
      notification: {
        id: Math.random(),
        message: 'There was an error trying to revoke the exemption. Try again later or contact support.',
        timeout: 6000,
      },
    });
  }
}
