import { put, call, fork, select, cancelled, spawn } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';
import isEmpty from 'lodash/isEmpty';

import * as api from '../../../api/courseHistory';
import * as actions from '../actions';
import at from '../types';
import * as schema from '../schema';

import selectors from '../selectors';

export function* fetch(filters) {
  yield put(actions.setPageFetchStatus(filters.page, 'loading'));
  try {
    yield delay(400);
    const state = yield select();
    const user = state.authentication.licenseeAccount.user;
    const { data } = yield call(api.fetch, user.id, filters);
    const normalizedData = normalize(data.items, schema.courseHistoryArray);
    yield put({
      type: at.FETCH_SUCCESS,
      filters,
      ...normalizedData,
      options: data.options,
    });
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put({ type: at.FETCH_FAILURE, filters });
  } finally {
    if (yield cancelled()) {
      yield put(actions.setPageFetchStatus(filters.page, 'cancelled'));
    }
  }
}

export function* fetchPrevAndNextIfNeeded(filters) {
  const state = yield select();
  const courseHistorySelectors = selectors(state);

  const { size, page } = filters;
  const [prevPage, nextPage] = [page - 1, page + 1];
  // const { totalItems } = licensesSelectors.getPagination();

  const prevEntities = courseHistorySelectors.getEntitiesByPage(prevPage);
  const prevPageFetchStatus = courseHistorySelectors.getPageFetchStatus(prevPage);

  const nextEntities = courseHistorySelectors.getEntitiesByPage(nextPage);
  const nextPageFetchStatus = courseHistorySelectors.getPageFetchStatus(nextPage);
  const totalCount = courseHistorySelectors.getTotalCount();
  // We will fetch the previous page if it's empty, if the current page is not the first page and if we are not already fetching the previous page
  if (isEmpty(prevEntities) && page > 1 && prevPageFetchStatus !== 'loading') {
    yield fork(fetch, { ...filters, page: prevPage });
  }

  // We will fetch the next page if it's empty, if the current page is not the last page and if we are not already fetching the next page
  if (isEmpty(nextEntities) && page * size < totalCount && nextPageFetchStatus !== 'loading') {
    yield fork(fetch, { ...filters, page: nextPage });
  }
}

export default function* watchFetch({ filters, meta = {} }) {
  if (meta.clearEntities) {
    yield put(actions.clearEntities());
  }
  const state = yield select();
  const courseHistorySelectors = selectors(state);
  const entities = courseHistorySelectors.getEntitiesByPage(filters.page);
  // If the page we are going to fetch is already loaded we just need to fetch the next and prev if needed
  if (!isEmpty(entities)) {
    yield spawn(fetchPrevAndNextIfNeeded, filters);
    return;
  }

  const pageFetchStatus = courseHistorySelectors.getPageFetchStatus(filters.page);
  if (pageFetchStatus !== 'loading') {
    yield call(fetch, filters, meta);
    yield spawn(fetchPrevAndNextIfNeeded, filters);
  }
}
