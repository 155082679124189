import { takeEvery, takeLatest } from 'redux-saga/effects';

import at from '../types';
import watchFetch from './fetch';
import fetchDetail from './fetchDetail';
import rateCourse from './rateCourse';
import deleteCourse from './deleteCourse';
import { fetchAttachments, deleteAttachment } from './attachments';
import requestDownloadPdf from './downloadPdf';
import getRequestStatus from './getRequestStatus';
import browserDownload from './browserDownload';
import updatePdfStatus from './updateDownloadPdfStatus';
import fetchSubjectAreas from './fetchSubjectAreas';

export default function* root() {
  yield takeEvery(at.UPDATE_PDF_STATUS, updatePdfStatus);
  yield takeEvery(at.DOWNLOAD_PDF_BROWSER, browserDownload);
  yield takeEvery(at.FETCH_REQUEST_PDF_STATUS, getRequestStatus);
  yield takeEvery(at.FETCH_REQUEST_PDF, requestDownloadPdf);
  yield takeLatest(at.FETCH_REQUEST, watchFetch);
  yield takeEvery(at.FETCH_DETAIL_REQUEST, fetchDetail);
  yield takeEvery(at.RATE_COURSE_REQUEST, rateCourse);
  yield takeEvery(at.DELETE_COURSE_REQUEST, deleteCourse);
  yield takeEvery(at.FETCH_ATTACHMENTS_REQUEST, fetchAttachments);
  yield takeEvery(at.DELETE_ATTACHMENT_REQUEST, deleteAttachment);
  yield takeEvery(at.FETCH_SUBJECT_AREAS_FILTER, fetchSubjectAreas);
}
