import at from './types';

export const setLicense = (licenseId) => ({
  type: at.SET_LICENSE,
  licenseId,
});

export const fetchSpotlightInfo = (params) => ({
  type: at.SPOTLIGHT_INFO_FETCH,
  params,
});

export const denySpotlightInfo = () => ({
  type: at.SPOTLIGHT_INFO_DENIED,
});

export const fetchPopularCECourses = (params) => ({
  type: at.POPULAR_COURSES_FETCH,
  params,
});

export const denyPopularCECourses = () => ({
  type: at.POPULAR_COURSES_DENIED,
});

export const fetchKeepLearningCECourses = (params) => ({
  type: at.KEEP_LEARNING_COURSES_FETCH,
  params,
});

export const denyKeepLearningCECourses = () => ({
  type: at.KEEP_LEARNING_COURSES_DENIED,
});

export const fetchRecentlyAddedCECourses = (params) => ({
  type: at.RECENTLY_ADDED_COURSES_FETCH,
  params,
});

export const denyRecentlyAddedCECourses = () => ({
  type: at.RECENTLY_ADDED_COURSES_DENIED,
});
