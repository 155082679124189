import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './SummaryHours.css';

function getDivider(i, j) {
  if (i !== j) {
    return <div className="ceb-col-divider" />;
  }
}

const SummaryHours = ({ className, values, labels, colors, ...rest }) => {
  return (
    <div className={classNames('summary-hours', className)} {...rest}>
      {values.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <div className="ceb-value-box">
              <div>
                <div className={classNames('ceb-value', colors[index])}>{item}</div>
                <div className="ceb-label">{labels[index]}</div>
              </div>
            </div>
            {getDivider(index, values.length - 1)}
          </React.Fragment>
        );
      })}
    </div>
  );
};

SummaryHours.propTypes = {
  className: PropTypes.string,
  values: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  colors: PropTypes.array,
};

export default SummaryHours;
