import at from './types';

export const add = (notification, meta) => ({
  type: at.ADD,
  notification,
  meta,
});

export const remove = (id, meta) => ({
  type: at.REMOVE,
  id,
  meta,
});
