import React from 'react';
import PropTypes from 'prop-types';

import { Button, Icon, Panel } from 'emerald-ui/lib';

import './SubjectAreaSelector.css';

import * as statisticsApi from '../../../api/statistics';

import utils from '../../../utils';
import config from '../../../config';
const { getCourseSearchUrl } = config;

class SubjectAreasSelector extends React.Component {
  handleSubjectAreaClick = (subjectAreaId) => {
    const { professionId = 0, stateCode = '', user = {}, loggedOnBehalf = false } = this.props;

    const statisticCode = 'COURSE_SEARCH_CLICK';
    const type = 'TRANSCRIPT';
    statisticsApi.saveStatistic(-1, statisticCode, type);

    const queryParams = {
      profession: professionId,
      state: stateCode,
      subjectArea: subjectAreaId,
    };
    if (loggedOnBehalf) {
      queryParams.userId = user.id;
    }
    const courseSearchUrl = getCourseSearchUrl(queryParams);
    utils.redirectTo(courseSearchUrl);
  };

  render() {
    const { parentSubjectAreaName, subjectAreas = [], onBack } = this.props;
    return (
      <Panel>
        <Panel.Body>
          <div style={{ display: 'flex' }}>
            <Button onClick={onBack}>
              <Icon name="arrow_back" style={{ marginRight: '5px' }} />
              Back
            </Button>
            <h2
              style={{
                margin: 0,
                marginLeft: '20px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {parentSubjectAreaName}
            </h2>
          </div>
          <p style={{ marginTop: '10px' }}>Multiple subject areas can be used to fulfill this requirement.</p>
          <p>Please select one subject area to search for courses.</p>
          <div className="subject-area-selector-table">
            {subjectAreas.map((sa) => {
              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                  key={sa.id}
                  style={{
                    display: 'flex',
                    padding: '14px 15px 5px 15px',
                    fontSize: '14px',
                  }}
                  onClick={() => this.handleSubjectAreaClick(sa.id)}
                >
                  <div style={{ flex: 1 }}>{sa.name}</div>
                  <div>
                    <Icon name="keyboard_arrow_right" />
                  </div>
                </div>
              );
            })}
          </div>
        </Panel.Body>
      </Panel>
    );
  }
}

SubjectAreasSelector.propTypes = {
  parentSubjectAreaName: PropTypes.string,
  professionId: PropTypes.number,
  stateCode: PropTypes.string,
  user: PropTypes.object,
  loggedOnBehalf: PropTypes.bool,
  subjectAreas: PropTypes.array,
  onBack: PropTypes.func,
};

export default SubjectAreasSelector;
