import { put, call, select } from 'redux-saga/effects';

import * as api from '../../../api/audits';
import at from '../types';

export default function* submitTask({ params: { pkLicense, cycleId } }) {
  const state = yield select();
  const {
    audits: { task = {} },
  } = state;

  try {
    const { data } = yield call(api.submitTask, pkLicense, cycleId);

    const {
      period: { id: idLicensePeriod },
      isSubmitted,
    } = data;

    const { periods = [] } = task;

    const newMapPeriods = periods.map((period) => {
      const newPeriod = { ...period };
      if (newPeriod.id === idLicensePeriod) {
        newPeriod.isSubmitted = isSubmitted;
      }

      return newPeriod;
    });

    yield put({ type: at.SET_TASK, data: { ...task, periods: newMapPeriods } });
    yield put({ type: at.SUBMIT_SUCCESS });
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put({ type: at.SUBMIT_FAILURE });
  }
}
