import React from 'react';
import PropTypes from 'prop-types';

import ReactGA from 'react-ga';

const { REACT_APP_ENABLE_GOOGLE_ANALYTICS = false } = process.env;

export default function withTracker(WrappedComponent, options = {}) {
  const trackPage = (page) => {
    ReactGA.set({ page, ...options });
    ReactGA.pageview(page, ['productsOperationLoad']);
  };

  class GATracker extends React.Component {
    componentDidMount() {
      if (REACT_APP_ENABLE_GOOGLE_ANALYTICS === 'true') {
        const { location } = this.props;
        const page = `${location.pathname}${location.search}`;
        trackPage(page);
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (REACT_APP_ENABLE_GOOGLE_ANALYTICS === 'true') {
        const currentPathname = this.props.location.pathname;
        const { pathname: nextPathname, search: nextSearch } = nextProps.location;
        const whiteList = ['/my-learning/discovery'];
        const hasToTrackParams = whiteList.includes(nextPathname);
        if (currentPathname !== nextPathname || (nextSearch && hasToTrackParams)) {
          const page = `${nextPathname}${nextSearch}`;
          trackPage(page);
        }
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  GATracker.propTypes = {
    location: PropTypes.object,
  };

  return GATracker;
}
