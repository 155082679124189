import at from './types';

export const fetchPaymentHistory = (filters, meta = {}) => ({
  type: at.FETCH_PAYMENT_HISTORY_REQUEST,
  filters,
  meta,
});

export const fetchPaymentHistoryDetail = (feeId, invoiceId, meta = {}) => ({
  type: at.FETCH_PAYMENT_HISTORY_DETAIL_REQUEST,
  feeId,
  invoiceId,
  reduxId: feeId,
  meta,
});

export const fetchSelfSubmission = (selfSubmissionId, feeId) => ({
  type: at.FETCH_SELF_SUBMISSION_REQUEST,
  selfSubmissionId,
  feeId,
  reduxId: feeId,
});

export const setPageFetchStatus = (page, status) => ({
  type: at.SET_PAGE_FETCH_STATUS,
  page,
  status,
});

export const clearEntities = () => ({
  type: at.CLEAR_ENTITIES,
});
