import React, { Component } from 'react';
import { Avatar, Panel, Col, Row, SkeletonLoader } from 'emerald-ui';
import './SettingsProfileInfo.css';

class SettingsProfileInfo extends Component {
  render() {
    return (
      <div id="settings-profile">
        <Panel>
          <Panel.Body>
            <Row>
              <Col md={4} xs={12}>
                <div className="settings-panel-title">
                  <h3>Personal info</h3>
                  <p>View and edit your personal information</p>
                </div>
              </Col>
              <Col md={8} xs={12}>
                <Row>
                  <Col md={12} xs={12}>
                    <div className="settings-section user-name">
                      <Avatar title={''} size="lg" />
                      <SkeletonLoader loading style={{ width: '130px', marginLeft: '15px' }} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} xs={12}>
                    <div className="settings-section">
                      <SkeletonLoader loading style={{ marginBottom: '15px' }} />
                      <SkeletonLoader loading />
                    </div>
                    <div className="settings-section">
                      <SkeletonLoader loading style={{ marginBottom: '15px' }} />
                      <SkeletonLoader loading />
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <div className="settings-section">
                      <SkeletonLoader loading style={{ marginBottom: '15px' }} />
                      <SkeletonLoader loading />
                    </div>
                    <div className="settings-section">
                      <SkeletonLoader loading style={{ marginBottom: '15px' }} />
                      <SkeletonLoader loading />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}

export default SettingsProfileInfo;
