import React from 'react';
import propTypes from 'prop-types';
import cebrokerIcon from './cebroker-icon.svg';
import './LoadingPage.css';

class LoadingPage extends React.Component {
  render() {
    const { extraContent } = this.props;
    return (
      <div id="loading-page">
        <div className="loader" />
        <div className="logo">
          <img src={cebrokerIcon} alt="CEBroker Logo" className="logo-fullscreen" />
          {extraContent ? <div className="extra-content">{extraContent}</div> : null}
        </div>
      </div>
    );
  }
}

LoadingPage.propTypes = {
  extraContent: propTypes.object,
};

export default LoadingPage;
