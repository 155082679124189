import React from 'react';
import './TimedText.css';

class TimedText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 0,
      interval: null,
      selectedText: { text: 'Receiving history' },
      changedText: false,
    };
    this.texts = [
      { text: 'Receiving history', sec: 0 },
      { text: 'Applying hours', sec: 3 },
      { text: 'Calculating compliance', sec: 6 },
    ];
  }

  UNSAFE_componentWillMount() {
    this.interval = setInterval(this.changeText, 1000, { firstTime: false });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  changeText = () => {
    const { time } = this.state;
    let selectedText = this.texts.find((t) => t.sec >= time);
    if (!selectedText) {
      selectedText = this.texts[this.texts.length - 1];
      clearInterval(this.interval);
    }

    if (this.interval) {
      this.setState(
        (prevState) => ({
          changedText: selectedText !== prevState.selectedText && selectedText.text !== this.texts[0].text,
        }),
        () => {
          setTimeout(() => {
            this.setState((prevState) => ({
              selectedText: selectedText,
              time: prevState.time + 1,
            }));
          }, 200);
        }
      );
    }
  };

  render() {
    const { selectedText = {}, changedText } = this.state;
    return <p className={changedText ? 'uptext' : ''}>{selectedText.text}</p>;
  }
}

export default TimedText;
