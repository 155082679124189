import { fork, takeEvery } from 'redux-saga/effects';

import { verifySecureToken } from './verifySecureToken';
import { watchLogout } from './logout';

import at from '../types';

export default function* root() {
  yield fork(verifySecureToken);
  yield takeEvery(at.LOGOUT, watchLogout);
}
