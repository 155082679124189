import React from 'react';
import PropTypes from 'prop-types';

import RedIndicator from 'emerald-ui/lib/RedIndicator';
import IconButton from 'emerald-ui/lib/IconButton';

const ShoppingCart = ({ showRedIndicator, redIndicatorNumber, hideForDesktop, onClick }) => {
  return (
    <RedIndicator
      className={`shopping-cart ${hideForDesktop && 'hidden-desktop'}`}
      active={showRedIndicator}
      number={redIndicatorNumber}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="nolink">
        <IconButton className="nav-cart-link" icon="shopping_cart" title="" onClick={onClick} />
      </a>
    </RedIndicator>
  );
};

ShoppingCart.propTypes = {
  showRedIndicator: PropTypes.bool.isRequired,
  redIndicatorNumber: PropTypes.number,
  hideForDesktop: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ShoppingCart;
