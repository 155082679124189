import { combineReducers } from 'redux';
import at from '../types';

export const fetch = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { data } = action;
      return {
        states: data.states,
        professionsByState: data.professionsByState,
      };
    }
    default:
      return state;
  }
};

export const fetchStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_REQUEST: {
      return 'loading';
    }
    case at.FETCH_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export default combineReducers({ fetch, fetchStatus });
