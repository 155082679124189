import { put, call } from 'redux-saga/effects';
import * as api from '../../../api/reportedExemptions';
import at from '../types';

export default function* fetchAttachments({ lpExemptionId }) {
  try {
    const { data } = yield call(api.fetchAttachments, lpExemptionId);
    yield put({
      type: at.FETCH_ATTACHMENTS_SUCCESS,
      lpExemptionId,
      attachments: data.items,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    yield put({
      type: at.FETCH_ATTACHMENTS_FAILURE,
      lpExemptionId,
    });
  }
}
