export default {
  FETCH_REQUEST: 'courseHistory/FETCH_REQUEST',
  FETCH_SUCCESS: 'courseHistory/FETCH_SUCCESS',
  FETCH_FAILURE: 'courseHistory/FETCH_FAILURE',

  SET_PAGE_FETCH_STATUS: 'courseHistory/SET_PAGE_FETCH_STATUS',
  CLEAR_ENTITIES: 'courseHistory/CLEAR_ENTITIES',

  FETCH_DETAIL_REQUEST: 'courseHistory/FETCH_DETAIL_REQUEST',
  FETCH_DETAIL_SUCCESS: 'courseHistory/FETCH_DETAIL_SUCCESS',
  FETCH_DETAIL_FAILURE: 'courseHistory/FETCH_DETAIL_FAILURE',

  RATE_COURSE_REQUEST: 'courseHistory/RATE_COURSE_REQUEST',
  RATE_COURSE_SUCCESS: 'courseHistory/RATE_COURSE_SUCCESS',
  RATE_COURSE_FAILURE: 'courseHistory/RATE_COURSE_FAILURE',

  DELETE_COURSE_REQUEST: 'courseHistory/DELETE_COURSE_REQUEST',
  DELETE_COURSE_SUCCESS: 'courseHistory/DELETE_COURSE_SUCCESS',
  DELETE_COURSE_FAILURE: 'courseHistory/DELETE_COURSE_FAILURE',

  ADD_ATTACHMENT_SUCCESS: 'courseHistory/ADD_ATTACHMENT_SUCCESS',

  FETCH_ATTACHMENTS_REQUEST: 'courseHistory/FETCH_ATTACHMENTS_REQUEST',
  FETCH_ATTACHMENTS_SUCCESS: 'courseHistory/FETCH_ATTACHMENTS_SUCCESS',
  FETCH_ATTACHMENTS_FAILURE: 'courseHistory/FETCH_ATTACHMENTS_FAILURE',

  DELETE_ATTACHMENT_REQUEST: 'courseHistory/DELETE_ATTACHMENT_REQUEST',
  DELETE_ATTACHMENT_SUCCESS: 'courseHistory/DELETE_ATTACHMENT_SUCCESS',
  DELETE_ATTACHMENT_FAILURE: 'courseHistory/DELETE_ATTACHMENT_FAILURE',

  FETCH_REQUEST_PDF: 'courseHistory/FETCH_REQUEST_PDF',
  FETCH_REQUEST_PDF_SUCCESS: 'courseHistory/FETCH_REQUEST_PDF_SUCCESS',
  FETCH_REQUEST_PDF_FAILURE: 'courseHistory/FETCH_REQUEST_PDF_FAILURE',

  FETCH_REQUEST_PDF_STATUS: 'courseHistory/FETCH_REQUEST_PDF_STATUS',
  FETCH_REQUEST_PDF_STATUS_SUCCESS: 'courseHistory/FETCH_REQUEST_PDF_STATUS_SUCCESS',
  FETCH_REQUEST_PDF_STATUS_FAILURE: 'courseHistory/FETCH_REQUEST_PDF_STATUS_FAILURE',
  FETCH_REQUEST_PDF_STATUS_COMPLETED: 'courseHistory/FETCH_REQUEST_PDF_STATUS_COMPLETED',
  FETCH_REQUEST_PDF_STATUS_RESET: 'courseHistory/FETCH_REQUEST_PDF_STATUS_RESET',

  RESET_REQUEST_PDF_STATUS: 'courseHistory/RESET_REQUEST_PDF_STATUS',

  UPDATE_PDF_STATUS: 'courseHistory/UPDATE_PDF_STATUS',
  UPDATE_PDF_STATUS_SUCCESS: 'courseHistory/UPDATE_PDF_STATUS_SUCCESS',
  UPDATE_PDF_STATUS_FAILURE: 'courseHistory/UPDATE_PDF_STATUS_FAILURE',

  DOWNLOAD_PDF_BROWSER: 'courseHistory/DOWLOAD_PDF_BROWSER',
  DOWNLOAD_PDF_BROWSER_SUCCESS: 'courseHistory/DOWLOAD_PDF_BROWSER_SUCCESS',
  DOWNLOAD_PDF_BROWSER_FAILURE: 'courseHistory/DOWLOAD_PDF_BROWSER_FAILURE',

  COURSE_HISTORY_SET_TIMER_DOWNLOAD: 'courseHistory/COURSE_HISTORY_SET_TIMER_DOWNLOAD',
  COURSE_HISTORY_RESET_TIMER_DOWNLOAD: 'courseHistory/COURSE_HISTORY_RESET_TIMER_DOWNLOAD',
  COURSE_HISTORY_CLEAR_TIMER_DOWNLOAD: 'courseHistory/COURSE_HISTORY_CLEAR_TIMER_DOWNLOAD',

  FETCH_SUBJECT_AREAS_FILTER: 'courseHistory/FETCH_SUBJECT_AREAS_FILTER',
  FETCH_SUCCESS_SUBJECT_AREAS_FILTER: 'courseHistory/FETCH_SUCCESS_SUBJECT_AREAS_FILTER',
  FETCH_FAILURE_SUBJECT_AREAS_FILTER: 'courseHistory/FETCH_FAILURE_SUBJECT_AREAS_FILTER',
};
