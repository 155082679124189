import { schema } from 'normalizr';

export const communications = new schema.Entity(
  'items',
  {},
  {
    idAttribute: (value) => `${value.inboxId}${value.requestId}`,
    processStrategy: (value) => ({
      ...value,
      reduxId: `${value.inboxId}${value.requestId}`,
    }),
  }
);

export const communicationsArray = new schema.Array(communications);
