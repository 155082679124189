import { takeEvery } from 'redux-saga/effects';

import at from '../types';
import fetch from './fetch';
import fetchDetail from './fetchDetail';
import saveRequestResponse from './saveRequestResponse';
import fetchAuditMessages from './fetchAuditMessages';
import saveAuditResponse from './saveAuditResponse';

export default function* root() {
  yield takeEvery(at.FETCH_REQUEST, fetch);
  yield takeEvery(at.FETCH_DETAIL_REQUEST, fetchDetail);
  yield takeEvery(at.SAVE_REQUEST_RESPONSE_REQUEST, saveRequestResponse);
  yield takeEvery(at.FETCH_AUDIT_REQUEST, fetchAuditMessages);
  yield takeEvery(at.SAVE_AUDIT_RESPONSE_REQUEST, saveAuditResponse);
}
