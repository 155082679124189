import { combineReducers } from 'redux';
import at from '../types';

export const show = (state = false, action) => {
  switch (action.type) {
    case at.SHOW_OVERVIEW: {
      return true;
    }
    case at.HIDE_OVERVIEW: {
      return false;
    }
    default:
      return state;
  }
};

export default combineReducers({
  show,
});
