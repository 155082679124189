import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Overview from '../../containers/Overview';
import OverviewAppHeader from '../OverviewAppHeader';
import TranscriptRouter from '../../containers/TranscriptRouter';
import ReportedExemptionsList from '../../containers/ReportedExemptionsList';
import './OverviewPage.css';
import useSavedOfferings from 'hooks/savedOfferings';

function OverviewPage({
  selectedLicense: license = {},
  location,
  transcriptStatus,
  user,
  professionBrandColor,
  isRequestedLicense,
  selectedRequestedLicense: requestedLicense = {},
  boardId,
  boardLogo,
  fetchBoardLogo,
  subscriptionType,
  fetchNotifications,
  showFavoriteIcon,
  addNotification,
}) {
  const [shouldResetCoursesTab, setShouldResetCoursesTab] = useState(false);
  const {
    savedOfferings: savedOfferingsList = [],
    handleSaveOffering,
    statuses: savingStatuses = {},
  } = useSavedOfferings(user.id, addNotification);

  useEffect(() => {
    if (!boardLogo) {
      fetchBoardLogo(boardId);
    }
    if (subscriptionType !== 'BAS') {
      fetchNotifications({ page: 1, size: 20, isArchived: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetCoursesTab = () => {
    setShouldResetCoursesTab(true);
  };

  const restoreTab = () => {
    setShouldResetCoursesTab(false);
  };

  const { profession = {} } = license;
  const { profession: professionRequested = {} } = requestedLicense;
  const { pathname } = location;

  return (
    <div id="overview-page">
      <OverviewAppHeader
        professionName={isRequestedLicense ? professionRequested.name : profession.name}
        canSeeReportedExemptions={license.inAllowReportExemption}
        pkLicense={license.id}
        selectedTab={pathname}
        boardLogo={boardLogo}
        transcriptStatus={transcriptStatus}
        subscriptionType={subscriptionType}
        user={user}
        resetCoursesTab={resetCoursesTab}
        professionBrandColor={professionBrandColor}
        isRequestedLicense={isRequestedLicense}
      />
      <Switch>
        <Route path="/license/:pkLicense/transcript" component={TranscriptRouter} />
        <Route path="/license/:pkLicense/reported-exemptions" component={ReportedExemptionsList} />
        <Route
          path="/license/:pkLicense"
          render={(props) => {
            return (
              <Overview
                {...props}
                resetCoursesTab={shouldResetCoursesTab}
                restoreTab={restoreTab}
                savedOfferingsList={savedOfferingsList}
                handleSaveOffering={handleSaveOffering}
                savingStatuses={savingStatuses}
                showFavoriteIcon={showFavoriteIcon}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
}

OverviewPage.propTypes = {
  selectedLicense: PropTypes.object,
  location: PropTypes.object,
  boardLogo: PropTypes.string,
  transcriptStatus: PropTypes.string,
  subscriptionType: PropTypes.string,
  user: PropTypes.object,
  fetchBoardLogo: PropTypes.func,
  boardId: PropTypes.number,
  professionBrandColor: PropTypes.string,
  isRequestedLicense: PropTypes.bool,
  selectedRequestedLicense: PropTypes.object,
  fetchNotifications: PropTypes.func,
  showFavoriteIcon: PropTypes.bool,
};

export default OverviewPage;
