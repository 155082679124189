import { combineReducers } from 'redux';
import at from '../types';

export const entities = (state = [], action) => {
  switch (action.type) {
    case at.FETCH_CART_SUCCESS: {
      return { ...state, ...action.entity };
    }
    default:
      return state;
  }
};

export const fetchStatuses = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_CART_REQUEST: {
      return 'loading';
    }
    case at.FETCH_CART_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_CART_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const fetchErrors = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_CART_REQUEST:
    case at.FETCH_CART_SUCCESS: {
      return state;
    }
    case at.FETCH_CART_FAILURE: {
      return action.error;
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  fetchStatuses,
  fetchErrors,
});
