import React from 'react';
import PropTypes from 'prop-types';

import { Icon, FileUploadList, Button, Row, Col, Container } from 'emerald-ui';

import PostingInfoSection from './PostingInfoSection';
import QuestionsSection from './QuestionsSection';
import SubjectAreasSection from './SubjectAreasSection';
import CourseHistoryDetailSkeleton from '../../CourseHistoryDetailSkeleton';
import { openDownloadCertificate } from '../../../config';

import './CourseHistoryDetail.css';

class CourseHistoryDetail extends React.Component {
  render() {
    const {
      course,
      subscriptionType,
      licenseLabelCap,
      courseLoadingStatus,
      onUploadFileClick,
      isSelfSubmission,
      onDeleteAttachment,
      getDeleteAttachmentStatus,
      onRatingChange,
    } = this.props;

    const files =
      course.attachments && course.attachments.length > 0
        ? course.attachments.map((a) => {
            let name = a.name || a.fileName || '';
            name = name.toLowerCase();
            const array = name.split('.');
            const isImage = array.length > 1 && (array[1] === 'png' || array[1] === 'jpg');
            const isDeletingAttachment = getDeleteAttachmentStatus(a.token) === 'deleting';
            return {
              id: `${a.token}`,
              name: name,
              icon: isImage ? a.url : '',
              onDownload: () => {
                window.open(a.url);
              },
              onCancel: (filename, id) => {
                if (!course.isDeleted && !isDeletingAttachment) {
                  onDeleteAttachment(filename, id, course);
                }
              },
              cancelIcon: isDeletingAttachment ? 'hourglass_empty' : 'delete',
            };
          })
        : [];

    return (
      <Container id="course-history-detail">
        {courseLoadingStatus === 'loading' && <CourseHistoryDetailSkeleton />}
        {courseLoadingStatus === 'loaded' && (
          <React.Fragment>
            {course.processError.length > 0 && (
              <Row className="warnings">
                {course.processError.map((e, i) => (
                  <p className="warning" key={`course-history-detail-process-error-${i}`}>
                    <Icon name="warning" className="warning-icon" />
                    <span>{e}</span>
                  </p>
                ))}
              </Row>
            )}
            {course.components && course.components.length > 0 && (
              <SubjectAreasSection
                isMustFind={course.isMustFind}
                components={course.components}
                rosterCode={course.rosterCode}
              />
            )}
            <Row>
              <Col xs={12}>
                <hr />
              </Col>
            </Row>
            <Row style={{ marginTop: '10px', marginBottom: '11px' }}>
              <Col xs={12} md={6}>
                <PostingInfoSection
                  course={course}
                  isSelfSubmission={isSelfSubmission}
                  onRatingChange={onRatingChange}
                  licenseLabelCap={licenseLabelCap}
                />
              </Col>
              {course.questions && course.questions.length > 0 && (
                <Col xs={12} md={6}>
                  <QuestionsSection questions={course.questions} />
                </Col>
              )}
            </Row>
            {course.postCECreditComments && (
              <Row>
                <Col xs={12}>
                  <hr />
                  <Col xs={12} className="section-col">
                    <h4 className="course-history-detail-header">Comments</h4>
                    {course.postCECreditComments}
                  </Col>
                </Col>
              </Row>
            )}
            {course.postCECreditSubmission && (
              <Row>
                <Col xs={12}>
                  <hr />
                  <Row className="section-row">
                    <Col xs={5} className="section-col">
                      <span className="course-history-detail-header">Credit submission will be applied to</span>
                    </Col>
                    <Col xs={7} className="section-col">
                      {course.postCECreditSubmission}
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            {course.certificateId > 0 ? (
              <div>
                <Col xs={12}>
                  <hr />
                </Col>
                <Col xs={12}>
                  <h4 className="course-history-detail-header">Attachments</h4>
                  <div className="attachments-box">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        openDownloadCertificate(course.certificateId);
                      }}
                    >
                      Certificate
                    </a>
                  </div>
                </Col>
              </div>
            ) : (
              <div>
                <Row>
                  <Col xs={12}>
                    <hr />
                  </Col>
                </Row>
                <Col xs={12} style={{ marginTop: '31px' }}>
                  <div className="attachments-box">
                    <div className="attachments-box-header">
                      <span className="attach-icon-text">
                        Attachments
                        {` (${files.length})`}
                      </span>
                      {subscriptionType !== 'BAS' && !course.isDeleted && (
                        <Button
                          className="upload-files-button"
                          color="info"
                          onClick={() => {
                            onUploadFileClick(course.id);
                          }}
                        >
                          Upload files
                        </Button>
                      )}
                    </div>
                    {files.length > 0 ? (
                      <div className="attachments-list">
                        <FileUploadList files={files} />
                      </div>
                    ) : (
                      <p>No attachments found</p>
                    )}
                  </div>
                </Col>
              </div>
            )}
          </React.Fragment>
        )}
      </Container>
    );
  }
}

CourseHistoryDetail.propTypes = {
  course: PropTypes.object,
  subscriptionType: PropTypes.string,
  licenseLabelCap: PropTypes.string,
  courseLoadingStatus: PropTypes.string,
  onUploadFileClick: PropTypes.func,
  isSelfSubmission: PropTypes.bool,
  onDeleteAttachment: PropTypes.func,
  onRatingChange: PropTypes.func,
  getDeleteAttachmentStatus: PropTypes.func,
};

CourseHistoryDetail.defaultProps = {
  course: {},
  subscriptionType: 'BAS',
  courseLoadingStatus: 'loading',
  licenseLabelCap: 'License',
};

export default CourseHistoryDetail;
