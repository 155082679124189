export default {
  FETCH_REQUEST: 'reportedExemptions/FETCH_REQUEST',
  FETCH_SUCCESS: 'reportedExemptions/FETCH_SUCCESS',
  FETCH_FAILURE: 'reportedExemptions/FETCH_FAILURE',

  FETCH_QUESTIONS_REQUEST: 'reportedExemptions/FETCH_QUESTIONS_REQUEST',
  FETCH_QUESTIONS_SUCCESS: 'reportedExemptions/FETCH_QUESTIONS_SUCCESS',
  FETCH_QUESTIONS_FAILURE: 'reportedExemptions/FETCH_QUESTIONS_FAILURE',

  FETCH_ATTACHMENTS_REQUEST: 'reportedExemptions/FETCH_ATTACHMENTS_REQUEST',
  FETCH_ATTACHMENTS_SUCCESS: 'reportedExemptions/FETCH_ATTACHMENTS_SUCCESS',
  FETCH_ATTACHMENTS_FAILURE: 'reportedExemptions/FETCH_ATTACHMENTS_FAILURE',

  REVOKE_EXEMPTION_REQUEST: 'reportedExemptions/REVOKE_EXEMPTION_REQUEST',
  REVOKE_EXEMPTION_SUCCESS: 'reportedExemptions/REVOKE_EXEMPTION_SUCCESS',
  REVOKE_EXEMPTION_FAILURE: 'reportedExemptions/REVOKE_EXEMPTION_FAILURE',

  SET_PAGE_FETCH_STATUS: 'reportedExemptions/SET_PAGE_FETCH_STATUS',
  CLEAR_ENTITIES: 'reportedExemptions/CLEAR_ENTITIES',
};
