import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { Button, Row, Col } from 'emerald-ui/lib';
import StarRatingComponent from 'react-star-rating-component';

import { INPUT_FORMAT_DATE } from '../../../config';

const formatPhone = (phone) => {
  const phoneString = phone.toString();
  if (phoneString.length < 7) {
    return phoneString;
  }

  const array = phoneString.split('');
  if (array.length === 7) {
    return [...array.slice(0, 3), '-', ...array.slice(3, array.length)].join('');
  }
  const number = array.length - 7;
  return [
    '(',
    ...array.slice(0, number),
    ') ',
    ...array.slice(number, number + 3),
    '-',
    ...array.slice(number + 3, array.length),
  ].join('');
};

const Address = ({ title, street, city, state, zipCode }) => (
  <div className="provider-address">
    <p className="provider-address-title">{title}</p>
    {street || city || state || zipCode ? (
      <Fragment>
        <p>{street}</p>
        {(city || state) && (
          <p>
            {city}
            {city && state && ', '}
            {state}
          </p>
        )}
        {zipCode && <p>Zip: {zipCode}</p>}
      </Fragment>
    ) : (
      <p>--</p>
    )}
  </div>
);

const ProviderInfo = ({ provider }) => {
  const { name, phone, phoneExtension, website, businessAddress, mailAddress } = provider;

  return (
    <Row>
      <Col xs={5} className="section-col">
        <span className="course-detail-subheader key-stl">Educational Provider</span>
      </Col>
      <Col xs={7} className="section-col">
        {name && (
          <p>
            <strong>{name}</strong>
          </p>
        )}
        {phone && (
          <p>
            {phone && formatPhone(phone)}
            {phone && phoneExtension && ` Extension ${phoneExtension}`}
          </p>
        )}
        {website && <p style={{ marginBottom: '20px' }}>{website}</p>}
        <Address
          title="Physical business address"
          street={businessAddress.streetAddress}
          city={businessAddress.city}
          state={businessAddress.state}
          zipCode={businessAddress.zipCode}
        />
        <Address
          title="Course address"
          street={mailAddress.streetAddress}
          city={mailAddress.city}
          state={mailAddress.state}
          zipCode={mailAddress.zipCode}
        />
      </Col>
    </Row>
  );
};

ProviderInfo.propTypes = {
  provider: PropTypes.object,
};

const getDateField = (field) => {
  return field ? moment(field, INPUT_FORMAT_DATE).format('MMM DD, YYYY') : '--';
};

class PostingInfoSection extends React.Component {
  render() {
    const { course, isSelfSubmission, onRatingChange, licenseLabelCap } = this.props;
    const courseType = course.courseType === 'CD_LIVE' ? 'Live' : course.courseType === 'CD_ANYTIME' ? 'Anytime' : '';
    return (
      <Row id="posting-info-section" className="section-row">
        <Col xs={12}>
          <h4 className="course-history-detail-header">Post info</h4>
          <Row>
            <Col xs={5} className="section-col">
              <span className="course-detail-subheader key-stl">Tracking #</span>
            </Col>
            <Col xs={7} className="section-col">
              {course.rosterCode === 'LICENSEE_POST' || course.rosterCode === 'BOARD_POST'
                ? `${course.trackingPrefix} - ${course.entityId}`
                : course.courseTrackingNumber}
            </Col>
          </Row>
          {(course.rosterCode === 'LICENSEE_POST' || course.rosterCode === 'BOARD_POST') && (
            <Row>
              <Col xs={5} className="section-col">
                <span className="course-detail-subheader key-stl">Expected</span>
              </Col>
              <Col xs={7} className="section-col">
                {getDateField(course.expectedDateCompleted)}
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={5} className="section-col">
              <span className="course-detail-subheader key-stl">Submitted</span>
            </Col>
            <Col xs={7} className="section-col">
              {getDateField(course.submittedDate)}
            </Col>
          </Row>
          <Row>
            <Col xs={5} className="section-col">
              <span className="course-detail-subheader key-stl">Posted by</span>
            </Col>
            <Col xs={7} className="section-col">
              {course.providerName === null ? course.source : course.providerName}
            </Col>
          </Row>
          <Row className="number-container">
            <Col xs={5} className="section-col">
              <span className="course-detail-subheader key-stl">{licenseLabelCap} number</span>
            </Col>
            <Col xs={7} className="section-col">
              {course.detailLicenseNumber}
            </Col>
          </Row>
          {isSelfSubmission && (
            <Row>
              <Col xs={5} className="section-col">
                <span className="course-detail-subheader key-stl">Status</span>
              </Col>
              <Col xs={7} className="section-col">
                {course.postCECreditStatus}
              </Col>
            </Row>
          )}
          {courseType && (
            <Row>
              <Col xs={5} className="section-col">
                <span className="course-detail-subheader key-stl">Course type</span>
              </Col>
              <Col xs={7} className="section-col">
                {courseType}
              </Col>
            </Row>
          )}
          {course.postCECreditDocumentation && (
            <Row>
              <Col xs={5} className="section-col">
                <span className="course-detail-subheader key-stl">Documentation</span>
              </Col>
              <Col xs={7} className="section-col">
                {course.postCECreditDocumentation}
              </Col>
            </Row>
          )}
          {!course.isDeleted && (
            <Row className="course-detail-rating">
              <Col
                xs={5}
                className="section-col"
                style={course.rating > 0 ? { marginTop: '12px' } : { marginTop: '14px' }}
              >
                <span className="course-detail-subheader key-stl">Rating</span>
              </Col>
              <Col xs={7} className="section-col">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div onClick={(e) => e.stopPropagation()}>
                  {course.rating > 0 ? (
                    <StarRatingComponent
                      name={`rate-${course.id}`}
                      starCount={5}
                      value={parseInt(course.rating, 10)}
                      starColor="#0073e9"
                      emptyStarColor="#E0E7EA"
                      className="rating-stars"
                      onStarClick={(rating, previousRating) => {
                        onRatingChange(course.reduxId, rating, previousRating);
                      }}
                    />
                  ) : (
                    <Button
                      shape="flat"
                      color="info"
                      className="btn-anchor"
                      onClick={() => {
                        onRatingChange(course.reduxId, 0, 0);
                      }}
                    >
                      Rate course
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          )}
          {course.deliveryMethod && (
            <Row>
              <Col xs={5} className="section-col">
                <span className="course-detail-subheader key-stl">Delivery method</span>
              </Col>
              <Col xs={7} className="section-col">
                {course.deliveryMethod}
              </Col>
            </Row>
          )}
          {course.provider && <ProviderInfo provider={course.provider} />}
          {course.course && (
            <Row>
              <Col xs={5} className="section-col">
                <span className="course-detail-subheader key-stl">Course</span>
              </Col>
              <Col xs={7} className="section-col">
                {course.course.name && (
                  <p>
                    <strong>{course.course.name}</strong>
                  </p>
                )}
                {course.course.id ? <p>{`20-${course.course.id}`}</p> : ''}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    );
  }
}

PostingInfoSection.propTypes = {
  course: PropTypes.object,
  isSelfSubmission: PropTypes.bool,
  licenseLabelCap: PropTypes.string,
  onRatingChange: PropTypes.func,
};

export default PostingInfoSection;
