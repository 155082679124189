import at from './types';

export const fetch = (filters, meta) => ({
  type: at.FETCH_REQUEST,
  filters,
  meta,
});

export const fetchDetail = (filters, meta) => ({
  type: at.FETCH_DETAIL_REQUEST,
  filters,
  meta,
});

export const saveRequestResponse = (filters, meta) => ({
  type: at.SAVE_REQUEST_RESPONSE_REQUEST,
  filters,
  meta,
});

export const setPageFetchStatus = (filters, status) => ({
  type: at.SET_PAGE_FETCH_STATUS,
  filters,
  status,
});

export const fetchAuditMessages = (actionType, meta) => ({
  type: at.FETCH_AUDIT_REQUEST,
  actionType,
  meta,
});

export const saveAuditResponse = (information, meta) => ({
  type: at.SAVE_AUDIT_RESPONSE_REQUEST,
  information,
  meta,
});

export const clearEntities = () => ({
  type: at.CLEAR_ENTITIES,
});
