export default {
  /* TOGGLE */
  GET_LICENSE: 'discoverySection/GET_LICENSE',
  SET_LICENSE: 'discoverySection/SET_LICENSE',

  /* SPOTLIGHT */
  SPOTLIGHT_INFO_FETCH: 'discoverySection/SPOTLIGHT_INFO_FETCH',
  SPOTLIGHT_INFO_SUCCESS: 'discoverySection/SPOTLIGHT_INFO_SUCCESS',
  SPOTLIGHT_INFO_FAILURE: 'discoverySection/SPOTLIGHT_INFO_FAILURE',
  SPOTLIGHT_INFO_DENIED: 'discoverySection/SPOTLIGHT_INFO_DENIED',

  /* POPULAR COURSES */
  POPULAR_COURSES_FETCH: 'discoverySection/POPULAR_COURSES_FETCH',
  POPULAR_COURSES_SUCCESS: 'discoverySection/POPULAR_COURSES_SUCCESS',
  POPULAR_COURSES_FAILURE: 'discoverySection/POPULAR_COURSES_FAILURE',
  POPULAR_COURSES_DENIED: 'discoverySection/POPULAR_COURSES_DENIED',

  /* KEEP LEARNING COURSES */
  KEEP_LEARNING_COURSES_FETCH: 'discoverySection/KEEP_LEARNING_COURSES_FETCH',
  KEEP_LEARNING_COURSES_SUCCESS: 'discoverySection/KEEP_LEARNING_COURSES_SUCCESS',
  KEEP_LEARNING_COURSES_FAILURE: 'discoverySection/KEEP_LEARNING_COURSES_FAILURE',
  KEEP_LEARNING_COURSES_DENIED: 'discoverySection/KEEP_LEARNING_COURSES_DENIED',

  /* RECENTLY ADDED COURSES */
  RECENTLY_ADDED_COURSES_FETCH: 'discoverySection/RECENTLY_ADDED_COURSES_FETCH',
  RECENTLY_ADDED_COURSES_SUCCESS: 'discoverySection/RECENTLY_ADDED_COURSES_SUCCESS',
  RECENTLY_ADDED_COURSES_FAILURE: 'discoverySection/RECENTLY_ADDED_COURSES_FAILURE',
  RECENTLY_ADDED_COURSES_DENIED: 'discoverySection/RECENTLY_ADDED_COURSES_DENIED',
};
