import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import CourseCommentSection from '../CourseCommentSection';
import CourseRatingDetail from '../CourseratingDetail/CourseRatingDetail';
import './CourseReviews.css';
import useCourseRatingNumbers from 'hooks/useCourseRatingNumbers';

const DEFAULT_RATING_VALUE_FILTER = 0;
export default function CourseReviews({ rating, ratingCount, offeringId }) {
  const [ratingValueSelected, setRatingValueSelected] = useState(DEFAULT_RATING_VALUE_FILTER);
  const [shouldResetRatingFilterSection, setShouldResetRatingFilterSection] = useState(false);

  const { data: courseRatingNumbers = {} } = useCourseRatingNumbers(offeringId);
  const { totalRatings } = courseRatingNumbers;

  if (!totalRatings) {
    return <Fragment />;
  }

  const handleRatingValueFilter = (ratingValue) => {
    setRatingValueSelected(ratingValue);
    setShouldResetRatingFilterSection(false);
  };

  const handleSortOptionFilter = () => {
    setRatingValueSelected(DEFAULT_RATING_VALUE_FILTER);
    setShouldResetRatingFilterSection(true);
  };

  return (
    <div id="course-rating-feedback-link" className="course-reviews-container">
      <CourseRatingDetail
        rating={rating}
        ratingCount={ratingCount}
        courseRatingNumbers={courseRatingNumbers}
        shouldResetValues={shouldResetRatingFilterSection}
        onRatingValueFilterChange={handleRatingValueFilter}
      />
      <CourseCommentSection
        rating={ratingValueSelected}
        offeringId={offeringId}
        onSortingOptionChange={handleSortOptionFilter}
      />
    </div>
  );
}

CourseReviews.propTypes = {
  ratings: PropTypes.array.isRequired,
  ratingCount: PropTypes.number.isRequired,
  rating: PropTypes.number.isRequired,
  offeringId: PropTypes.number.isRequired,
};
