export default function (globalState) {
  const state = globalState.discoveryTab;

  const getLicenseSelected = () => state.licenseSelected;
  const getSpotlightInfo = () => state.spotlightSection;
  const getPopularCECourses = () => state.popularCECourses;
  const getKeepLearningCECourses = () => state.keepLearningCECourses;
  const getRecentlyAddedCECourses = () => state.recentlyAddedCECourses;

  return {
    getLicenseSelected,
    getSpotlightInfo,
    getPopularCECourses,
    getKeepLearningCECourses,
    getRecentlyAddedCECourses,
  };
}
