import { call, put } from 'redux-saga/effects';

import * as api from '../../../api/boards';
import at from '../actions/types';

export default function* fetchBoardLogo({ boardId }) {
  try {
    const boardLogo = yield call(api.fetchBoardLogo, boardId);
    yield put({
      type: at.FETCH_BOARD_LOGO_SUCCESS,
      data: boardLogo.data.boardLogoUrl,
      boardId,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({ type: at.FETCH_BOARD_LOGO_FAILURE, boardId });
  }
}
