import { schema } from 'normalizr';

export const courseHistory = new schema.Entity(
  'items',
  {},
  {
    idAttribute: (value) => `${value.id}-${value.pkLicense}`,
    processStrategy: (value) => ({
      ...value,
      reduxId: `${value.id}-${value.pkLicense}`,
    }),
  }
);

export const courseHistoryArray = new schema.Array(courseHistory);
