import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as transcriptStore from '../store/transcript';
import { actions as courseHistoryActions } from '../store/courseHistory';
import { actions as reportedExemptionsActions } from '../store/reportedExemptions';
import { actions as auditActions } from '../store/audits';
import * as authenticationStore from '../store/authentication';
import * as discoveryStore from '../store/discoveryTab';
import { refineQuery } from '../utils';
import queryString from 'query-string';

import SelectLicense from '../components/SelectLicense';

function mapStateToProps(state, ownProps) {
  const { match } = ownProps;
  const transcriptSelectors = transcriptStore.selectors(state);
  const authenticationSelectors = authenticationStore.selectors(state);
  const discoverySelectors = discoveryStore.selectors(state);
  const licenseeAccount = authenticationSelectors.getLicenseeAccount();
  const selectedLicenseId = discoverySelectors.getLicenseSelected();
  const { user, associationUsersInfo = {}, subscriptions = [] } = licenseeAccount;
  let { licenses, requestedLicenses = [] } = licenseeAccount;

  const { pkLicense } = match.params;

  const selectedLicense = licenses.find((l) => l.id === pkLicense);

  requestedLicenses = requestedLicenses.map((requestedLicense) => ({
    ...requestedLicense,
    isRequestedLicense: true,
  }));

  licenses = licenses.concat(requestedLicenses);

  const filters = refineQuery(queryString.parse(ownProps.location.search));
  filters.pkLicense = pkLicense;
  if (!filters.page) {
    filters.page = 1;
  }
  if (!filters.size) {
    filters.size = 10;
  }

  const boardLogos = transcriptSelectors.getBoardLogos();
  let subscriptionType = '';

  if (subscriptions.length > 0) {
    subscriptionType = subscriptions[0].type;
  }

  return {
    user,
    selectedLicense,
    licenses,
    filters,
    associationUsersInfo,
    boardLogos,
    selectedLicenseId,
    subscriptionType,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchTranscript: transcriptStore.actions.fetch,
    fetchBoardLogo: transcriptStore.actions.fetchBoardLogo,
    fetchCourseHistory: courseHistoryActions.fetch,
    fetchReportedExemptions: reportedExemptionsActions.fetch,
    clearTimerDownload: courseHistoryActions.clearTimerDownload,
    fetchAuditTask: auditActions.fetch,
    setLicenseToggle: discoveryStore.actions.setLicense,
    fetchSubjectAreas: courseHistoryActions.fetchSubjectAreas,
  })(SelectLicense)
);
