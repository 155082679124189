import React from 'react';

import { SkeletonLoader } from 'emerald-ui/lib';

import './CourseDetailLoader.css';

class CourseDetailLoader extends React.PureComponent {
  render() {
    return (
      <div className="course-detail-info-panel loader">
        <div className="course-detail-info-wrapper">
          <div className="course-detail-header loader">
            <SkeletonLoader loading />
            <SkeletonLoader loading style={{ height: '41px' }} />
          </div>
          <div className="course-detail-floating-section loader">
            <SkeletonLoader loading style={{ height: '193px', marginBottom: '15px' }} />
            <SkeletonLoader loading style={{ height: '221px', marginBottom: '15px' }} />
            <SkeletonLoader loading style={{ height: '116px' }} />
          </div>

          <div className="course-detail-content loader">
            <SkeletonLoader loading style={{ height: '200px' }} />
          </div>
        </div>
      </div>
    );
  }
}

export default CourseDetailLoader;
