import { put, call } from 'redux-saga/effects';
import * as api from '../../../api/discoveryTab';
import at from '../types';

export default function* fetchPopularCECourses({ params }) {
  try {
    const results = yield call(api.fetchPopularCourses, params);
    const { items } = results && results.data;
    yield put({
      type: at.POPULAR_COURSES_SUCCESS,
      popularCECourses: items,
    });
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    yield put({
      type: at.POPULAR_COURSES_FAILURE,
    });
  }
}
