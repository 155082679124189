import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import queryString from 'query-string';

import { SingleSelect, DropdownButton, DropdownItem } from 'emerald-ui/lib';

import './CycleSelector.css';

class CycleSelector extends React.Component {
  constructor(props) {
    super(props);

    this.licenseInfo = {
      number: '',
      stateCode: '',
      professionId: 0,
    };
  }

  handlePrintOptionsSelect = (key) => {
    const { onDownloadPdf, onPrintPdf } = this.props;
    if (key === '1') {
      onPrintPdf();
    } else if (key === '2') {
      onDownloadPdf();
    }
  };

  handleCycleSelect = (id) => {
    const { fetchTranscript, location = {}, history = {}, match = {}, currentLicense = {} } = this.props;

    const { number, professionId, stateCode } = this.licenseInfo;

    const { params = {} } = match;
    const query = queryString.parse(location.search);
    query.cycle = id > 0 ? id : 0;
    const search = queryString.stringify(query);
    history.push(`?${search}`);

    const fetchParams = {
      pkLicense: params.pkLicense,
      cycleId: query.cycle,
      licenseNumber: number,
      professionId,
      stateCode,
    };

    if (currentLicense.isDualTranscript) {
      fetchParams.licenseNumber = currentLicense.number;
      fetchParams.stateCode = currentLicense.profession.board.state.code;
      fetchParams.professionId = currentLicense.profession.id;
    }

    fetchTranscript(fetchParams);
  };

  render() {
    const { licensePeriods, selectedPeriodId } = this.props;

    const options = Array.isArray(licensePeriods)
      ? licensePeriods
          .filter((p) => p.id > 0)
          .map((p) => {
            const {
              transcriptFields: { name },
            } = p;
            return (
              <option key={p.id} value={p.id} selected={p.id === selectedPeriodId}>
                {name}
              </option>
            );
          })
      : [];

    return (
      <div className="cycle-selector">
        <div className="cycle-select-container">
          <SingleSelect
            label="Transcript cycle"
            shape="default"
            id="license_periods"
            onSelect={this.handleCycleSelect}
            className="cycle-select"
          >
            {options}
          </SingleSelect>
        </div>
        <DropdownButton
          title={<i className="material-icons">more_horiz</i>}
          noCaret
          className="print-options-dropdown"
          id="print-options-dropdown"
          onSelect={this.handlePrintOptionsSelect}
        >
          <DropdownItem eventKey="1">
            <i className="material-icons">print</i> Print transcript
          </DropdownItem>
          <DropdownItem eventKey="2">
            <i className="material-icons">cloud_download</i> Export to pdf
          </DropdownItem>
        </DropdownButton>
      </div>
    );
  }
}

CycleSelector.propTypes = {
  licensePeriods: PropTypes.array,
  selectedPeriodId: PropTypes.number,
  fetchTranscript: PropTypes.func,
  onDownloadPdf: PropTypes.func,
  onPrintPdf: PropTypes.func,

  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  currentLicense: PropTypes.object,
};

CycleSelector.defaultProps = {
  licenses: [],
};

export default withRouter(CycleSelector);
