import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import Button from 'emerald-ui/lib/Button';
import SingleSelect from 'emerald-ui/lib/SingleSelect';

import './CourseCommentSection.css';
import CourseComment from '../CourseComment/CourseComment';
import { useCourseReviews } from 'hooks/useCoursesReviews';

const { REACT_APP_REVIEWS_ENABLED } = process.env;
const DEFAULT_PAGE_SIZE = 5;
const DEFAULT_SORT_ORDER = 'MOST_RECENT';
const REVIEWS_SORT_OPTIONS = [
  { value: 'MOST_RECENT', text: 'Most recent' },
  { value: 'HIGHEST_RATED', text: 'Highest rated' },
  { value: 'LOWEST_RATED', text: 'Lowest rated' },
];

export default function CourseCommentSection({ offeringId, rating, onSortingOptionChange }) {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [sortOptionSelected, setSortOptionSelected] = useState(DEFAULT_SORT_ORDER);

  const queryClient = useQueryClient();
  const { isLoading, data } = useCourseReviews({
    pageSize,
    offeringId,
    orderBy: sortOptionSelected,
    rating: rating > 0 ? rating : undefined,
  });

  const { reviews = [], hasMore = false } = data || {};

  useEffect(() => {
    if (Boolean(rating) && sortOptionSelected !== DEFAULT_SORT_ORDER) {
      setSortOptionSelected(DEFAULT_SORT_ORDER);
    }
    setPageSize(DEFAULT_PAGE_SIZE);

    return () => {
      queryClient.removeQueries({ queryKey: ['reviews'], exact: true });
    };
  }, [queryClient, rating, sortOptionSelected]);

  const isReviewsSectionEnabled = REACT_APP_REVIEWS_ENABLED === 'true';

  const onSelectSortOption = (optionSelected) => {
    setSortOptionSelected(optionSelected);
    onSortingOptionChange(optionSelected);
  };

  const fetchNextPage = () => {
    const newPageSize = pageSize + DEFAULT_PAGE_SIZE;
    setPageSize(newPageSize);
  };

  if ((!isReviewsSectionEnabled || reviews.length === 0) && !isLoading) {
    return (
      <div className="blank-state">
        <p>Be the first to share feedback about your learning experience!</p>
      </div>
    );
  }

  return (
    <div className="course-comment-section-container">
      <SingleSelect
        id="reviewsSortOptions"
        className="reviews-sort-options"
        onSelect={(value) => {
          onSelectSortOption(value);
        }}
      >
        {REVIEWS_SORT_OPTIONS.map((opt) => (
          <option value={opt.value} key={opt.value} selected={opt.value === sortOptionSelected}>
            {opt.text}
          </option>
        ))}
      </SingleSelect>
      {isReviewsSectionEnabled && reviews.length > 0 && (
        <>
          {reviews.map(({ rosterAttendeeId, rating, author, comment, createdAt }) => (
            <CourseComment
              key={rosterAttendeeId}
              rating={rating}
              author={author}
              comment={comment}
              createdAt={createdAt}
            />
          ))}
          <Button disabled={isLoading || !hasMore} onClick={fetchNextPage}>
            More reviews
          </Button>
        </>
      )}

      {isReviewsSectionEnabled && isLoading && reviews.length === 0 && <CourseComment isLoading={isLoading} />}
    </div>
  );
}

CourseCommentSection.propTypes = {
  offeringId: PropTypes.number.isRequired,
  rating: PropTypes.number,
  onSortingOptionChange: PropTypes.func,
};
