import { takeLatest, takeEvery } from 'redux-saga/effects';

import at from '../types';
import fetchPaymentInfo from './fetchPaymentInfo';
import updatePaymentInfo from './updatePaymentInfo';
import fetchRenewFee from './fetchRenewFee';
import fetchHasActiveSubscription from './fetchHasActiveSubscription';
import cancelSubscription from './cancelSubscription';
import downgradeToPro from './downgradeToPro';
import fetchLastSubscription from './fetchLastSubscription';

export default function* root() {
  yield takeEvery(at.FETCH_LAST_SUBSCRIPTION_REQUEST, fetchLastSubscription);
  yield takeLatest(at.UPDATE_PAYMENT_INFO_REQUEST, updatePaymentInfo);
  yield takeLatest(at.FETCH_PAYMENT_INFO_REQUEST, fetchPaymentInfo);
  yield takeLatest(at.DOWNGRADE_ACCOUNT_REQUEST, downgradeToPro);
  yield takeLatest(at.CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription);
  yield takeLatest(at.FETCH_RENEW_FEE_REQUEST, fetchRenewFee);
  yield takeLatest(at.FETCH_ACTIVE_SUBSCRIPTION_REQUEST, fetchHasActiveSubscription);
}
