import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'emerald-ui/lib/Button';
import utils from '../../utils';
import { AnalyticsTrackingEntities } from '../../config';
import './RenewSubscriptionBanner.css';
import classnames from 'classnames';

class RenewSubscriptionBanner extends Component {
  constructor(props) {
    super(props);
    const isBannerHidden = utils.getSessionItem('isBannerHidden') === 'true';
    this.state = {
      isBannerHidden,
    };
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleCloseClick() {
    this.setState({ isBannerHidden: true });
    utils.setSessionItem('isBannerHidden', 'true');
  }

  handleButtonClick() {
    const { handleTrackedClick } = this.props;
    const { actions } = AnalyticsTrackingEntities;
    handleTrackedClick(actions.sectionBannerAdUpgrade);
  }

  render() {
    return (
      <div className={classnames('alert-wrapper', { hidden: this.state.isBannerHidden })}>
        <div className="left-container">
          <span className="icon">!</span>
          <div>
            <h3>Your account subscription has ended!</h3>
            <p>
              You no longer have access to your CE Compliance Transcript and will have to calculate compliance manually.
            </p>
          </div>
        </div>
        <Button color="success" onClick={this.handleButtonClick} href="/upgrade" size="xs">
          Renew subscription
        </Button>
        <button className="alert-close" onClick={this.handleCloseClick}>
          <span>+</span>
        </button>
      </div>
    );
  }
}

RenewSubscriptionBanner.propTypes = {
  handleTrackedClick: PropTypes.func,
};

export default RenewSubscriptionBanner;
