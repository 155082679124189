import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Panel from 'emerald-ui/lib/Panel';
import queryString from 'query-string';

import SummaryHours from '../SummaryHours';
import CycleSelector from '../CycleSelector';

class BasicInfoSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPeriod: {
        text: '...loading',
        id: null,
      },
      licenseInfo: {
        number: '',
        stateCode: '',
        professionId: 0,
      },
    };
  }

  handleSelect = (key) => {
    const { onDownloadPdf, onPrintPdf } = this.props;
    if (key === '1') {
      onPrintPdf();
    } else if (key === '2') {
      onDownloadPdf();
    }
  };

  handleSelectCycle = (period) => {
    const { fetchTranscript, location = {}, history = {}, match = {} } = this.props;

    const { licenseInfo: { number, professionId, stateCode } = {} } = this.state;

    const { id: previousCycleSelected } = this.state.selectedPeriod;
    if (previousCycleSelected !== period.id) {
      this.setState({ selectedPeriod: { text: period.text, id: period.id } });
      const { params = {} } = match;
      const query = queryString.parse(location.search);
      query.cycle = period.id > 0 ? period.id : 0;
      const search = queryString.stringify(query);
      history.push(`?${search}`);
      fetchTranscript({
        pkLicense: params.pkLicense,
        cycleId: query.cycle,
        licenseNumber: number,
        professionId,
        stateCode,
      });
    }
  };

  render() {
    const {
      licenses,
      selectedLicenseId,
      totalHoursApplied,
      totalHoursNeeded,
      totalHoursRequired,
      licensePeriods,
      selectedPeriodId,
      currentLicense,
      onDownloadPdf,
      onPrintPdf,
      fetchTranscript,
      isAuditTranscript,
    } = this.props;
    const options = licenses.map((l) => (
      <option key={l.id} value={l.id} selected={l.id.toString() === selectedLicenseId}>
        {l.number}
      </option>
    ));
    options.unshift(
      <option key="0" disabled>
        SWITCH LICENSE
      </option>
    );
    return (
      <Panel className="basic-info-panel">
        <div className="basic-info-container" style={{ justifyContent: 'space-between' }}>
          <div className="basic-info-item">
            <SummaryHours
              values={[totalHoursRequired, totalHoursApplied, totalHoursNeeded]}
              labels={['Hours required', 'Hours applied', 'Hours needed']}
              colors={['text-color', 'text-success', 'text-danger']}
            />
          </div>
          {!isAuditTranscript && (
            <div className="basic-info-item desktop-only">
              <CycleSelector
                {...{
                  licensePeriods,
                  selectedPeriodId,
                  fetchTranscript,
                  onDownloadPdf,
                  onPrintPdf,
                  currentLicense,
                }}
              />
            </div>
          )}
        </div>
      </Panel>
    );
  }
}

BasicInfoSummary.propTypes = {
  selectedLicenseId: PropTypes.string,
  licenses: PropTypes.array,
  totalHoursRequired: PropTypes.number,
  totalHoursApplied: PropTypes.number,
  totalHoursNeeded: PropTypes.number,
  onDownloadPdf: PropTypes.func,
  onPrintPdf: PropTypes.func,
  fetchTranscript: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  items: PropTypes.array,
  title: PropTypes.object,
  licensePeriods: PropTypes.array,
  selectedPeriodId: PropTypes.number,
  currentLicense: PropTypes.object,
  isAuditTranscript: PropTypes.bool,
};

BasicInfoSummary.defaultProps = {
  licenses: [],
  isAuditTranscript: false,
};

export default withRouter(BasicInfoSummary);
