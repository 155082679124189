import { put, call, select } from 'redux-saga/effects';

import * as api from '../../../api/courseHistory';
import at from '../types';

export default function* rateCourse({ reduxId, id, data }) {
  const state = yield select();
  const user = state.authentication.licenseeAccount.user;
  const token = state.authentication.legacyToken.token;
  data.userId = user.id;
  try {
    yield call(api.rateCourse, id, data, token);
    yield put({ type: at.RATE_COURSE_SUCCESS, reduxId, data });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({ type: at.RATE_COURSE_FAILURE, reduxId, data });
  }
}
