import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button, Icon, Popover, Panel, Progressbar, OverlayTrigger } from 'emerald-ui/lib';
import classnames from 'classnames';
import FindCoursesButton from '../FindCoursesButton';
import CourseHistory from '../../../containers/CourseHistory';

import SubjectAreaSelector from '../SubjectAreaSelector';

import config from '../../../config';
import utils from '../../../utils';
import * as eventsApi from '../../../api/events';
import './Requirement.css';

import { getTotalOfferingsCount } from '../../../api/offerings';

import * as statisticsApi from '../../../api/statistics';

const { getCourseSearchUrl } = config;

const { legacyUrl } = config;

function handleRedirectToCEReport(pkLicense, subjectAreaId, licensePeriodId) {
  utils.setCookie({
    name: 'CAME_FROM_REPORTCE',
    value: window.location.href,
    domain: 'cebroker.com',
  });
  window.location.href = `${legacyUrl}/licensee/lc_ce_request_lst_v2.aspx?lic=${pkLicense || 0}&subject=${
    subjectAreaId || 0
  }&cycle=${licensePeriodId || 0}`;
}

class Requirement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFindCoursesButton: false,
      totalCourses: 0,
      showSubjectAreaSelector: false,
      subjectAreas: [],
    };
  }

  async componentDidMount() {
    const { item = {}, professionId = 0, isCompleted, isAuditTranscript } = this.props;

    if (isCompleted) {
      return;
    }

    let subjectAreas = [];

    if (item.subjectAreaProfessions[0].allowCourseSearch) {
      subjectAreas = [
        {
          name: item.name,
          id: item.subjectAreaProfessions[0].subjectAreaId,
        },
      ];
    }

    const hasChildren = item.children && item.children.length > 0;
    if (hasChildren) {
      for (const c of item.children) {
        if (c.subjectAreaProfessions[0].allowCourseSearch) {
          subjectAreas.push({
            name: c.name,
            id: c.subjectAreaProfessions[0].subjectAreaId,
          });
        }
      }
    }

    const subjectAreaIds = subjectAreas.map((sa) => sa.id);

    let totalCourses = 0;
    if (!isAuditTranscript && subjectAreaIds.length > 0) {
      const result = await getTotalOfferingsCount({
        profession: professionId,
        subjectAreas: subjectAreaIds,
      });
      totalCourses = result.data.totalItems;
    }

    const showFindCoursesButton = totalCourses > 0;

    this.setState({ totalCourses, showFindCoursesButton, subjectAreas });
  }

  onClickReportCE = () => {
    const { item = {} } = this.props;

    let subjectAreaId = 0;
    if (item.subjectAreaProfessions && item.subjectAreaProfessions.length > 0) {
      subjectAreaId = item.subjectAreaProfessions[0].subjectAreaId;
    }

    const { user = {}, pkLicense, licensePeriodId } = this.props;
    const statisticCode = 'REPORTCE';
    const type = 'TRANSCRIPT_REQUIREMENT';
    const entityId = pkLicense;
    eventsApi.saveEvent(user.id, statisticCode, type, entityId);
    handleRedirectToCEReport(pkLicense, subjectAreaId, licensePeriodId);
  };

  hadleCourseSearchClick = (event) => {
    const { professionId = 0, stateCode = '', user = {}, loggedOnBehalf = false } = this.props;

    event.preventDefault();

    const { subjectAreas } = this.state;

    if (subjectAreas.length === 1) {
      const statisticCode = 'COURSE_SEARCH_CLICK';
      const type = 'TRANSCRIPT';
      statisticsApi.saveStatistic(-1, statisticCode, type);

      const queryParams = {
        profession: professionId,
        state: stateCode,
        subjectArea: subjectAreas[0].id,
      };
      if (loggedOnBehalf) {
        queryParams.userId = user.id;
      }
      const courseSearchUrl = getCourseSearchUrl(queryParams);
      utils.redirectTo(courseSearchUrl);
      return;
    }

    this.setState({ showSubjectAreaSelector: true });
  };

  handleBackClick = () => {
    this.setState({ showSubjectAreaSelector: false });
  };

  render() {
    const {
      item = {},
      hoursNeeded,
      appliedHours,
      isCompleted,
      isNotRequired,
      professionId = 0,
      stateCode = '',
      user = {},
      loggedOnBehalf = false,
      isAuditTranscript = false,
    } = this.props;

    const parentSubjectAreaName = item.name;

    const { showFindCoursesButton, totalCourses, showSubjectAreaSelector, subjectAreas } = this.state;

    if (showSubjectAreaSelector) {
      return (
        <SubjectAreaSelector
          parentSubjectAreaName={parentSubjectAreaName}
          subjectAreas={subjectAreas}
          onBack={this.handleBackClick}
          professionId={professionId}
          stateCode={stateCode}
          user={user}
          loggedOnBehalf={loggedOnBehalf}
        />
      );
    }

    const percentage = (appliedHours / item.requiredHours) * 100;

    const hasChildren = item.children && item.children.length > 0;
    let childrenCourses = [];
    const allChildrenCourses = [];
    if (hasChildren) {
      for (const c of item.children) {
        const hasCourses = c.creditCourses.length > 0;
        if (hasCourses || c.isVisible) {
          childrenCourses.push({
            name: c.name,
            courses: c.creditCourses,
            id: Math.random(),
            hasCourses,
            isVisible: c.isVisible,
            isExemptionDependent: c.isExemptionDependent,
            requiredHours: c.requiredHours,
          });
        }
        allChildrenCourses.push({
          name: c.name,
          id: Math.random(),
        });
      }
    }

    //If it does not have courses, move it to the bottom of the array
    childrenCourses = childrenCourses.sort((a, b) => (a.hasCourses ? -1 : b.hasCourses ? 1 : 0));

    return (
      <Panel
        style={{ marginBottom: '19px' }}
        className={classnames('requirements', {
          complete: isCompleted && !isNotRequired,
          'not-complete': !isCompleted,
          'not-required': isNotRequired,
        })}
      >
        <Panel.Body>
          <div className={`requirements-content${isAuditTranscript ? '-audit' : ''}`}>
            <div className="primary-content">
              <div className="requirements-header">
                {hasChildren ? (
                  <OverlayTrigger
                    triggerType="hover"
                    placement="right"
                    delay={500}
                    overlay={
                      <Popover id="filterForm" theme="dark">
                        Multiple subject areas can be used to fulfill this requirement
                      </Popover>
                    }
                  >
                    <div className="subject-area-name-container">
                      <h2 className="dashed-underline with-children">{item.name}</h2>
                    </div>
                  </OverlayTrigger>
                ) : (
                  <div className="subject-area-name-container">
                    <h2>{item.name}</h2>
                  </div>
                )}
                <p className="hours-required">
                  {item.isFlag ? (
                    'Yes/No requirement'
                  ) : (
                    <Fragment>
                      Hours required <span>{item.requiredHours}</span>
                    </Fragment>
                  )}
                </p>
              </div>
              <CourseHistory
                courses={item.creditCourses}
                childrenCourses={childrenCourses}
                isFlag={item.isFlag}
                totalHoursApplied={appliedHours}
                deliveryMethodRestricted={item.deliveryMethodRestricted}
              />
              <div className="bottom-requirement-section">
                <div className="complete-message-section">
                  {isAuditTranscript && (
                    <Fragment>
                      {isNotRequired ? (
                        <Fragment>
                          <Icon className="grey-icon" name="remove_circle" />
                          <p className="reset-mg">This subject area is not required this cycle</p>
                        </Fragment>
                      ) : isCompleted ? (
                        <Fragment>
                          <Icon className="check-icon" name="check_circle" />
                          <p className="reset-mg">This subject area is complete</p>
                        </Fragment>
                      ) : null}
                    </Fragment>
                  )}
                </div>
                <div className="hours-section">
                  {!item.isFlag && (
                    <div className="hours-needed-section">
                      {!isCompleted && <Progressbar progress={percentage} style={{ marginBottom: 0 }} color="info" />}
                      <p className="hours-needed">
                        <span className="text-color">Hours needed </span>
                        <span className={isCompleted ? 'text-success' : 'text-danger'}>{hoursNeeded}</span>
                      </p>
                    </div>
                  )}
                  {item.isFlag && !isCompleted && (
                    <div className="hours-needed-section">
                      <p>This requirement has not been met </p>
                      <Icon name="remove_circle" />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {!isAuditTranscript && (
              <div className={`secondary-content ${isCompleted ? 'complete' : ''}`}>
                {isCompleted ? (
                  <div className="complete-message">
                    {isNotRequired ? (
                      <Fragment>
                        <Icon className="grey-icon" name="remove_circle" />
                        <p>This subject area is not required this cycle</p>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Icon name="check_circle" />
                        <p>This subject area is complete</p>
                      </Fragment>
                    )}
                  </div>
                ) : (
                  <Fragment>
                    {showFindCoursesButton && (
                      <FindCoursesButton totalCourses={totalCourses} onClick={this.hadleCourseSearchClick} />
                    )}
                    {!showFindCoursesButton && <p>You can report activities that meet this requirement</p>}
                    <Button className="report-ce-button" onClick={this.onClickReportCE}>
                      <Icon name="add" />
                      Report CE
                    </Button>
                  </Fragment>
                )}
              </div>
            )}
          </div>
        </Panel.Body>
      </Panel>
    );
  }
}

Requirement.propTypes = {
  item: PropTypes.object.isRequired,
  hoursNeeded: PropTypes.number,
  appliedHours: PropTypes.number,
  professionId: PropTypes.number,
  stateCode: PropTypes.string,
  pkLicense: PropTypes.number,
  isCompleted: PropTypes.bool,
  isNotRequired: PropTypes.bool,
  licensePeriodId: PropTypes.number,
  user: PropTypes.object,
  loggedOnBehalf: PropTypes.bool,
  isAuditTranscript: PropTypes.bool,
};

export default Requirement;
