export default {
  //FETCH TYPE
  FETCH_REQUEST: 'inbox/FETCH_REQUEST',
  FETCH_SUCCESS: 'inbox/FETCH_SUCCESS',
  FETCH_FAILURE: 'inbox/FETCH_FAILURE',
  //DELETE TYPE
  DELETE_REQUEST: 'inbox/DELETE_REQUEST',
  DELETE_SUCCESS: 'inbox/DELETE_SUCCESS',
  DELETE_FAILURE: 'inbox/DELETE_FAILURE',
  //ARCHIVE TYPE
  ARCHIVE_REQUEST: 'inbox/ARCHIVE_REQUEST',
  ARCHIVE_SUCCESS: 'inbox/ARCHIVE_SUCCESS',
  ARCHIVE_FAILURE: 'inbox/ARCHIVE_FAILURE',
  // OTHER TYPES
  SET_PAGE_FETCH_STATUS: 'inbox/SET_PAGE_FETCH_STATUS',
  CLEAR_ENTITIES: 'inbox/CLEAR_ENTITIES',
};
