import React from 'react';
import PropTypes from 'prop-types';
import './SubjectAreaThumbnail.css';

class SubjectAreaThumbnail extends React.Component {
  render() {
    const { subjectAreaName, hoursNeeded, isYesNotRequirement = false, selected = false } = this.props;
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div className={`subject-area-thumbnail`} onClick={() => this.props.onSelect(this.props)}>
        <div className={`subject-area-panel ${selected ? 'selected' : ''}`}>
          <h1 className="subject-area-thumbnail-name">{subjectAreaName}</h1>
          {!isYesNotRequirement && (
            <label className="subject-area-thumbnail-hours">
              <b>{hoursNeeded}</b> hours needed
            </label>
          )}
          {isYesNotRequirement && (
            <label className="subject-area-thumbnail-hours" htmlFor="requirement">
              This requirement has not been met
            </label>
          )}
        </div>
      </div>
    );
  }
}

SubjectAreaThumbnail.propTypes = {
  subjectAreaName: PropTypes.string.isRequired,
  hoursNeeded: PropTypes.number,
  isYesNotRequirement: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default SubjectAreaThumbnail;
