export default function (globalState) {
  const state = globalState.subscriptions;

  const getCancelTrialStatus = () => state.cancelStatus;
  const getCancelTrialInfo = () => state.cancelTrialInfo;
  const getSubscriptionPrices = () => state.subscriptionPrices;
  const getSubsPricesStatus = () => state.subsPricesStatus;
  const getSubscriptionPlans = () => state.subscriptionPlans;
  const getSubscriptionPlansStatus = () => state.subscriptionPlansStatus;

  return {
    getCancelTrialStatus,
    getCancelTrialInfo,
    getSubscriptionPrices,
    getSubsPricesStatus,
    getSubscriptionPlans,
    getSubscriptionPlansStatus,
  };
}
