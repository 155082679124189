import { combineReducers } from 'redux';
import at from '../types';
import { types as licensesAt } from '../../licenses';
import { types as auditsAt } from '../../audits';

export const token = (state = {}, action) => {
  switch (action.type) {
    case at.SET_TOKEN: {
      return action.token;
    }
    case at.LOGOUT: {
      return {};
    }
    default:
      return state;
  }
};

export const legacyToken = (state = {}, action) => {
  switch (action.type) {
    case at.SET_LEGACY_TOKEN: {
      return action.token;
    }
    case at.LOGOUT: {
      return {};
    }
    default:
      return state;
  }
};

export const status = (state = 'unauthenticated', action) => {
  switch (action.type) {
    case at.SET_STATUS: {
      return action.status;
    }
    case at.LOGOUT: {
      return 'unauthenticated';
    }
    default:
      return state;
  }
};

export const authenticatedAccount = (state = {}, action) => {
  switch (action.type) {
    case at.SET_AUTHENTICATED_ACCOUNT: {
      return action.authenticatedAccount;
    }
    case at.LOGOUT: {
      return {};
    }
    default:
      return state;
  }
};

export const licenseeAccount = (state = {}, action) => {
  switch (action.type) {
    case at.SET_LICENSEE_ACCOUNT: {
      return action.licenseeAccount;
    }
    case licensesAt.FETCH_LICENSE_PERIODS_SUCCESS: {
      const { filters, periods } = action;
      const { pkLicense } = filters;
      const licenses = state.licenses.map((l) => {
        if (parseInt(l.id, 10) === parseInt(pkLicense, 10)) {
          l.periods = periods;
        }
        return l;
      });
      return { ...state, licenses };
    }
    case at.LOGOUT: {
      return {};
    }
    case auditsAt.FETCH_SUCCESS: {
      const {
        data: { pkLicense, periods = [] },
      } = action;
      if (periods.length === 0) {
        return state;
      }

      const { activeAudits = [] } = state;

      const auditExists = !!activeAudits.find((at) => at.pkLicense === pkLicense);
      if (auditExists) {
        return state;
      }
      activeAudits.push({ pkLicense, cycleId: periods[0].id });

      return { ...state, activeAudits };
    }
    default:
      return state;
  }
};

export default combineReducers({
  token,
  legacyToken,
  status,
  authenticatedAccount,
  licenseeAccount,
});
