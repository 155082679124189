import { combineReducers } from 'redux';
import at from '../types';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.SAVE_CART_ITEM_SUCCESS: {
      return { ...state, ...action.entity };
    }
    default:
      return state;
  }
};

export const fetchStatuses = (state = '', action) => {
  switch (action.type) {
    case at.SAVE_CART_ITEM_REQUEST: {
      return 'loading';
    }
    case at.SAVE_CART_ITEM_SUCCESS: {
      return 'loaded';
    }
    case at.SAVE_CART_ITEM_FAILURE: {
      return 'failed';
    }
    case at.CLEAR_SAVE_CART_ITEM_STATUS: {
      return {};
    }
    default:
      return state;
  }
};

export const fetchErrors = (state = {}, action) => {
  switch (action.type) {
    case at.SAVE_CART_ITEM_REQUEST:
    case at.SAVE_CART_ITEM_SUCCESS: {
      return state;
    }
    case at.SAVE_CART_ITEM_FAILURE: {
      return action.error;
    }
    case at.CLEAR_SAVE_CART_ITEM_STATUS: {
      return {};
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  fetchStatuses,
  fetchErrors,
});
