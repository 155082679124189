import { put, select } from 'redux-saga/effects';

import * as utils from '../../../utils';
import * as authenticationStore from '../../authentication';
import config from '../../../config';

const { secureCookie } = config;

export function* verifySecureToken() {
  while (true) {
    yield utils.timeout(2000);

    const state = yield select();
    const authenticationSelectors = authenticationStore.selectors(state);
    const authenticationStatus = authenticationSelectors.getAuthenticationStatus();

    if (authenticationStatus === 'authenticated') {
      const secureToken = utils.getCookie(secureCookie);
      if (!secureToken) {
        yield put(authenticationStore.actions.logout());
      }
    }
  }
}
