import { put, call, select } from 'redux-saga/effects';

import * as api from '../../../api/audits';
import at from '../types';

function selectPeriod({ pkLicense, periods = [], existMultiAudits, statusRequest }) {
  if (!existMultiAudits) {
    const [period] = periods;
    return { pkLicense, statusRequest, ...period };
  }
}
export default function* fetch({ params }) {
  const state = yield select();
  const {
    authentication: { licenseeAccount = {} },
  } = state;

  const { user } = licenseeAccount;

  const { pkLicense } = params;
  try {
    yield put({ type: at.FETCH_REQUEST_LOADING });

    const { data } = yield call(api.getTask, pkLicense);

    const { id: idLicensePeriod } = selectPeriod(data);

    const { data: flagUser } = yield call(api.getFlagUserAuditTask, user.id, `audit_${pkLicense}_${idLicensePeriod}`);

    yield put({ type: at.FETCH_SUCCESS, data });

    const flagUserAuditTask = flagUser && flagUser.value === 'true';

    yield put({ type: at.SET_FLAG, data: { flagUserAuditTask } });
  } catch (error) {
    const { data } = error.response;
    console.error(error); // eslint-disable-line
    yield put({ type: at.FETCH_FAILURE, data });
  }
}
