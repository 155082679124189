import map from 'lodash/map';
import unique from 'lodash/uniq';

const buildTags = (tagsObj = {}) => Object.entries(tagsObj).map(([key, value]) => ({ [key]: value }));

export default function initializePendo(user) {
  if (window.pendo) {
    window.pendo.initialize({
      visitor: {
        id: `licensee-${user.id}`,
        userName: user.userName,
        name: `${user.firstName} ${user.lastName}`,
        role: user.role,
        isBetaAvailable: user.isBetaAvailable,
        stripeCustomerId: user.customerId,
        subscriptionId: user.subscriptionId,
        subscriptionType: user.subscriptionType,
        isFtoAvailable: user.isFTOAvailable,
        professionId: map(user.licenses, 'professionId'),
        licenseNumber: map(user.licenses, 'licenseNumber'),
        professionName: map(user.licenses, 'professionName'),
        boardStateCode: unique(map(user.licenses, 'stateCode')),
        tags: buildTags({
          'Account Type': [user.subscriptionType],
          Role: [user.role],
          Profession: map(user.licenses, 'professionName'),
          'Is FTO Available': [user.isFTOAvailable ? 'True' : 'False'],
        }),
      },
      account: {
        id: 'licensee',
      },
    });
  }
}
