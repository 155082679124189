import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import CourseThumbnail from '../CourseThumbnail';
import utils from '../../utils';
import config from '../../config';
import './CoursesListItems.css';

const { checkIfIsNowCourse } = utils;

class CoursesListItems extends React.PureComponent {
  render() {
    const {
      courses,
      isFeatured,
      splitHours,
      professionId,
      onCourseClick,
      onFavoriteClick,
      savedOfferingsObject = {},
      areAllFavorites,
      showFavoriteIcon,
      className,
      userId,
      saveCartItem,
      coursesInCart,
      loggedOnBehalf,
      shouldShowProPlusBadge,
    } = this.props;
    return (
      <div
        className={
          isFeatured && Array.isArray(courses) && courses.length > 0 ? `${className} featured-course-list` : className
        }
      >
        {courses.map((offering) => {
          const savedOffering = savedOfferingsObject[offering.id];
          const isFavorite = areAllFavorites || (!!savedOffering && !savedOffering.deleted);

          const { course = {} } = offering;
          const { provider = {}, deliveryMethod, rating, ratingCount } = course;
          const { inCoursescriptionEnrolled } = provider;
          const showProPlusBadge = shouldShowProPlusBadge && inCoursescriptionEnrolled;

          const isNowCourse = checkIfIsNowCourse(course);
          const location =
            course.type === 'CD_LIVE' && offering.location.city
              ? `${offering.location.city.toLowerCase()} ${offering.location.state}`
              : '';

          const isLiveCourseWithoutDates =
            offering.startDate === '01/01/0001' && offering.endDate === '01/01/0001' && course.type === 'CD_LIVE';

          let date = '';
          if (isLiveCourseWithoutDates) {
            date = 'Contact provider';
          } else if (course.type === 'CD_LIVE' && offering.startDate) {
            date = moment(offering.startDate).format('MMM Do, YYYY');
          }

          course.components = course.components || [];
          const component = course.components.filter(
            (component) =>
              component.profession.id === parseInt(professionId, 10) ||
              component.profession.id === parseInt(offering.professionId, 10)
          )[0];

          const associationLabels = utils.getAssociationLabelsFromComponents(course.components);

          const profession = professionId || offering.professionId;

          let duration;

          if (component) {
            const compProfession = component.profession;
            if (splitHours && compProfession.subjectAreas[0].approvedHours !== compProfession.totalHours) {
              duration = `${compProfession.subjectAreas[0].approvedHours} ${
                compProfession.subjectAreas[0].approvedHours > 1 ? 'Hours' : 'Hour'
              } (${compProfession.totalHours} total)`;
            } else {
              duration = `${compProfession.totalHours} ${compProfession.totalHours > 1 ? 'Hours' : 'Hour'}`;
            }
          }

          const bannerUrl = course.featuredBanner ? `${config.storageServiceUrl}/${course.featuredBanner}` : '';

          return (
            <CourseThumbnail
              key={offering.id}
              isFeatured={isFeatured}
              name={course.name}
              id={offering.id}
              courseId={course.id}
              offeringId={offering.id}
              providerId={provider.id}
              providerName={provider.name}
              duration={duration}
              hasPrice={offering.hasPrice}
              isFree={offering.isFree}
              price={offering.price}
              courseType={course.type}
              deliveryMethodCode={deliveryMethod.code}
              deliveryMethodDescription={deliveryMethod.description}
              location={location}
              date={date}
              isLiveCourseWithoutDates={isLiveCourseWithoutDates}
              bannerUrl={bannerUrl}
              professionId={profession}
              onClick={onCourseClick}
              isFavorite={isFavorite}
              onFavoriteClick={onFavoriteClick}
              showFavoriteIcon={showFavoriteIcon}
              isNowCourse={isNowCourse}
              associationLabels={associationLabels}
              userId={userId}
              saveCartItem={saveCartItem}
              coursesInCart={coursesInCart}
              loggedOnBehalf={loggedOnBehalf}
              startDate={offering.startDate}
              endDate={offering.endDate}
              rating={rating}
              ratingCount={ratingCount}
              shouldShowProPlusBadge={showProPlusBadge}
            />
          );
        })}
      </div>
    );
  }
}

CoursesListItems.propTypes = {
  courses: PropTypes.array,
  isFeatured: PropTypes.bool,
  areAllFavorites: PropTypes.bool,
  splitHours: PropTypes.bool,
  professionId: PropTypes.number,
  savedOfferingsObject: PropTypes.object,
  showFavoriteIcon: PropTypes.bool,
  className: PropTypes.string,
  onCourseClick: PropTypes.func,
  onFavoriteClick: PropTypes.func,
  saveCartItem: PropTypes.func,
  coursesInCart: PropTypes.array,
  loggedOnBehalf: PropTypes.bool,
  userId: PropTypes.number,
  shouldShowProPlusBadge: PropTypes.bool,
};

CoursesListItems.defaultProps = {
  courses: [],
  isFeatured: false,
  splitHours: false,
  className: '',
  shouldShowProPlusBadge: false,
};

export default CoursesListItems;
