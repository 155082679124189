import { takeLatest } from 'redux-saga/effects';

import at from '../types';
import fetch from './fetch';
import fetchInProgressCourses from './fetchInProgressCourses';

export default function* root() {
  yield takeLatest(at.FETCH_IN_PROGRESS_REQUEST, fetch);
  yield takeLatest(at.FETCH_COMPLETED_REQUEST, fetch);
  yield takeLatest(at.FETCH_IN_PROGRESS_LIST_REQUEST, fetchInProgressCourses);
}
