import { combineReducers } from 'redux';
import at from '../types';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_PAYMENT_HISTORY_SUCCESS: {
      const { entities } = action;
      return { ...state, ...entities.items };
    }
    case at.FETCH_PAYMENT_HISTORY_DETAIL_SUCCESS: {
      const { reduxId, paymentHistoryDetail } = action;
      return {
        ...state,
        [reduxId]: { ...state[reduxId], ...paymentHistoryDetail },
      };
    }
    case at.FETCH_SELF_SUBMISSION_SUCCESS: {
      const { reduxId, pkLicense, statusCode } = action;
      return {
        ...state,
        [reduxId]: { ...state[reduxId], ...{ pkLicense, statusCode } },
      };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const entitiesFetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_PAYMENT_HISTORY_DETAIL_REQUEST: {
      const { reduxId } = action;
      return { ...state, [reduxId]: 'loading' };
    }
    case at.FETCH_PAYMENT_HISTORY_DETAIL_SUCCESS: {
      const { reduxId } = action;
      return { ...state, [reduxId]: 'loaded' };
    }
    case at.FETCH_PAYMENT_HISTORY_DETAIL_FAILURE: {
      const { reduxId } = action;
      return { ...state, [reduxId]: 'failed' };
    }
    case at.FETCH_SELF_SUBMISSION_REQUEST: {
      const { reduxId } = action;
      return { ...state, [reduxId]: 'loading' };
    }
    case at.FETCH_SELF_SUBMISSION_SUCCESS: {
      const { reduxId } = action;
      return { ...state, [reduxId]: 'loaded' };
    }
    case at.FETCH_SELF_SUBMISSION_FAILURE: {
      const { reduxId } = action;
      return { ...state, [reduxId]: 'failed' };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const pages = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_PAYMENT_HISTORY_SUCCESS: {
      const { filters, result } = action;
      return { ...state, [filters.page]: result };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const pagesFetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_PAYMENT_HISTORY_SUCCESS: {
      const { filters } = action;
      return { ...state, [filters.page]: 'loaded' };
    }
    case at.FETCH_PAYMENT_HISTORY_FAILURE: {
      const { filters } = action;
      return { ...state, [filters.page]: 'failed' };
    }
    case at.SET_PAGE_FETCH_STATUS: {
      const { page, status } = action;
      return { ...state, [page]: status };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const totalCount = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_PAYMENT_HISTORY_SUCCESS: {
      return action.totalRecords;
    }
    case at.CLEAR_ENTITIES: {
      return 0;
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  entitiesFetchStatus,
  pages,
  pagesFetchStatus,
  totalCount,
});
