import React from 'react';
import PropTypes from 'prop-types';
import StarRatingComponent from 'react-star-rating-component';
import { TableHeader, ExpansionTableRow, ExpansionTableRowGroup, Button, IconButton, Icon } from 'emerald-ui/lib';

import CourseHistoryDetail from './CourseHistoryDetail';
import RowToolbar from './RowToolbar';
import NameColumn from './NameColumn';
import AttachmentsColumn from '../AttachmentsColumn';
import courseHistoryIcon from './course-history-icon.svg';
import moment from 'moment';
import './CourseHistoryList.css';
import { titleCase } from '../../utils';

import { INPUT_FORMAT_DATE } from '../../config';
import TableNoResultsState from '../TableNoResultsState';

class CourseHistoryList extends React.Component {
  componentDidMount() {
    const { fetchSubjectAreas, selectedLicense } = this.props;
    const { profession: { id: professionId = 0 } = {} } = selectedLicense;
    if (professionId && professionId > 0) {
      fetchSubjectAreas(professionId);
    }
  }

  handleRowToggle = ({ reduxId, courseId, entityId, section = null }) => {
    const { courseHistoryFetchStatus, fetchCourseHistoryDetail, fetchCourseAttachments, selectedLicense } = this.props;
    const fetchStatus = courseHistoryFetchStatus[reduxId];
    if (fetchStatus !== 'loaded' && fetchStatus !== 'loading') {
      const { profession: { id: professionId = 0 } = {} } = selectedLicense;
      fetchCourseHistoryDetail(reduxId, courseId, entityId, section, professionId);
      fetchCourseAttachments(reduxId);
    }
  };

  getSelectedCourse = (reduxId) => {
    return this.props.courseHistory.find((ch) => ch.reduxId === reduxId) || {};
  };

  getCourseAttachmentInfo = (reduxId) => {
    const selectedCourse = this.getSelectedCourse(reduxId);
    let attachmentCode;
    let attachmentDocumentNumber;
    switch (selectedCourse.trackingPrefix) {
      case '10':
      case 'ProviderPost': {
        attachmentCode = 'ROSTER_ATTENDEE';
        attachmentDocumentNumber = selectedCourse.id;
        break;
      }
      case '94':
      case 'DisciplinaryPost': {
        attachmentCode = 'DISC_POST';
        attachmentDocumentNumber = selectedCourse.idDiscPostCourse;
        break;
      }
      default: {
        attachmentCode = 'POST_CREDIT';
        attachmentDocumentNumber = selectedCourse.entityId;
      }
    }
    return { attachmentCode, attachmentDocumentNumber };
  };

  handleDeleteAttachment = (filename, token, course) => {
    const { deleteCourseHistoryAttachment } = this.props;
    const { attachmentCode, attachmentDocumentNumber } = this.getCourseAttachmentInfo(course.reduxId);
    deleteCourseHistoryAttachment(course.reduxId, token, attachmentCode, attachmentDocumentNumber);
  };

  renderAttachmentIcon = (item, files, attachments, subscriptionType, onShowAttachments) => {
    const { getAttachmentsStatus } = this.props;

    if (item.hasAttachments || attachments.length > 0) {
      return (
        <AttachmentsColumn
          {...{
            files,
            attachmentsFetchStatus: getAttachmentsStatus(item.reduxId),
            onShowAttachments: () => {
              onShowAttachments(item.reduxId);
            },
          }}
        />
      );
    } else if (item.certificateId > 0) {
      return (
        <IconButton
          icon="attach_file"
          className="table-icons"
          title="Certificate"
          onClick={(e) => {
            onShowAttachments(item.reduxId);
            e.stopPropagation();
          }}
        />
      );
    }
  };

  render() {
    const {
      courseHistory,
      previousCourseHistory,
      courseHistoryFetchStatus,
      pageFetchStatus,
      subscriptionType,
      showCreditCourseType,
      onAttachmentIconClick,
      onRatingChange,
      onDeleteCourseClick,
      getDeleteAttachmentStatus,
      section,
      selectedLicense,
    } = this.props;

    if (pageFetchStatus === 'loading') {
      return null;
    }

    let items = courseHistory;
    if (courseHistory.length === 0) {
      items = previousCourseHistory;
    }

    if (items.length === 0) {
      return <TableNoResultsState />;
    }

    const showWarning = items.some((ch) => ch.processError.length > 0);
    const isRemoved = section === 'removed';
    const { profession: { licenseLabelCap = 'License' } = {} } = selectedLicense;

    return (
      <div id="course-history-list">
        <ExpansionTableRowGroup hover id="g1" preventHorizontalGrowth>
          <colgroup>
            <col width="0%" />
            <col width="100%" />
            {!isRemoved && <col width="0%" />}
            <col width="0%" />
          </colgroup>
          {items.length > 0 && (
            <TableHeader useCheckbox={false}>
              <th />
              <th className="course-history-name-header">Course</th>
              {!isRemoved ? <th className="course-history-rate-header">Rating</th> : <th />}
              <th style={{ textAlign: 'center' }}>Hours</th>
              {!isRemoved && <th className="course-history-toolbar-header" />}
            </TableHeader>
          )}
          {items.map((ch) => {
            const isSelfSubmission = ch.trackingPrefix === '99' || ch.trackingPrefix === 'SelfSubmission';
            const canRemoveCourse = isSelfSubmission && ch.rosterCode !== 'BOARD_POST' && !isRemoved;
            const canSeeDetail = !isRemoved || isSelfSubmission;

            const courseTypeLabels = {
              CD_LIVE: 'Live',
              CD_ANYTIME: 'Anytime',
            };

            const courseType = courseTypeLabels[ch.courseType] || '';
            const attachments = ch.attachments || [];

            return (
              <ExpansionTableRow
                customId={ch.reduxId}
                onToggle={() => {
                  if (canSeeDetail) {
                    this.handleRowToggle({
                      reduxId: ch.reduxId,
                      courseId: ch.id,
                      entityId: ch.entityId,
                      section: ch.section,
                    });
                  }
                }}
                key={ch.reduxId}
                useCheckbox={false}
              >
                <ExpansionTableRow.Summary>
                  <td className="icons-column">
                    <Icon name="keyboard_arrow_down" className="text-lightest" />
                    <img src={courseHistoryIcon} alt="" />
                  </td>
                  <td className="ch-name-column">
                    <NameColumn
                      {...{
                        showWarning,
                        processError: ch.processError,
                        name: ch.name,
                        additionalItemMessage: ch.additionalItemMessage,
                        dateCompleted: moment(ch.dateCompleted, INPUT_FORMAT_DATE).utc().format('MM/DD/YYYY'),
                      }}
                    />
                  </td>
                  {!isRemoved ? (
                    <td className="course-history-rate">
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                      <div className="course-rating-container" onClick={(e) => e.stopPropagation()}>
                        {ch.rating > 0 ? (
                          <StarRatingComponent
                            name={`rate-${ch.id}`}
                            starCount={5}
                            value={parseInt(ch.rating, 10)}
                            starColor="#0073e9"
                            emptyStarColor="#E0E7EA"
                            className="rating-stars"
                            onStarClick={(rating, previousRating) => {
                              onRatingChange(ch.reduxId, rating, previousRating);
                            }}
                          />
                        ) : (
                          <Button
                            shape="flat"
                            color="info"
                            className="rate-course"
                            onClick={() => {
                              onRatingChange(ch.reduxId, 0, 0);
                            }}
                          >
                            Rate course
                          </Button>
                        )}
                      </div>
                    </td>
                  ) : (
                    <td />
                  )}
                  <td>
                    <div>
                      <p className="course-hours">
                        {ch.postCourseType === 'COURSE_COMPLETED' ? 'Yes, Completed' : ch.hours}
                      </p>
                      {showCreditCourseType && courseType && <p className="course-type">{courseType}</p>}
                    </div>
                  </td>
                  {!isRemoved ? (
                    <td className="course-history-toolbar">
                      <RowToolbar
                        {...{
                          courseId: ch.id,
                          canRemoveCourse,
                          canAddAttachments: subscriptionType !== 'BAS',
                          attachments,
                          isSpecialAttachment: ch.certificateId > 0,
                          onAttachmentIconClick: () => {
                            onAttachmentIconClick(ch.reduxId);
                          },
                          onDeleteCourseClick: () => {
                            onDeleteCourseClick(ch.reduxId);
                          },
                        }}
                      />
                    </td>
                  ) : null}
                </ExpansionTableRow.Summary>
                <ExpansionTableRow.AlternativeSummary>
                  <div className="detail-title">
                    <div className="title-wrapper">
                      <Icon name="keyboard_arrow_up" className="text-lightest" />
                      <img src={courseHistoryIcon} alt="" />
                      <div className="detail-title-text">
                        <h3>{titleCase(ch.name)}</h3>
                        <span className="course-history-date-completed text-lighter">
                          Completed {moment(ch.dateCompleted, INPUT_FORMAT_DATE).utc().format('MM/DD/YYYY')}
                        </span>
                      </div>
                    </div>
                    <RowToolbar
                      {...{
                        courseId: ch.id,
                        canRemoveCourse,
                        canAddAttachments: subscriptionType !== 'BAS',
                        attachments,
                        isSpecialAttachment: ch.certificateId > 0,
                        onAttachmentIconClick: () => {
                          onAttachmentIconClick(ch.reduxId);
                        },
                        onDeleteCourseClick: () => {
                          onDeleteCourseClick(ch.reduxId);
                        },
                      }}
                      className="only-mobile-visible"
                    />
                    {canRemoveCourse && (
                      <IconButton
                        icon="delete"
                        className="table-icons only-desktop-visible"
                        title="Delete course"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteCourseClick(ch.reduxId);
                        }}
                      />
                    )}
                  </div>
                </ExpansionTableRow.AlternativeSummary>
                <ExpansionTableRow.Content>
                  {canSeeDetail ? (
                    <CourseHistoryDetail
                      course={ch}
                      subscriptionType={subscriptionType}
                      licenseLabelCap={licenseLabelCap}
                      courseLoadingStatus={courseHistoryFetchStatus[ch.reduxId]}
                      onUploadFileClick={() => {
                        onAttachmentIconClick(ch.reduxId);
                      }}
                      isSelfSubmission={isSelfSubmission}
                      onDeleteAttachment={this.handleDeleteAttachment}
                      getDeleteAttachmentStatus={getDeleteAttachmentStatus}
                      onRatingChange={onRatingChange}
                    />
                  ) : (
                    <p className="no-content-detail">There is no content to preview for this type of credit.</p>
                  )}
                </ExpansionTableRow.Content>
              </ExpansionTableRow>
            );
          })}
        </ExpansionTableRowGroup>
      </div>
    );
  }
}

CourseHistoryList.propTypes = {
  courseHistoryFetchStatus: PropTypes.object,
  courseHistory: PropTypes.array,
  previousCourseHistory: PropTypes.array,
  subscriptionType: PropTypes.string,
  showCreditCourseType: PropTypes.bool,
  onAttachmentIconClick: PropTypes.func,
  onShowAttachments: PropTypes.func,
  onRatingChange: PropTypes.func,
  onDeleteCourseClick: PropTypes.func,
  fetchCourseHistoryDetail: PropTypes.func,
  fetchCourseAttachments: PropTypes.func,
  deleteCourseHistoryAttachment: PropTypes.func,
  getAttachmentsStatus: PropTypes.func,
  getDeleteAttachmentStatus: PropTypes.func,
  fetchSubjectAreas: PropTypes.func,
  section: PropTypes.string,
  selectedLicense: PropTypes.object,
};

export default CourseHistoryList;
