import { put, call, select } from 'redux-saga/effects';

import * as api from '../../../api/audits';
import at from '../types';

export default function* fetch({ params }) {
  const state = yield select();
  const {
    authentication: { licenseeAccount = {} },
  } = state;

  const { user } = licenseeAccount;

  try {
    yield put({ type: at.FETCH_REQUEST_LOADING_FLAG });

    const { data } = yield call(api.setFlagUserAuditTask, user.id, params, true);

    const flagUserAuditTask = data.value === 'true';

    yield put({ type: at.FETCH_SUCCESS_FLAG, data: { flagUserAuditTask } });
  } catch (error) {
    const { data } = error.response;
    console.error(error); // eslint-disable-line
    yield put({ type: at.FETCH_FAILURE_FLAG, data });
  }
}
