import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { actions as transcriptActions, selectors as transcriptSelectors } from '../store/transcript';
import * as auditStore from '../store/audits';
import * as authenticationStore from '../store/authentication';
import queryString from 'query-string';
import Overview from '../components/Overview';
import * as tourStore from '../store/tour';
import * as adsStore from '../store/ads';
import * as usersStore from '../store/users';
import * as notificationsStore from '../store/notifications/';
import * as cartStore from '../store/cart';
import * as subscriptionsStore from '../store/subscriptions';
import { getSubscriptionConditions } from 'utils';
import { SUBSCRIPTION_TYPES } from 'config';

function mapStateToProps(state, ownProps) {
  const { match, location } = ownProps;
  const query = queryString.parse(location.search);
  const authenticationSelectors = authenticationStore.selectors(state);
  const subscriptionsSelectors = subscriptionsStore.selectors(state);
  const auditSelectors = auditStore.selectors(state);
  const usersSelector = usersStore.selectors(state);
  const cartSelectors = cartStore.selectors(state);
  const transcriptSelector = transcriptSelectors(state);
  const licenseeAccount = authenticationSelectors.getLicenseeAccount();
  const authenticatedAccount = authenticationSelectors.getAuthenticatedAccount();
  const auditTasks = auditSelectors.getTask();
  const loggedOnBehalf = authenticatedAccount.role === 'helpdesk';
  const {
    user = {},
    subscriptions = [],
    licenses = [],
    isDohUser,
    requestedLicenses = [],
    autoRenewSettings,
  } = licenseeAccount;
  let subscriptionType = '';
  let isFTOAvailable = false;

  if (subscriptions.length > 0) {
    subscriptionType = subscriptions[0].code;
    isFTOAvailable = subscriptions[0].isftoAvailable;
  }

  let customerId;
  if (autoRenewSettings) {
    customerId = autoRenewSettings.customerId;
  }

  const { pkLicense } = match.params;
  const { cycle: cycleId = 0 } = query;
  const license = licenses.find((l) => l.id.toString() === pkLicense);
  const selectedRequestedLicense = requestedLicenses.find((l) => l.id.toString() === pkLicense);
  const isRequestedLicense = !!selectedRequestedLicense;
  const currentId = `${pkLicense}-${cycleId}`;
  const transcript = transcriptSelector.getEntityById(currentId);
  const transcriptStatus = transcriptSelector.getStatusById(currentId);

  const adsSelectors = adsStore.selectors(state);
  const ad = adsSelectors.getAd();
  const fetchAdsStatus = adsSelectors.fetchAdsStatus();
  const fetchAdsDetailStatus = adsSelectors.fetchAdsDetailStatus();

  const professionIds = licenses.map((l) => l.profession.id);

  const seeCheckoutPage = usersSelector.getUserFlag('seeCheckoutPage');

  const coursesInCart = cartSelectors.getCartEntities();
  const saveCartItemStatus = cartSelectors.saveCartItemStatus();
  const totalItemsInCart = cartSelectors.getCartTotalItems();
  const subscriptionPlans = subscriptionsSelectors.getSubscriptionPlans();

  const licenseeSubscription = authenticationSelectors.getLicenseeSubscription();
  const { code: licenseeSubscriptionCode } = licenseeSubscription;

  const shouldShowProPlusBadge = getSubscriptionConditions({
    subscriptionPlans,
    subscriptionTypeToFind: SUBSCRIPTION_TYPES.PRO_PLUS,
    currentSubscriptionType: licenseeSubscriptionCode,
  });

  return {
    ad,
    user,
    subscriptionType,
    license,
    transcript,
    transcriptStatus,
    fetchAdsStatus,
    fetchAdsDetailStatus,
    match,
    location,
    isDohUser,
    loggedOnBehalf,
    professionIds,
    isFTOAvailable,
    selectedRequestedLicense,
    isRequestedLicense,
    auditTasks,
    seeCheckoutPage,
    customerId,
    coursesInCart,
    saveCartItemStatus,
    totalItemsInCart,
    shouldShowProPlusBadge,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchTranscript: transcriptActions.fetch,
    showOverviewBetaTour: tourStore.actions.showOverview,
    hideOverviewBetaTour: tourStore.actions.hideOverview,
    fetchAds: adsStore.actions.fetch,
    fetchAuditTask: auditStore.actions.fetch,
    addNotification: notificationsStore.actions.add,
    saveCartItem: cartStore.actions.saveCartItem,
    clearStatusCart: cartStore.actions.clearStatusCart,
  })(Overview)
);
