import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'emerald-ui/lib';

class FindCoursesButton extends React.Component {
  render() {
    const { totalCourses, onClick } = this.props;
    return (
      <Fragment>
        <p>
          We found <strong>{totalCourses}</strong> courses that will fulfill this requirement
        </p>
        <Button color="info" className="find-courses-button" onClick={onClick}>
          View courses
        </Button>
      </Fragment>
    );
  }
}

FindCoursesButton.propTypes = {
  totalCourses: PropTypes.number,
  onClick: PropTypes.func,
};

export default FindCoursesButton;
