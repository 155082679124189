import { put, call, select } from 'redux-saga/effects';
import * as api from '../../../api/payments';
import at from '../types';

export default function* cancelSubscription({ meta = {} }) {
  const state = yield select();
  const user = state.authentication.licenseeAccount.user;
  const userLogonId = state.authentication.authenticatedAccount.id;
  try {
    yield call(api.cancelSubscription, user.id, userLogonId);
    yield put({
      type: at.CANCEL_SUBSCRIPTION_SUCCESS,
      userId: user.id,
      userLogonId,
      meta,
    });
  } catch (error) {
    console.error(error); //eslint-disable-line
    yield put({
      type: at.CANCEL_SUBSCRIPTION_FAILURE,
      userId: user.id,
      userLogonId,
      meta,
    });
  } finally {
    //eslint-disable-line
  }
}
