import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AddLicense from '../components/AddLicense';
import * as authenticationStore from '../store/authentication';
import * as notificationsStore from '../store/notifications';
import * as addLicenseInfoStore from '../store/addLicenseInfo';

function mapStateToProps(state) {
  const authenticationSelectors = authenticationStore.selectors(state);
  const licenseeAccount = authenticationSelectors.getLicenseeAccount();
  const { user, subscriptions = [] } = licenseeAccount;
  const authenticatedAccount = authenticationSelectors.getAuthenticatedAccount();
  const loggedOnBehalf = authenticatedAccount.role === 'helpdesk';

  const addLicenseInfoSelectors = addLicenseInfoStore.selectors(state);
  const states = addLicenseInfoSelectors.getStates();
  const professions = addLicenseInfoSelectors.getProfessions();
  const subscriptionType = subscriptions[0] ? subscriptions[0].type : '';
  return {
    user,
    authenticatedUser: authenticatedAccount,
    loggedOnBehalf,
    states,
    professions,
    currentFetchStatus: addLicenseInfoSelectors.getFetchStatus(),
    subscriptionType,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    addNotification: notificationsStore.actions.add,
    fetchStatesProfessions: addLicenseInfoStore.actions.fetch,
  })(AddLicense)
);
