import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'emerald-ui';
import { withRouter, Link } from 'react-router-dom';
import { saveEvent } from '../../api/statistics';
import backgroundImage from './dashboard-upgrade-card.svg';
import './HeartcardUpgradeAd.css';

class HeartcardUpgradeAd extends React.Component {
  onClickUpgrade = (e) => {
    e.preventDefault();
    const { user, history } = this.props;
    saveEvent(user.id, 'UPGRADE', 'HEARTHCARD_AD');
    history.push('/upgrade');
  };

  render() {
    const { isftoAvailable } = this.props;
    return (
      <div className="heartcard-upgrade-ad">
        <h1 className="add-record-title">Upgrade required</h1>
        <div className="heartcard-add-container">
          <img src={backgroundImage} alt="heartcard upgrade img" />
          <p className="heartcard-ad-title">Upgrade to a professional account to add a new heart card</p>
          <p className="heartcard-ad-body">
            With a CE Broker professional account, you can keep track of certification renewal dates, see your official
            CE compliance transcript for license renewal, and so much more!
          </p>
        </div>
        <Link to="/upgrade">
          <Button color="info" onClick={this.onClickUpgrade}>
            {isftoAvailable ? 'Free trial' : 'Upgrade'}
          </Button>
        </Link>
      </div>
    );
  }
}

HeartcardUpgradeAd.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  isftoAvailable: PropTypes.bool,
};

HeartcardUpgradeAd.defaultProps = {
  user: {},
  history: {},
  isftoAvailable: false,
};

export default withRouter(HeartcardUpgradeAd);
