import React from 'react';
import { Modal } from 'emerald-ui-3';
import PropTypes from 'prop-types';
import AddLicense from '../../containers/AddLicense';
import AddHeartcard from '../../containers/AddHeartcard';
import './AddRecordModal.css';

class AddRecordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalSelection: false,
      modalSelected: '',
      stateSelected: null,
    };
  }

  onHideAddRecord = () => {
    this.props.onHideModal();
  };

  render() {
    const { modalSelected, showModal } = this.props;
    return (
      <div id="add-record-modal">
        <Modal show={showModal} onHide={this.onHideAddRecord} hideOnOutsideClick>
          <Modal.Header
            closeButton
            closeButtonProps={{
              'data-testid': 'heartcard-modal-close-button',
            }}
          >
            <Modal.Title>{modalSelected === 'add-license' ? 'Add License / Certificate' : 'Add Heartcard'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalSelected === 'add-license' && <AddLicense onHideAddRecord={this.onHideAddRecord} />}
            {modalSelected === 'add-heartcard' && <AddHeartcard onHideAddRecord={this.onHideAddRecord} />}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

AddRecordModal.propTypes = {
  modalSelected: PropTypes.string,
  showModal: PropTypes.bool,
  onHideModal: PropTypes.func,
};

export default AddRecordModal;
