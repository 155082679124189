import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal, Button } from 'emerald-ui';
import { DatePicker } from 'emerald-ui-3';
import SubjectAreaDropdown from '../SubjectAreaDropdown';

class FiltersModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      minCompletedDate: props.minCompletedDate || null,
      maxCompletedDate: props.maxCompletedDate || null,
      subjectArea: props.subjectArea,
    };
  }

  handleDateChange = (property) => (date) => {
    this.setState({ [property]: date ? moment(date).format('L') : null });
  };

  handleSubjectAreaChange = (newSubjectArea) => {
    let subjectAreaValue = newSubjectArea;
    if (!subjectAreaValue) {
      subjectAreaValue = '';
    }
    this.setState({ subjectArea: subjectAreaValue.toString() });
  };

  handleResetFilters = () => {
    const { onResetFilters } = this.props;
    this.setState({
      minCompletedDate: null,
      maxCompletedDate: null,
      subjectArea: '',
    });
    onResetFilters(this.state);
  };

  handleApplyFilters = () => {
    const { onApplyFilters } = this.props;
    onApplyFilters(this.state);
  };

  render() {
    const { show, onHide, subjectAreaList, showSubjectsFilter } = this.props;
    const {
      minCompletedDate: minCompletedDateState,
      maxCompletedDate: maxCompletedDateState,
      subjectArea,
    } = this.state;

    const minDate = new Date(1990, 0, 1);
    const maxYear = new Date().getFullYear();
    const maxDate = new Date(maxYear, 11, 31);

    const minCompletedDate = minCompletedDateState ? new Date(minCompletedDateState) : null;
    const maxCompletedDate = maxCompletedDateState ? new Date(maxCompletedDateState) : null;

    return (
      <Modal id="course-history-filters" show={show} onHide={onHide} size="sm" hideOnOutsideClick={true}>
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: '100%' }}>
            <div className="filter-modal-container">
              <label htmlFor="date-range">Completion date range</label>
              <div className="dateRange">
                <DatePicker
                  id="start-date"
                  label="Start date"
                  shape="classic"
                  ariaLabel="Start date"
                  placeholderText="mm/dd/yyyy"
                  selected={minCompletedDate}
                  selectsStart
                  startDate={minCompletedDate}
                  endDate={maxCompletedDate}
                  onChange={this.handleDateChange('minCompletedDate')}
                  minDate={minDate}
                  maxDate={maxDate}
                  filterDate={(date) => {
                    return maxCompletedDate ? date <= new Date(maxCompletedDate) : true;
                  }}
                />
                <p className="date-range-divider">to</p>
                <DatePicker
                  id="end-date"
                  label="End date"
                  shape="classic"
                  ariaLabel="End date"
                  placeholderText="mm/dd/yyyy"
                  selected={maxCompletedDate}
                  selectsEnd
                  startDate={minCompletedDate}
                  endDate={maxCompletedDate}
                  onChange={this.handleDateChange('maxCompletedDate')}
                  minDate={minDate}
                  maxDate={maxDate}
                  filterDate={(date) => {
                    return date >= new Date(minCompletedDate);
                  }}
                />
              </div>
              {showSubjectsFilter && (
                <React.Fragment>
                  <label htmlFor="subject-area">Subject Area</label>
                  <SubjectAreaDropdown
                    subjectAreas={subjectAreaList}
                    selectedSubjectArea={subjectArea}
                    className="header-select header-select-white-bg"
                    onSubjectAreaChange={this.handleSubjectAreaChange}
                  />
                </React.Fragment>
              )}
            </div>
            <div className="buttons-container">
              <Button color="info" shape="flat" style={{ marginRight: '5px' }} onClick={this.handleResetFilters}>
                Reset filters
              </Button>
              <Button color="info" onClick={this.handleApplyFilters}>
                Apply
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

FiltersModal.propTypes = {
  minCompletedDate: PropTypes.string,
  maxCompletedDate: PropTypes.string,
  subjectArea: PropTypes.string,
  subjectAreaList: PropTypes.array,
  showSubjectsFilter: PropTypes.bool,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onApplyFilters: PropTypes.func,
  onResetFilters: PropTypes.func,
};

export default FiltersModal;
