import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Icon, Button, Spinner } from 'emerald-ui';

const SelectedAuditModal = (props) => {
  const { auditTask, userFlagsAudit, professionName, showModal, closeModal, onSubmit } = props;

  const { deadlineDate, renewalEndDate, licenseNumber, board } = auditTask;

  return (
    <Modal show={showModal} onHide={closeModal} hideOnOutsideClick className="center-modal at-modal">
      <Modal.Header closeButton>
        <Modal.Title className="title">
          <span>You have been selected for an audit</span>
          {userFlagsAudit.statusRequest === 'loading' ? <Spinner size="sm" color="info" /> : null}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The {board && board.name} has selected to audit your {professionName} license {licenseNumber} for the cycle
          ending on {renewalEndDate}
        </p>
        <div className="at-modal-description">
          <Icon name="check_circle" className="icon-current-light" />
          <span>You will need to submit all necessary records by {deadlineDate}.</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onSubmit} disabled={userFlagsAudit.statusRequest === 'loading'} color="info">
          Begin
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SelectedAuditModal.propTypes = {
  auditTask: PropTypes.object,
  userFlagsAudit: PropTypes.object,
  fetchFlagUser: PropTypes.func,
  history: PropTypes.object,
  professionName: PropTypes.string,
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default SelectedAuditModal;
