import { put, call, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import * as api from '../../../api/payments';
import at from '../types';
import selectors from '../selectors';
import * as actions from '../actions';
import * as schema from '../schema';

export default function* fetchPaymentHistory({ filters, meta = {} }) {
  if (meta.clearEntities) {
    yield put(actions.clearEntities());
  }

  const state = yield select();
  const pageStatus = selectors(state).getPageFetchStatus(filters.page);
  if (pageStatus === 'loaded') {
    return;
  }
  yield put(actions.setPageFetchStatus(filters.page, 'loading'));
  const user = state.authentication.licenseeAccount.user;
  try {
    const { data: resultData } = yield call(api.fetchPaymentHistory, user.id, filters);
    const { paymentHistory } = resultData;
    const totalRecords = paymentHistory.length > 0 ? paymentHistory[0].totalRows : 0;
    const data = normalize(paymentHistory, schema.paymentHistoryArray);
    yield put({
      type: at.FETCH_PAYMENT_HISTORY_SUCCESS,
      userId: user.id,
      ...data,
      filters,
      totalRecords,
      meta,
    });
    // yield put(actions.setPageFetchStatus(filters.page, 'loaded'));
  } catch (error) {
    console.error(error); //eslint-disable-line
    yield put({
      type: at.FETCH_PAYMENT_HISTORY_FAILURE,
      userId: user.id,
      filters,
      meta,
    });
    // yield put(actions.setPageFetchStatus(filters.page, 'failed'));
  } finally {
    //eslint-disable-line
  }
}
