import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Icon } from 'emerald-ui';

class DownloadPdfModal extends React.Component {
  handleDownloadPdfReport = () => {
    const { cchPdfData, getBrowserDownload, updatePdfStatus } = this.props;
    getBrowserDownload(`${cchPdfData.storageUrl}`);
    updatePdfStatus(cchPdfData);
  };

  onPdfDownload = (blobBuffer) => {
    const blob = new Blob([blobBuffer.data]);
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, 'CCH-Report.pdf');
    } else {
      const ref = document.createElement('a');
      ref.href = URL.createObjectURL(blob);
      ref.download = 'CCH-Report.pdf';
      ref.click();
    }
  };

  componentDidUpdate(prevProps) {
    const { browserDownload } = this.props;
    if (prevProps.browserDownload.data !== browserDownload.data) {
      this.onPdfDownload(browserDownload);
    }
  }

  render() {
    const { show, onHide } = this.props;
    return (
      <Modal show={show} onHide={onHide} size="sm" hideOnOutsideClick={true} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>Your report is ready</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: '100%' }}>
            <div className="downloadFile">
              <div className="downloadLogo"></div>
            </div>
            <div style={{ textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
              <Button color="info" onClick={this.handleDownloadPdfReport}>
                <Icon name="cloud_download" />
                <span>Download PDF Report</span>
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

DownloadPdfModal.propTypes = {
  show: PropTypes.bool,
  cchPdfData: PropTypes.object,
  onHide: PropTypes.func,
  getBrowserDownload: PropTypes.func,
  browserDownload: PropTypes.object,
  updatePdfStatus: PropTypes.func,
  onResetModal: PropTypes.func,
};

export default DownloadPdfModal;
