import React from 'react';
import PropTypes from 'prop-types';
import { DropdownButton, DropdownItem, Icon } from 'emerald-ui';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getSlugFromName } from '../../utils';
import { courseSearchUrl } from '../../config';
import './ShareCourse.css';

class ShareCourse extends React.Component {
  constructor(props) {
    super(props);

    const { isFeatured = false, offeringId, courseName } = this.props;

    const slug = getSlugFromName(courseName);
    const pathName = `/courses/${offeringId}-${slug}`;

    const urlClipboard = `${courseSearchUrl}${pathName}?isFeatured=${isFeatured}&share=true`;

    this.state = {
      urlClipboard,
    };
  }

  handleCourseShareClick = (e) => {
    e.stopPropagation();
  };

  handleCourseShareSelect = (key, e) => {
    e.stopPropagation();

    const { courseName } = this.props;

    let urlShare = '';

    switch (key) {
      case 'facebook':
        urlShare = `https://www.facebook.com/sharer/sharer.php?u=${this.state.urlClipboard}`;
        break;

      case 'twitter':
        urlShare = `https://twitter.com/home?status=Interested in ${encodeURIComponent(courseName)} course at: ${
          this.state.urlClipboard
        }&t=${encodeURIComponent(courseName)}`;
        break;

      case 'email':
        urlShare = `mailto:?body=I found this course on the CE Broker Course Search and thought you might enjoy it. Take this link and check it out! ${
          this.state.urlClipboard
        }&subject=${encodeURIComponent(courseName)}`;
        break;

      default:
        break;
    }

    if (urlShare) {
      window.open(urlShare, '_blank');
    }
  };

  handleCopyUrl = () => {
    const { addNotification } = this.props;
    addNotification(
      {
        id: Math.random(),
        message: 'Link has been copied',
        timeout: 3000,
        actionText: '',
      },
      { single: true }
    );
  };
  render() {
    return (
      <div className="share-course">
        <DropdownButton
          title={
            <React.Fragment>
              <Icon name="reply" />
              <p className="functional-label-button"> Share </p>
            </React.Fragment>
          }
          icon="reply"
          className="action-icon-button"
          id="share-course-button"
          onSelect={this.handleCourseShareSelect}
          onClick={this.handleCourseShareClick}
        >
          <DropdownItem eventKey="facebook">
            <i className="fab fa-facebook-f facebook-icon" /> Facebook
          </DropdownItem>
          <DropdownItem eventKey="twitter">
            <i className="fab fa-twitter twitter-icon" /> Twitter
          </DropdownItem>
          <DropdownItem eventKey="email">
            <i className="material-icons">email</i> Email
          </DropdownItem>
          <DropdownItem separator />
          <CopyToClipboard text={this.state.urlClipboard} onCopy={this.handleCopyUrl}>
            <DropdownItem eventKey="copy-link">
              <i className="material-icons">link</i> Copy link
            </DropdownItem>
          </CopyToClipboard>
        </DropdownButton>
      </div>
    );
  }
}

ShareCourse.propTypes = {
  isFeatured: PropTypes.bool,
  offeringId: PropTypes.number,
  courseName: PropTypes.string,
  addNotification: PropTypes.func,
};

ShareCourse.defaultProps = {
  isCourseDetail: false,
  addNotification: () => {},
};

export default ShareCourse;
