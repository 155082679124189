import React, { Component } from 'react';
import PropTypes from 'prop-types';
import config from '../../config';
import utils from '../../utils';
import { Modal, Button } from 'emerald-ui/lib';

import './DohReturnLink.css';

const { getLogoutUrl } = config;

const logoutUrl = getLogoutUrl();

class DohReturnLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDohUserWelcome: false,
    };
  }

  UNSAFE_componentWillMount() {
    const showDohUserWelcome = utils.getSessionItem('showDohUserWelcome') === 'true';
    if (showDohUserWelcome) {
      this.setState({
        showDohUserWelcome: true,
      });
    }
  }

  handleDohUserWelcomeClose = () => {
    const { betaTourStartHandle } = this.props;
    this.setState({
      showDohUserWelcome: false,
    });
    utils.setSessionItem('showDohUserWelcome', 'false');
    if (betaTourStartHandle) {
      betaTourStartHandle();
    }
  };

  render() {
    const { isDohUser } = this.props;
    const dohText = isDohUser === 'MEN' ? 'MQA Services' : 'License Renewal';
    return (
      <div>
        {isDohUser && (
          <div className="doh-return-link">
            <Modal show={this.state.showDohUserWelcome} bsSize="sm" id="doh-user-welcome">
              <Modal.Header>
                <Modal.Title>Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="doh-info">
                  You were automatically logged into the Department&apos;s continuing education tracking system.
                </p>
                <p className="doh-info">
                  To return to <strong>{dohText}</strong> at anytime, please click the blue link on the left side of the
                  screen that says &#x22;<em>Return to {dohText}</em>&#x22;, or simply log out of your CE Broker
                  account.
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button color="success" onClick={this.handleDohUserWelcomeClose}>
                  OK
                </Button>
              </Modal.Footer>
            </Modal>
            <a href={logoutUrl}>
              Return to <strong>{dohText}</strong>
            </a>
          </div>
        )}
      </div>
    );
  }
}

DohReturnLink.propTypes = {
  isDohUser: PropTypes.string,
  betaTourStartHandle: PropTypes.func,
};

DohReturnLink.defaultProps = {
  isDohUser: '',
  betaTourStartHandle: () => {},
};

export default DohReturnLink;
