import { schema } from 'normalizr';

export const inboxNotifications = new schema.Entity(
  'items',
  {},
  {
    idAttribute: (value) => value.inboxId,
    processStrategy: (value) => ({
      ...value,
      reduxId: value.inboxId,
    }),
  }
);

export const inboxNotificationsArray = new schema.Array(inboxNotifications);
