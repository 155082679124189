import axios from 'axios';
import config from '../config';

const { licenseesApiInternal } = config;

export const getStates = () => {
  return axios.request({
    method: 'get',
    url: `${licenseesApiInternal}/states?allowedInCourseSearch=true`,
    headers: {},
  });
};

export const getStatesAndProfessions = () => {
  return axios.request({
    method: 'GET',
    url: `${licenseesApiInternal}/states/professions/add-license`,
  });
};
