import React from 'react';
import PropTypes from 'prop-types';
import Requirement from '../Requirement';
import './RequirementsList.css';

const RequirementsList = (props) => {
  const {
    items = [],
    showCourseTypeColumn,
    scenarioType,
    isViewOnlyCompleted,
    professionId,
    stateCode,
    pkLicense,
    title,
    user,
    licensePeriodId,
    loggedOnBehalf,
    isAuditTranscript = false,
  } = props;
  const requirements = items.map((item) => {
    const appliedHours = item.totalAppliedHours;
    const remainingHours = item.remainingHours;
    return (
      <Requirement
        item={item}
        key={`${item.id}-requirements`}
        showCourseTypeColumn={showCourseTypeColumn}
        scenarioType={scenarioType}
        isViewOnlyCompleted={isViewOnlyCompleted}
        hoursNeeded={remainingHours}
        appliedHours={appliedHours}
        professionId={professionId}
        stateCode={stateCode}
        isCompleted={item.isCompleted}
        isNotRequired={item.isNotRequired}
        pkLicense={pkLicense}
        licensePeriodId={licensePeriodId}
        user={user}
        loggedOnBehalf={loggedOnBehalf}
        isAuditTranscript={isAuditTranscript}
      />
    );
  });

  return (
    <div className="requirements-list">
      <span>
        <h3 className="title">{title}</h3>
      </span>
      {requirements}
    </div>
  );
};

RequirementsList.propTypes = {
  items: PropTypes.array.isRequired,
  showCourseTypeColumn: PropTypes.bool,
  scenarioType: PropTypes.string,
  isViewOnlyCompleted: PropTypes.bool,
  professionId: PropTypes.number,
  stateCode: PropTypes.string,
  pkLicense: PropTypes.number,
  title: PropTypes.string,
  user: PropTypes.object,
  licensePeriodId: PropTypes.number,
  loggedOnBehalf: PropTypes.bool,
  isAuditTranscript: PropTypes.bool,
};

export default RequirementsList;
