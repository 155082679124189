import { put, call, select } from 'redux-saga/effects';
import * as api from '../../../api/users';
import at from '../types';

export default function* update({ userProfile, meta = {} }) {
  const state = yield select();
  const user = state.authentication.licenseeAccount.user;
  try {
    yield call(api.updateProfile, user.id, userProfile);
    yield put({
      type: at.UPDATE_SUCCESS,
      userId: user.id,
      userProfile,
      meta,
    });
  } catch (error) {
    console.error(error); //eslint-disable-line
    yield put({ type: at.UPDATE_FAILURE, userId: user.id, meta });
  } finally {
    //eslint-disable-line
  }
}
