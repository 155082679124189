import 'babel-polyfill';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { store } from './store/configureStore';
import 'emerald-ui-3/lib/styles.css';
import 'emerald-ui/lib/styles-ceb.css';

import './index.css';
import config from './config';

import App from './containers/App';

import LoadingPage from './components/LoadingPage';
import LoginPage from './containers/LoginPage';
import withGATraker from './HOC/withGATracker';
import UpgradeSuccessPage from './containers/UpgradeSuccessPage';
import ReactivateSubscription from './containers/ReactivateSubscription';

import { init as initApm } from '@elastic/apm-rum';
import { ApmRoute } from '@elastic/apm-rum-react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

const AsyncUpgradePage = lazy(() => import('./containers/UpgradePage'));
const { urlBase = '/' } = config;

const {
  REACT_APP_ENABLE_GOOGLE_ANALYTICS = false,
  REACT_APP_ENV,
  REACT_APP_APM_SERVER_URL,
  REACT_APP_ENABLE_LOG_ROCKET,
  REACT_APP_LOG_ROCKET_PROJECT_ID,
} = process.env;

if (REACT_APP_ENABLE_GOOGLE_ANALYTICS === 'true') {
  console.log('Initializing google analytics'); //eslint-disable-line

  // 07/24/2019 - AW - https://trello.com/c/rUDnR1hw: set Google Analytics tracking number by environment
  let GATrackingId = 'UA-0000000-1';
  let GATrackingIdProductsOperationLoad = 'UA-0000000-1';
  if (REACT_APP_ENV === 'production') {
    GATrackingId = 'UA-58010485-1';
    GATrackingIdProductsOperationLoad = 'UA-144919153-5';
  } else if (REACT_APP_ENV === 'demo') {
    GATrackingId = 'UA-144116999-2';
    GATrackingIdProductsOperationLoad = 'UA-144897168-5';
  } else if (REACT_APP_ENV === 'staging') {
    GATrackingId = 'UA-58010485-4';
    GATrackingIdProductsOperationLoad = 'UA-144908044-4';
  }
  ReactGA.initialize(
    [
      {
        trackingId: GATrackingId,
        gaOptions: {},
      },
      {
        trackingId: GATrackingIdProductsOperationLoad,
        gaOptions: {
          name: 'productsOperationLoad',
        },
      },
    ],
    { titleCase: false }
  );
}

// APM initialization

const ENVIRONMENTS = {
  staging: 'Test',
  demo: 'Demo',
  production: 'Production',
};

if (REACT_APP_ENV && REACT_APP_ENV !== 'development') {
  initApm({
    serviceName: 'ceb-licensee',
    serverUrl: REACT_APP_APM_SERVER_URL,
    environment: ENVIRONMENTS[REACT_APP_ENV],
    serviceVersion: '1.0.0',
  });
}

if (REACT_APP_LOG_ROCKET_PROJECT_ID && REACT_APP_ENABLE_LOG_ROCKET) {
  LogRocket.init(REACT_APP_LOG_ROCKET_PROJECT_ID, {
    rootHostname: 'cebroker.com',
  });
  setupLogRocketReact(LogRocket);
}
const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <BrowserRouter basename={urlBase}>
        <Suspense fallback={<LoadingPage />}>
          <Switch>
            <ApmRoute path="/login" component={withGATraker(LoginPage)} />
            <Route path="/upgrade" component={withGATraker(AsyncUpgradePage)} />
            <ApmRoute path="/upgrade-success" component={withGATraker(UpgradeSuccessPage)} />
            <ApmRoute path="/reactivate-subscription" component={ReactivateSubscription} />
            <Route path="/" component={withGATraker(App)} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </Provider>
    <ReactQueryDevtools />
  </QueryClientProvider>,
  document.getElementById('root')
);
