import at from './types';

export const fetchPaymentInfo = (meta = {}) => ({
  type: at.FETCH_PAYMENT_INFO_REQUEST,
  meta,
});

export const updatePaymentInfo = ({ creditCard, promoCode, meta = {}, address }) => ({
  type: at.UPDATE_PAYMENT_INFO_REQUEST,
  creditCard,
  promoCode,
  meta,
  address,
});

export const fetchRenewFee = (meta = {}) => ({
  type: at.FETCH_RENEW_FEE_REQUEST,
  meta,
});

export const fetchHasActiveSubscription = (meta = {}) => ({
  type: at.FETCH_ACTIVE_SUBSCRIPTION_REQUEST,
  meta,
});

export const cancelSubscription = (meta = {}) => ({
  type: at.CANCEL_SUBSCRIPTION_REQUEST,
  meta,
});

export const downgradeToPro = (meta = {}) => ({
  type: at.DOWNGRADE_ACCOUNT_REQUEST,
  meta,
});

export const fetchLastSubscription = (userId) => ({
  type: at.FETCH_LAST_SUBSCRIPTION_REQUEST,
  userId,
});
