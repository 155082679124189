export default {
  FETCH_CART: 'cart/FETCH_REQUEST',
  FETCH_CART_REQUEST: 'cart/FETCH_CART_REQUEST',
  FETCH_CART_SUCCESS: 'cart/FETCH_CART_SUCCESS',
  FETCH_CART_FAILURE: 'cart/FETCH_CART_FAILURE',

  SAVE_CART_ITEM: 'cart/SAVE_CART_ITEM',
  SAVE_CART_ITEM_REQUEST: 'cart/SAVE_CART_ITEM_REQUEST',
  SAVE_CART_ITEM_SUCCESS: 'cart/SAVE_CART_ITEM_SUCCESS',
  SAVE_CART_ITEM_FAILURE: 'cart/SAVE_CART_ITEM_FAILURE',

  DELETE_CART_ITEM: 'cart/DELETE_CART_ITEM',
  DELETE_CART_ITEM_REQUEST: 'cart/DELETE_CART_ITEM_REQUEST',
  DELETE_CART_ITEM_SUCCESS: 'cart/DELETE_CART_ITEM_SUCCESS',
  DELETE_CART_ITEM_FAILURE: 'cart/DELETE_CART_ITEM_FAILURE',

  ADD_ENTITIES: 'cart/ADD_ENTITIES',
  CLEAR_ENTITIES: 'cart/CLEAR_ENTITIES',
  CLEAR_SAVE_CART_ITEM_STATUS: 'cart/CLEAR_SAVE_CART_ITEM_STATUS',
};
