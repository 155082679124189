import { takeLatest } from 'redux-saga/effects';

import { cancelTrial } from './cancelTrial';
import { getSubscriptionPrices } from './getSubscriptionPrices';
import { getSubscriptionPlans } from './getSubscriptionPlans';

import at from '../types';

export default function* root() {
  yield takeLatest(at.CANCEL_TRIAL, cancelTrial);
  yield takeLatest(at.FETCH_SUBSCRIPTION_PRICES_REQUEST, getSubscriptionPrices);
  yield takeLatest(at.FETCH_SUBSCRIPTION_PLANS_REQUEST, getSubscriptionPlans);
}
