import { put, call, select } from 'redux-saga/effects';

import * as api from '../../../api/communications';
import at from '../types';

export default function* fetchAuditMessages({ actionType }) {
  let actionTypeSuccess, actionTypeFailure;
  if (actionType === 'REQUEST') {
    actionTypeSuccess = at.FETCH_AUDIT_REQUEST_SUCCESS;
    actionTypeFailure = at.FETCH_AUDIT_REQUEST_FAILURE;
  } else {
    actionTypeSuccess = at.FETCH_AUDIT_RESPONSE_SUCCESS;
    actionTypeFailure = at.FETCH_AUDIT_RESPONSE_FAILURE;
  }

  try {
    const state = yield select();
    const ownerId = state.authentication.licenseeAccount.licenses[0].ownerId;
    const {
      data: { messages, totalMessages },
    } = yield call(api.fetchAuditMessages, ownerId, actionType);
    yield put({
      type: actionTypeSuccess,
      actionType,
      messages,
      totalMessages,
    });
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put({ type: actionTypeFailure, actionType });
  }
}
