import { takeEvery } from 'redux-saga/effects';

import at from '../types';
import fetch from './fetch';
import archiveNotifications from './archiveNotifications';
import deleteNotifications from './deleteNotifications';

export default function* root() {
  yield takeEvery(at.FETCH_REQUEST, fetch);
  yield takeEvery(at.ARCHIVE_REQUEST, archiveNotifications);
  yield takeEvery(at.DELETE_REQUEST, deleteNotifications);
}
