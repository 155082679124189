import React from 'react';
import PropTypes from 'prop-types';
import { SingleSelect } from 'emerald-ui';
import queryString from 'query-string';
import './SelectLicense.css';
import AddRecordModal from '../AddRecordModal';
import utils from '../../utils';

const PANEL_HEADING = 'MY LICENSE(S) / CERTIFICATE(S)';
export default class SelectLicense extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalAddLicense: false,
    };
  }

  handleLicenseChange = (newLicenseId) => {
    const {
      history = {},
      location,
      fetchTranscript,
      fetchCourseHistory,
      fetchReportedExemptions,
      fetchBoardLogo,
      fetchAuditTask,
      filters,
      licenses,
      resetCoursesTab,
      clearTimerDownload,
      boardLogos,
      setLicenseToggle,
      fetchSubjectAreas,
      subscriptionType,
    } = this.props;

    if (newLicenseId === 'add_new_license') {
      this.setState({ showModalAddLicense: true });
      return;
    }

    const { pathname = '' } = location;
    const section = pathname.split('/')[3];
    const query = queryString.parse(location.search);
    const selectedLicense = licenses.find((l) => l.id === newLicenseId);
    const {
      isRequestedLicense = false,
      currentCycleId,
      profession: { board: { id: boardId } = {} } = {},
    } = selectedLicense;

    const isEmployee = subscriptionType === 'EMP';

    if (boardId && !boardLogos[boardId]) {
      fetchBoardLogo(boardId);
    }

    if (isRequestedLicense) {
      setLicenseToggle('');
      utils.removeUseLocalStorage('toggleIdSelected');
      history.push(`/license/${newLicenseId}/overview`);
      return;
    }

    if (selectedLicense) {
      if (!isEmployee) {
        fetchAuditTask({ pkLicense: newLicenseId });
      }
      const { profession: { id: professionId = 0 } = {} } = selectedLicense;
      if (professionId && professionId > 0) {
        fetchSubjectAreas(professionId);
      }
    }

    query.cycle = currentCycleId;
    delete query.subjectArea;

    const search = queryString.stringify(query);
    utils.setUseLocalStorage('toggleIdSelected', newLicenseId);
    utils.setUseLocalStorage('toggleChanged', true);
    setLicenseToggle(newLicenseId);
    history.push(`/license/${newLicenseId}/${section}?${search}`);

    delete filters.subjectArea;

    fetchTranscript({ pkLicense: newLicenseId, cycleId: currentCycleId });
    const csFilters = { ...filters, pkLicense: newLicenseId };
    fetchCourseHistory(csFilters, { clearEntities: true });
    const reFiltes = { ...filters, license: newLicenseId };

    if (selectedLicense.inAllowReportExemption) {
      fetchReportedExemptions(reFiltes, { clearEntities: true });
    } else if (section === 'reported-exemptions') {
      history.push(`/license/${newLicenseId}/overview?${search}`);
    }

    if (resetCoursesTab) {
      resetCoursesTab();
    }

    if (clearTimerDownload) {
      clearTimerDownload();
    }
  };

  handleDiscoverLicenseChange = (newLicenseId) => {
    const { setLicenseToggle } = this.props;
    utils.setUseLocalStorage('toggleIdSelected', newLicenseId);
    setLicenseToggle(newLicenseId);
  };

  onHideAddRecord = () => {
    this.setState({ showModalAddLicense: false });
  };

  setCurrentLicenseId = () => {
    const { match, selectedLicenseId, history } = this.props;
    const isLicenseOnStorage = utils.getUseLocalStorage('toggleIdSelected');
    const { params = {} } = match;

    if (isLicenseOnStorage && isLicenseOnStorage !== 'undefined') {
      return isLicenseOnStorage;
    }

    const license =
      selectedLicenseId && history.location.pathname === '/my-learning/discovery'
        ? selectedLicenseId.toString()
        : params.pkLicense;

    utils.setUseLocalStorage('toggleIdSelected', license);

    return license;
  };

  render() {
    const { licenses = [], associationUsersInfo, label, shape, theme, showActions } = this.props;

    let licensesFiltered = [];

    if (!showActions) {
      licensesFiltered = licenses.filter((license) => !license.isRequestedLicense);
    }

    const selectedLicense = this.setCurrentLicenseId();

    return (
      <div id="licenses-container">
        {showActions ? (
          <SingleSelect
            className="select-license-container"
            id="licenses"
            shape={shape}
            theme={theme}
            onSelect={this.handleLicenseChange}
            label={label}
          >
            <optgroup key="1" label={PANEL_HEADING}>
              {licenses.map((license) => (
                <option key={license.id} value={license.id} selected={license.id.toString() === selectedLicense}>
                  <div className="select-licenses-list-container">
                    <div className="select-licenses-list-detail">
                      <div className="select-licenses-profession-name">{license.profession.name}</div>
                      <div className="select-licenses-number">
                        <div className="select-licenses-state">{license.profession.board.state.name}</div>|{' '}
                        {license.number}
                      </div>
                    </div>
                    <div className="select-licenses-status">{license.isRequestedLicense ? 'Verifying' : ''}</div>
                  </div>
                </option>
              ))}
            </optgroup>
            {associationUsersInfo.isAssociationUser ? (
              []
            ) : (
              <option key="button" value="add_new_license" className="add-license-section">
                <div className="add-license-option">
                  <i className="icon material-icons">add_circle</i>
                  <label htmlFor="add-license">ADD A NEW LICENSE/CERTIFICATE</label>
                </div>
              </option>
            )}
          </SingleSelect>
        ) : (
          <SingleSelect
            className="select-license-container discover-toggle"
            id="licenses"
            shape={shape}
            theme={theme}
            onSelect={this.handleDiscoverLicenseChange}
            label={label}
          >
            <optgroup key="1" label={PANEL_HEADING}>
              {licensesFiltered.map((license) => (
                <option key={license.id} value={license.id} selected={license.id.toString() === selectedLicense}>
                  <div className="select-licenses-list-container">
                    <div className="select-licenses-list-detail">
                      <div className="select-licenses-profession-name">{license.profession.name}</div>
                      <div className="select-licenses-number">
                        <div className="select-licenses-state">{license.profession.board.state.name}</div>|{' '}
                        {license.number}
                      </div>
                    </div>
                  </div>
                </option>
              ))}
            </optgroup>
          </SingleSelect>
        )}
        <AddRecordModal
          modalSelected={'add-license'}
          showModal={this.state.showModalAddLicense}
          onHideModal={this.onHideAddRecord}
        />
      </div>
    );
  }
}

SelectLicense.propTypes = {
  selectedLicenseId: PropTypes.any,
  licenses: PropTypes.array,
  fetchTranscript: PropTypes.func,
  fetchCourseHistory: PropTypes.func,
  fetchReportedExemptions: PropTypes.func,
  fetchBoardLogo: PropTypes.func,
  fetchAuditTask: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  filters: PropTypes.object,
  resetCoursesTab: PropTypes.func,
  associationUsersInfo: PropTypes.object,
  clearTimerDownload: PropTypes.func,
  fetchSubjectAreas: PropTypes.func,
  boardLogos: PropTypes.object,
  label: PropTypes.string,
  shape: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  showActions: PropTypes.bool.isRequired,
  setLicenseToggle: PropTypes.func.isRequired,
  subscriptionType: PropTypes.string,
};

SelectLicense.defaultProps = {
  label: '',
};
