import React from 'react';

import { Icon, Spinner } from 'emerald-ui/lib';
import TimedText from '../../TimedText';

function LoadingState() {
  return (
    <div className="transcript transcript-loading">
      <div className="container">
        <div className="basic-info-skeleton">
          <Spinner />
          <h3>{`Sit tight, we're building your transcript`}</h3>
          <TimedText />
        </div>
        <div className="subject-areas-title">
          <h3>Hour based requirements</h3>
        </div>
        <div className="subject-areas-skeleton">
          <div className="left-skeleton">
            <Icon name="radio_button_unchecked" />
            <div className="title-container">
              <p className="skeleton-stripe subtitle-skeleton" />
              <p className="skeleton-stripe title-skeleton" />
            </div>
          </div>
          <div className="skeleton-stripe subject-area-status" />
        </div>
        <div className="subject-areas-skeleton">
          <div className="left-skeleton">
            <Icon name="radio_button_unchecked" />
            <div className="title-container">
              <p className="skeleton-stripe subtitle-skeleton" />
              <p className="skeleton-stripe title-skeleton" />
            </div>
          </div>
          <div className="skeleton-stripe subject-area-status" />
        </div>
      </div>
    </div>
  );
}

export default LoadingState;
