import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ReportedExemptionFiltersModal from '../components/ReportedExemptionFiltersModal';

import * as licensesStore from '../store/licenses';
import * as authenticationStore from '../store/authentication';

function mapStateToProps(state, ownProps) {
  const authenticationSelectors = authenticationStore.selectors(state);
  const licenseeAccount = authenticationSelectors.getLicenseeAccount();

  const { licenses = [] } = licenseeAccount;

  const {
    match: {
      params: { pkLicense },
    },
  } = ownProps;

  return {
    licenses,
    pkLicense,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchLicensePeriods: licensesStore.actions.fetchLicensePeriods,
  })(ReportedExemptionFiltersModal)
);
