import { combineReducers } from 'redux';
import at from '../types';

export const inProgressEntities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_IN_PROGRESS_SUCCESS: {
      return {
        ...state,
        ...action.entities.items,
      };
    }
    default:
      return state;
  }
};

export const inProgressCourses = (state = [], action) => {
  switch (action.type) {
    case at.FETCH_IN_PROGRESS_LIST_SUCCESS: {
      return action.data;
    }
    case at.FETCH_IN_PROGRESS_LIST_FAILURE: {
      return [];
    }
    default:
      return state;
  }
};

export const inProgressFetchStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_IN_PROGRESS_REQUEST: {
      return 'loading';
    }
    case at.FETCH_IN_PROGRESS_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_IN_PROGRESS_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const inProgressPages = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_IN_PROGRESS_SUCCESS: {
      const { offset = 0, limit = 5, result } = action;
      const pageNumber = Math.floor(offset / limit + 1);
      return {
        ...state,
        [pageNumber]: result,
      };
    }
    default:
      return state;
  }
};

export const inProgressOptions = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_IN_PROGRESS_SUCCESS: {
      const { next, previous, first, last, total } = action;
      return {
        next,
        previous,
        first,
        last,
        total,
      };
    }
    default:
      return state;
  }
};

export const completedEntities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_COMPLETED_SUCCESS: {
      return {
        ...state,
        ...action.entities.items,
      };
    }
    default:
      return state;
  }
};

export const completedFetchStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_COMPLETED_REQUEST: {
      return 'loading';
    }
    case at.FETCH_COMPLETED_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_COMPLETED_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const completedPages = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_COMPLETED_SUCCESS: {
      const { offset = 0, limit = 5, result } = action;
      const pageNumber = Math.floor(offset / limit + 1);
      return {
        ...state,
        [pageNumber]: result,
      };
    }
    default:
      return state;
  }
};

export const completedOptions = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_COMPLETED_SUCCESS: {
      const { next, previous, first, last, total } = action;
      return {
        next,
        previous,
        first,
        last,
        total,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  inProgressEntities,
  inProgressFetchStatus,
  inProgressPages,
  inProgressOptions,
  completedEntities,
  completedFetchStatus,
  completedPages,
  completedOptions,
  inProgressCourses,
});
