import { takeLatest, takeEvery } from 'redux-saga/effects';

import at from '../types';
import watchFetch from './fetch';
import watchDeleteHeartcard from './deleteHeartcard';
import addHeartcard from './addHeartcard';
import addAttachment from './addAttachment';
import deleteAttachment from './deleteAttachment';

export default function* root() {
  yield takeLatest(at.FETCH_REQUEST, watchFetch);
  yield takeLatest(at.DELETE_HEARTCARD_REQUEST, watchDeleteHeartcard);
  yield takeLatest(at.ADD_HEARTCARD_REQUEST, addHeartcard);
  yield takeEvery(at.ADD_ATTACHMENT_REQUEST, addAttachment);
  yield takeEvery(at.DELETE_ATTACHMENT_REQUEST, deleteAttachment);
}
