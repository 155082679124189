import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Icon, SkeletonLoader } from 'emerald-ui';
import CardCorner from '../../../CardCorner';

class ComplianceStatusCard extends Component {
  render() {
    const {
      transcriptLabel,
      transcriptStatus,
      transcriptTitle,
      transcriptDescription,
      subscriptionType,
      percentageCompleted,
      transcriptActionText,
      handleClickTranscript,
    } = this.props;

    return (
      <Card className="card-compliance-status-box">
        <div className="card-content">
          <span className="card-title-sup">COMPLIANCE STATUS</span>
          {transcriptStatus !== 'loading' && !transcriptTitle ? (
            <div className="transcript-error">
              <Icon name="error" className="text-danger" size="lg" />
              <p className="text-danger header">There was an error loading your transcript.</p>
              <p>
                Please, try again later or contact the support center by clicking on the Help button located at the
                bottom right of your screen.
              </p>
            </div>
          ) : (
            <Fragment>
              <div className="compliance-status-container">
                <div className="compliance-status-description">
                  <p className={`card-title license-status ${transcriptLabel}`}>
                    {transcriptStatus === 'loading' ? (
                      <SkeletonLoader height="24px" loading style={{ margin: '5px 0' }} />
                    ) : (
                      transcriptTitle
                    )}
                  </p>
                  <div className="card-description">
                    {transcriptStatus === 'loading' ? (
                      <SkeletonLoader height="45px" loading style={{ margin: '5px 0' }} />
                    ) : (
                      <p>{transcriptDescription}</p>
                    )}
                  </div>
                </div>
                <div className="compliance-status-progress">
                  <CardCorner
                    isLoading={transcriptStatus === 'loading' || transcriptStatus === 'notLoaded'}
                    isBasic={subscriptionType === 'BAS'}
                    isHeartcard={false}
                    cardStatus={transcriptLabel}
                    percentage={percentageCompleted}
                    size="lg"
                  />
                </div>
              </div>
              <Button id="show-me-what-i-missing-button" color="info" onClick={handleClickTranscript}>
                {transcriptActionText}
                <Icon name="arrow_forward" style={{ marginLeft: '5px' }} />
              </Button>
            </Fragment>
          )}
        </div>
      </Card>
    );
  }
}

ComplianceStatusCard.propTypes = {
  transcriptStatus: PropTypes.string,
  transcriptTitle: PropTypes.string,
  transcriptDescription: PropTypes.string,
  subscriptionType: PropTypes.string,
  percentageCompleted: PropTypes.number,
  transcriptLabel: PropTypes.string,
  transcriptActionText: PropTypes.string,
  handleClickTranscript: PropTypes.func,
};

ComplianceStatusCard.defaultProps = {
  handleClickTranscript: () => {},
};

export default ComplianceStatusCard;
