import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import Progressbar from 'emerald-ui/lib/Progressbar';

import './CourseRatingDetail.css';
import StarRating from '../../../StarRating';

function RatingSection({ value, quantity, total, onChangeRating, disabled, onRemoveRatingFilter, isSelected }) {
  const percentage = ((quantity / total) * 100).toFixed(1);
  const progressPercentage = parseFloat(percentage);
  const progressPercentageRounded = Math.round(progressPercentage);
  const percentageText = percentage > 0 && percentage < 1 ? `<1%` : `${progressPercentageRounded}%`;

  return (
    <div className="rating-section-contain">
      <div
        className={`${disabled ? 'txt-star-noselected' : ''}`}
        id="rating-section"
        onClick={() => {
          if (!disabled) {
            onChangeRating(value);
          }
        }}
        disabled={disabled}
      >
        <span className="star-number">{`${value} ${value === 1 ? 'star' : 'stars'}`}</span>
        <Progressbar
          className={`${isSelected ? 'progress-bar-selected' : ''} progress-bar-noselected`}
          progress={progressPercentage}
          backgroundColor="black"
        />
        <span className={`${disabled ? 'btn-rating-filter-noselected' : ''} btn-rating-filter percentage-value`}>
          {percentageText}
        </span>
      </div>
      {isSelected > 0 && (
        <button
          className="btn-clear-rating eui-icon-button close eui-icon-button-default"
          onClick={() => {
            onChangeRating(0);
            onRemoveRatingFilter();
          }}
        >
          <i className="eui-icon material-icons">close</i>
        </button>
      )}
    </div>
  );
}

export default function CourseRatingDetail({
  rating,
  ratingCount,
  showRating = false,
  shouldResetValues,
  courseRatingNumbers,
  onRatingValueFilterChange,
}) {
  const ratingFixed = rating.toFixed(1);

  const [ratingSelected, setRatingSelected] = useState(0);
  const [isRatingFilterEnabled, setIsRatingFilterEnabled] = useState(false);

  const onSelectRating = (selectedValue) => {
    onRatingValueFilterChange(selectedValue);
    setRatingSelected(selectedValue);
    setIsRatingFilterEnabled(true);
  };

  const onRemoveRatingFilter = () => {
    setIsRatingFilterEnabled(false);
  };

  useEffect(() => {
    if (shouldResetValues) {
      setIsRatingFilterEnabled(false);
      setRatingSelected(0);
    }
  }, [shouldResetValues]);

  const { totalRatings, oneRatings, twoRatings, threeRatings, fourRatings, fiveRatings } = courseRatingNumbers;
  const ratingNumbers = [oneRatings, twoRatings, threeRatings, fourRatings, fiveRatings];

  return (
    <div className="course-rating-feedback">
      <span className="section-title">Learner feedback</span>
      <div className="course-rating-feedback-container">
        <div className="rating-section-info text-light">
          <span className="rating-value">{ratingFixed}</span>
          <div className="rating-container">
            <StarRating rating={parseFloat(ratingFixed)} showRating={showRating} />
            <p>{`${ratingCount} ${ratingCount === 1 ? 'rating' : 'ratings'}`}</p>
          </div>
        </div>
        <div className="rating-stars-container">
          {ratingNumbers.map((ratingNumber, index) => {
            const ratingValue = index + 1;
            return (
              <RatingSection
                isSelected={ratingSelected === ratingValue && isRatingFilterEnabled}
                disabled={ratingSelected !== ratingValue && isRatingFilterEnabled}
                onRemoveRatingFilter={onRemoveRatingFilter}
                onChangeRating={onSelectRating}
                total={totalRatings}
                quantity={ratingNumber}
                value={ratingValue}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

CourseRatingDetail.propTypes = {
  rating: propTypes.number.isRequired,
  ratingList: propTypes.array.isRequired,
  ratingCount: propTypes.number.isRequired,
  showRating: propTypes.bool,
  onRatingValueFilterChange: propTypes.func,
  shouldResetValues: propTypes.bool,
};
