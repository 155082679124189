export default function (globalState) {
  const state = globalState.dashboardCards;

  const getEntityById = (id) => state.entities[id] || {};

  const getEntityByHeartcardId = (id) => {
    const all = getEntities();
    return all.find((h) => h.id === id);
  };

  const getEntitiesByIds = (ids) => ids.map(getEntityById);

  const getFetchStatus = () => state.fetchStatus;

  const getDeleteHeartcardStatus = () => state.deleteHeartcardStatus;

  const getAddHeartcardStatus = () => state.addHeartcardStatus;

  const getEntities = () => state.all.map(getEntityById);

  return {
    getEntityById,
    getEntityByHeartcardId,
    getEntitiesByIds,
    getFetchStatus,
    getEntities,
    getDeleteHeartcardStatus,
    getAddHeartcardStatus,
  };
}
