import { call } from 'redux-saga/effects';
import queryString from 'query-string';

import config from '../../../config';
import utils from '../../../utils';

const { legacyUrl, licenseeTokenCookie, licenseeAuthInfoCookie } = config;

export function logout({ params }) {
  utils.deleteCookie(licenseeTokenCookie, 'cebroker.com');
  utils.deleteCookie(licenseeAuthInfoCookie, 'cebroker.com');

  const { loggedOnBehalf = false, isAssociationUser = false, associationReturnURL } = params || {};

  if (isAssociationUser && !!associationReturnURL && !loggedOnBehalf) {
    const { associationReturnURL, associationUserId, associationToken } = params;

    const redirectionPayload = {
      clientUserID: associationUserId,
      code: 200,
      reason: 'LOGOUT',
    };

    if (associationToken) {
      redirectionPayload.token = associationToken;
    }

    let compoundReturnlUrl = `${associationReturnURL}?${queryString.stringify(redirectionPayload)}`;
    compoundReturnlUrl = encodeURIComponent(compoundReturnlUrl);

    window.open(
      `${legacyUrl}/public/pb_auth_gateway.aspx?action=logout_associations&returnURL=${compoundReturnlUrl}`,
      '_self'
    );
  } else {
    window.open(`${legacyUrl}/public/pb_auth_gateway.aspx?action=logout`, '_self');
  }
}

export function* watchLogout(params) {
  yield call(logout, params);
}
