import { put, call, select } from 'redux-saga/effects';
import * as api from '../../../api/communications';
import at from '../types';

export default function* saveRequestResponse({ filters, meta }) {
  const state = yield select();
  const {
    licenseeAccount: { user },
    authenticatedAccount: { id: userLogonId } = {},
    token: { token: authToken } = {},
  } = state.authentication;
  let result;

  const userId = userLogonId || user.id;

  try {
    result = yield call(api.postCommunicationResponse, userId, authToken, filters);
  } catch (error) {
    console.error(error); // eslint-disable-line
    const {
      response: {
        data: { error: message },
      },
    } = error;
    yield put({
      type: at.SAVE_REQUEST_RESPONSE_FAILURE,
      userId,
      authToken,
      filters,
      message: message || 'There was a problem trying to submit your request. Please try again or contact support.',
    });
    return;
  }
  const { data } = result;
  yield put({
    type: at.SAVE_REQUEST_RESPONSE_SUCCESS,
    filters,
    data,
  });
  yield put({
    type: at.CLEAR_ENTITIES,
  });
  const { requestPage = 1, responsePage = 1, requestSize = 20, responseSize = 20 } = meta;
  yield put({
    type: at.FETCH_REQUEST,
    filters: { responsePage, responseSize, communicationsType: 'RESPONSE' },
  });
  yield put({
    type: at.FETCH_REQUEST,
    filters: { requestPage, requestSize, communicationsType: 'REQUEST' },
  });
}
