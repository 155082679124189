import React from 'react';
import PropTypes from 'prop-types';
import './CourseDetailDisclosureStatement.css';
import SanitizeHTML from '../../../SanitizeHTML';
class CourseDetailDisclosureStatement extends React.Component {
  render() {
    const { disclosureStatement } = this.props;
    if (!disclosureStatement) {
      return <React.Fragment />;
    }
    return (
      <div id="disclosure-statement" className="course-detail-content">
        <span className="course-detail-title">Disclosure statements</span>
        <SanitizeHTML className="disclosure-statement-content" html={disclosureStatement} />
      </div>
    );
  }
}

CourseDetailDisclosureStatement.propTypes = {
  disclosureStatement: PropTypes.string,
};

export default CourseDetailDisclosureStatement;
