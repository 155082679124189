import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Progressbar, SkeletonLoader } from 'emerald-ui';
import './CardCorner.css';

class CardCorner extends React.Component {
  render() {
    const { isLoading, isBasic, isHeartcard, cardStatus, percentage, size } = this.props;

    if ((isHeartcard && cardStatus !== 'success') || ['default', 'pending'].includes(cardStatus) || isBasic) {
      let cardIcon;
      switch (cardStatus) {
        case 'warning':
        case 'danger':
          cardIcon = 'warning';
          break;
        case 'default':
          cardIcon = 'cached';
          break;
        case 'success':
          cardIcon = 'done';
          break;
        case 'pending':
          cardIcon = 'sync';
          break;
        default:
          break;
      }

      return (
        <div className={`card-icon-container ${cardStatus} ${isHeartcard ? 'heartcard' : ''}`}>
          <Icon className={`card-icon ${cardStatus} ${isHeartcard ? 'heartcard' : ''}`} name={cardIcon} />
        </div>
      );
    }

    return isLoading ? (
      <SkeletonLoader loading width="82px" circle style={{ margin: '0' }} />
    ) : (
      <Progressbar size={size} progress={parseFloat(percentage)} circle color={cardStatus} />
    );
  }
}

CardCorner.propTypes = {
  isLoading: PropTypes.bool,
  isBasic: PropTypes.bool,
  isHeartcard: PropTypes.bool,
  cardStatus: PropTypes.string,
  size: PropTypes.string,
  percentage: PropTypes.number,
};

CardCorner.defaultProps = {
  isLoading: false,
  isBasic: false,
  isHeartcard: false,
  cardStatus: '',
  percentage: 0,
  size: 'sm',
};

export default CardCorner;
