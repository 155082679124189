import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import AuditTask from '../components/AuditTask';
import * as auditStore from '../store/audits';
import { refineQuery } from '../utils';
import * as bannerStore from '../store/banner';

function mapStateToProps(state, ownProps) {
  const { match, location } = ownProps;
  const params = refineQuery(queryString.parse(location.search));
  const auditSelectors = auditStore.selectors(state);
  const auditTasks = auditSelectors.getTask();
  const userFlagsAudit = auditSelectors.getUserFlagsAudit();
  const bannerSelectors = bannerStore.selectors(state);

  const {
    authentication: { licenseeAccount },
  } = state;
  const licenses = licenseeAccount.licenses;
  let licenseSubscriber,
    professionName = '';

  params.pkLicense = match.params.pkLicense || auditTasks.pkLicense;
  const pkLicense = parseInt(params.pkLicense, 10);

  for (const key in licenses) {
    if (licenses[key].id === pkLicense) {
      licenseSubscriber = licenses[key];
      professionName = licenseSubscriber && licenseSubscriber.profession ? licenseSubscriber.profession.name : '';
      break;
    }
  }

  return {
    auditTasks,
    userFlagsAudit,
    professionName,
    banner: bannerSelectors.getBanner(),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchFlagUser: auditStore.actions.setFlag,
  })(AuditTask)
);
