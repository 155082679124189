import { put, call, select } from 'redux-saga/effects';
import at from '../types';
import * as api from '../../../api/courseHistory';

export default function* requestDownloadPdf({ credentials }) {
  const state = yield select();
  const token = state.authentication.legacyToken.token;
  try {
    const { data } = yield call(api.requestDownloadPdf, credentials, token);
    yield put({ type: at.FETCH_REQUEST_PDF_SUCCESS, data });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({ type: at.FETCH_REQUEST_PDF_FAILURE });
  }
}
