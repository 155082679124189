import at from './types';

export const clearAuthentication = (meta) => ({
  type: at.CLEAR,
  meta,
});

export const setAuthenticationToken = (token, meta) => ({
  type: at.SET_TOKEN,
  token,
  meta,
});

export const setAuthenticationLegacyToken = (token, meta) => ({
  type: at.SET_LEGACY_TOKEN,
  token,
  meta,
});

export const setAuthenticationStatus = (status, meta) => ({
  type: at.SET_STATUS,
  status,
  meta,
});

export const setAuthenticatedAccount = (authenticatedAccount, meta) => ({
  type: at.SET_AUTHENTICATED_ACCOUNT,
  authenticatedAccount,
  meta,
});

export const setLicenseeAccount = (licenseeAccount, meta) => ({
  type: at.SET_LICENSEE_ACCOUNT,
  licenseeAccount,
  meta,
});

export const fetchLicensePeriods = (pkLicense) => ({
  type: at.FETCH_PERIODS_REQUEST,
  pkLicense,
});

export const logout = (params, meta) => ({
  type: at.LOGOUT,
  params,
  meta,
});
