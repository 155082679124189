import at from './types';

export const fetch = (params) => ({
  type: at.FETCH_REQUEST,
  params,
  cycleId: params.cycleId || 0,
});

export const fetchBoardLogo = (boardId) => ({
  type: at.FETCH_BOARD_LOGO_REQUEST,
  boardId,
});
