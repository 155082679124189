import React from 'react';
import PropTypes from 'prop-types';
import StarRatingComponent from 'react-star-rating-component';

import './StarRating.css';

const handleRenderStarIconHalf = () => (
  <span className="start-icon-half">
    <span style={{ position: 'absolute' }}>
      <i className="far fa-star" />
    </span>
    <span>
      <i className="fas fa-star-half" />
    </span>
  </span>
);

function StarRating({
  rating = 0,
  starColor = '#FFAA00',
  emptyStarColor = '#E6E8EC',
  className = '',
  ratingCount = 0,
  showRatingCount = false,
  showRating = true,
  reviewsLink = ''
}) {
  const courseRating = rating === null && isNaN(rating) ? 0 : rating.toFixed(1);
  const value = Number(courseRating);
  const ratingCountText = `(${ratingCount} ${ratingCount === 1 ? 'rating' : 'ratings'})`;
  const name = `rate-${courseRating} ${className}`.trim();
  return (
    <div id="course-rating">
      <div className="rating-wrapper">
        <StarRatingComponent
          id="star-rating-component"
          name={name}
          starCount={5}
          editing={false}
          value={value}
          starColor={starColor}
          emptyStarColor={emptyStarColor}
          className="rating-stars"
          renderStarIconHalf={handleRenderStarIconHalf}
        />
        {showRating && Boolean(value) && <span className="rate-value">{courseRating}</span>}
      </div>
      {showRatingCount && <span className="rating-count text-light">
        {reviewsLink && !!ratingCount ? <a className='text-light' href={reviewsLink}>{ratingCountText}</a> : ratingCountText}
      </span>}
    </div>
  );
}

StarRating.propTypes = {
  rating: PropTypes.number,
  starColor: PropTypes.string,
  emptyStarColor: PropTypes.string,
  className: PropTypes.string,
  ratingCount: PropTypes.number,
  showRatingCount: PropTypes.bool,
  reviewsUrl: PropTypes.string,
  showRating: PropTypes.bool
};

export default StarRating;
