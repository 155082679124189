import { put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';
import * as api from '../../../api/nowCourses';
import at from '../types';
import * as schema from '../schema';

export default function* fetch({ params }) {
  const { courseStatus, page = 1, size: limit = 10, professionIds = '' } = params;
  const [successActionType, failureActionType] =
    courseStatus === 'progress'
      ? [at.FETCH_IN_PROGRESS_SUCCESS, at.FETCH_IN_PROGRESS_FAILURE]
      : [at.FETCH_COMPLETED_SUCCESS, at.FETCH_COMPLETED_FAILURE];
  params.offset = (page - 1) * limit;
  params.limit = limit;
  params.professionIds = professionIds;
  try {
    yield delay(400);
    const { data } = yield call(api.fetch, params);
    const normalizedData = normalize(data.items, schema.nowCourseArray);
    yield put({
      type: successActionType,
      totalItems: data.size,
      next: data.next,
      previous: data.previous,
      first: data.first,
      last: data.last,
      total: data.total,
      ...params,
      ...normalizedData,
    });
  } catch (error) {
    console.error(error); //eslint-disable-line
    yield put({ type: failureActionType });
  } finally {
    //eslint-disable-line
  }
}
