import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'emerald-ui';
import CardCorner from '../../../CardCorner';

class RequestedLicenseCard extends Component {
  render() {
    const { license, subscriptionType } = this.props;
    return (
      <Card className="card-requested-license-info">
        <div className="requested-license-content">
          <div className="requested-license-info-container">
            <h1>Verifying</h1>
            {license.dtAdded && <span>Submitted on {license.dtAdded}</span>}
            <p>We&#39;ll let you know when this license/certificate is verified.</p>
          </div>
          <div className="requested-license-logo">
            <CardCorner
              isLoading={false}
              isBasic={subscriptionType === 'BAS'}
              isHeartcard={false}
              cardStatus="pending"
              size="lg"
            />
          </div>
        </div>
      </Card>
    );
  }
}

RequestedLicenseCard.propTypes = {
  license: PropTypes.object,
  subscriptionType: PropTypes.string,
};

RequestedLicenseCard.defaultProps = {
  license: {},
};

export default RequestedLicenseCard;
