export default {
  FETCH_REQUEST: 'audits/FETCH_REQUEST',
  FETCH_SUCCESS: 'audits/FETCH_SUCCESS',
  FETCH_FAILURE: 'audits/FETCH_FAILURE',
  SET_TASK: 'audits/SET_TASK',
  FETCH_REQUEST_LOADING: 'audit/FETCH_REQUEST_LOADING',
  CLEAR_ENTITIES: 'audits/CLEAR_ENTITIES',
  SET_FLAG: 'audits-flag/SET_FLAG',
  FETCH_REQUEST_FLAG: 'audits-flag/FETCH_REQUEST_FLAG',
  FETCH_SUCCESS_FLAG: 'audits-flag/FETCH_SUCCESS_FLAG',
  FETCH_FAILURE_FLAG: 'audits-flag/FETCH_FAILURE_FLAG',
  FETCH_REQUEST_LOADING_FLAG: 'audit-flag/FETCH_REQUEST_LOADING_FLAG',

  SUBMIT_REQUEST: 'audits/SUBMIT_REQUEST',
  SUBMIT_SUCCESS: 'audits/SUBMIT_SUCCESS',
  SUBMIT_FAILURE: 'audits/SUBMIT_FAILURE',
};
