export default {
  FETCH_IN_PROGRESS_REQUEST: 'nowCourses/FETCH_IN_PROGRESS_REQUEST',
  FETCH_IN_PROGRESS_SUCCESS: 'nowCourses/FETCH_IN_PROGRESS_SUCCESS',
  FETCH_IN_PROGRESS_FAILURE: 'nowCourses/FETCH_IN_PROGRESS_FAILURE',

  FETCH_COMPLETED_REQUEST: 'nowCourses/FETCH_COMPLETED_REQUEST',
  FETCH_COMPLETED_SUCCESS: 'nowCourses/FETCH_COMPLETED_SUCCESS',
  FETCH_COMPLETED_FAILURE: 'nowCourses/FETCH_COMPLETED_FAILURE',

  FETCH_IN_PROGRESS_LIST_REQUEST: 'nowCourses/FETCH_IN_PROGRESS_LIST_REQUEST',
  FETCH_IN_PROGRESS_LIST_SUCCESS: 'nowCourses/FETCH_IN_PROGRESS_LIST_SUCCESS',
  FETCH_IN_PROGRESS_LIST_FAILURE: 'nowCourses/FETCH_IN_PROGRESS_LIST_FAILURE',
};
