import React from 'react';
import PropTypes from 'prop-types';
import ShowMoreText from 'react-show-more-text';

import './CourseDetailInfoDescription.css';

function CourseDetailInfoDescription({ description }) {
  return (
    <>
      {description && (
        <div className="course-detail-content course-detail-description">
          <span className="course-detail-title">Course Overview</span>
          <ShowMoreText more="More" less="Less" className="course-detail-top" anchorClass="course-detail-dsc-more">
            <p>{description}</p>
          </ShowMoreText>
        </div>
      )}
    </>
  );
}

CourseDetailInfoDescription.propTypes = {
  description: PropTypes.string,
};

export default CourseDetailInfoDescription;
