import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import config from '../../../config';
import BasicInfoSummary from './BasicInfoSummary';
import BasicInfoDetail from './BasicInfoDetail';
import ComplianceProgress from '../../../components/ComplianceProgress';
import utils from '../../../utils';
import * as eventsApi from '../../../api/events';
import './BasicInfo.css';

const { legacyUrl } = config;

class BasicInfo extends Component {
  handleRedirectToCEReport = () => {
    utils.setCookie({
      name: 'CAME_FROM_REPORTCE',
      value: window.location.href,
      domain: 'cebroker.com',
    });
    const { transcriptBasicInfo: { licensePeriod: { license: { id = '' } = {} } = {} } = {} } = this.props;
    const { user = {} } = this.props;
    const statisticCode = 'REPORTCE';
    const type = 'TRANSCRIPT_PRINCIPAL';
    const entityId = id;
    eventsApi.saveEvent(user.id, statisticCode, type, entityId);
    window.location.href = `${legacyUrl}/licensee/lc_ce_request_lst_v2.aspx?lic=${id || 0}`;
  };

  render() {
    const {
      transcriptBasicInfo = {},
      className,
      selectedLicense = '0',
      licensePeriods,
      selectedPeriodId,
      onDownloadPdf,
      onPrintPdf,
      fetchTranscript,
      exemptions,
      currentLicense,
      isAuditTranscript,
      ...rest
    } = this.props;
    delete rest.user;

    const {
      boardLogo = '',
      licenses = [],
      licensePeriod = {},
      completionDate,
      licenseExpirationDate,
      totalHoursRequired = 0,
      totalHoursNeeded = 0,
      messageScenario = '',
      isBoardWithoutLisensingTranscript,
      isEMTorPMDProfession,
    } = transcriptBasicInfo;

    const renewalEndDate = moment(licensePeriod.renewalEndDate).utc().startOf('day');
    const todayDate = moment().utc().startOf('day');
    const daysRemaining = renewalEndDate.diff(todayDate, 'days') + 1;

    let totalHoursApplied = parseFloat((totalHoursRequired - totalHoursNeeded).toFixed(2));
    totalHoursApplied = totalHoursApplied >= 0 ? totalHoursApplied : 0;

    const { licenseLabel, licenseLabelCap } = currentLicense.profession;

    return (
      <div {...rest} className={classnames('basic-info', className)}>
        <ComplianceProgress progress={transcriptBasicInfo.completedPercentage} />
        <BasicInfoSummary
          {...{
            licenses,
            selectedLicenseId: selectedLicense,
            totalHoursApplied,
            totalHoursNeeded,
            totalHoursRequired,
            licensePeriods,
            selectedPeriodId,
            onDownloadPdf,
            onPrintPdf,
            fetchTranscript,
            currentLicense,
            isAuditTranscript,
          }}
        />
        {!isAuditTranscript && (
          <BasicInfoDetail
            {...{
              messageScenario,
              isBoardWithoutLisensingTranscript,
              licensePeriod,
              boardLogo,
              isEMTorPMDProfession,
              daysRemaining,
              completionDate,
              licenseExpirationDate,
              exemptions,
              licenseLabel,
              licenseLabelCap,
            }}
          />
        )}
      </div>
    );
  }
}

BasicInfo.defaultProps = {
  transcriptBasicInfo: {
    daysRemaining: '',
    totalHoursRequired: '',
    totalHoursPosted: '',
    totalHoursNeeded: '',
    totalHoursApplied: '',
    status: '',
    inAudit: '',
    isProcessedByAudit: '',
    isEmployer: '',
    isRecommended: '',
    specialty: '',
    completedPercentage: 0,
    completionDate: '',
    licenseExpirationDate: '',
    boardLogo: '',
    //pathLogoEmployer: null,
    licenses: [],
    licensePeriod: {
      license: {
        profession: {
          name: '',
        },
      },
    },
  },
  isAuditTranscript: false,
};

BasicInfo.propTypes = {
  transcriptBasicInfo: PropTypes.object,
  currentLicense: PropTypes.object,
  onDownloadPdf: PropTypes.func,
  onPrintPdf: PropTypes.func,
  user: PropTypes.object,
  className: PropTypes.string,
  selectedLicense: PropTypes.string,
  items: PropTypes.array,
  title: PropTypes.object,
  fetchTranscript: PropTypes.func,
  licensePeriods: PropTypes.array,
  selectedPeriodId: PropTypes.number,
  exemptions: PropTypes.array,
  isAuditTranscript: PropTypes.bool,
};

export default BasicInfo;
