import { put, call } from 'redux-saga/effects';

import * as api from '../../../api/banner';
import utils from '../../../utils';
import at from '../types';

export default function* fetch() {
  try {
    const { data } = yield call(api.getBanner);
    const bannerString = window.localStorage.getItem(utils.cebBanner);
    const banner = JSON.parse(bannerString);

    if (banner && banner.name !== data.name) {
      window.localStorage.setItem(
        utils.cebBanner,
        JSON.stringify({
          ...data,
          showBanner: true,
        })
      );
      yield put({
        type: at.FETCH_SUCCESS,
        data: { ...data, showBanner: !!banner.name },
      });
    } else if (banner) {
      yield put({ type: at.FETCH_SUCCESS, data: banner });
    } else {
      yield put({
        type: at.FETCH_SUCCESS,
        data: { ...data, showBanner: !!data.name },
      });
    }
  } catch (error) {
    yield put({ type: at.FETCH_FAILURE });
  }
}
