import { put, call, select } from 'redux-saga/effects';
import * as api from '../../../api/payments';
import notificationsAt from '../../notifications/types';
import at from '../types';

export default function* updatePaymentInfo({ creditCard, promoCode, meta = {}, address }) {
  const state = yield select();
  const user = state.authentication.licenseeAccount.user;
  const userLogonId = state.authentication.authenticatedAccount.id;
  try {
    const { data } = yield call(api.updatePaymentInfo, {
      userId: user.id,
      creditCard,
      promoCode,
      userLogonId,
      address,
    });
    yield put({
      type: at.UPDATE_PAYMENT_INFO_SUCCESS,
      userId: user.id,
      creditCard: data.creditCard,
      promoCodeResult: data.promoCodeResult,
      meta,
      address: data.address || address,
    });
    yield put({
      type: notificationsAt.ADD,
      notification: {
        timeout: 2000,
        actionText: 'Close',
        message: 'Payment info updated',
        id: Math.random(),
      },
    });
    yield put({
      type: at.FETCH_RENEW_FEE_REQUEST,
    });
  } catch (error) {
    console.error(error); //eslint-disable-line
    const { response: { data: { data: { error: { message: stripeErrorMessage = '' } = {} } = {} } = {} } = {} } = error;
    yield put({
      type: at.UPDATE_PAYMENT_INFO_FAILURE,
      userId: user.id,
      creditCard,
      promoCode,
      meta,
    });
    let errorMessage = 'Payment info failed';
    if (stripeErrorMessage) {
      errorMessage += `. ${stripeErrorMessage}`;
    }
    yield put({
      type: notificationsAt.ADD,
      notification: {
        timeout: 4000,
        message: errorMessage,
        id: Math.random(),
      },
    });
  } finally {
    //eslint-disable-line
  }
}
