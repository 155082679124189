import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ExpansionPanel from 'emerald-ui/lib/ExpansionPanel';
import InfoTranscript from '../InfoTranscript';

import { INPUT_FORMAT_DATE } from '../../../config';

class BasicInfoDetail extends React.Component {
  render() {
    const {
      messageScenario,
      isBoardWithoutLisensingTranscript,
      licensePeriod = {},
      boardLogo,
      isEMTorPMDProfession,
      daysRemaining,
      completionDate,
      licenseExpirationDate,
      exemptions = [],
      licenseLabel,
      licenseLabelCap,
    } = this.props;

    const { isEmployerScenario, scenario = {}, activityStatus, licenseStatus, license = {} } = licensePeriod;

    const { owner: { firstName = '', lastName = '', middleName = '' } = {} } = license;
    const licenseeName = `${firstName} ${middleName} ${lastName}`;

    const completionDateText = completionDate
      ? moment(completionDate, INPUT_FORMAT_DATE).format('L')
      : `Your deadline is your ${licenseLabel} renewal date.`;

    const formatedlicenseExpDate =
      licenseExpirationDate && licenseExpirationDate !== '0001-01-01T00:00:00'
        ? moment(licenseExpirationDate, INPUT_FORMAT_DATE).format('L')
        : '';

    return (
      <div>
        {(isBoardWithoutLisensingTranscript || messageScenario !== '') && (
          <div className="basic-info-scenario-message eui-panel">
            <InfoTranscript message={messageScenario} />
          </div>
        )}
        <ExpansionPanel className="basic-info-detail expand-up" reversed growHorizontally={false} noCaret={true}>
          <ExpansionPanel.Content>
            <div className="basic-info-container">
              <div className="basic-info-item" style={{ flexGrow: '2', flexDirection: 'column' }}>
                <div className="basic-info-row">
                  <span className="basic-info-col title text-color">Name</span>
                  <span className="basic-info-col transcript-licensee-name">{licenseeName.toLowerCase()}</span>
                </div>

                {/* Conditional row */}
                {(activityStatus || licenseStatus) && (
                  <div className="basic-info-row">
                    <span className="basic-info-col title text-color">Status</span>
                    <span className="basic-info-col transcript-status">
                      {activityStatus.toLowerCase()}
                      {licenseStatus ? `/${licenseStatus.toLowerCase()}` : ''}
                    </span>
                  </div>
                )}

                {/* Conditional row */}
                {!isEMTorPMDProfession && (
                  <div className="basic-info-row">
                    <span className="basic-info-col title text-color">Days Remaining</span>
                    <span className="basic-info-col transcript-dayRemaining">
                      {daysRemaining < 0 ? 0 : daysRemaining}
                    </span>
                  </div>
                )}

                <div className="basic-info-row">
                  <span className="basic-info-col title text-color">Completion Date</span>
                  <span className="basic-info-col transcript-complationDate">{completionDateText}</span>
                </div>

                {/* Conditional row */}
                {formatedlicenseExpDate && (
                  <div className="basic-info-row basic-info-renewal-end">
                    <span className="basic-info-col title text-color">{licenseLabelCap} Expiration Date</span>
                    <span className="basic-info-col">{formatedlicenseExpDate}</span>
                  </div>
                )}

                {/* Conditional row */}
                {scenario.description && (
                  <div className="basic-info-row basic-info-scenario">
                    <span className="basic-info-col title text-color">Scenario</span>
                    <span className="basic-info-col">
                      {exemptions.length === 0
                        ? scenario.description
                        : exemptions.map((e) => {
                            return (
                              <span key={e.id}>
                                {e.name}
                                <br />
                              </span>
                            );
                          })}
                    </span>
                  </div>
                )}
              </div>

              {isEmployerScenario && (
                <div className="basic-info-item employer-logo" style={{ textAlign: 'right' }}>
                  <img
                    alt="employer logo"
                    style={{ display: 'inline-block' }}
                    src={boardLogo}
                    className="img-responsive basic-info-employer-logo"
                  />
                </div>
              )}
            </div>
          </ExpansionPanel.Content>
          <ExpansionPanel.Summary>
            <p className="toggle-content">
              <span className="toggle-text">See more info</span>
              <span className="material-icons more-info-arrow">keyboard_arrow_down</span>
            </p>
          </ExpansionPanel.Summary>
          <ExpansionPanel.AlternativeSummary>
            <p className="toggle-content">
              <span className="toggle-text">Hide details</span>
              <span className="material-icons">keyboard_arrow_up</span>
            </p>
          </ExpansionPanel.AlternativeSummary>
        </ExpansionPanel>
      </div>
    );
  }
}

BasicInfoDetail.propTypes = {
  messageScenario: PropTypes.string,
  isBoardWithoutLisensingTranscript: PropTypes.bool,
  licensePeriod: PropTypes.object,
  boardLogo: PropTypes.string,
  isEMTorPMDProfession: PropTypes.bool,
  daysRemaining: PropTypes.number,
  completionDate: PropTypes.string,
  licenseExpirationDate: PropTypes.string,
  exemptions: PropTypes.array,
  licenseLabel: PropTypes.string,
  licenseLabelCap: PropTypes.string,
};

export default BasicInfoDetail;
