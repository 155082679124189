import { put, call } from 'redux-saga/effects';
import at from '../types';
import * as api from '../../../api/courseHistory';

export default function* browserDownload({ urlDocument }) {
  try {
    const data = yield call(api.browserDownload, urlDocument);
    yield put({ type: at.DOWNLOAD_PDF_BROWSER_SUCCESS, data });
  } catch (error) {
    console.log(error); //eslint-disable-line
    yield put({ type: at.DOWNLOAD_PDF_BROWSER_FAILURE });
  }
}
