import { put, call, select } from 'redux-saga/effects';

import * as api from '../../../api/courseHistory';
import { types as transcriptTypes } from '../../transcript';
import at from '../types';

export default function* deleteCourse({ reduxId, selfSubmitId, filters }) {
  const state = yield select();
  const token = state.authentication.legacyToken.token;
  const userId = state.authentication.authenticatedAccount.id;
  const ownerId = state.authentication.licenseeAccount.user.ownerId;

  const { pkLicense, cycle } = filters;
  try {
    yield call(api.deleteCourse, { selfSubmitId, userId, ownerId, token });
    yield put({ type: at.DELETE_COURSE_SUCCESS, reduxId, page: filters.page });
    yield put({
      type: transcriptTypes.FETCH_REQUEST,
      params: { pkLicense, cycle },
      cycleId: cycle || 0,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({ type: at.DELETE_COURSE_FAILURE, reduxId });
  }
}
