export default {
  FETCH_REQUEST: 'users/FETCH_REQUEST',
  FETCH_SUCCESS: 'users/FETCH_SUCCESS',
  FETCH_FAILURE: 'users/FETCH_FAILURE',
  UPDATE_REQUEST: 'users/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'users/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'users/UPDATE_FAILURE',
  UPDATE_USERNAME_REQUEST: 'users/UPDATE_USERNAME_REQUEST',
  UPDATE_USERNAME_SUCCESS: 'users/UPDATE_USERNAME_SUCCESS',
  UPDATE_USERNAME_FAILURE: 'users/UPDATE_USERNAME_FAILURE',
  UPDATE_PASSWORD_REQUEST: 'users/UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS: 'users/UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'users/UPDATE_PASSWORD_FAILURE',
  RESET_UPDATE_STATUS: 'users/RESET_UPDATE_STATUS',
  RESET_UPDATE_EMAIL_SETTINGS_STATUS: 'users/RESET_UPDATE_EMAIL_SETTINGS_STATUS',
  FETCH_EMAIL_SETTINGS_REQUEST: 'users/FETCH_EMAIL_SETTINGS_REQUEST',
  FETCH_EMAIL_SETTINGS_SUCCESS: 'users/FETCH_EMAIL_SETTINGS_SUCCESS',
  FETCH_EMAIL_SETTINGS_FAILURE: 'users/FETCH_EMAIL_SETTINGS_FAILURE',
  UPDATE_EMAIL_SETTINGS_REQUEST: 'users/UPDATE_EMAIL_SETTINGS_REQUEST',
  UPDATE_EMAIL_SETTINGS_SUCCESS: 'users/UPDATE_EMAIL_SETTINGS_SUCCESS',
  UPDATE_EMAIL_SETTINGS_FAILURE: 'users/UPDATE_EMAIL_SETTINGS_FAILURE',
  FETCH_CONCIERGE_MANAGER_REQUEST: 'users/FETCH_CONCIERGE_MANAGER_REQUEST',
  FETCH_CONCIERGE_MANAGER_SUCCESS: 'users/FETCH_CONCIERGE_MANAGER_SUCCESS',
  FETCH_CONCIERGE_MANAGER_FAILURE: 'users/FETCH_CONCIERGE_MANAGER_FAILURE',
  FETCH_COMMUNICATION_SETTINGS_REQUEST: 'users/FETCH_COMMUNICATION_SETTINGS_REQUEST',
  FETCH_COMMUNICATION_SETTINGS_SUCCESS: 'users/FETCH_COMMUNICATION_SETTINGS_SUCCESS',
  FETCH_COMMUNICATION_SETTINGS_FAILURE: 'users/FETCH_COMMUNICATION_SETTINGS_FAILURE',
  UPDATE_COMMUNICATION_SETTINGS_REQUEST: 'users/UPDATE_COMMUNICATION_SETTINGS_REQUEST',
  UPDATE_COMMUNICATION_SETTINGS_SUCCESS: 'users/UPDATE_COMMUNICATION_SETTINGS_SUCCESS',
  UPDATE_COMMUNICATION_SETTINGS_FAILURE: 'users/UPDATE_COMMUNICATION_SETTINGS_FAILURE',
  FETCH_USER_FLAG_REQUEST: 'users/FETCH_USER_FLAG_REQUEST',
  FETCH_USER_FLAG_SUCCESS: 'users/FETCH_USER_FLAG_SUCCESS',
  FETCH_USER_FLAG_FAILURE: 'users/FETCH_USER_FLAG_FAILURE',
};
