import { put, call, select } from 'redux-saga/effects';
import * as api from '../../../api/inbox';
import at from '../types';

export default function* archive({ inboxIds, page, callback }) {
  try {
    const state = yield select();
    const userLogonId = state.authentication.authenticatedAccount.id;

    yield call(api.archive, userLogonId, inboxIds);

    yield put({
      type: at.ARCHIVE_SUCCESS,
      userLogonId,
      inboxIds,
      page,
    });
    callback();
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put({ type: at.ARCHIVE_FAILURE, inboxIds });
  }
}
