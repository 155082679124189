import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'emerald-ui/lib/Button';
import IconButton from 'emerald-ui/lib/IconButton';
import TextTruncate from 'react-text-truncate';
import Responsive from '../Responsive';
import utils from '../../utils';
import isEmpty from 'lodash/isEmpty';
import ReactGA from 'react-ga';
import './Ad.css';

class Ad extends Component {
  constructor(props) {
    super(props);
    const isAdHidden = utils.getSessionItem('isAdHidden') === 'true';
    this.state = {
      isAdHidden,
    };
  }

  handleClick = () => {
    const { onClick, ad = {} } = this.props;
    onClick();
    // Send an event to google analytics
    ReactGA.event({
      category: 'lic_beta_detail_ad',
      action: 'click',
      label: `providerId=${ad.providerId},courseId=${ad.courseId}`,
    });
  };

  handleHideFeatured = () => {
    utils.setSessionItem('isAdHidden', 'true');
    this.setState({
      isAdHidden: true,
    });
  };

  render() {
    const { ad, adStatus } = this.props;
    const { imageUrl, title, tagline, description } = ad;

    if (this.state.isAdHidden) {
      return <div />;
    }

    if ((adStatus === 'loaded' && isEmpty(ad)) || adStatus === 'failed') {
      return <div className="featured-section-empty" />;
    }

    if (adStatus === 'loading') {
      return <div id="featured-section-blank" />;
    }

    return (
      <div id="featured-section">
        <Responsive size="tabletPortraitUp">
          <img src={imageUrl} alt={title} />
          <section className="featured-section-content">
            <h3>{tagline}</h3>
            <h2>{title}</h2>
            <TextTruncate line={3} element="span" truncateText="..." text={description} />
          </section>
          <Button className="featured-call-to-action" color="success" size="small" onClick={this.handleClick}>
            View course
          </Button>
          <IconButton
            className="close-feature-section"
            icon="close"
            title="Hide featured section"
            onClick={this.handleHideFeatured}
          />
        </Responsive>
        <Responsive size="phoneOnly">
          <IconButton
            className="close-feature-section"
            icon="close"
            title="Hide featured section"
            onClick={this.handleHideFeatured}
          />
          <div className="mobile-content">
            <img src={imageUrl} alt={title} />
            <section className="featured-section-content">
              <h3>{tagline}</h3>
              <h2>{title}</h2>
            </section>
          </div>
          <Button className="featured-call-to-action" color="success" size="small" onClick={this.handleClick}>
            View course
          </Button>
        </Responsive>
      </div>
    );
  }
}

Ad.propTypes = {
  ad: PropTypes.object,
  onClick: PropTypes.func,
  adStatus: PropTypes.string,
};

export default Ad;
