import at from './types';

export const cancelTrial = (meta) => ({
  type: at.CANCEL_TRIAL,
  meta,
});

export const getSubscriptionPrices = (meta) => ({
  type: at.FETCH_SUBSCRIPTION_PRICES_REQUEST,
  meta,
});

export const getSubscriptionPlans = (meta) => ({
  type: at.FETCH_SUBSCRIPTION_PLANS_REQUEST,
  meta,
});
