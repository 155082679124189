import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Button, Alert, Container, Row, Col } from 'emerald-ui';
import { DatePicker, FileUpload } from 'emerald-ui-3';
import HeartcardUpgradeAd from '../HeartcardUpgradeAd';
import moment from 'moment';
import config from '../../config';
import './AddHeartcard.css';

const { storageUrl } = config;

class AddHeartcard extends React.Component {
  constructor(props) {
    super(props);
    const { isEditing, certificationNameEdit, expirationDateEdit } = this.props;
    const loadExpirationDate = isEditing && moment(expirationDateEdit).isValid();
    this.state = {
      showAddHeartcardError: false,
      addLicenseStatus: null,
      certificationName: isEditing ? certificationNameEdit : '',
      expirationDate: loadExpirationDate ? new Date(expirationDateEdit) : null,
      isValidExpirationDate: true,
      files: [],
      currentAddHeartcardStatus: '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { addHeartcardStatus, onHideAddRecord, fetchHeartCards } = nextProps;

    if (this.state.currentAddHeartcardStatus !== addHeartcardStatus) {
      this.setState({
        currentAddHeartcardStatus: addHeartcardStatus,
        showAddHeartcardError: addHeartcardStatus === 'failed',
      });

      if (addHeartcardStatus === 'added') {
        onHideAddRecord();
        fetchHeartCards();
      }
    }
  }

  handleCloseAddHeartcardError = () => {
    this.setState({
      showAddHeartcardError: false,
    });
  };

  onChangeCertificationName = (e) => {
    const value = e.target.value;
    this.setState({
      certificationName: value,
    });
  };

  onChangeExpirationDate = (value) => {
    const isValid = moment(value).isValid();
    this.setState({
      expirationDate: value,
      isValidExpirationDate: isValid,
    });
  };

  validateForm = () => {
    const { certificationName, expirationDate, files } = this.state;
    const isValidExpirationDate = moment(expirationDate).isValid();
    let isUploadingFiles = false;

    if (files) {
      for (const file of files) {
        isUploadingFiles = typeof file.key === 'undefined';
      }
    }

    return certificationName && isValidExpirationDate && files && !isUploadingFiles;
  };

  handleAddClick = () => {
    const { addHeartcard, user, authenticatedUser, loggedOnBehalf, heartcardId, isEditing } = this.props;
    const { certificationName, expirationDate, files } = this.state;

    if (this.validateForm()) {
      const userOnBehalfId = loggedOnBehalf ? authenticatedUser.id : 0;
      const heartCard = { name: certificationName, expirationDate, files };
      const loggedUserInfo = { userId: user.id, userOnBehalfId };

      if (isEditing) {
        heartCard.id = heartcardId;
      }

      addHeartcard(heartCard, loggedUserInfo, isEditing);
    }
  };

  onUploadStart = (files) => {
    const { files: oldFiles } = this.state;
    const allFiles = [...oldFiles, ...files];
    this.setState({
      files: allFiles,
    });
  };

  onUploadSuccess = (event, id) => {
    const { files } = this.state;
    const newFiles = files.map((f) => {
      return f.id === id ? { ...f, key: event.currentTarget.response } : f;
    });
    this.setState({
      files: newFiles,
    });
  };

  onProgress = (file) => {
    this.setState((prevState) => {
      const newFiles = prevState.files.map((prevFile) => {
        if (prevFile.id === file.id) {
          prevFile.progress = file.progress;
          prevFile.speed = file.speed;
        }
        return prevFile;
      });
      return { files: newFiles };
    });
  };

  onCancel = (fileName, id, aborted) => {
    if (!aborted) {
      console.log('This will be removed from the server'); //eslint-disable-line
    }
    this.setState((prevState) => {
      const newFiles = [];
      prevState.files.forEach((prevFile) => {
        if (prevFile.id !== id) {
          newFiles.push(prevFile);
        }
      });
      return { files: newFiles };
    });
  };

  render() {
    const { certificationName, expirationDate, files, isValidExpirationDate, showAddHeartcardError } = this.state;

    const { subscriptionType, isftoAvailable, user, isEditing, handleCancelEditing, addHeartcardStatus } = this.props;
    const validForm = this.validateForm();
    const isBasic = subscriptionType === 'BAS';
    const errorMessage = showAddHeartcardError
      ? `There was an error trying to ${!isEditing ? 'add' : 'edit'} your heartcard, please try again later.`
      : '';
    const mappedFile = files.map((f) => {
      return { ...f, cancelIcon: 'delete' };
    });
    return (
      <div>
        {isBasic && <HeartcardUpgradeAd isftoAvailable={isftoAvailable} user={user} />}
        {!isBasic && (
          <div className={`${isEditing ? 'editing-content' : ''} add-record-content`}>
            <div className="add-heartcard-content">
              <div className="add-record-group text-field-group">
                <TextField
                  label="Enter the name of your certification"
                  value={certificationName}
                  className="add-record-input"
                  onChange={this.onChangeCertificationName}
                />
              </div>
              <div className="add-record-group text-field-group">
                <DatePicker
                  label="Expiration date"
                  className="add-record-input"
                  onChange={this.onChangeExpirationDate}
                  selected={expirationDate}
                  dateFormat="MM/dd/yyyy"
                  errorMessage={!isValidExpirationDate ? 'Invalid date' : ''}
                  onKeyDown={this.onKeyDownExpirationDate}
                />
              </div>
              {!isEditing && (
                <div className="add-record-group text-field-group">
                  <p className="text-color">Attach card (optional)</p>
                  <FileUpload
                    onUploadSuccess={this.onUploadSuccess}
                    onUploadStart={this.onUploadStart}
                    onProgress={this.onProgress}
                    onCancel={this.onCancel}
                    files={mappedFile}
                    url={`${storageUrl}/cebroker`}
                    onDragEnter={this.onDrop}
                  ></FileUpload>
                </div>
              )}
              {showAddHeartcardError && (
                <Alert dismissible onDismiss={this.handleCloseAddHeartcardError} color="danger">
                  {errorMessage}
                </Alert>
              )}
              <Container>
                <Row center>
                  <Col className="add-heartcard-button-toolbar">
                    {isEditing && (
                      <Button
                        className="cancel-button"
                        onClick={handleCancelEditing}
                        data-testid="heartcard-modal-cancel-button"
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      color="info"
                      onClick={this.handleAddClick}
                      disabled={!validForm || addHeartcardStatus === 'adding'}
                      loading={addHeartcardStatus === 'adding'}
                      data-testid="heartcard-modal-save-button"
                    >
                      {!isEditing ? 'Add heartcard' : 'Save'}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        )}
      </div>
    );
  }
}

AddHeartcard.propTypes = {
  heartcardId: PropTypes.number,
  addHeartcardStatus: PropTypes.string,
  certificationNameEdit: PropTypes.string,
  expirationDateEdit: PropTypes.string,
  subscriptionType: PropTypes.string,
  user: PropTypes.object,
  authenticatedUser: PropTypes.object,
  isftoAvailable: PropTypes.bool,
  showModal: PropTypes.bool,
  isEditing: PropTypes.bool,
  loggedOnBehalf: PropTypes.bool,
  handleCancelEditing: PropTypes.func,
  onClickBack: PropTypes.func,
  onHideAddRecord: PropTypes.func,
  fetchCards: PropTypes.func,
  addHeartcard: PropTypes.func,
  fetchHeartCards: PropTypes.func,
};

AddHeartcard.defaultProps = {
  heartcardId: 0,
  addHeartcardStatus: '',
  certificationNameEdit: '',
  expirationDateEdit: '',
  subscriptionType: 'BAS',
  user: {},
  authenticatedUser: {},
  isftoAvailable: false,
  showModal: false,
  isEditing: false,
  loggedOnBehalf: false,
  handleCancelEditing: () => {},
  onClickBack: () => {},
  onHideAddRecord: () => {},
  fetchCards: () => {},
  addHeartcard: () => {},
  fetchHeartCards: () => {},
};

export default AddHeartcard;
