import { connect } from 'react-redux';
import App from '../components/App';
import get from 'lodash/get';

import * as authenticationStore from '../store/authentication';
import * as inboxNotificationsStore from '../store/inboxNotifications';
import * as notificationsStore from '../store/notifications';
import * as bannerStore from '../store/banner';
import * as discoveryStore from '../store/discoveryTab';
import * as auditStore from '../store/audits';
import * as subscriptionsStore from '../store/subscriptions';
import * as cartStore from '../store/cart';
import * as nowCoursesStore from '../store/nowCourses';

import * as utils from '../utils';
import config from '../config';
import * as usersStore from '../store/users';

const { secureCookie } = config;

function mapStateToProps(state) {
  const authenticationSelectors = authenticationStore.selectors(state);
  const inboxNotificationsSelectors = inboxNotificationsStore.selectors(state);
  const notificationSelectors = notificationsStore.selectors(state);
  const discoverySelectors = discoveryStore.selectors(state);
  const cartSelectors = cartStore.selectors(state);
  const authenticationStatus = authenticationSelectors.getAuthenticationStatus();
  const token = authenticationSelectors.getAuthenticationLegacyToken().token;
  const licenseeAccount = authenticationSelectors.getLicenseeAccount();
  const authenticatedAccount = authenticationSelectors.getAuthenticatedAccount();
  const subscription = authenticationSelectors.getLicenseeSubscription();
  const licenseeSubscriptionType = subscription.type || '';
  const bannerSelectors = bannerStore.selectors(state);
  const auditSelectors = auditStore.selectors(state);
  const auditTasks = auditSelectors.getTask();
  const usersSelector = usersStore.selectors(state);
  const seeCheckoutPage = usersSelector.getUserFlag('seeCheckoutPage');

  const selectedLicenseDiscovery = discoverySelectors.getLicenseSelected();
  const { selectedLicenseId } = selectedLicenseDiscovery;

  const {
    user = {},
    subscriptions = [],
    licenses = [],
    isDohUser = '',
    autoRenewSettings,
    lastSubscription,
    associationUsersInfo = {},
    isPaidByBoard,
    isWyoming,
  } = licenseeAccount;

  let subscriptionType = '';
  let subscriptionTypeLabel = '';
  let isFTOAvailable = false;
  let firstProfession = {};
  let defaultLicenseSelected = null;
  let accountAddOns = {};
  let subscriptionCode = '';

  if (subscriptions.length > 0) {
    /* DISCLAIMER: We use the `type` field which returns the standard original subscription types: BAS, FTO, PRO, CON, EMP.
       Please be careful if you decide to update this and use the `code` field, since this support the original plans mentioned above plus the PRO_PLUS and CON_PLUS
       and it could impact components using the `subscriptionType` prop if it is not expecting the new plans. */
    subscriptionType = subscriptions[0].type;
    isFTOAvailable = subscriptions[0].isftoAvailable;
    subscriptionTypeLabel = subscriptions[0].label;
    accountAddOns = { ...subscriptions[0].addOns };
    subscriptionCode = subscriptions[0].code;
  }

  const showBrowseCourseLink = licenses.some((l) => {
    return l.profession.isAvailableInCourseSearch === true;
  });

  if (showBrowseCourseLink) {
    firstProfession = licenses.find((l) => l.profession.isAvailableInCourseSearch === true).profession;
  }

  const licenseWithBrandColor = licenses.find((l) => l.profession.color);
  const { id: defaultLicenseId, currentCycleId } = licenses[0] ? licenses[0] : {};
  const professionBrandColor = licenseWithBrandColor ? licenseWithBrandColor.profession.color : '';
  const loggedOnBehalf = authenticatedAccount.role === 'helpdesk';
  const secureToken = utils.getCookie(secureCookie);

  const notification = notificationSelectors.getSingleNotification();

  defaultLicenseSelected = selectedLicenseId || defaultLicenseId;

  const discoverPermissions = authenticationSelectors.getDiscoverPermissions();
  const canSeeDiscover = utils.evaluateDiscoverTabVisibility({ discoverPermissions, accountAddOns });

  const saveCartItemStatus = cartSelectors.saveCartItemStatus();

  const pendoLicenses = licenses.map((l) => ({
    licenseNumber: l.number,
    professionId: l.profession.id,
    professionName: `${l.profession.name} ${get(l, 'profession.board.state.code')}`,
    stateCode: get(l, 'profession.board.state.code'),
  }));

  const totalItemsInCart = cartSelectors.getCartTotalItems();

  const userProfessions = authenticationSelectors.getLicenseeProfessionsIds(licenses).join();

  return {
    authenticationStatus,
    user,
    authenticatedAccount,
    isDohUser,
    subscriptionType,
    isFTOAvailable,
    pendoLicenses,
    loggedOnBehalf,
    token,
    firstProfession,
    secureToken,
    showBrowseCourseLink,
    totalNotifications: inboxNotificationsSelectors.getTotalCount(),
    autoRenewSettings,
    lastSubscription,
    associationUsersInfo,
    isPaidByBoard,
    isWyoming,
    professionBrandColor,
    defaultLicenseSelected,
    currentCycleId,
    notification,
    banner: bannerSelectors.getBanner(),
    auditTasks,
    canSeeDiscover,
    seeCheckoutPage,
    saveCartItemStatus,
    licenseeSubscriptionType,
    totalItemsInCart,
    subscriptionTypeLabel,
    subscriptionCode,
    userProfessions,
  };
}

export default connect(mapStateToProps, {
  logout: authenticationStore.actions.logout,
  fetchBanner: bannerStore.actions.fetch,
  changeVisibilyBanner: bannerStore.actions.changeVisibily,
  fetchUserFlag: usersStore.actions.fetchUserFlag,
  getSubscriptionPrices: subscriptionsStore.actions.getSubscriptionPrices,
  getSubscriptionPlans: subscriptionsStore.actions.getSubscriptionPlans,
  fetchCart: cartStore.actions.fetchCart,
  addNotification: notificationsStore.actions.add,
  fetchNowCourses: nowCoursesStore.actions.fetchInProgressCourses,
  clearStatusCart: cartStore.actions.clearStatusCart,
})(App);
