import { put, call, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import * as api from '../../../api/dashboardCards';
import at from '../types';
import * as schema from '../schema';

export default function* fetch({ section, meta = {} }) {
  const state = yield select();
  const user = state.authentication.licenseeAccount.user;
  try {
    const { data } = yield call(api.fetchHeartCards, user.id);
    const normalizedData = normalize(data, schema.dashboardCardsArray);
    yield put({
      type: at.FETCH_SUCCESS,
      section,
      userId: user.id,
      ...normalizedData,
      meta,
    });
  } catch (error) {
    console.error(error); //eslint-disable-line
    yield put({ type: at.FETCH_FAILURE, userId: user.id, section, meta });
  } finally {
    //eslint-disable-line
  }
}
