import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './Banner.css';
import utils from '../../utils';

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      expanded: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleBannerClick = this.handleBannerClick.bind(this);
    this.setBannerLocalStorage = this.setBannerLocalStorage.bind(this);
    this.getBannerLocalStorage = this.getBannerLocalStorage.bind(this);
  }

  componentDidMount() {
    const bannerString = this.getBannerLocalStorage();
    const banner = JSON.parse(bannerString);
    if (banner) {
      this.setState({ banner, show: banner.showBanner });
    }
    const { fetchBanner } = this.props;
    fetchBanner();
  }

  setBannerLocalStorage(banner) {
    window.localStorage.setItem(utils.cebBanner, JSON.stringify(banner));
  }

  getBannerLocalStorage() {
    return window.localStorage.getItem(utils.cebBanner);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ show: nextProps.show });
  }

  handleClick() {
    const { onDismiss } = this.props;

    if (onDismiss) {
      onDismiss();
    }
  }

  handleBannerClick(event) {
    if (event.target.tagName !== 'A') {
      this.setState((prevState) => ({ expanded: !prevState.expanded }));
    }
  }

  render() {
    const { className, children, theme, ...rest } = this.props;

    delete rest.show;
    delete rest.fetchBanner;
    delete rest.onDismiss;

    const { show, expanded } = this.state;

    return (
      <div
        {...rest}
        className={classnames('banner', className, theme, {
          in: show,
          out: !show,
          expanded,
        })}
        onTouchStart={this.handleBannerClick}
      >
        <div className={classnames('eui-container', className)}>
          <i className="material-icons chevron-down">keyboard_arrow_down</i>
          <div className="content">{children}</div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <i className="eui-icon material-icons eui-icon-button" onClick={this.handleClick}>
            cancel
          </i>
        </div>
      </div>
    );
  }
}

Banner.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  show: PropTypes.bool,
  theme: PropTypes.oneOf(['banner-primary', 'banner-warning', 'banner-danger', 'banner-info', 'banner-success']),
  onDismiss: PropTypes.func,
  fetchBanner: PropTypes.func,
};

Banner.defaultProps = {
  theme: 'banner-primary',
};

export default Banner;
