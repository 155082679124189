import axios from 'axios';
import config from '../config';

const { licenseesApiInternal } = config;

export const saveEvent = (userId, statisticCode, type, entityId = '') => {
  return axios.request({
    method: 'post',
    url: `${licenseesApiInternal}/events`,
    data: {
      statisticCode,
      userId,
      type,
      entityId,
    },
    headers: {
      // Authorization: `Bearer ${authToken}`
    },
  });
};
