import React from 'react';
import PropTypes from 'prop-types';

import { Icon, SkeletonLoader } from 'emerald-ui';

import SelectedAuditModal from './SelectedAuditModal';

import './AuditTask.css';

class AuditTask extends React.Component {
  state = {
    showModal: false,
  };

  componentDidUpdate(prevProps) {
    const { auditTasks, userFlagsAudit } = this.props;
    if (prevProps.userFlagsAudit.statusRequest !== userFlagsAudit.statusRequest) {
      if (userFlagsAudit.statusRequest === 'loaded' && userFlagsAudit.flagUserAuditTask) {
        const { pkLicense, id: idLicensePeriod } = this.mapData(auditTasks);
        this.goAndClose(pkLicense, idLicensePeriod);
      }
    }
  }

  goToTranscriptSimplified = (pkLicense, idLicensePeriod, cancelStep = false) => {
    const { history } = this.props;

    if (cancelStep) {
      return;
    }

    history.push(`/audit-access/${pkLicense}?cycle=${idLicensePeriod}`);
  };

  mapData = ({ pkLicense, periods = [], existMultiAudits, statusRequest, firstShow }) => {
    if (!existMultiAudits) {
      const [period] = periods;
      return { pkLicense, statusRequest, firstShow, ...period };
    }
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  goAndClose = (...params) => {
    this.closeModal();
    this.goToTranscriptSimplified(...params);
  };

  goToCommunicationsPage = () => {
    const { history } = this.props;
    history.push('/inbox/communications?requestPage=1&requestSize=20&responsePage=1&responseSize=20');
  };

  pushAuditStep = (pkLicense, idLicensePeriod, isSubmitted = false) => {
    return (e) => {
      e.stopPropagation();

      const {
        userFlagsAudit,
        auditTasks: { statusRequest, periods },
      } = this.props;

      if (['loading', 'failed'].includes(statusRequest)) {
        return;
      }

      if (!userFlagsAudit.flagUserAuditTask && !isSubmitted) {
        this.openModal();
        return;
      }
      if (periods.length > 0) {
        const cycleAudited = periods[0];
        const { isInfoRequested = false } = cycleAudited;

        if (isInfoRequested) {
          this.goToCommunicationsPage();
          return;
        }
      }

      this.goAndClose(pkLicense, idLicensePeriod, isSubmitted);
    };
  };

  handleClickFlagAudit = (pkLicense, idLicensePeriod) => {
    const { fetchFlagUser } = this.props;
    return () => {
      fetchFlagUser(`audit_${pkLicense}_${idLicensePeriod}`);
    };
  };

  skeletonRenderText = (data = null) => {
    return data || <SkeletonLoader loading width="60px" />;
  };

  render() {
    const { showModal } = this.state;
    const { auditTasks, userFlagsAudit, professionName, banner = {} } = this.props;
    const { showBanner } = banner;

    const auditTaskSelected = this.mapData(auditTasks);

    const {
      statusRequest,
      firstShow,
      isSubmitted,
      isInfoRequested,
      deadlineDate,
      renewalEndDate,
      pkLicense,
      id: idLicensePeriod,
      licenseNumber,
    } = auditTaskSelected;

    const shouldHideBanner = ['loading', 'failed'].includes(statusRequest);

    return (
      <React.Fragment>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className={`audit-task ${shouldHideBanner && !firstShow ? 'none' : isSubmitted ? 'warning' : 'danger'} ${
            showBanner && 'audit-task-margin'
          }
          `}
          onClick={this.pushAuditStep(pkLicense, idLicensePeriod, isSubmitted)}
        >
          <div className="at-navbar">
            <div className="at-container">
              <div className="content">
                {isSubmitted ? (
                  <React.Fragment>
                    <span>
                      <strong>Change or review your audit submission</strong> Your Audit submission is currently under
                      review for {licenseNumber} for cycle ending {renewalEndDate}. You will be notified when the review
                      process is complete.
                    </span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {isInfoRequested ? (
                      <span>
                        <strong>Review and resubmit your audit </strong> The auditor has given you feedback about your
                        transcript. Please review your message box, make any necessary corrections, and submit your
                        audit again.
                      </span>
                    ) : (
                      <span className="at-center-s">
                        <strong className="r-margin">Complete your audit</strong>
                        {'   '}
                        Complete your audit of license {this.skeletonRenderText(licenseNumber)} for cycle ending on{' '}
                        {this.skeletonRenderText(renewalEndDate)} by {this.skeletonRenderText(deadlineDate)}{' '}
                      </span>
                    )}
                  </React.Fragment>
                )}
              </div>

              {!shouldHideBanner && (
                <div className="action">
                  <button
                    onClick={this.pushAuditStep(pkLicense, idLicensePeriod)}
                    className={isSubmitted ? 'w-shading' : ''}
                  >
                    {isSubmitted ? 'Review Submission' : <Icon name="arrow_forward_ios" style={{ fontSize: '15px' }} />}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <SelectedAuditModal
          showModal={showModal}
          closeModal={this.closeModal}
          userFlagsAudit={userFlagsAudit}
          auditTask={auditTaskSelected}
          professionName={professionName}
          onSubmit={this.handleClickFlagAudit(pkLicense, idLicensePeriod)}
        />
      </React.Fragment>
    );
  }
}

AuditTask.propTypes = {
  auditTasks: PropTypes.object,
  userFlagsAudit: PropTypes.object,
  fetchFlagUser: PropTypes.func,
  history: PropTypes.object,
  professionName: PropTypes.string,
  banner: PropTypes.object,
};

export default AuditTask;
