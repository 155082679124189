import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'emerald-ui';
import { licenseesApiInternal } from '../../config';
import CourseHistoryAttachmentUploader from '../../containers/CourseHistoryAttachmentUploader';

const AttachmentsModal = ({
  show,
  selectedCourseReduxId,
  attachmentDocumentNumber,
  attachmentCode,
  userId,
  attachments,
  token,
  onHide,
  onDelete,
  onUploadSuccess,
  getDeleteAttachmentStatus,
}) => {
  const files = attachments
    ? attachments.map((a) => {
        const isDeletingAttachment = getDeleteAttachmentStatus(a.token) === 'deleting';
        return {
          name: a.fileName,
          id: a.token,
          cancelIcon: isDeletingAttachment ? 'hourglass_empty' : 'delete',
        };
      })
    : [];
  return (
    <Modal show={show} onHide={onHide} hideOnOutsideClick={true}>
      <Modal.Header closeButton>
        <Modal.Title>Add attachment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CourseHistoryAttachmentUploader
          url={`${licenseesApiInternal}/attachments?documentNumber=${attachmentDocumentNumber}&documentType=${attachmentCode}&userId=${userId}`}
          headers={{
            Authorization: `bearer ${token}`,
          }}
          files={files}
          onDelete={(attachmentId) => {
            onDelete(selectedCourseReduxId, attachmentId);
          }}
          onUploadSuccess={onUploadSuccess}
          showAttachmentsModal={show}
        />
      </Modal.Body>
    </Modal>
  );
};

AttachmentsModal.propTypes = {
  show: PropTypes.bool,
  selectedCourseReduxId: PropTypes.string,
  attachmentDocumentNumber: PropTypes.number,
  attachmentCode: PropTypes.string,
  userId: PropTypes.number,
  attachments: PropTypes.array,
  token: PropTypes.string,
  onHide: PropTypes.func,
  onDelete: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  getDeleteAttachmentStatus: PropTypes.func,
};

export default AttachmentsModal;
