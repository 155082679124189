import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem, IconDropdown } from 'emerald-ui-3';

const RowToolbar = ({
  canRemoveCourse,
  canAddAttachments,
  onAttachmentIconClick,
  onDeleteCourseClick,
  isSpecialAttachment = false,
}) => {
  if ((!canAddAttachments && !canRemoveCourse) || isSpecialAttachment) {
    return <div />;
  }
  return (
    <IconDropdown
      id="course-menu"
      icon="more_horiz"
      className="table-icons"
      onClick={(e) => {
        e.stopPropagation();
      }}
      fromRight
    >
      {canAddAttachments && (
        <DropdownItem eventKey="2" onClick={onAttachmentIconClick}>
          Add attachments
        </DropdownItem>
      )}
      {canRemoveCourse && (
        <DropdownItem eventKey="1" onClick={onDeleteCourseClick} color="danger">
          Delete course
        </DropdownItem>
      )}
    </IconDropdown>
  );
};

RowToolbar.propTypes = {
  canRemoveCourse: PropTypes.bool,
  canAddAttachments: PropTypes.bool,
  isSpecialAttachment: PropTypes.bool,
  onAttachmentIconClick: PropTypes.func,
  onDeleteCourseClick: PropTypes.func,
  className: PropTypes.string,
};

export default RowToolbar;
