import { put, call, select } from 'redux-saga/effects';
import * as api from '../../../api/users';
import notificationsAt from '../../notifications/types';
import at from '../types';

export default function* updateCommunicationSettings({ communicationSettings, meta = {} }) {
  const state = yield select();
  const user = state.authentication.licenseeAccount.user;
  const userLogonId = state.authentication.authenticatedAccount.id;
  try {
    yield call(api.updateCommunicationSettings, user.id, communicationSettings, userLogonId);
    yield put({
      type: at.UPDATE_COMMUNICATION_SETTINGS_SUCCESS,
      userId: user.id,
      communicationSettings,
      meta,
    });
    yield put({
      type: notificationsAt.ADD,
      notification: {
        timeout: 2000,
        message: 'Communication settings updated',
        id: Math.random(),
      },
    });
  } catch (error) {
    console.error(error); //eslint-disable-line
    yield put({
      type: at.UPDATE_COMMUNICATION_SETTINGS_FAILURE,
      userId: user.id,
      communicationSettings,
      meta,
    });
    yield put({
      type: notificationsAt.ADD,
      notification: {
        timeout: 2000,
        message: 'Communication settings failed',
        id: Math.random(),
      },
    });
  } finally {
    //eslint-disable-line
  }
}
