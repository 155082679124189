export default function (globalState) {
  const state = globalState.reportedExemptions;

  const getEntityById = (id) => state.entities[id] || {};

  const getEntitiesByIds = (ids) => ids.map(getEntityById);

  const getEntitiesByPage = (page) => getEntitiesByIds(state.pages[page] || []);

  const getPageFetchStatus = (page) => state.pagesFetchStatus[page] || '';

  const getEntitiesFetchStatus = () => state.entitiesFetchStatus;

  const getTotalCount = () => state.totalCount;

  const getRevokeReqStatus = () => state.revokeReqStatus;

  return {
    getEntityById,
    getEntitiesByIds,
    getEntitiesByPage,
    getPageFetchStatus,
    getEntitiesFetchStatus,
    getTotalCount,
    getRevokeReqStatus,
  };
}
