import axios from 'axios';
import config from '../config';
const { licenseesApiInternal, downloadPdfStatuses } = config;

export const fetch = (licenseeId, filters) => {
  const { page, size, startDate, endDate, section, ...rest } = filters;
  const query = {
    userId: licenseeId,
    isExpanded: true,
    pageIndex: page,
    pageSize: size,
    excludeEmployee: true,
    ...rest,
  };

  // change send term instead of q
  if (query.q) {
    query.term = query.q;
    delete query.q;
  }

  if (startDate) {
    query.startEarnedDate = startDate;
  }
  if (endDate) {
    query.endEarnedDate = endDate;
  }

  let url = `${licenseesApiInternal}/course-history`;
  if (section === 'removed') {
    url += '/deleted';
  }
  return axios.request({
    url,
    params: query,
    headers: {},
    method: 'GET',
  });
};

export const fetchDetail = (postingId, token, entityId, section, userId, professionId) => {
  let url = `${licenseesApiInternal}/course-history/${postingId}`;
  if (section === 'removed') {
    url += '/deleted';
  }
  return axios.request({
    url,
    params: {
      onlyAttendeeComp: true,
      excludeEmployee: true,
      userId,
      entityId,
      professionId,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  });
};

export const rateCourse = (postingId, data, token) => {
  if (data.isNew) {
    return axios.request({
      url: `${licenseesApiInternal}/attendee-reviews`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: {
        rosterAttendeeId: postingId,
        userId: data.userId,
        rating: data.rating,
        comment: data.comment,
      },
    });
  }
  return axios.request({
    url: `${licenseesApiInternal}/attendee-reviews/${postingId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'PUT',
    data: {
      userId: data.userId,
      rating: data.rating,
      comment: data.comment,
    },
  });
};

export const deleteCourse = ({ selfSubmitId, userId, ownerId, token }) => {
  return axios.request({
    url: `${licenseesApiInternal}/self-submissions/${selfSubmitId}`,
    data: {
      userId,
      ownerId,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'DELETE',
  });
};

export const fetchAttachments = (attachmentDocumentNumber, attachmentCode, token) => {
  return axios.request({
    url: `${licenseesApiInternal}/attachments`,
    params: {
      documentNumber: attachmentDocumentNumber,
      documentType: attachmentCode,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  });
};

export const deleteAttachment = (attachmentId, token, attachmentCode, attachmentDocumentNumber) => {
  return axios.request({
    url: `${licenseesApiInternal}/attachments`,
    data: {
      attachments: [{ attachmentId, documentType: attachmentCode, documentNumber: attachmentDocumentNumber }],
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'DELETE',
  });
};

export const requestDownloadPdf = (credentials, token) => {
  return axios.request({
    url: `${licenseesApiInternal}/cch-report/download-pdf`,
    data: {
      subscriberCredentials: credentials,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
  });
};

export const getRequestStatus = (credentials, token) => {
  return axios.request({
    url: `${licenseesApiInternal}/cch-report/get-request-status`,
    params: {
      userId: credentials.userId,
      pkLicense: credentials.pkLicense,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  });
};

export const browserDownload = (urlDocument) => {
  return axios.request({
    url: `${urlDocument}`,
    method: 'GET',
    responseType: 'blob',
  });
};

export const updatePdfStatus = (pdfDataObject, token) => {
  pdfDataObject.statusRequest = downloadPdfStatuses.downloaded;

  return axios.request({
    url: `${licenseesApiInternal}/cch-report/update-status-pdf`,
    data: {
      subscriberCredentials: pdfDataObject,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
  });
};

export const fetchSubjectAreas = (professionId, userId = 0) => {
  return axios.request({
    method: 'GET',
    url: `${licenseesApiInternal}/course-filters`,
    params: {
      isAvailableInCourseSearch: true,
      professionId,
      userId,
    },
  });
};
