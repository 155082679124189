import { connect } from 'react-redux';
import UpgradeSuccessPage from '../components/UpgradeSuccessPage';

import * as authenticationStore from '../store/authentication';

function mapStateToProps(state) {
  const authenticationSelectors = authenticationStore.selectors(state);
  const licenseeAccount = authenticationSelectors.getLicenseeAccount();
  const authenticatedAccount = authenticationSelectors.getAuthenticatedAccount();

  const loggedOnBehalf = authenticatedAccount.role === 'helpdesk';

  const {
    user: { id: userId },
  } = licenseeAccount;
  return {
    loggedOnBehalf,
    userId,
  };
}

export default connect(mapStateToProps, {})(UpgradeSuccessPage);
