import at from './types';

export const fetch = (section, meta) => ({
  type: at.FETCH_REQUEST,
  section,
  meta,
});

export const deleteHeartcard = (heartcardId, params) => ({
  type: at.DELETE_HEARTCARD_REQUEST,
  heartcardId,
  params,
});

export const addHeartcard = (heartcard, loggedUserInfo, isEditing = false) => ({
  type: at.ADD_HEARTCARD_REQUEST,
  heartcard,
  loggedUserInfo,
  isEditing,
});

export const addAttachment = (file, heartcardId, reduxId, localFileId) => ({
  type: at.ADD_ATTACHMENT_REQUEST,
  file,
  heartcardId,
  reduxId,
  localFileId,
});

export const deleteAttachment = (fileId, heartcardId, reduxId) => ({
  type: at.DELETE_ATTACHMENT_REQUEST,
  fileId,
  heartcardId,
  reduxId,
});
