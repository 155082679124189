import React from 'react';
import PropTypes from 'prop-types';
const TakeItHereLabel = ({ active }) => {
  if (!active) {
    return <React.Fragment />;
  }
  return <span className="course-thumbnail-now-tag">TAKE IT HERE</span>;
};

TakeItHereLabel.propTypes = {
  active: PropTypes.bool,
};

export default TakeItHereLabel;
