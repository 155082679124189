import { takeLatest } from 'redux-saga/effects';

import at from '../types';
import fetchPaymentHistory from './fetchPaymentHistory';
import fetchPaymentHistoryDetail from './fetchPaymentHistoryDetail';
import fetchSelfSubmission from './fetchSelfSubmission';

export default function* root() {
  yield takeLatest(at.FETCH_PAYMENT_HISTORY_REQUEST, fetchPaymentHistory);
  yield takeLatest(at.FETCH_PAYMENT_HISTORY_DETAIL_REQUEST, fetchPaymentHistoryDetail);
  yield takeLatest(at.FETCH_SELF_SUBMISSION_REQUEST, fetchSelfSubmission);
}
