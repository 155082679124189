import axios from 'axios';
import config from '../config';

const { licenseesApiInternal } = config;

export const getBanner = () => {
  return axios.request({
    method: 'get',
    url: `${licenseesApiInternal}/banner`,
  });
};
