import React, { Component } from 'react';
import propTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';

class SanitizeHTML extends Component {
  render() {
    const { html, ...rest } = this.props;

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(html, {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
          }),
        }}
        {...rest}
      />
    );
  }
}

SanitizeHTML.propTypes = {
  html: propTypes.string,
};

export default SanitizeHTML;
