import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as transcriptStore from '../store/transcript';
import * as authenticationStore from '../store/authentication';
import * as inboxNotificationsStore from '../store/inboxNotifications';
import * as notificationsStore from '../store/notifications';
import queryString from 'query-string';
import OverviewPage from '../components/OverviewPage';

function mapStateToProps(state, ownProps) {
  const { match, location } = ownProps;
  const authenticationSelectors = authenticationStore.selectors(state);
  const licenseeAccount = authenticationSelectors.getLicenseeAccount();
  const authenticatedAccount = authenticationSelectors.getAuthenticatedAccount();
  const loggedOnBehalf = authenticatedAccount.role === 'helpdesk';
  const { user = {}, subscriptions = [], licenses = [], requestedLicenses = [] } = licenseeAccount;

  let subscriptionType = '';

  if (subscriptions.length > 0) {
    subscriptionType = subscriptions[0].type;
  }

  const { pkLicense } = match.params;
  const selectedLicense = licenses.find((l) => l.id.toString() === pkLicense);
  const selectedRequestedLicense = requestedLicenses.find((l) => l.id.toString() === pkLicense);
  const isRequestedLicense = !!selectedRequestedLicense;
  const query = queryString.parse(location.search);
  const { cycle: cycleId = 0 } = query;
  const transcriptSelectors = transcriptStore.selectors(state);
  const transcriptStatus = transcriptSelectors.getStatusById(`${pkLicense}-${cycleId}`);
  let boardId = 0;
  if (selectedLicense) {
    boardId = selectedLicense.profession.board.id;
  }
  if (selectedRequestedLicense) {
    boardId = selectedRequestedLicense.profession.board.id;
  }
  const boardLogo = transcriptSelectors.getBoardLogo(boardId);

  const licenseWithBrandColor = licenses.find((l) => l.profession.color);
  const professionBrandColor = licenseWithBrandColor ? licenseWithBrandColor.profession.color : '';

  const authenticationStatus = authenticationSelectors.getAuthenticationStatus();
  const subscription = authenticationSelectors.getLicenseeSubscription();
  const licenseeSubscriptionType = subscription.type || '';
  const showFavoriteIcon = authenticationStatus === 'authenticated' && licenseeSubscriptionType !== 'BAS';

  return {
    user,
    subscriptionType,
    selectedLicense,
    loggedOnBehalf,
    boardLogo,
    transcriptStatus,
    boardId,
    professionBrandColor,
    isRequestedLicense,
    selectedRequestedLicense,
    showFavoriteIcon,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchTranscript: transcriptStore.actions.fetch,
    fetchBoardLogo: transcriptStore.actions.fetchBoardLogo,
    fetchNotifications: inboxNotificationsStore.actions.fetch,
    addNotification: notificationsStore.actions.add,
  })(OverviewPage)
);
