import React, { Component } from 'react';
import propTypes from 'prop-types';
import UpgradeAd from '../../containers/UpgradeAd';
import TranscriptPage from '../../containers/TranscriptPage';
import config from '../../config';

const { SUBSCRIPTION_TYPES } = config;

export default class TranscriptRouter extends Component {
  render() {
    const { subscriptionType } = this.props;
    return <div>{subscriptionType === SUBSCRIPTION_TYPES.BAS ? <UpgradeAd /> : <TranscriptPage />}</div>;
  }
}

TranscriptRouter.propTypes = {
  subscriptionType: propTypes.string,
};
