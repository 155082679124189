import { put, call, select } from 'redux-saga/effects';
import * as api from '../../../api/users';
import at from '../types';

export default function* updateUsername({ username, meta = {} }) {
  const state = yield select();
  const user = state.authentication.licenseeAccount.user;
  try {
    yield call(api.updateUsername, user.id, username);
    yield put({
      type: at.UPDATE_USERNAME_SUCCESS,
      userId: user.id,
      username,
      meta,
    });
  } catch (error) {
    console.error(error); //eslint-disable-line
    yield put({
      type: at.UPDATE_USERNAME_FAILURE,
      userId: user.id,
      meta,
      error: error.response.data.message ? error.response.data : '',
    });
  } finally {
    //eslint-disable-line
  }
}
