import axios from 'axios';
import queryString from 'query-string';
import config, { OutUSCountryOption } from '../config';

const { licenseesApiInternal } = config;

export const getFee = (userId, upgradeTo, promoCode = '') => {
  const queryParams = `type=${upgradeTo.toUpperCase()}&promoCode=${promoCode}`;
  return axios.request({
    method: 'get',
    url: `${licenseesApiInternal}/subscriptions/fee-amount/${userId}?${queryParams}`,
    headers: {
      // Authorization: `Bearer ${authToken}`
    },
  });
};

export const getRenewFee = (userId) => {
  return axios.request({
    method: 'get',
    url: `${licenseesApiInternal}/subscriptions/renew/fee-amount/${userId}`,
    headers: {
      // Authorization: `Bearer ${authToken}`
    },
  });
};

export const getHasActiveSubscription = (userId) => {
  return axios.request({
    url: `${licenseesApiInternal}/subscriptions/${userId}/active`,
    method: 'GET',
  });
};

export const validatePromocode = (userId, promoCode = '') => {
  const params = {
    promoCode,
  };

  return axios.request({
    method: 'get',
    url: `${licenseesApiInternal}/subscriptions/validate-promocode/${userId}`,
    headers: {
      // Authorization: `Bearer ${authToken}`
    },
    params,
  });
};

export const upgradeLicensee = ({
  userId,
  upgradeTo,
  promoCode = '',
  creditCardInfo,
  selectedCountry,
  selectedState,
  zipCode,
  city,
  streetAddress,
  streetAddress2,
  amount,
}) => {
  const payload = {
    type: upgradeTo,
    upgradeTo,
    promoCode,
    creditCardInfo,
  };

  if (selectedCountry !== OutUSCountryOption.code) {
    payload.selectedCountry = selectedCountry;
    payload.zipCode = zipCode;
    payload.city = city;
    payload.selectedState = selectedState;
    payload.streetAddress = streetAddress;
    payload.streetAddress2 = streetAddress2;
    payload.amount = amount;
  }
  return axios.request({
    method: 'post',
    url: `${licenseesApiInternal}/subscriptions/upgrade/${userId}`,
    headers: {
      // Authorization: `Bearer ${authToken}`
    },
    data: payload,
  });
};

export const fetchPaymentInfo = (userId) => {
  return axios.request({
    url: `${licenseesApiInternal}/subscriptions/${userId}/payment-info`,
    method: 'GET',
    headers: {
      // Authorization: `Bearer ${authToken}`
    },
  });
};

export const updatePaymentInfo = ({ userId, creditCard, promoCode, userLogonId, address }) => {
  return axios.request({
    url: `${licenseesApiInternal}/subscriptions/${userId}/payment-info`,
    method: 'PUT',
    headers: {
      // Authorization: `Bearer ${authToken}`
    },
    data: {
      creditCard,
      userLogonId,
      promoCode,
      address,
    },
  });
};

export const cancelSubscription = (userId, userLogonId) => {
  return axios.request({
    url: `${licenseesApiInternal}/subscriptions/${userId}/expiration?userLogonId=${userLogonId}`,
    method: 'POST',
    headers: {
      // Authorization: `Bearer ${authToken}`
    },
  });
};

export const downgradeToPro = (userId, userLogonId) => {
  return axios.request({
    url: `${licenseesApiInternal}/subscriptions/${userId}/account-downgrades?userLogonId=${userLogonId}`,
    method: 'POST',
    headers: {
      // Authorization: `Bearer ${authToken}`
    },
  });
};

export const fetchPaymentHistory = (userId, filters) => {
  const query = queryString.stringify(filters);
  return axios.request({
    url: `${licenseesApiInternal}/subscriptions/${userId}/payment-history?${query}`,
    method: 'GET',
    headers: {
      // Authorization: `Bearer ${authToken}`
    },
  });
};

export const fetchPaymentHistoryDetail = (userId, invoiceId) => {
  return axios.request({
    url: `${licenseesApiInternal}/subscriptions/${userId}/payment-history/${invoiceId}`,
    method: 'GET',
    headers: {
      // Authorization: `Bearer ${authToken}`
    },
  });
};
