export default function (globalState) {
  const state = globalState.inboxNotifications;

  const getEntityById = (id) => state.entities[id] || {};

  const getEntitiesByIds = (ids) => ids.map(getEntityById);

  const getEntitiesByPage = (page, isArchived) => {
    return isArchived ? getEntitiesByIds(state.archivedPages[page] || []) : getEntitiesByIds(state.pages[page] || []);
  };

  const getPageFetchStatus = (page, isArchived) => {
    return isArchived ? state.archivedPagesFetchStatus[page] || '' : state.pagesFetchStatus[page] || '';
  };

  const getEntitiesFetchStatus = () => state.entitiesFetchStatus;

  const getTotalCount = () => state.totalCount;

  const getDeleteStatus = () => state.deleteStatus;

  const getArchiveStatus = () => state.archiveStatus;

  return {
    getEntityById,
    getEntitiesByIds,
    getEntitiesByPage,
    getPageFetchStatus,
    getEntitiesFetchStatus,
    getTotalCount,
    getDeleteStatus,
    getArchiveStatus,
  };
}
