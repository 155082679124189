import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import CourseRecommendationCard from './CourseRecommendationCard';

import * as coursesRecommendedStore from '../../store/coursesRecommended';
import * as authenticationStore from '../../store/authentication';
import * as usersStore from '../../store/users';
import * as notificationsStore from '../../store/notifications/';
import * as cartStore from '../../store/cart';

function mapStateToProps(state, ownProps) {
  const coursesRecommendedSelectors = coursesRecommendedStore.selectors(state);
  const authenticationSelectors = authenticationStore.selectors(state);
  const usersSelector = usersStore.selectors(state);
  const cartSelectors = cartStore.selectors(state);
  const { pkLicense } = ownProps.match.params;
  const query = queryString.parse(ownProps.location.search);
  const { cycle: cycleId = 0 } = query;
  const currentId = `${pkLicense}-${cycleId}`;
  let recommendations = coursesRecommendedSelectors.getCoursesRecommended(currentId);
  recommendations = recommendations
    .map((recommendation) => ({
      ...recommendation,
      hasMore: Array.isArray(recommendation.coursesRecommended) && recommendation.coursesRecommended.length > 3,
      coursesRecommended: Array.isArray(recommendation.coursesRecommended)
        ? recommendation.coursesRecommended.slice(0, 3)
        : [],
    }))
    .filter(({ coursesRecommended }) => Array.isArray(coursesRecommended) && coursesRecommended.length > 0);

  const seeCheckoutPage = usersSelector.getUserFlag('seeCheckoutPage');

  const coursesInCart = cartSelectors.getCartEntities();
  const saveCartItemStatus = cartSelectors.saveCartItemStatus();

  const licenseAccount = authenticationSelectors.getLicenseeAccount();
  const authenticatedAccount = authenticationSelectors.getAuthenticatedAccount();
  const loggedOnBehalf = authenticatedAccount.role === 'helpdesk';
  const { user = {}, licenses = [], autoRenewSettings } = licenseAccount;
  const license = licenses.find((l) => l.id === parseInt(pkLicense, 10)) || {};
  const { profession = {} } = license;
  const { id: professionId, board = {} } = profession;
  const { state: stateObject = {} } = board;
  const { code: stateCode } = stateObject;

  let customerId;
  if (autoRenewSettings) {
    customerId = autoRenewSettings.customerId;
  }

  const totalItemsInCart = cartSelectors.getCartTotalItems();

  return {
    recommendations,
    professionId,
    stateCode,
    loggedOnBehalf,
    user,
    seeCheckoutPage,
    customerId,
    location: ownProps.location,
    coursesInCart,
    saveCartItemStatus,
    totalItemsInCart,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    addNotification: notificationsStore.actions.add,
    saveCartItem: cartStore.actions.saveCartItem,
    clearStatusCart: cartStore.actions.clearStatusCart,
  })(CourseRecommendationCard)
);
