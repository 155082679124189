import React from 'react';
import Avatar from 'emerald-ui/lib/Avatar';
import PropTypes from 'prop-types';
import SkeletonLoader from 'emerald-ui/lib/SkeletonLoader';

import './CourseComment.css';
import StarRatingComponent from 'components/StarRating';
import { dateStringFormat, formatAuthorName } from 'utils';

export default function CourseComment({
  rating = 0,
  author = 'anonymous user',
  comment,
  createdAt,
  isLoading = false,
}) {
  const formattedDate = createdAt ? dateStringFormat(createdAt, 'MM/DD/YYYY', 'DD MMM YYYY') : '';
  return (
    <div className="course-comment-container">
      {isLoading ? (
        <SkeletonLoader circle={true} width={50} loading={isLoading} />
      ) : (
        <Avatar size="lg" title={author} />
      )}
      <div className="course-comment-container-content">
        <div className="course-comment-container-rating ">
          <div className="course-comment-container-author">
            {isLoading ? (
              <SkeletonLoader height={20} width={200} loading={isLoading} />
            ) : (
              <span className="author-name">{formatAuthorName(author)}</span>
            )}
            {isLoading ? (
              <SkeletonLoader height={20} width={150} loading={isLoading} />
            ) : (
              <span className="review-date">{formattedDate}</span>
            )}
          </div>
          {isLoading ? (
            <SkeletonLoader height={20} width={100} loading={isLoading} />
          ) : (
            <StarRatingComponent rating={rating} showRating={false} />
          )}
        </div>
        {isLoading ? <SkeletonLoader height={20} width={340} loading={isLoading} /> : <p>{comment}</p>}
      </div>
    </div>
  );
}

CourseComment.propTypes = {
  rating: PropTypes.number,
  author: PropTypes.string,
  comment: PropTypes.string,
  createdAt: PropTypes.string,
  isLoading: PropTypes.bool,
};
