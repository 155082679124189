export default function (globalState) {
  const state = globalState.addLicenseInfo;

  const getStates = () => state.fetch.states || [];

  const getProfessionsByStateCode = (stateCode) => state.fetch.professionsByState[stateCode] || [];

  const getFetchStatus = () => state.fetchStatus;

  const getProfessions = () => state.fetch.professionsByState || {};

  return {
    getStates,
    getProfessions,
    getProfessionsByStateCode,
    getFetchStatus,
  };
}
