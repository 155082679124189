import { put, call, select } from 'redux-saga/effects';
import * as api from '../../../api/users';
import at from '../types';

export default function* updatePassword({ currentPassword, newPassword, confirmPassword, meta = {} }) {
  const state = yield select();
  const user = state.authentication.licenseeAccount.user;
  const token = state.authentication.legacyToken.token;
  try {
    yield call(api.updatePassword, user.id, currentPassword, newPassword, confirmPassword, token);
    yield put({
      type: at.UPDATE_PASSWORD_SUCCESS,
      userId: user.id,
      meta,
    });
  } catch (error) {
    console.error(error); //eslint-disable-line
    yield put({ type: at.UPDATE_PASSWORD_FAILURE, userId: user.id, meta });
  } finally {
    //eslint-disable-line
  }
}
