export function loadStateFromLocalStorage() {
  try {
    const state = localStorage['licensees-reduxstore'] ? JSON.parse(localStorage['licensees-reduxstore']) : {};
    return state;
  } catch (err) {
    return null;
  }
}

export function saveStateToLocalStorage(state) {
  try {
    localStorage.setItem('licensees-reduxstore', JSON.stringify(state));
  } catch (err) {
    //Log error if you want
  }
}
