import axios from 'axios';
import * as flags from './flags';
import config from '../config';

export function getTask(pkLicense) {
  return axios.request({
    method: 'GET',
    url: `${config.licenseesApiInternal}/audits/task/${pkLicense}`,
  });
}

export function getFlagUserAuditTask(userId, key) {
  return flags.getFlag({ userId, key });
}

export function setFlagUserAuditTask(userId, key, value = true) {
  return flags.setFlag({ userId, key, value });
}

export function submitTask(pkLicense, cycleId) {
  return axios.request({
    method: 'PUT',
    url: `${config.licenseesApiInternal}/audits/submit/${pkLicense}/period/${cycleId}`,
  });
}
