import axios from 'axios';
import config from '../config';

const { licenseesApiInternal } = config;

export const fetch = (userId, filters) => {
  const { page, size, communicationsType, ...rest } = filters;
  const query = {
    pageNumber: page,
    pageSize: size,
    actionType: communicationsType,
    ...rest,
  };

  return axios.request({
    url: `${licenseesApiInternal}/communications/${userId}/pending-actions`,
    params: query,
    headers: {},
    method: 'GET',
  });
};

export const fetchDetail = (userId, filters) => {
  const { inboxId, ...rest } = filters;
  const query = {
    ...rest,
  };

  return axios.request({
    url: `${licenseesApiInternal}/communications/${userId}/pending-actions/${inboxId}`,
    params: query,
    headers: {},
    method: 'GET',
  });
};

export const postCommunicationResponse = (userId, authToken, filters) => {
  const { documentNumber, inboxTypeCode: documentType, formData, requestId } = filters;
  return axios.request({
    url: `${licenseesApiInternal}/attachments/communications?documentNumber=${documentNumber}&documentType=${documentType}&userId=${userId}&requestId=${requestId}`,
    headers: {
      Authorization: `bearer ${authToken}`,
    },
    method: 'POST',
    data: formData,
  });
};

export const fetchAuditMessages = (ownerId, actionType) => {
  return axios.request({
    url: `${licenseesApiInternal}/audits/communications/${ownerId}`,
    params: { actionType },
    headers: {},
    method: 'GET',
  });
};

export const postSaveAuditResponse = (conversationId, data) => {
  return axios.request({
    url: `${licenseesApiInternal}/audits/communications/${conversationId}`,
    headers: {},
    method: 'POST',
    data,
  });
};
