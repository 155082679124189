import { put, call, select } from 'redux-saga/effects';

import * as api from '../../../api/subscriptions';
import at from '../types';

export function* cancelTrial() {
  const state = yield select();
  const user = state.authentication.licenseeAccount.user;
  const loggedUser = state.authentication.authenticatedAccount;
  const token = state.authentication.token;
  yield put({
    type: at.CANCEL_TRIAL_REQUEST,
  });
  try {
    const result = yield call(api.cancelTrial, {
      userId: user.id,
      loggedUserId: loggedUser.id,
      token: token.token,
      tokenType: token.tokenType,
    });
    yield put({
      type: at.CANCEL_TRIAL_SUCCESS,
      data: result.data,
    });
  } catch (error) {
    console.log(error); //eslint-disable-line
    yield put({
      type: at.CANCEL_TRIAL_FAILED,
      error: error.response.data.error,
    });
  }
}
