import { schema } from 'normalizr';

const schemaId = (value) => {
  if (value.type === 'DASHBOARD_HEARTCARD') {
    return `${value.id}-${value.type}`;
  }
  return `${value.id}-${value.name}-${value.pklicense}-${value.type}`;
};

export const dashboardCards = new schema.Entity(
  'items',
  {},
  {
    idAttribute: schemaId,
    processStrategy: (value) => ({
      ...value,
      reduxId: schemaId(value),
    }),
  }
);

export const dashboardCardsArray = new schema.Array(dashboardCards);
