import axios from 'axios';
import config from '../config';

export function fetchAds(data) {
  return axios.request({
    method: 'POST',
    url: `${config.licenseesApiInternal}/generate-ads`,
    data,
  });
}

export function fetchCourseDetail(offeringId) {
  return axios.request({
    method: 'GET',
    url: `${config.licenseesApiInternal}/offerings/${offeringId}`,
  });
}
