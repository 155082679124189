import { put, call, select } from 'redux-saga/effects';
import at from '../types';
import * as api from '../../../api/courseHistory';

export default function* updateDownloadPdfStatus({ pdfDataObject }) {
  const state = yield select();
  const token = state.authentication.legacyToken.token;
  try {
    const { data } = yield call(api.updatePdfStatus, pdfDataObject, token);
    yield put({ type: at.UPDATE_PDF_STATUS_SUCCESS, data });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({ type: at.UPDATE_PDF_STATUS_FAILURE });
  }
}
