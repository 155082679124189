import { put, call, select } from 'redux-saga/effects';

import * as api from '../../../api/subscriptions';
import at from '../types';
import config from '../../../config';

const { SUBSCRIPTION_TYPES } = config;

export function* getSubscriptionPlans() {
  const state = yield select();
  const { subscriptions, user } = state.authentication.licenseeAccount;
  const [subscription] = subscriptions;

  try {
    if (subscription && subscription.code === SUBSCRIPTION_TYPES.EMP) {
      return yield put({
        type: at.FETCH_SUBSCRIPTION_PLANS_SUCCESS,
        data: { plans: [] },
      });
    }

    const { data } = yield call(api.getSubscriptionPlans, user.id, subscription.code);

    let indexOfPopularPlan = -1;
    indexOfPopularPlan = data.plans.findIndex((item) => item.subscriptionType === SUBSCRIPTION_TYPES.PRO_PLUS);

    if (indexOfPopularPlan < 0) {
      indexOfPopularPlan = data.plans.findIndex((item) => item.subscriptionType === SUBSCRIPTION_TYPES.PRO);
    }

    if (indexOfPopularPlan >= 0) {
      data.plans[indexOfPopularPlan].isPopular = true;
    }

    yield put({
      type: at.FETCH_SUBSCRIPTION_PLANS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: at.FETCH_SUBSCRIPTION_PLANS_FAILURE,
      error: error && error.response && error.response.data,
    });
  }
}
