import axios from 'axios';
import config from '../config';

const { licenseesApiInternal } = config;

export const cancelTrial = ({ userId, loggedUserId, token, tokenType }) => {
  return axios.request({
    method: 'post',
    url: `${licenseesApiInternal}/subscriptions/cancel-trial/${userId}`,
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
    data: {
      loggedUserId,
    },
  });
};

export const getLastSubscription = (userId) => {
  return axios.request({
    method: 'get',
    url: `${licenseesApiInternal}/subscriptions/recapture/${userId}`,
  });
};

export const getSubscriptionPrices = () => {
  return axios.request({
    method: 'GET',
    url: `${licenseesApiInternal}/subscriptions/prices`,
  });
};

export const getSubscriptionPlans = (userId, subscriptionType) => {
  return axios.request({
    method: 'GET',
    url: `${licenseesApiInternal}/subscriptions/${userId}/plans`,
    params: {
      ...(subscriptionType && { subscriptionType }),
    },
  });
};
