import { connect } from 'react-redux';
import CourseHistoryAttachmentUploader from '../components/CourseHistoryPage/CourseHistoryAttachmentUploader';
import * as notificationsStore from '../store/notifications';

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {
  addNotification: notificationsStore.actions.add,
})(CourseHistoryAttachmentUploader);
