import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col, Container, FileUploadList } from 'emerald-ui/lib';

import './ReportedExemptionDetail.css';

const filesList = (attachments) => {
  return attachments && attachments.length > 0
    ? attachments.map((a) => {
        let name = a.filename;
        name = name.toLowerCase();
        const array = name.split('.');
        const isImage = array.length > 1 && (array[1] === 'png' || array[1] === 'jpg');
        return {
          name,
          icon: isImage ? a.url : '',
          onDownload: () => {
            window.open(a.url);
          },
        };
      })
    : [];
};

class ReportedExemptionDetail extends React.Component {
  UNSAFE_componentWillMount() {
    const { fetchQuestions, exemption } = this.props;
    const { exemptionId, lpExemptionId } = exemption;
    fetchQuestions(exemptionId, lpExemptionId);
  }

  render() {
    const { exemption } = this.props;
    const { questions = [], attachments = [] } = exemption;

    const files = filesList(attachments);

    return (
      <Container id="reported-exemption-detail">
        <Row className="info-section exemption-info">
          <Col className="exemption-info-col" xs={12} md={6}>
            <h4>Exemption info</h4>
            <Row className="section-subcontent">
              <Col xs={4}>
                <h5>License / Certificate</h5>
              </Col>
              <Col xs={8}>
                <span>{exemption.licenseNumber}</span>
              </Col>
            </Row>
            <Row className="section-subcontent">
              <Col xs={4}>
                <h5>Period</h5>
              </Col>
              <Col xs={8}>
                <span>
                  {moment(exemption.renewalStartDate).format('MM/DD/YYYY')} -{' '}
                  {moment(exemption.renewalEndDate).format('MM/DD/YYYY')}
                </span>
              </Col>
            </Row>
            <Row className="section-subcontent">
              <Col xs={4}>
                <h5>Status</h5>
              </Col>
              <Col xs={8}>
                <span>{exemption.status === 'REVOKE' ? 'REVOKED' : exemption.status}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="info-section">
          <Col>
            <h4>Rule</h4>
            <p>{exemption.comments}</p>
          </Col>
        </Row>
        {questions && questions.length > 0 && (
          <Row className="info-section">
            <Col>
              <h4>Questions</h4>
              {questions.map((q) => {
                return (
                  <Row className="section-subcontent subcontent-column" key={q.questionId}>
                    <Col>
                      <h5 className="q-a-item">
                        <span>Q:</span>
                        <span>{q.questionText}</span>
                      </h5>
                      {q.answers.length === 1 ? (
                        <p className="q-a-item">
                          <span>A:</span>
                          <span>{q.answers[0]}</span>
                        </p>
                      ) : (
                        <ul>
                          {q.answers.map((answer) => {
                            return (
                              <li key={answer}>
                                <p>A: {answer}</p>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        )}
        <Row className="info-section attachments">
          <Col>
            <h4>Attachments</h4>
            {files.length > 0 ? (
              <div className="attachments-list">
                <FileUploadList files={files} />
              </div>
            ) : (
              <p>No attachments found</p>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

ReportedExemptionDetail.propTypes = {
  fetchQuestions: PropTypes.func,
  exemption: PropTypes.object,
};

ReportedExemptionDetail.defaultProps = {
  fetchQuestions: () => {},
  exemption: {},
};

export default ReportedExemptionDetail;
