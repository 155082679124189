import React from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import isFunction from 'lodash/isFunction';

import Popover from 'emerald-ui/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import AssociationLabel from '../AssociationLabels';
import ImageWithFallback from '../ImageWithFallback';
import Responsive from '../Responsive';
import defaultBanner from '../../static/images/default-featured-course-banner.png';
import CourseLogo from '../CourseDetail/components/CourseLogo';
import DeliveryMethodIcon from '../DeliveryMethodIcon';
import './CourseThumbnail.css';
import StarRating from '../StarRating';
import ProPlusBadge from 'components/common/ProPlusBadge';

class Price extends React.PureComponent {
  static propTypes = {
    priceText: PropTypes.string,
    isContactProvider: PropTypes.bool,
  };

  render() {
    const { priceText, isContactProvider } = this.props;
    return (
      <Responsive size="TabletPortraitUp">
        {(matches) => {
          if (matches && isContactProvider) {
            return (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <OverlayTrigger
                  trigger={['hover', 'click']}
                  placement="left"
                  delayShow={500}
                  delayHide={300}
                  overlay={
                    <Popover id="filterForm" title="Price not reported">
                      {`We don't have any pricing data for this course`}
                    </Popover>
                  }
                >
                  <span className="course-thumbnail-price price-not-reported">{priceText}</span>
                </OverlayTrigger>
              </div>
            );
          }
          return (
            <span className={`course-thumbnail-price ${isContactProvider ? 'price-not-reported' : ''}`}>
              {priceText}
            </span>
          );
        }}
      </Responsive>
    );
  }
}

class CourseThumbnail extends React.PureComponent {
  handleClick = () => {
    const { offeringId, courseId, isFeatured, professionId, onClick, name, providerId } = this.props;
    if (isFunction(onClick)) {
      onClick({
        offeringId,
        courseId,
        isFeatured,
        professionId: professionId,
        name,
        providerId,
      });
    }
  };

  handleFavoriteClick = (e) => {
    e.stopPropagation();
    const { name, courseId, offeringId, professionId, onFavoriteClick, isFavorite, providerId, providerName } =
      this.props;
    const action = isFavorite ? 'delete' : 'save';
    if (isFunction(onFavoriteClick)) {
      onFavoriteClick({
        courseId,
        courseName: name,
        offeringId,
        professionId,
        providerId,
        providerName,
        action,
      });
    }
  };

  render() {
    const {
      name,
      duration,
      hasPrice,
      isFree,
      price,
      location,
      date,
      isLiveCourseWithoutDates,
      deliveryMethodCode,
      deliveryMethodDescription,
      courseType,
      isFeatured,
      bannerUrl,
      professionId,
      isNowCourse,
      associationLabels,
      rating,
      ratingCount,
      shouldShowProPlusBadge,
    } = this.props;
    const isContactProvider = (!isFree && hasPrice && (price === 0 || price >= 99999)) || isLiveCourseWithoutDates;
    let priceText = accounting.formatMoney(price, '$ ');
    if (isContactProvider) {
      priceText = 'Price not reported';
    }
    if (isFree) {
      priceText = 'Free';
    }

    return (
      <div className={`course-thumbnail ${isFeatured ? 'feature-banner' : ''}`} key={name} onClick={this.handleClick}>
        <div className={`course-thumbnail-logo ${isFeatured ? 'feature-banner' : ''}`}>
          {/* Show the course banner only if it is a featured course */}
          {isFeatured ? (
            <ImageWithFallback
              width={'269px'}
              height={'150px'}
              alt="Featured course banner"
              src={bannerUrl}
              defaultSrc={defaultBanner}
            />
          ) : (
            <CourseLogo courseType={courseType} courseDetail={{ type: courseType }} />
          )}
        </div>
        <div className="course-thumbnail-info-container">
          <div className="course-thumbnail-data">
            <div>
              <h1 className="course-thumbnail-title">{name}</h1>
              {isFeatured && (
                <span className="featured-label course-association-label" key={'FEATURED'}>
                  {'FEATURED'}
                </span>
              )}
              <AssociationLabel labels={associationLabels} />
            </div>
            <div className="course-thumbnail-additional-info-container">
              {professionId && (
                <p className="course-thumbnail-additional-info">
                  <i className="material-icons">schedule</i>
                  <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>{duration}</span>
                </p>
              )}
              <p className="course-thumbnail-additional-info">
                <DeliveryMethodIcon deliveryMethodCode={deliveryMethodCode} courseType={courseType} />
                {deliveryMethodDescription}
                {location && ` • ${location}`}
                {date && ` • ${date}`}
              </p>
            </div>
          </div>

          <div className="course-thumbnail-actions">
            <Price priceText={priceText} isContactProvider={isContactProvider} />
            <StarRating rating={rating} ratingCount={ratingCount} />
            <div className="action-icon-buttons">
              {shouldShowProPlusBadge && <ProPlusBadge />}
              {isNowCourse && <span className="course-thumbnail-now-tag">TAKE IT HERE</span>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CourseThumbnail.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  providerId: PropTypes.number.isRequired,
  providerName: PropTypes.string.isRequired,
  duration: PropTypes.string,
  hasPrice: PropTypes.bool,
  isFree: PropTypes.bool,
  price: PropTypes.any,
  deliveryMethodCode: PropTypes.string.isRequired,
  deliveryMethodDescription: PropTypes.string.isRequired,
  courseType: PropTypes.string.isRequired,
  location: PropTypes.string,
  date: PropTypes.string,
  isLiveCourseWithoutDates: PropTypes.bool,
  bannerUrl: PropTypes.string,
  professionId: PropTypes.number,
  offeringId: PropTypes.number,
  courseId: PropTypes.number,
  isFeatured: PropTypes.bool,
  isFavorite: PropTypes.bool,
  showFavoriteIcon: PropTypes.bool,
  onClick: PropTypes.func,
  onFavoriteClick: PropTypes.func,
  isNowCourse: PropTypes.bool,
  isCebNowLiveCourse: PropTypes.bool,
  associationLabels: PropTypes.array,
  saveCartItem: PropTypes.func,
  coursesInCart: PropTypes.array,
  loggedOnBehalf: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  userId: PropTypes.number,
  savedOfferingsList: PropTypes.array,
  handleSaveOffering: PropTypes.func,
  savingStatuses: PropTypes.object,
};

CourseThumbnail.defaultProps = {
  showFavoriteIcon: true,
  onClick: () => {},
  onFavoriteClick: () => {},
  associationLabels: [],
};

export default CourseThumbnail;
