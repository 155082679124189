import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Button } from 'emerald-ui';
import { withRouter } from 'react-router-dom';
import checkMarkImg from './check-mark-illustration.svg';
import queryString from 'query-string';
import { forceAuthenticate } from '../../utils';
import './style.css';

const SECONDS = 5;

class UpgradeSuccessPage extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.PerformRedirection();
    }, SECONDS * 1000);
  }
  handleClickOk = (e) => {
    e.stopPropagation();
    this.PerformRedirection();
  };

  PerformRedirection() {
    const { loggedOnBehalf, userId } = this.props;
    forceAuthenticate(userId, loggedOnBehalf);
  }

  render() {
    const { location } = this.props;
    const { upgradedTo = 'pro' } = queryString.parse(location.search);
    return (
      <div id="upgrade-success-page">
        <img src={checkMarkImg} alt="Check mark" />
        <h3>Success! Your upgrade is complete</h3>
        <p>You can now enjoy all the benefits of the {upgradedTo.toUpperCase()} account.</p>
        <Button color="success" onClick={this.handleClickOk}>
          Access my account
        </Button>
        <p className="redirection-message">You will be redirected to your Dashboard in {`${SECONDS}`} seconds</p>
      </div>
    );
  }
}

UpgradeSuccessPage.propTypes = {
  history: propTypes.object,
  location: propTypes.object,
  onHide: propTypes.func,
  loggedOnBehalf: propTypes.bool,
  userId: propTypes.number,
};

UpgradeSuccessPage.defaultProps = {
  history: {},
  location: {},
  onHide: () => {},
  loggedOnBehalf: false,
  userId: 0,
};

export default withRouter(UpgradeSuccessPage);
