import at from './types';

export const fetch = (filters, meta) => ({
  type: at.FETCH_REQUEST,
  filters,
  meta,
});

export const deleteNotifications = (inboxIds, inboxType, page, callback, meta) => ({
  type: at.DELETE_REQUEST,
  inboxIds,
  inboxType,
  page,
  callback,
  meta,
});

export const archiveNotifications = (inboxIds, page, callback, meta) => ({
  type: at.ARCHIVE_REQUEST,
  inboxIds,
  page,
  callback,
  meta,
});

export const setPageFetchStatus = (filters, status) => ({
  type: at.SET_PAGE_FETCH_STATUS,
  filters,
  status,
});

export const clearEntities = () => ({
  type: at.CLEAR_ENTITIES,
});
