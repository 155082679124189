import { put, call, select, cancelled } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';

import * as api from '../../../api/communications';
import * as actions from '../actions';
import at from '../types';
import * as schema from '../schema';

export default function* fetch({ filters }) {
  const { communicationsType } = filters;
  yield put(actions.setPageFetchStatus(filters, 'loading'));
  const actionTypeSuccess = communicationsType === 'REQUEST' ? at.FETCH_REQUEST_SUCCESS : at.FETCH_RESPONSE_SUCCESS;
  const actionTypeFailure = communicationsType === 'REQUEST' ? at.FETCH_REQUEST_FAILURE : at.FETCH_RESPONSE_FAILURE;

  try {
    yield delay(400);
    const state = yield select();
    const user = state.authentication.licenseeAccount.user;
    const { data } = yield call(api.fetch, user.id, filters);
    const normalizedData = normalize(data.items, schema.communicationsArray);
    yield put({
      type: actionTypeSuccess,
      filters,
      ...normalizedData,
      options: data.options,
    });
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put({ type: actionTypeFailure, filters });
  } finally {
    if (yield cancelled()) {
      yield put(actions.setPageFetchStatus(filters.page, 'cancelled'));
    }
  }
}
