import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import { refineQuery } from '../utils';

import * as courseHistory from '../store/courseHistory';

import CourseHistoryListPager from '../components/CourseHistoryListPager';

function mapStateToProps(state, ownProps) {
  const { match, location } = ownProps;
  const courseHistorySelectors = courseHistory.selectors(state);
  const params = refineQuery(queryString.parse(location.search));
  if (!params.page) {
    params.page = 1;
  }
  if (!params.size) {
    params.size = 10;
  }
  params.pkLicense = match.params.pkLicense;

  return {
    params,
    totalCount: courseHistorySelectors.getTotalCount(),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchCourseHistory: courseHistory.actions.fetch,
  })(CourseHistoryListPager)
);
