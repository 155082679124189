import React from 'react';
import PropTypes from 'prop-types';

import { Modal, Button, TextField } from 'emerald-ui';
import StarRatingComponent from 'react-star-rating-component';

const RatingModal = ({ show, onHide, courseName, rating, comment, onRatingChange, onCommentChange, onSubmit }) => {
  return (
    <Modal show={show} size="sm" onHide={onHide} centered={true} hideOnOutsideClick={true}>
      <Modal.Body className="survey-body">
        <h3 className="survey-title">How would you rate {courseName}?</h3>
        <StarRatingComponent
          name={`rate-modal`}
          starCount={5}
          value={rating || 0}
          starColor="#42A5F5"
          emptyStarColor="#E0E7EA"
          className="rating-stars"
          onStarClick={(rating) => {
            onRatingChange(rating);
          }}
        />
        <TextField
          textarea
          value={comment || ''}
          onChange={onCommentChange}
          placeholder="Comments"
          className="course-history-rating-comments"
        />
        <h5>Click a star to rate. Feedback is not anonymous.</h5>
      </Modal.Body>
      <Modal.Footer className="buttons-rate-modal">
        <Button shape="flat" onClick={onHide} ariaLabel="Cancel rating">
          Cancel
        </Button>
        <Button color="info" onClick={onSubmit} disabled={rating === 0} ariaLabel="Submit rating">
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RatingModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  courseName: PropTypes.string,
  rating: PropTypes.number,
  comment: PropTypes.string,
  onRatingChange: PropTypes.func,
  onCommentChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default RatingModal;
