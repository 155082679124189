export default {
  FETCH_REQUEST: 'dashboardCards/FETCH_REQUEST',
  FETCH_SUCCESS: 'dashboardCards/FETCH_SUCCESS',
  FETCH_FAILURE: 'dashboardCards/FETCH_FAILURE',
  DELETE_HEARTCARD_REQUEST: 'dashboardCards/DELETE_HEARTCARD_REQUEST',
  DELETE_HEARTCARD_SUCCESS: 'dashboardCards/DELETE_HEARTCARD_SUCCESS',
  DELETE_HEARTCARD_FAILURE: 'dashboardCards/DELETE_HEARTCARD_FAILURE',
  ADD_HEARTCARD_REQUEST: 'dashboardCards/ADD_HEARTCARD_REQUEST',
  ADD_HEARTCARD_SUCCESS: 'dashboardCards/ADD_HEARTCARD_SUCCESS',
  ADD_HEARTCARD_FAILURE: 'dashboardCards/ADD_HEARTCARD_FAILURE',
  ADD_ATTACHMENT_REQUEST: 'dashboardCards/ADD_ATTACHMENT_REQUEST',
  ADD_ATTACHMENT_SUCCESS: 'dashboardCards/ADD_ATTACHMENT_SUCCESS',
  ADD_ATTACHMENT_FAILURE: 'dashboardCards/ADD_ATTACHMENT_FAILURE',
  DELETE_ATTACHMENT_REQUEST: 'dashboardCards/DELETE_ATTACHMENT_REQUEST',
  DELETE_ATTACHMENT_SUCCESS: 'dashboardCards/DELETE_ATTACHMENT_SUCCESS',
  DELETE_ATTACHMENT_FAILURE: 'dashboardCards/DELETE_ATTACHMENT_FAILURE',
};
