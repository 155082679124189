import { schema } from 'normalizr';

export const nowCourse = new schema.Entity(
  'items',
  {},
  {
    idAttribute: (value) => `${value.id}`,
    processStrategy: (value) => ({
      ...value,
      reduxId: `${value.id}`,
    }),
  }
);

export const nowCourseArray = new schema.Array(nowCourse);
