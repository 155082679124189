import { put, call, select } from 'redux-saga/effects';
import * as api from '../../../api/users';
import at from '../types';

export default function* fetch({ meta = {} }) {
  const state = yield select();
  const user = state.authentication.licenseeAccount.user;
  const licenses = state.authentication.licenseeAccount.licenses || [];
  let pkLicense;
  if (licenses.length > 0) {
    pkLicense = licenses[0].id;
  }
  try {
    const { data } = yield call(api.fetchUserProfile, user.id, pkLicense);
    yield put({
      type: at.FETCH_SUCCESS,
      userId: user.id,
      data,
      meta,
    });
  } catch (error) {
    console.error(error); //eslint-disable-line
    yield put({ type: at.FETCH_FAILURE, userId: user.id, pkLicense, meta });
  } finally {
    //eslint-disable-line
  }
}
