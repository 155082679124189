import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../Loader';
import CourseHistoryList from '../../containers/CourseHistoryList';
import CourseHistoryListToolbar from '../../containers/CourseHistoryListToolbar';
import CourseHistoryListPager from '../../containers/CourseHistoryListPager';
import CourseHistoryEmptyState from '../CourseHistoryEmptyState';

import { openDownloadCertificate } from '../../config';
import './styles.css';

class CourseHistoryDeletedPage extends React.Component {
  state = {
    showRatingModal: false,
    showAttachmentModal: false,
    showDeleteCourseConfirmationModal: false,
    selectedCourseReduxId: null,
    attachmentCode: null,
    attachmentDocumentNumber: null,
    rating: null,
    comment: '',
    isNewRating: false,
  };

  componentDidMount() {
    const { fetchCourseHistory, filters } = this.props;
    filters.section = 'removed';
    fetchCourseHistory(filters, { clearEntities: true });
  }

  getSelectedCourse = (reduxId) => {
    return this.props.courseHistory.find((ch) => ch.reduxId === reduxId) || {};
  };

  handleModalCommentChange = (e) => {
    this.setState({ comment: e.target.value });
  };

  handleRatingModalSubmit = () => {
    const { selectedCourseReduxId, rating, comment, isNewRating } = this.state;
    const { rateCourse } = this.props;
    const selectedCourse = this.getSelectedCourse(selectedCourseReduxId);
    if (rating > 0) {
      rateCourse(selectedCourseReduxId, selectedCourse.id, {
        rating,
        comment,
        isNew: isNewRating,
      });
    }
    this.setState({
      showRatingModal: false,
      selectedCourseReduxId: null,
      rating: null,
      comment: '',
    });
  };

  getCourseAttachmentInfo = (reduxId) => {
    const selectedCourse = this.getSelectedCourse(reduxId);
    let attachmentCode;
    let attachmentDocumentNumber;
    switch (selectedCourse.trackingPrefix) {
      case '10':
      case 'ProviderPost': {
        attachmentCode = 'ROSTER_ATTENDEE';
        attachmentDocumentNumber = selectedCourse.id;
        break;
      }
      case '94':
      case 'DisciplinaryPost': {
        attachmentCode = 'DISC_POST';
        attachmentDocumentNumber = selectedCourse.idDiscPostCourse;
        break;
      }
      default: {
        attachmentCode = 'POST_CREDIT';
        attachmentDocumentNumber = selectedCourse.entityId;
      }
    }
    return { attachmentCode, attachmentDocumentNumber };
  };

  fetchCourseAttachments = (reduxId) => {
    const { fetchCourseHistoryAttachments } = this.props;
    const { attachmentCode, attachmentDocumentNumber } = this.getCourseAttachmentInfo(reduxId);

    fetchCourseHistoryAttachments(reduxId, attachmentDocumentNumber, attachmentCode);
  };

  handleAttachmentIconClick = (reduxId) => {
    const { attachmentCode, attachmentDocumentNumber } = this.getCourseAttachmentInfo(reduxId);
    this.fetchCourseAttachments(reduxId);
    this.setState({
      showAttachmentModal: true,
      selectedCourseReduxId: reduxId,
      attachmentCode,
      attachmentDocumentNumber,
    });
  };

  handleShowAttachments = (reduxId) => {
    const selectedCourse = this.getSelectedCourse(reduxId);
    if (selectedCourse.certificateId > 0) {
      openDownloadCertificate(selectedCourse.certificateId);
    } else {
      this.fetchCourseAttachments(reduxId);
    }
  };

  handleCloseAttachmentModal = () => {
    this.setState({
      showAttachmentModal: false,
      attachmentCode: null,
    });
  };

  handleUploadAttachmentSuccess = (attachment) => {
    const { selectedCourseReduxId } = this.state;
    const { addCourseHistoryAttachment } = this.props;
    addCourseHistoryAttachment(selectedCourseReduxId, attachment);
  };

  handleDeleteAttachment = (courseId, id) => {
    const { deleteCourseHistoryAttachment } = this.props;
    const { attachmentCode, attachmentDocumentNumber } = this.getCourseAttachmentInfo(courseId);
    deleteCourseHistoryAttachment(courseId, id, attachmentCode, attachmentDocumentNumber);
  };

  handleDeleteCourseMenuClick = (reduxId) => {
    this.setState({
      showDeleteCourseConfirmationModal: true,
      selectedCourseReduxId: reduxId,
    });
  };

  handleDeleteCourse = () => {
    const { deleteCourse, filters } = this.props;
    const { selectedCourseReduxId } = this.state;
    const selectedCourse = this.getSelectedCourse(selectedCourseReduxId);
    deleteCourse(selectedCourseReduxId, selectedCourse.entityId, filters);
    this.setState({
      selectedCourseReduxId: null,
      showDeleteCourseConfirmationModal: false,
    });
  };

  handleCloseDeleteCourseCourseModal = () => {
    this.setState({
      showDeleteCourseConfirmationModal: false,
      selectedCourse: null,
    });
  };

  render() {
    const { selectedCourseReduxId } = this.state;

    const {
      pageFetchStatus,
      courseHistory,
      fetchCourseHistoryDetail,
      filters,
      subscriptionType,
      resetCurrentPage,
      navKey,
    } = this.props;

    const { q, startDate, endDate, pkLicense: licenseId } = filters;
    const selectedCourse = this.getSelectedCourse(selectedCourseReduxId);

    // We are going to show the empty state if we are not loading the course history,
    // if the course history is an empty list and if there are not filters applied to the search
    const showCourseHistoryEmptyState =
      pageFetchStatus === 'loaded' && courseHistory.length === 0 && !(q || startDate || endDate);

    return (
      <div className="course-history-page">
        <div id="course-history-page">
          {showCourseHistoryEmptyState ? (
            <CourseHistoryEmptyState subscriptionType={subscriptionType} licenseId={licenseId} />
          ) : (
            <div className="table-wrapper">
              <CourseHistoryListToolbar navKey={navKey} />
              <div>
                {(pageFetchStatus === 'loading' || !pageFetchStatus) && <Loader color="info" />}
                <CourseHistoryList
                  {...{
                    fetchCourseHistoryDetail,
                    pageFetchStatus,
                    attachments: selectedCourse.attachments,
                    fetchCourseAttachments: this.fetchCourseAttachments,
                    onAttachmentIconClick: this.handleAttachmentIconClick,
                    onShowAttachments: this.handleShowAttachments,
                    onRatingChange: this.handleRatingChange,
                    onDeleteCourseClick: this.handleDeleteCourseMenuClick,
                    section: 'removed',
                  }}
                />
              </div>
              <CourseHistoryListPager resetCurrentPage={resetCurrentPage} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

CourseHistoryDeletedPage.propTypes = {
  authenticatedAccount: PropTypes.object,
  courseHistory: PropTypes.array,
  previousCourseHistory: PropTypes.array,
  pageFetchStatus: PropTypes.string,
  attachmentsFetchStatus: PropTypes.string,
  userId: PropTypes.number,
  subscriptionType: PropTypes.string,
  fetchCourseHistory: PropTypes.func,
  filters: PropTypes.object,
  deleteCourse: PropTypes.func,
  authToken: PropTypes.string,
  fetchCourseHistoryDetail: PropTypes.func,
  rateCourse: PropTypes.func,
  addCourseHistoryAttachment: PropTypes.func,
  deleteCourseHistoryAttachment: PropTypes.func,
  fetchCourseHistoryAttachments: PropTypes.func,
  getDeleteAttachmentStatus: PropTypes.func,
  resetCurrentPage: PropTypes.bool,
  navKey: PropTypes.string,
};

export default CourseHistoryDeletedPage;
