import React, { Component } from 'react';
import propTypes from 'prop-types';

export default class AssociationLabels extends Component {
  render() {
    const { labels } = this.props;
    return (
      <React.Fragment>
        {labels.map((label) => {
          return (
            <span className="course-association-label" key={label}>
              {label}
            </span>
          );
        })}
      </React.Fragment>
    );
  }
}

AssociationLabels.propTypes = {
  labels: propTypes.array,
};

AssociationLabels.defaultProps = {
  labels: [],
};
