export default function (globalState) {
  const state = globalState.nowCourses;

  const getInProgressEntityById = (id) => state.inProgressEntities[id] || {};

  const getInProgressEntitiesByIds = (ids) => ids.map(getInProgressEntityById);

  const getInProgressEntitiesByPage = (page) => {
    const currentPage = state.inProgressPages[page] || [];
    return getInProgressEntitiesByIds(currentPage);
  };

  const getInProgressEntitiesFetchStatus = () => state.inProgressFetchStatus;

  const getInProgressOptions = () => state.inProgressOptions;

  const getCompletedEntityById = (id) => state.completedEntities[id] || {};

  const getCompletedEntitiesByIds = (ids) => ids.map(getCompletedEntityById);

  const getCompletedEntitiesByPage = (page) => {
    const currentPage = state.completedPages[page] || [];
    return getCompletedEntitiesByIds(currentPage);
  };

  const getCompletedEntitiesFetchStatus = () => state.completedFetchStatus;

  const getCompletedOptions = () => state.completedOptions;

  const getInProgressCourses = () => state.inProgressCourses || [];

  return {
    getInProgressEntityById,
    getInProgressEntitiesByIds,
    getInProgressEntitiesByPage,
    getInProgressEntitiesFetchStatus,
    getInProgressOptions,
    getCompletedEntityById,
    getCompletedEntitiesByIds,
    getCompletedEntitiesByPage,
    getCompletedEntitiesFetchStatus,
    getCompletedOptions,
    getInProgressCourses,
  };
}
