import { connect } from 'react-redux';
import ShareCourses from '../ShareCourse';

import * as notificationsStore from '../../store/notifications';

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {
  addNotification: notificationsStore.actions.add,
})(ShareCourses);
