import axios from 'axios';
import config from '../config';
const { licenseesApiInternal } = config;

export const getFlag = ({ userId, key }) => {
  return axios.request({
    url: `${licenseesApiInternal}/users/${userId}/flags/${key}`,
    method: 'GET',
    headers: {
      Pragma: 'no-cache',
    },
  });
};

export const setFlag = ({ userId, key, value }) => {
  return axios.request({
    url: `${licenseesApiInternal}/users/${userId}/flags`,
    method: 'POST',
    data: {
      key,
      value,
    },
  });
};
