import { put, call } from 'redux-saga/effects';
import * as api from '../../../api/reportedExemptions';
import at from '../types';

export default function* fetchQuestions({ exemptionId, lpExemptionId }) {
  try {
    const { data } = yield call(api.fetchQuestions, exemptionId, lpExemptionId);
    yield put({
      type: at.FETCH_QUESTIONS_SUCCESS,
      exemptionId,
      lpExemptionId,
      questions: data.items,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    yield put({
      type: at.FETCH_QUESTIONS_FAILURE,
      exemptionId,
      lpExemptionId,
    });
  }
}
