import { put, call } from 'redux-saga/effects';

import * as api from '../../../api/users';
import at from '../types';

export function* fetch({ filters }) {
  try {
    const results = yield call(api.fetchLicensePeriods, filters);
    yield put({
      type: at.FETCH_LICENSE_PERIODS_SUCCESS,
      periods: results.data,
      filters,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    yield put({ type: at.FETCH_LICENSE_PERIODS_FAILURE, filters });
  }
}
