import { combineReducers } from 'redux';
import at from '../types';

export const cancelStatus = (state = '', action) => {
  switch (action.type) {
    case at.CANCEL_TRIAL_REQUEST: {
      return 'cancelling';
    }
    case at.CANCEL_TRIAL_FAILED: {
      return 'failed';
    }
    case at.CANCEL_TRIAL_SUCCESS: {
      return 'cancelled';
    }
    default:
      return state;
  }
};

export const cancelTrialInfo = (state = {}, action) => {
  switch (action.type) {
    case at.CANCEL_TRIAL_SUCCESS: {
      return action.data;
    }
    default:
      return state;
  }
};

export const subscriptionPrices = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUBSCRIPTION_PRICES_SUCCESS:
      return action.data;
    default:
      return state;
  }
};

export const subscriptionPlans = (state = [], action) => {
  switch (action.type) {
    case at.FETCH_SUBSCRIPTION_PLANS_SUCCESS:
      return action.data.plans;
    default:
      return state;
  }
};

export const subscriptionPlansStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_SUBSCRIPTION_PLANS_REQUEST:
      return 'loading';
    case at.FETCH_SUBSCRIPTION_PLANS_SUCCESS:
      return 'loaded';
    case at.FETCH_SUBSCRIPTION_PLANS_FAILURE:
      return 'failed';
    default:
      return state;
  }
};

export const subsPricesStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_SUBSCRIPTION_PRICES_REQUEST:
      return 'loading';
    case at.FETCH_SUBSCRIPTION_PRICES_SUCCESS:
      return 'loaded';
    case at.FETCH_SUBSCRIPTION_PRICES_FAILURE:
      return 'failed';
    default:
      return state;
  }
};

export default combineReducers({
  cancelStatus,
  cancelTrialInfo,
  subscriptionPrices,
  subsPricesStatus,
  subscriptionPlans,
  subscriptionPlansStatus,
});
