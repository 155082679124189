import { put, call, select } from 'redux-saga/effects';
import * as api from '../../../api/cart';
import at from '../types';

export function* saveCartItem({ courses, subscription }) {
  const state = yield select();

  const {
    authentication: { licenseeAccount },
  } = state;
  const { user } = licenseeAccount;
  yield put({ type: at.SAVE_CART_ITEM_REQUEST });

  try {
    const {
      data: { courses: cart, sessionUuid, totalItems },
    } = yield call(api.saveCartItem, { userId: user.id, data: { courses, subscription } });
    yield put({
      type: at.SAVE_CART_ITEM_SUCCESS,
      userId: user.id,
      entity: cart,
      sessionUuid,
      totalItems,
    });
  } catch (error) {
    yield put({
      type: at.SAVE_CART_ITEM_FAILURE,
      userId: user.id,
      error: error.message,
    });
  }
}
