import React from 'react';
import PropTypes from 'prop-types';
import CourseRecommendationCard from '../CourseRecommendationCard';
import './CourseRecommendationSection.css';
import BlurredImage from '../BlurredImage';
import { Link } from 'react-router-dom';
import { saveEvent } from '../../api/events';
import image from './CourseRecommendationCard.jpg';
import config from '../../config';
const { REACT_APP_DISPLAY_COURSE_RECOMMENDED_UPGRADE_BANNER = false } = process.env;

const {
  AnalyticsTrackingEntities: { actions },
} = config;

class CourseRecommendationSection extends React.Component {
  componentDidMount() {
    const { subscriptionType, fetchCoursesRecommended, filters = {}, fetchStatus } = this.props;

    if (
      subscriptionType !== 'BAS' &&
      fetchStatus !== 'loaded' &&
      Array.isArray(filters.subjectsAreas) &&
      filters.subjectsAreas.length > 0
    ) {
      fetchCoursesRecommended(filters);
    }
  }

  handleUpgradeClick = (e) => {
    e.preventDefault();
    const { user = {}, history = {}, handleTrackedClick } = this.props;
    const statisticCode = 'UPGRADE';
    const type = 'COURSE_RECOMMENDATION';
    saveEvent(user.id, statisticCode, type);
    handleTrackedClick(actions.sectionTranscriptWidgetUpgrade);
    history.push('/upgrade');
  };

  render() {
    const {
      fetchStatus,
      subscriptionType,
      emptyRecommendations,
      pkLicense,
      cycleId,
      savedOfferingsList,
      handleSaveOffering,
      savingStatuses,
      showFavoriteIcon,
      shouldShowProPlusBadge,
    } = this.props;

    const showRecommendations =
      (!emptyRecommendations && subscriptionType !== 'BAS' && fetchStatus === 'loaded') || subscriptionType === 'BAS';

    if (!showRecommendations) {
      return <React.Fragment />;
    }

    return (
      <div className="course-recommendation desktop-only">
        {this.props.subscriptionType === 'BAS' ? (
          REACT_APP_DISPLAY_COURSE_RECOMMENDED_UPGRADE_BANNER === 'true' && (
            <React.Fragment>
              <h4>
                <b>See exactly what courses to take with a Professional account</b>
              </h4>
              <h5>Get compliant with personalized CE recommendations.</h5>
              <BlurredImage
                imgSrc={image}
                actionText={`${this.props.isFTOAvailable ? 'Activate my free trial' : 'Renew Subscription'}`}
                onActionClick={this.handleUpgradeClick}
              />
            </React.Fragment>
          )
        ) : (
          <div>
            <h4>
              Here&#39;s a list of courses to help <b>finish your {this.props.professionName} requirements</b>:
            </h4>
            <h5>
              Personalized recommendations based on your official CE transcript.{' '}
              <Link to={`/license/${pkLicense}/transcript?cycle=${cycleId}`}>View Transcript</Link>
            </h5>
            <CourseRecommendationCard
              savedOfferingsList={savedOfferingsList}
              handleSaveOffering={handleSaveOffering}
              savingStatuses={savingStatuses}
              showFavoriteIcon={showFavoriteIcon}
              shouldShowProPlusBadge={shouldShowProPlusBadge}
            />
          </div>
        )}
      </div>
    );
  }
}

CourseRecommendationSection.propTypes = {
  professionName: PropTypes.string,
  subscriptionType: PropTypes.string,
  isFTOAvailable: PropTypes.bool,
  user: PropTypes.object,
  filters: PropTypes.object,
  history: PropTypes.object,
  onUpgradeClick: PropTypes.func,
  fetchStatus: PropTypes.string,
  fetchCoursesRecommended: PropTypes.func,
  emptyRecommendations: PropTypes.bool,
  pkLicense: PropTypes.string,
  cycleId: PropTypes.string,
  handleTrackedClick: PropTypes.func,
  savedOfferingsList: PropTypes.array,
  handleSaveOffering: PropTypes.func,
  savingStatuses: PropTypes.object,
  showFavoriteIcon: PropTypes.bool,
  shouldShowProPlusBadge: PropTypes.bool,
};

export default CourseRecommendationSection;
