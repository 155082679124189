import { schema } from 'normalizr';

export const paymentHistory = new schema.Entity(
  'items',
  {},
  {
    idAttribute: (value) => value.feeId,
    processStrategy: (value) => ({
      ...value,
      reduxId: (value) => value.feeId,
    }),
  }
);

export const paymentHistoryArray = new schema.Array(paymentHistory);
