import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { actions as transcriptActions, selectors as transcriptSelectors } from '../store/transcript';
import * as authenticationStore from '../store/authentication';
import queryString from 'query-string';
import TranscriptPage from '../components/TranscriptPage';

function mapStateToProps(state, ownProps) {
  const { match, location } = ownProps;
  const query = queryString.parse(location.search);
  const authenticationSelectors = authenticationStore.selectors(state);
  const transcript = transcriptSelectors(state);
  const licenseeAccount = authenticationSelectors.getLicenseeAccount();
  const authenticatedAccount = authenticationSelectors.getAuthenticatedAccount();
  const loggedOnBehalf = authenticatedAccount.role === 'helpdesk';
  const { user = {}, subscriptions = [], licenses = [] } = licenseeAccount;

  let subscriptionType = '';

  if (subscriptions.length > 0) {
    subscriptionType = subscriptions[0].type;
  }

  const { pkLicense } = match.params;
  const { cycle: cycleId = 0 } = query;

  const currentLicense = licenses.find((l) => l.id === parseInt(pkLicense, 10));
  const currentId = `${pkLicense}-${cycleId}`;
  let currentTranscript = transcript.getEntityById(currentId);
  let currentStatus = transcript.getStatusById(currentId);
  if (!currentTranscript) {
    const defaultId = `${pkLicense}-${0}`;
    const defaultTranscript = transcript.getEntityById(defaultId);
    const defaultStatus = transcript.getStatusById(defaultId);
    if (defaultTranscript && defaultTranscript.transcriptDetail.licensePeriod.id === cycleId) {
      currentTranscript = defaultTranscript;
      currentStatus = defaultStatus;
    }
  }

  return {
    transcript: currentTranscript,
    transcriptStatus: currentStatus,
    user,
    subscriptionType,
    loggedOnBehalf,
    currentLicense,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchTranscript: transcriptActions.fetch,
  })(TranscriptPage)
);
