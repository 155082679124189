export default function (globalState) {
  const state = globalState.transcript;

  const getEntityById = (id) => state.data[id] || {};
  const getStatusById = (id) => state.fetchStatus[id] || 'notLoaded';
  const getBoardLogo = (boardId) => {
    const {
      data: { boardLogos = {} },
    } = state;
    return boardLogos[boardId];
  };
  const getBoardLogos = () => {
    const {
      data: { boardLogos = {} },
    } = state;
    return boardLogos;
  };
  return {
    getEntityById,
    getStatusById,
    getBoardLogo,
    getBoardLogos,
  };
}
