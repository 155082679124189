import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import CourseDetailLoader from './components/CourseDetailLoader';
import CourseDetailHeader from './components/CourseDetailHeader';
import CourseRegisterInformation from './components/CourseRegisterInformation';
import CourseDetailInfo from './components/CourseDetailInfo';
import CourseDetailSubjectAreas from '../common/CourseDetailSubjectAreas';
import CourseDetailInfoDescription from './components/CourseDetailInfoDescription';
import CourseDetailDisclosureStatement from './components/CourseDetailDisclosureStatement';
import CourseDetailEducator from './components/CourseDetailEducator';
import CourseLearningObjectives from './components/CourseLearningObjectives';
import CourseRegisterLink from './components/CourseRegisterLink';
import isFunction from 'lodash/isFunction';
import './CourseDetail.css';

import { getSlugFromName, handleDisplayAdaComponent } from '../../utils';
import CourseReviews from './components/CourseReviews';

class CourseDetail extends React.Component {
  constructor(props) {
    super(props);
    this.handleResize = this.handleResize.bind(this);
  }
  componentDidMount() {
    const now = new Date();
    this.today = `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`;
    this.fetchAdditionalOfferings(this.props);

    // Management of the ADA component
    handleDisplayAdaComponent(false);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.courseId !== this.props.courseId) {
      this.fetchAdditionalOfferings(nextProps);
    }
  }

  fetchAdditionalOfferings = (props) => {
    const { fetchCourses, coursesFilters, courseId } = props;
    const filters = { ...coursesFilters, courseId, startDate: this.today };
    delete filters.term;
    if (isFunction(fetchCourses)) {
      fetchCourses({ ...filters });
    }
  };

  handleViewProviderDetailClick = ({ id, name }) => {
    const { isOutOfCourseSearch = false, professionId, stateCode } = this.props;
    if (isOutOfCourseSearch) {
      const { userId, loggedOnBehalf, getCourseSearchUrl } = this.props;
      const slug = getSlugFromName(name);
      const url = `/providers/${id}-${slug}`;
      const queryParams = {};
      if (loggedOnBehalf) {
        queryParams.userId = userId;
      }
      if (stateCode) {
        queryParams.state = stateCode;
      }
      if (professionId) {
        queryParams.profession = professionId;
      }
      queryParams.redirect_to = url;
      const redirectUrl = getCourseSearchUrl(queryParams);
      window.location.href = redirectUrl;
      return;
    }
    const { history, coursesFilters } = this.props;
    coursesFilters.providerId = id;
    coursesFilters.coursePageIndex = 1;
    coursesFilters.sortField = 'RELEVANCE';
    if (stateCode) {
      coursesFilters.state = stateCode;
    }
    if (professionId) {
      coursesFilters.profession = professionId;
    }
    delete coursesFilters.term;
    delete coursesFilters.courseType;
    delete coursesFilters.deliveryType;
    delete coursesFilters.subjectArea;
    delete coursesFilters.startDate;
    delete coursesFilters.locationTerm;
    delete coursesFilters.courseId;
    delete coursesFilters.providerId;
    const queryFilters = queryString.stringify(coursesFilters);
    const slug = getSlugFromName(name);
    history.push(`/providers/${id}-${slug}?${queryFilters}`);
  };

  handleFavoriteClick = (isCourseSaved) => {
    const { professionId, course, userId, handleSaveOffering } = this.props;
    const { id: offeringId, course: courseBase } = course;
    const { id: courseId, name: courseName, provider } = courseBase;
    const { id: providerId, name: providerName } = provider;

    handleSaveOffering({
      userId,
      courseId,
      courseName,
      offeringId,
      professionId,
      providerId,
      providerName,
      isCourseSaved,
    });
  };

  handleAdditionalOfferingsFavoriteClick = (data) => {
    const { onFavoriteClick } = this.props;
    if (isFunction(onFavoriteClick)) {
      onFavoriteClick(data);
    }
  };

  handleResize = () => {
    const displayAdaComponent = window.innerWidth >= 768;
    handleDisplayAdaComponent(displayAdaComponent);
  };

  render() {
    const {
      isFeatured,
      course,
      professionIds,
      professionInfo,
      stateFetchStatus,
      courseDetailFetchStatus,
      favorite,
      showFavoriteIcon,
      showShareIcon,
      offeringId,
      licenseeId,
      isFromSavedCourses,
      isOutOfCourseSearch,
      categoryGA,
      userId,
      courseId,
      authenticationStatus,
      professionId,
      seeCheckoutPage,
      customerId,
      addNotification,
      location,
      saveCartItem,
      coursesInCart,
      saveCartItemStatus,
      clearStatusCart,
      loggedOnBehalf,
      savedOfferingsList,
      totalItemsInCart,
      shouldShowProPlusBadge,
    } = this.props;

    const { specialties: professionSpecialties = [] } = professionInfo;

    const showTotalHours =
      stateFetchStatus === 'loaded' && !(professionSpecialties && professionSpecialties.length > 0);
    const offering = course;

    if (courseDetailFetchStatus === '' || courseDetailFetchStatus === 'loading') {
      return <CourseDetailLoader />;
    }

    if (courseDetailFetchStatus === 'failed') {
      return <h2 style={{ padding: '35px 20px 0 20px' }}>Error trying to load the course detail: network error</h2>;
    }

    if (!offering) {
      return (
        <h2 style={{ padding: '35px 20px 0 20px' }}>
          {`We're sorry, there was an error trying to load the course detail. Please try again later.`}
        </h2>
      );
    }

    const courseDetail = offering.course || {};

    const { rating, ratingCount } = courseDetail;

    const { course: courseBase = {} } = course;

    return (
      <React.Fragment>
        <div className="course-detail-info-wrapper">
          <CourseDetailHeader
            courseDetail={courseDetail}
            favorite={favorite}
            isFeatured={isFeatured}
            offeringId={offeringId || offering.id}
            onFavoriteClick={this.handleFavoriteClick}
            onViewProviderDetail={this.handleViewProviderDetailClick}
            showFavoriteIcon={showFavoriteIcon}
            showShareIcon={showShareIcon}
            savedOfferingsList={savedOfferingsList}
            handleSaveOffering={this.handleFavoriteClick}
            courseId={courseBase.id}
            shouldShowProPlusBadge={shouldShowProPlusBadge}
          />

          <div className="course-detail-floating-section">
            <CourseRegisterInformation
              isFeatured={isFeatured}
              courseDetail={courseDetail}
              onFavoriteClick={this.handleFavoriteClick}
              favorite={favorite}
              price={offering.price}
              isFree={offering.isFree}
              showTotalHours={showTotalHours}
              offeringId={offeringId || offering.id}
              licenseeId={licenseeId}
              isFromSavedCourses={isFromSavedCourses}
              professionIds={professionIds}
              isOutOfCourseSearch={isOutOfCourseSearch}
              categoryGA={categoryGA}
              userId={userId}
              courseId={courseId}
              authenticationStatus={authenticationStatus}
              seeCheckoutPage={seeCheckoutPage}
              customerId={customerId}
              location={location}
              saveCartItem={saveCartItem}
              coursesInCart={coursesInCart}
              saveCartItemStatus={saveCartItemStatus}
              clearStatusCart={clearStatusCart}
              loggedOnBehalf={loggedOnBehalf}
              totalItemsInCart={totalItemsInCart}
              shouldShowProPlusBadge={shouldShowProPlusBadge}
            />
            <CourseDetailInfo
              isFeatured={isFeatured}
              course={course}
              offeringId={offeringId || offering.id}
              licenseeId={licenseeId}
              isOutOfCourseSearch={isOutOfCourseSearch}
              categoryGA={categoryGA}
            />
            <CourseDetailEducator
              courseDetail={courseDetail}
              onViewProviderDetail={this.handleViewProviderDetailClick}
            />
            <CourseRegisterLink
              isFeatured={isFeatured}
              course={courseDetail}
              offeringId={offeringId}
              isOutOfCourseSearch={isOutOfCourseSearch}
              categoryGA={categoryGA}
            />
          </div>

          <CourseLearningObjectives learningObjectives={courseDetail.learningObjectives} />

          <CourseDetailInfoDescription description={courseDetail.description} />

          <CourseDetailSubjectAreas course={course} isCourseDetailModal={true} professionId={professionId} />

          <CourseReviews rating={rating} ratingCount={ratingCount} offeringId={offeringId || offering.id} />
          <CourseDetailDisclosureStatement disclosureStatement={courseDetail.additionalDescription} />
        </div>
        <CourseRegisterInformation
          isFeatured={isFeatured}
          courseDetail={courseDetail}
          onFavoriteClick={this.handleFavoriteClick}
          favorite={favorite}
          price={offering.price}
          isFree={offering.isFree}
          showTotalHours={showTotalHours}
          offeringId={offeringId || offering.id}
          licenseeId={licenseeId}
          isFromSavedCourses={isFromSavedCourses}
          professionIds={professionIds}
          isOutOfCourseSearch={isOutOfCourseSearch}
          categoryGA={categoryGA}
          userId={userId}
          courseId={courseId}
          authenticationStatus={authenticationStatus}
          seeCheckoutPage={seeCheckoutPage}
          customerId={customerId}
          isFloating={true}
          addNotification={addNotification}
          location={location}
          saveCartItem={saveCartItem}
          coursesInCart={coursesInCart}
          saveCartItemStatus={saveCartItemStatus}
          clearStatusCart={clearStatusCart}
          loggedOnBehalf={loggedOnBehalf}
          totalItemsInCart={totalItemsInCart}
          shouldShowProPlusBadge={shouldShowProPlusBadge}
        />
      </React.Fragment>
    );
  }
}

CourseDetail.propTypes = {
  courseId: PropTypes.number,
  isFeatured: PropTypes.bool,
  onCourseDetailClose: PropTypes.func,
  course: PropTypes.object,
  professionIds: PropTypes.array,
  professionInfo: PropTypes.object,
  stateFetchStatus: PropTypes.string,
  courseDetailFetchStatus: PropTypes.string,
  history: PropTypes.object,
  coursesFilters: PropTypes.object,
  favorite: PropTypes.bool,
  onFavoriteClick: PropTypes.func,
  showFavoriteIcon: PropTypes.bool,
  showShareIcon: PropTypes.bool,
  fetchCourses: PropTypes.func,
  offeringId: PropTypes.number,
  licenseeId: PropTypes.number,
  isFromSavedCourses: PropTypes.bool,
  isOutOfCourseSearch: PropTypes.bool,
  loggedOnBehalf: PropTypes.bool,
  getCourseSearchUrl: PropTypes.func,
  categoryGA: PropTypes.string,
  professionId: PropTypes.number,
  stateCode: PropTypes.string,
  userId: PropTypes.number,
  authenticationStatus: PropTypes.string,
  onCourseClick: PropTypes.func,
  seeCheckoutPage: PropTypes.bool,
  customerId: PropTypes.string,
  addNotification: PropTypes.func,
  location: PropTypes.object,
  saveCartItem: PropTypes.func,
  coursesInCart: PropTypes.array,
  saveCartItemStatus: PropTypes.string,
  clearStatusCart: PropTypes.func,
  savedOfferingsList: PropTypes.array,
  handleSaveOffering: PropTypes.func,
  totalItemsInCart: PropTypes.number,
  shouldShowProPlusBadge: PropTypes.bool,
};

CourseDetail.defaultProps = {
  onCourseDetailClose: () => {},
  course: {},
  professionInfo: {},
  showAdditionalOfferings: false,
  isFromSavedCourses: false,
  onCourseClick: () => {},
  seeCheckoutPage: false,
  addNotification: () => {},
  saveCartItem: () => {},
  coursesInCart: [],
  saveCartItemStatus: () => {},
  clearStatusCart: () => {},
  shouldShowProPlusBadge: false,
};

export default CourseDetail;
