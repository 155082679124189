import { combineReducers } from 'redux';
import at from '../types';
import config from '../../../config';

const initialState = config.discoverInitialStates;

export const licenseSelected = (state = initialState.licenseToggleId, action) => {
  switch (action.type) {
    case at.GET_LICENSE: {
      return state;
    }
    case at.SET_LICENSE: {
      return action.licenseId;
    }
    default:
      return state;
  }
};

export const spotlightSection = (state = initialState.spotlight, action) => {
  switch (action.type) {
    case at.SPOTLIGHT_INFO_FETCH: {
      return {
        ...state,
        spotlightStatus: 'LOADING',
      };
    }
    case at.SPOTLIGHT_INFO_SUCCESS: {
      return {
        ...state,
        spotlightData: action.spotlightData,
        spotlightStatus: 'LOADED',
      };
    }
    case at.SPOTLIGHT_INFO_FAILURE: {
      return {
        ...state,
        spotlightStatus: 'FAILED',
      };
    }
    case at.SPOTLIGHT_INFO_DENIED: {
      return {
        ...state,
        spotlightStatus: 'LOADED',
        spotlightData: [],
      };
    }
    default:
      return state;
  }
};

export const popularCECourses = (state = initialState.popularCE, action) => {
  switch (action.type) {
    case at.POPULAR_COURSES_FETCH: {
      return {
        ...state,
        popularCEStatus: 'LOADING',
      };
    }
    case at.POPULAR_COURSES_SUCCESS: {
      return {
        ...state,
        popularCECourses: action.popularCECourses,
        popularCEStatus: 'LOADED',
      };
    }
    case at.POPULAR_COURSES_FAILURE: {
      return {
        ...state,
        popularCEStatus: 'FAILED',
      };
    }
    case at.POPULAR_COURSES_DENIED: {
      return {
        ...state,
        popularCEStatus: 'LOADED',
        popularCECourses: [],
      };
    }
    default:
      return state;
  }
};

export const keepLearningCECourses = (state = initialState.keepLearningCE, action) => {
  switch (action.type) {
    case at.KEEP_LEARNING_COURSES_FETCH: {
      return {
        ...state,
        keepLearningCEStatus: 'LOADING',
      };
    }
    case at.KEEP_LEARNING_COURSES_SUCCESS: {
      return {
        ...state,
        keepLearningCECourses: action.keepLearningCECourses,
        keepLearningCEStatus: 'LOADED',
      };
    }
    case at.KEEP_LEARNING_COURSES_FAILURE: {
      return {
        ...state,
        keepLearningCEStatus: 'FAILED',
      };
    }
    case at.KEEP_LEARNING_COURSES_DENIED: {
      return {
        ...state,
        keepLearningCEStatus: 'LOADED',
        keepLearningCECourses: [],
      };
    }
    default:
      return state;
  }
};

export const recentlyAddedCECourses = (state = initialState.recentlyAddedCE, action) => {
  switch (action.type) {
    case at.RECENTLY_ADDED_COURSES_FETCH: {
      return {
        ...state,
        recentlyAddedCEStatus: 'LOADING',
      };
    }
    case at.RECENTLY_ADDED_COURSES_SUCCESS: {
      return {
        ...state,
        recentlyAddedCECourses: action.recentlyAddedCECourses,
        recentlyAddedCEStatus: 'LOADED',
      };
    }
    case at.RECENTLY_ADDED_COURSES_FAILURE: {
      return {
        ...state,
        recentlyAddedCEStatus: 'FAILED',
      };
    }
    case at.RECENTLY_ADDED_COURSES_DENIED: {
      return {
        ...state,
        recentlyAddedCEStatus: 'LOADED',
        recentlyAddedCECourses: [],
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  licenseSelected,
  spotlightSection,
  popularCECourses,
  keepLearningCECourses,
  recentlyAddedCECourses,
});
