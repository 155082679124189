import axios from 'axios';
import config from '../config';

export function fetchCoursesRecommended(params) {
  return axios.request({
    method: 'GET',
    url: `${config.licenseesApiInternal}/recommended`,
    params,
  });
}
