import axios from 'axios';
import config from '../config';

const { licenseesApiInternal } = config;

export const fetchLicensePeriods = ({ pkLicense }) => {
  return axios.request({
    url: `${licenseesApiInternal}/licenses/${pkLicense}/periods`,
    method: 'GET',
  });
};

export const fetchUserProfile = (userId, pkLicense) => {
  return axios.request({
    url: `${licenseesApiInternal}/users/${userId}/profile?pkLicense=${pkLicense}`,
    method: 'GET',
  });
};

export const updateProfile = (userId, userProfile) => {
  return axios.request({
    url: `${licenseesApiInternal}/users/${userId}/profile`,
    method: 'PUT',
    data: userProfile,
  });
};

export const updateUsername = (userId, username) => {
  return axios.request({
    url: `${licenseesApiInternal}/users/${userId}/username`,
    method: 'PUT',
    data: {
      username,
    },
  });
};

export const updatePassword = (userId, currentPassword, newPassword, confirmPassword, token) => {
  return axios.request({
    url: `${licenseesApiInternal}/change_password`,
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      currentPassword,
      newPassword,
      confirmPassword,
    },
  });
};

export const fetchEmailSettings = (userId) => {
  return axios.request({
    url: `${licenseesApiInternal}/users/${userId}/email-settings`,
    method: 'GET',
  });
};

export const updateEmailSettings = (userId, emailSettings) => {
  return axios.request({
    url: `${licenseesApiInternal}/users/${userId}/email-settings`,
    method: 'POST',
    data: emailSettings,
  });
};

export const fetchConciergeManager = (userId) => {
  return axios.request({
    url: `${licenseesApiInternal}/users/${userId}/concierge-manager`,
    method: 'GET',
  });
};

export const fetchCommunicationSettings = (userId) => {
  return axios.request({
    url: `${licenseesApiInternal}/users/${userId}/communication-settings`,
    method: 'GET',
  });
};

export const updateCommunicationSettings = (userId, communicationSettings, userLogonId) => {
  return axios.request({
    url: `${licenseesApiInternal}/users/${userId}/communication-settings`,
    method: 'PUT',
    data: {
      ...communicationSettings,
      userLogonId,
    },
  });
};
