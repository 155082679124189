import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SettingsHeaderLoading from './SettingsHeaderLoading';
import * as authenticationStore from '../../store/authentication';

function mapStateToProps(state) {
  const authenticationSelectors = authenticationStore.selectors(state);
  const licenseeAccount = authenticationSelectors.getLicenseeAccount();
  const { licenses } = licenseeAccount;
  const licenseWithBrandColor = licenses.find((l) => l.profession.color);
  const professionBrandColor = licenseWithBrandColor ? licenseWithBrandColor.profession.color : '';

  return {
    professionBrandColor,
  };
}

export default withRouter(connect(mapStateToProps, {})(SettingsHeaderLoading));
