import at from './types';

export const fetch = (params, meta) => ({
  type: at.FETCH_REQUEST,
  params,
  meta,
});

export const setFlag = (params) => ({
  type: at.FETCH_REQUEST_FLAG,
  params,
});

export const submitTask = (params) => ({
  type: at.SUBMIT_REQUEST,
  params,
});
