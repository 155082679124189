import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { refineQuery } from '../utils';

import * as courseHistory from '../store/courseHistory';

import CourseHistoryListToolbar from '../components/CourseHistoryListToolbar';

function mapStateToProps(state, ownProps) {
  const { match, location } = ownProps;
  const {
    authentication: { licenseeAccount, authenticatedAccount },
    courseHistory: { requestDownloadPdfStatus, getRequestStatus, browserDownload, timerDownload },
  } = state;
  const params = refineQuery(queryString.parse(location.search));
  if (!params.page) {
    params.page = 1;
  }
  if (!params.size) {
    params.size = 10;
  }
  params.pkLicense = match.params.pkLicense;

  const pkLicense = parseInt(params.pkLicense, 10);
  const licenses = licenseeAccount.licenses;
  let licenseSubscriber;
  for (const key in licenses) {
    if (licenses[key].id === pkLicense) {
      licenseSubscriber = licenses[key];
      break;
    }
  }

  const subscriberCredentials = {
    userId: licenseeAccount.user.id,
    pkLicense: pkLicense,
    name: `${licenseeAccount.user.firstName} ${licenseeAccount.user.lastName}`,
    professionName: licenseSubscriber.profession.name,
    licenseNumber: licenseSubscriber.number,
    licenseIssueDate: null,
    licenseStatus: null,
    reportDate: moment().format('LLLL'),
    subjectArea: params.subjectArea,
    range: {
      startDate: params.startDate,
      endDate: params.endDate,
    },
  };
  let subjectAreaList = [];
  const { profession: { id: professionId } = {} } = licenseSubscriber;
  if (professionId) {
    const courseHistorySelectors = courseHistory.selectors(state);
    subjectAreaList = courseHistorySelectors.getSubjectAreasByProfession(professionId);
  }

  const { subscriptions = [] } = licenseeAccount;
  let subscriptionType = 'BAS';
  if (subscriptions.length > 0) {
    subscriptionType = subscriptions[0].type;
  }

  const loggedOnBehalf = authenticatedAccount.role !== 'licensee';

  return {
    params,
    subscriberCredentials,
    requestDownloadPdfStatus,
    getRequestStatus,
    browserDownload,
    timerDownload,
    subjectAreaList,
    subscriptionType,
    loggedOnBehalf,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchCourseHistory: courseHistory.actions.fetch,
    downloadPdfCall: courseHistory.actions.requestDownloadPdf,
    getPdfStatusRequest: courseHistory.actions.getRequestStatus,
    getBrowserDownload: courseHistory.actions.browserDownload,
    updatePdfStatus: courseHistory.actions.updatePdfStatus,
    resetRequestStatus: courseHistory.actions.resetRequestStatus,
    setTimerDownload: courseHistory.actions.setTimerDownload,
    resetTimerDownload: courseHistory.actions.resetTimerDownload,
    clearTimerDownload: courseHistory.actions.clearTimerDownload,
    resetDownloadPdf: courseHistory.actions.resetDownloadPdf,
  })(CourseHistoryListToolbar)
);
