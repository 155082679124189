import { combineReducers } from 'redux';
import has from 'lodash/has';
import at from '../types';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return { ...state, ...action.entities.items };
    }
    case at.FETCH_DETAIL_SUCCESS: {
      const { reduxId, data } = action;
      delete data.attachments;
      data.detailLicenseNumber = data.licenseNumber;
      delete data.licenseNumber;
      delete data.pkLicense;
      delete data.hours;
      const entity = {
        ...state[reduxId],
        ...data,
      };
      if (has(entity, 'course.name')) {
        const name = entity.course.name;
        entity.course.name = name.replace(/&quot;/g, '"');
      }
      return { ...state, [reduxId]: entity };
    }
    case at.FETCH_ATTACHMENTS_SUCCESS: {
      const { reduxId, data } = action;
      const entity = { ...state[reduxId], attachments: data };
      return { ...state, [reduxId]: entity };
    }
    case at.ADD_ATTACHMENT_SUCCESS: {
      const { reduxId, attachment } = action;
      const entity = { ...state[reduxId] };
      entity.attachments.push(attachment);
      return { ...state, [reduxId]: entity };
    }
    case at.DELETE_ATTACHMENT_SUCCESS: {
      const { reduxId, attachmentId } = action;
      const entity = { ...state[reduxId] };
      entity.attachments = entity.attachments.filter((a) => a.token !== attachmentId);
      if (entity.attachments.length === 0) {
        entity.hasAttachments = false;
      }
      return { ...state, [reduxId]: { ...entity } };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    case at.RATE_COURSE_SUCCESS: {
      const { reduxId, data } = action;
      const { rating, comment } = data;
      const entity = { ...state[reduxId], rating, ratingComment: comment };
      return { ...state, [reduxId]: entity };
    }
    case at.DELETE_COURSE_SUCCESS: {
      const { reduxId } = action;
      const newState = { ...state };
      delete newState[reduxId];
      return newState;
    }
    default:
      return state;
  }
};

export const entitiesFetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_DETAIL_REQUEST: {
      const { reduxId } = action;
      return { ...state, [reduxId]: 'loading' };
    }
    case at.FETCH_DETAIL_SUCCESS: {
      const { reduxId } = action;
      return { ...state, [reduxId]: 'loaded' };
    }
    case at.FETCH_DETAIL_FAILURE: {
      const { reduxId } = action;
      return { ...state, [reduxId]: 'failed' };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const attachmentsFetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_ATTACHMENTS_REQUEST: {
      const { reduxId } = action;
      return { ...state, [reduxId]: 'loading' };
    }
    case at.FETCH_ATTACHMENTS_SUCCESS: {
      const { reduxId } = action;
      return { ...state, [reduxId]: 'loaded' };
    }
    case at.FETCH_ATTACHMENTS_FAILURE: {
      const { reduxId } = action;
      return { ...state, [reduxId]: 'failed' };
    }
    case at.FETCH_SUCCESS: {
      return {};
    }
    default:
      return state;
  }
};

export const attachmentsDeleteStatus = (state = {}, action) => {
  switch (action.type) {
    case at.DELETE_ATTACHMENT_REQUEST: {
      const { attachmentId } = action;
      return { ...state, [attachmentId]: 'deleting' };
    }
    case at.DELETE_ATTACHMENT_SUCCESS: {
      const { attachmentId } = action;
      return { ...state, [attachmentId]: 'deleted' };
    }
    case at.DELETE_ATTACHMENT_FAILURE: {
      const { attachmentId } = action;
      return { ...state, [attachmentId]: 'failed' };
    }
    case at.FETCH_SUCCESS: {
      return {};
    }
    default:
      return state;
  }
};

export const pages = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { filters, result } = action;
      return { ...state, [filters.page]: result };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    case at.DELETE_COURSE_SUCCESS: {
      const { reduxId, page } = action;
      const pageData = state[page].filter((i) => {
        return i !== reduxId;
      });
      return { ...state, [page]: pageData };
    }
    default:
      return state;
  }
};

export const pagesFetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { filters } = action;
      return { ...state, [filters.page]: 'loaded' };
    }
    case at.FETCH_FAILURE: {
      const { filters } = action;
      return { ...state, [filters.page]: 'failed' };
    }
    case at.SET_PAGE_FETCH_STATUS: {
      const { page, status } = action;
      return { ...state, [page]: status };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const totalCount = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { options } = action;
      return options.totalRecords;
    }
    case at.CLEAR_ENTITIES: {
      return 0;
    }
    default:
      return state;
  }
};

export const deletedPages = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { filters, result } = action;
      return { ...state, [filters.page]: result };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    case at.DELETE_COURSE_SUCCESS: {
      const { reduxId, page } = action;
      const pageData = state[page].filter((i) => {
        return i !== reduxId;
      });
      return { ...state, [page]: pageData };
    }
    default:
      return state;
  }
};

export const deletedPagesFetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { filters } = action;
      return { ...state, [filters.page]: 'loaded' };
    }
    case at.FETCH_FAILURE: {
      const { filters } = action;
      return { ...state, [filters.page]: 'failed' };
    }
    case at.SET_PAGE_FETCH_STATUS: {
      const { page, status } = action;
      return { ...state, [page]: status };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const deletedTotalCount = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { options } = action;
      return options.totalRecords;
    }
    case at.CLEAR_ENTITIES: {
      return 0;
    }
    default:
      return state;
  }
};

export const previousPage = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      if (action.filters.oldPage) {
        return action.filters.oldPage;
      }
      return action.filters.page;
    }
    default:
      return state;
  }
};

export const requestDownloadPdfStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_REQUEST_PDF: {
      return { ...state, ...action.credentials };
    }
    case at.FETCH_REQUEST_PDF_SUCCESS: {
      return { ...state, ...action.data };
    }
    case at.FETCH_REQUEST_PDF_FAILURE: {
      return { ...state };
    }
    case at.FETCH_REQUEST_PDF_STATUS_RESET: {
      return {};
    }
    default:
      return state;
  }
};

export const getRequestStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_REQUEST_PDF_STATUS_SUCCESS: {
      return { ...state, ...action.data };
    }
    case at.FETCH_REQUEST_PDF_STATUS_FAILURE: {
      return { ...state };
    }
    case at.UPDATE_PDF_STATUS_SUCCESS: {
      return { ...state, ...action.data };
    }
    case at.RESET_REQUEST_PDF_STATUS: {
      return {};
    }
    default:
      return state;
  }
};

export const browserDownload = (state = {}, action) => {
  switch (action.type) {
    case at.DOWNLOAD_PDF_BROWSER_SUCCESS: {
      return { ...state, ...action.data };
    }
    case at.DOWNLOAD_PDF_BROWSER_FAILURE: {
      return { ...state };
    }
    default:
      return state;
  }
};

export const updatePdfStatus = (state = {}, action) => {
  switch (action.type) {
    case at.UPDATE_PDF_STATUS_FAILURE: {
      return { ...state };
    }
    default:
      return state;
  }
};

export const timerDownload = (state = null, action) => {
  switch (action.type) {
    case at.COURSE_HISTORY_SET_TIMER_DOWNLOAD: {
      return action.timer;
    }
    case at.COURSE_HISTORY_RESET_TIMER_DOWNLOAD: {
      return null;
    }
    case at.COURSE_HISTORY_CLEAR_TIMER_DOWNLOAD: {
      if (state) {
        clearInterval(state);
      }
      return null;
    }
    default:
      return state;
  }
};

export const subjectAreasFilter = (state = {}, action) => {
  const { professionId, data = [] } = action;
  switch (action.type) {
    case at.FETCH_SUCCESS_SUBJECT_AREAS_FILTER: {
      return { ...state, [professionId]: data };
    }
    case at.FETCH_FAILURE_SUBJECT_AREAS_FILTER: {
      return { ...state, [professionId]: [] };
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  entitiesFetchStatus,
  pages,
  pagesFetchStatus,
  totalCount,
  previousPage,
  attachmentsFetchStatus,
  attachmentsDeleteStatus,
  deletedPages,
  deletedPagesFetchStatus,
  deletedTotalCount,
  requestDownloadPdfStatus,
  getRequestStatus,
  browserDownload,
  updatePdfStatus,
  timerDownload,
  subjectAreasFilter,
});
