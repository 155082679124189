import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import { Table, Popover, Icon } from 'emerald-ui/lib';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import { v4 as uuidv4 } from 'uuid';

import { INPUT_FORMAT_DATE } from '../../../config';
import './CourseHistory.css';

class CourseHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpandedCourseHistory: false,
    };
  }

  renderAlert(messages, key) {
    const myMessages = messages.map((m, index) => {
      return <p key={`${key}-message-row-${index}`}>{m}</p>;
    });

    const overlay = (
      <Popover id={'{key}-cch-tooltip'} key={`${key}-tooltip`} placement="top" theme="dark">
        {myMessages}
      </Popover>
    );

    return (
      <OverlayTrigger overlay={overlay} placement="top" key={`${key}-overlay`}>
        <Icon
          name="warning"
          key={`${key}-icon`}
          className="text-warning"
          style={{ color: '#ffa726', fontSize: '15px', cursor: 'pointer' }}
        />
      </OverlayTrigger>
    );
  }

  getMessages(course) {
    const { isFlag, scenarioType, isViewOnlyCompleted, deliveryMethodRestricted } = this.props;

    const { messages = [] } = course;

    const myMessages = [];

    messages.forEach((m) => {
      if (!(m.message === 'Excess hours do not carry.' && course.courseHoursEarned === course.hours)) {
        myMessages.push(m.message);
      }
    });

    if (!isFlag) {
      if (course.hoursToReact > 0) {
        myMessages.push(`${course.hoursToReact} hour(s) used for reactivation of license`);
      }

      if (course.excessHours > 0 && scenarioType !== 'DISCIPLINARY') {
        if (!isViewOnlyCompleted) {
          if (course.isDiscCourseManipulation === 0) {
            myMessages.push(`Excess Hours not applied: ${course.excessHours}`);
          }
        }
      }

      if (course.isFullFillSpecial === 1 || course.isParentFullFillSpecial === 1) {
        myMessages.push('Simultaneously posting to multiple subject areas');
      }

      if (deliveryMethodRestricted !== '') {
        if (course.isCreditDeliveryRes === 1) {
          if (course.courseHoursApplied < course.courseHoursEarned) {
            myMessages.push(`Requirement on ${deliveryMethodRestricted} course`);
          }
        }
      }
    }

    return myMessages;
  }

  renderChildrenCourses(childrenCourses = []) {
    let rows = [];
    let independentRows = [];
    for (const children of childrenCourses) {
      let childRows = [];
      const showRow = children.isVisible || children.isExemptionDependent;
      const isSpecialty = children.isExemptionDependent && children.requiredHours > 0;
      if (showRow) {
        const rowContent = (
          <tr key={children.id} className="children-subject-area-name">
            <td colSpan="3">
              {`${children.name} `}
              {isSpecialty && (
                <span style={{ fontWeight: 600, marginLeft: '5px' }}>- {children.requiredHours} hr Required</span>
              )}
            </td>
          </tr>
        );
        childRows.push(rowContent);
      }
      const childrenCoursesArray = this.renderCourses(children.courses);
      const coursesRows = showRow ? childrenCoursesArray : [];
      if (!showRow) {
        independentRows = [...independentRows, ...childrenCoursesArray];
      }
      childRows = [...childRows, ...coursesRows];
      rows = isSpecialty ? [...childRows, ...rows] : [...rows, ...childRows];
    }
    return [...independentRows, ...rows];
  }

  renderCourses(courses = []) {
    const { showCourseTypeColumn, isFlag } = this.props;
    const rows = courses.map((course) => {
      const messages = this.getMessages(course);
      const myMessages = messages.length > 0 ? this.renderAlert(messages, course.rosterAttendeeId) : '';
      return (
        <tr key={uuidv4()}>
          <td className="course-name-column">
            <span className="no-white-space course-history-date">
              {moment(course.dateEarned, INPUT_FORMAT_DATE).format('MMM D YYYY')}
            </span>
            <span className="course-history-name">{course.courseName}</span>
          </td>
          {!isFlag && (
            <td className={classnames({ hide: !showCourseTypeColumn }, 'desktop-only')}>
              <span>{course.courseTypeDescription}</span>
            </td>
          )}
          <td style={{ width: '10px' }}>{myMessages}</td>
          {!isFlag && (
            <td>
              <span>{course.appliedHours}</span>
            </td>
          )}
        </tr>
      );
    });
    return rows;
  }

  handleViewMoreClick = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      isExpandedCourseHistory: !prevState.isExpandedCourseHistory,
    }));
  };

  render() {
    const { courses, childrenCourses, showCourseTypeColumn, isFlag } = this.props;
    let { totalHoursApplied = 0 } = this.props;

    const { isExpandedCourseHistory } = this.state;
    if (totalHoursApplied === 0 && courses) {
      for (const course of courses) {
        totalHoursApplied += course.appliedHours;
      }
    }
    const isNoCourses = courses.length === 0 && childrenCourses.length === 0;

    const coursesRows = this.renderCourses(courses);
    const childrenCoursesRows = this.renderChildrenCourses(childrenCourses);
    const allRows = [...coursesRows, ...childrenCoursesRows];
    const rowsToShow = 5;
    const noCoursesText = isFlag ? 'No requirements applied' : 'No hours applied';
    return (
      <div className="transcript-course-history">
        <Table>
          {courses.length > 0 && (
            <thead>
              <tr>
                <th className="ch-table-title" style={{ width: '70%' }}>
                  Course history
                </th>
                {!isFlag && <th className={classnames({ hide: !showCourseTypeColumn }, 'desktop-only')}>Type</th>}
                <th style={{ width: '10px' }} className="desktop-only" />
                {!isFlag && <th>Hrs. applied</th>}
              </tr>
            </thead>
          )}
          <tbody>
            {allRows.slice(0, rowsToShow)}
            {allRows.length > rowsToShow && (
              <Fragment>
                {isExpandedCourseHistory && allRows.slice(rowsToShow)}
                <tr onClick={this.handleViewMoreClick} className="show-more-course-history">
                  <td colSpan="4">
                    {isExpandedCourseHistory ? (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a>
                        Show less <Icon name="keyboard_arrow_up" />
                      </a>
                    ) : (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a>
                        Show more <Icon name="keyboard_arrow_down" />
                      </a>
                    )}
                  </td>
                </tr>
              </Fragment>
            )}
          </tbody>
          <tfoot className={isNoCourses ? 'no-courses' : ''}>
            <tr>
              <td>
                <span>{isNoCourses ? noCoursesText : 'Total'}</span>
              </td>
              <td className={classnames({ hide: !showCourseTypeColumn }, 'desktop-only')} />
              <td style={{ width: '10px' }} className="desktop-only" />
              {!isFlag && (
                <td>
                  <span>{totalHoursApplied}</span>
                </td>
              )}
            </tr>
          </tfoot>
        </Table>
      </div>
    );
  }
}

CourseHistory.propTypes = {
  isFlag: PropTypes.bool,
  scenarioType: PropTypes.string,
  isViewOnlyCompleted: PropTypes.bool,
  deliveryMethodRestricted: PropTypes.string,
  courses: PropTypes.array,
  childrenCourses: PropTypes.array,
  totalHoursApplied: PropTypes.number,
  showCourseTypeColumn: PropTypes.bool,
};

CourseHistory.defaultProps = {
  courses: [],
  childrenCourses: [],
};

export default CourseHistory;
