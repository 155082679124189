import { put, call, select } from 'redux-saga/effects';

import * as api from '../../../api/courseHistory';
import at from '../types';

export default function* fetchDetail({ reduxId, id, section, entityId, professionId }) {
  const state = yield select();
  const { legacyToken, licenseeAccount } = state.authentication;
  const token = legacyToken.token;
  const userId = licenseeAccount.user.id;

  try {
    const { data } = yield call(api.fetchDetail, id, token, entityId, section, userId, professionId);
    yield put({ type: at.FETCH_DETAIL_SUCCESS, reduxId, data });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({ type: at.FETCH_DETAIL_FAILURE, reduxId });
  }
}
