export default function (globalState) {
  const state = globalState.ads;

  const getAd = () => state.ad;

  const fetchAdsStatus = () => state.adStatus;
  const fetchAdsDetailStatus = () => state.adDetailStatus;

  return {
    getAd,
    fetchAdsStatus,
    fetchAdsDetailStatus,
  };
}
