import { put, call, select } from 'redux-saga/effects';
import * as api from '../../../api/cart';
import at from '../types';

export function* removeCartItem({ courseId, meta = {} }) {
  const state = yield select();
  const {
    authentication: { licenseeAccount },
    cart: { entities: cartInfo },
  } = state;
  const { user } = licenseeAccount;
  yield put({ type: at.DELETE_CART_ITEM_REQUEST });

  try {
    const { sessionUuid } = cartInfo;
    const { couponCodes = [] } = meta;
    const requestToRemoveItem = courseId ? api.removeCartItem : api.removeSubscriptionFromCart;
    const {
      data: { courses: cart, totalAmountWithDiscounts, isTalonOneActive, totalDiscount, subscription, totalItems },
    } = yield call(requestToRemoveItem, { userId: user.id, courseId, couponCodes });
    const discountsData = {
      totalAmountWithDiscounts,
      isTalonOneActive,
      totalDiscount,
    };
    const cartData = {
      userId: user.id,
      entity: cart,
      sessionUuid,
      discountsData,
      subscription,
      totalItems,
    };

    yield put({
      type: at.DELETE_CART_ITEM_SUCCESS,
      ...cartData,
    });
  } catch (error) {
    yield put({
      type: at.DELETE_CART_ITEM_FAILURE,
      userId: user.id,
      error: error.message,
    });
  }
}
