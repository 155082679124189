import at from './types';

export const fetch = (filters, meta) => ({
  type: at.FETCH_REQUEST,
  filters,
  meta,
});

export const deleteCourse = (reduxId, selfSubmitId, filters) => ({
  type: at.DELETE_COURSE_REQUEST,
  reduxId,
  selfSubmitId,
  filters,
});

export const fetchDetail = (reduxId, id, entityId, section, professionId) => ({
  type: at.FETCH_DETAIL_REQUEST,
  reduxId,
  id,
  entityId,
  section,
  professionId,
});

export const fetchAttachments = (reduxId, attachmentDocumentNumber, attachmentCode, meta) => ({
  type: at.FETCH_ATTACHMENTS_REQUEST,
  reduxId,
  attachmentDocumentNumber,
  attachmentCode,
  meta,
});

export const addAttachment = (reduxId, attachment, meta) => ({
  type: at.ADD_ATTACHMENT_SUCCESS,
  reduxId,
  attachment,
  meta,
});

export const deleteAttachment = (reduxId, attachmentId, attachmentCode, attachmentDocumentNumber, meta) => ({
  type: at.DELETE_ATTACHMENT_REQUEST,
  reduxId,
  attachmentId,
  attachmentCode,
  attachmentDocumentNumber,
  meta,
});

export const rateCourse = (reduxId, id, data) => ({
  type: at.RATE_COURSE_REQUEST,
  reduxId,
  id,
  data,
});

export const setPageFetchStatus = (page, status) => ({
  type: at.SET_PAGE_FETCH_STATUS,
  page,
  status,
});

export const clearEntities = () => ({
  type: at.CLEAR_ENTITIES,
});

export const requestDownloadPdf = (credentials) => ({
  type: at.FETCH_REQUEST_PDF,
  credentials,
});

export const getRequestStatus = (credentials) => ({
  type: at.FETCH_REQUEST_PDF_STATUS,
  credentials,
});

export const resetDownloadPdf = () => ({
  type: at.FETCH_REQUEST_PDF_STATUS_RESET,
});

export const browserDownload = (urlDocument) => ({
  type: at.DOWNLOAD_PDF_BROWSER,
  urlDocument,
});

export const updatePdfStatus = (pdfDataObject) => ({
  type: at.UPDATE_PDF_STATUS,
  pdfDataObject,
});

export const resetRequestStatus = () => ({
  type: at.RESET_REQUEST_PDF_STATUS,
});

export const setTimerDownload = (timer) => ({
  type: at.COURSE_HISTORY_SET_TIMER_DOWNLOAD,
  timer,
});

export const resetTimerDownload = () => ({
  type: at.COURSE_HISTORY_RESET_TIMER_DOWNLOAD,
});

export const clearTimerDownload = () => ({
  type: at.COURSE_HISTORY_CLEAR_TIMER_DOWNLOAD,
});

export const fetchSubjectAreas = (professionId) => ({
  type: at.FETCH_SUBJECT_AREAS_FILTER,
  professionId,
});
