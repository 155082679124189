import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import CourseRecommendationSection from './CourseRecommendationSection';
import * as authenticationStore from '../../store/authentication';
import * as transcriptStore from '../../store/transcript';
import * as coursesRecommendedStore from '../../store/coursesRecommended';
import { uniq } from 'lodash';

function mapStateToProps(state, ownProps) {
  const { location } = ownProps;
  const authenticationSelectors = authenticationStore.selectors(state);
  const transcriptSelectors = transcriptStore.selectors(state);
  const coursesRecommendedSelectors = coursesRecommendedStore.selectors(state);
  const query = queryString.parse(location.search);
  const { cycle: cycleId = 0 } = query;
  const { pkLicense } = ownProps.match.params;
  const currentId = `${pkLicense}-${cycleId}`;
  const recommendations = coursesRecommendedSelectors.getCoursesRecommended(currentId);
  const licenseAccount = authenticationSelectors.getLicenseeAccount();
  const { user = {}, licenses = [], subscriptions = [] } = licenseAccount;
  const license = licenses.find((l) => l.id === parseInt(pkLicense, 10)) || {};
  const { profession = {} } = license;
  const { name: professionName, id: professionId } = profession;

  const fetchStatus = coursesRecommendedSelectors.fetchCoursesRecommendedStatus(currentId);
  const transcript = transcriptSelectors.getEntityById(currentId);
  const subscriptionType = subscriptions[0] ? subscriptions[0].type : '';
  const isFTOAvailable = subscriptions[0] ? subscriptions[0].isftoAvailable : false;

  const subjectsAreas = transcript.transcriptDetail.items
    .filter(
      ({ remainingHours = 0, isFlag, isPosted, subjectAreaProfessions = [] }) =>
        (remainingHours > 0 || (isFlag && !isPosted)) &&
        subjectAreaProfessions.length > 0 &&
        subjectAreaProfessions[0].allowCourseSearch
    )
    .map(({ name, remainingHours, isFlag, isPosted, subjectAreaProfessions, creditCourses }) => ({
      subjectAreaId: subjectAreaProfessions[0].subjectAreaId,
      subjectAreaName: name,
      hoursNeeded: remainingHours,
      isYesNotRequirement: isFlag && !isPosted,
      coursesRecommended: [],
      childrenIds: [],
      courseIds: uniq(
        creditCourses
          .reduce((prev, child) => [...prev, child], [])
          .map(({ courseId }) => courseId)
          .filter((courseId) => !!courseId)
      ),
      allowCourseSearch: subjectAreaProfessions[0].allowCourseSearch,
    }))
    .filter(({ allowCourseSearch = false }) => allowCourseSearch);

  const emptyRecommendations =
    (recommendations.length > 0 &&
      recommendations.every(
        ({ coursesRecommended }) => Array.isArray(coursesRecommended) && coursesRecommended.length === 0
      )) ||
    recommendations.length === 0;

  const filters = {
    pkLicense,
    cycleId,
    professionId,
    subjectsAreas,
  };

  return {
    user,
    license,
    professionName,
    subscriptionType,
    isFTOAvailable,
    pkLicense,
    cycleId,
    filters,
    fetchStatus,
    emptyRecommendations,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchCoursesRecommended: coursesRecommendedStore.actions.fetch,
  })(CourseRecommendationSection)
);
