export default {
  CANCEL_TRIAL: 'subscriptions/CANCEL_TRIAL',
  CANCEL_TRIAL_REQUEST: 'subscriptions/CANCEL_TRIAL_REQUEST',
  CANCEL_TRIAL_FAILED: 'subscriptions/CANCEL_TRIAL_FAIL',
  CANCEL_TRIAL_SUCCESS: 'subscriptions/CANCEL_TRIAL_SUCCESS',

  FETCH_SUBSCRIPTION_PRICES_REQUEST: 'subscriptions/FETCH_SUBSCRIPTION_PRICES_REQUEST',
  FETCH_SUBSCRIPTION_PRICES_SUCCESS: 'subscriptions/FETCH_SUBSCRIPTION_PRICES_SUCCESS',
  FETCH_SUBSCRIPTION_PRICES_FAILURE: 'subscriptions/FETCH_SUBSCRIPTION_PRICES_FAILURE',

  FETCH_SUBSCRIPTION_PLANS_REQUEST: 'subscriptions/FETCH_SUBSCRIPTION_PLANS_REQUEST',
  FETCH_SUBSCRIPTION_PLANS_SUCCESS: 'subscriptions/FETCH_SUBSCRIPTION_PLANS_SUCCESS',
  FETCH_SUBSCRIPTION_PLANS_FAILURE: 'subscriptions/FETCH_SUBSCRIPTION_PLANS_FAILURE',
};
