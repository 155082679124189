import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'emerald-ui';
import './BlurredImage.css';

class BlurredImage extends React.Component {
  render() {
    return (
      <div className="blurred-image">
        <img src={this.props.imgSrc} alt="Blurred" />
        <Button className="blurred-action-button" color="primary" size="small" onClick={this.props.onActionClick}>
          {this.props.actionText}
        </Button>
      </div>
    );
  }
}

BlurredImage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  onActionClick: PropTypes.func.isRequired,
};

export default BlurredImage;
