// URLs
export const legacyUrl = 'https://secure.cebroker.com';
export const storageUrl = 'https://storage.cebroker.com';
export const launchpadUrl = 'https://launchpad.cebroker.com';
export const urlBase = '/';
export const licenseesApiInternal = 'https://licensees.cebroker.com/api';
export const licenseesUrl = 'https://licensees.cebroker.com';
export const cebHome = 'https://cebroker.com';
export const conditionsUrl = 'https://cebroker.com/terms';
export const privacyUrl = 'https://cebroker.com/privacy';

export const courseSearchUrl = 'https://courses.cebroker.com';
export const courseSearchApiUrl = 'https://courses.cebroker.com/api';
export const signInUrl = 'https://launchpad.cebroker.com/login';
export const lmsUrl = 'https://learning.cebroker.com';
export const callToll = '1-877-434-6323';
export const lmsUrlAcademyImplementation = 'https://learning-v2.cebroker.com';

export const helpWidgetUrl = 'https://help.cebroker.com';

// Cookies
export const secureCookie = 'cebtoken';
export const licenseeTokenCookie = 'licensee_token';
export const licenseeAuthInfoCookie = 'licensee_auth_info';

// Export all
export default {
  legacyUrl,
  storageUrl,
  launchpadUrl,
  urlBase,
  licenseesApiInternal,
  secureCookie,
  courseSearchUrl,
  courseSearchApiUrl,
  licenseesUrl,
  cebHome,
  helpWidgetUrl,
  conditionsUrl,
  privacyUrl,
  signInUrl,
  lmsUrl,
  callToll,
  lmsUrlAcademyImplementation,
  licenseeTokenCookie,
  licenseeAuthInfoCookie,
};
