import React from 'react';
import PropTypes from 'prop-types';
import { getCourseRegistrationHierarchy, checkIfIsNowCourse } from '../../../../utils';
import ReactGA from 'react-ga';

const CourseRegistrationLink = (props) => {
  const { course } = props;
  const handleWebsiteClick = (e) => {
    e.preventDefault();
    const {
      course: { provider, registrationWebsite, defaultCourseUrl, id: courseId = {} } = {},
      isFeatured,
      offeringId,
      isOutOfCourseSearch,
      categoryGA,
    } = props;
    const { id: providerId } = provider;
    const registerUrl = registrationWebsite || defaultCourseUrl || provider.website;

    if (isOutOfCourseSearch) {
      ReactGA.event({
        category: categoryGA ? categoryGA : 'lic_beta_register_ad',
        action: 'click',
        label: `providerId=${providerId},courseId=${courseId},offeringId=${offeringId}`,
      });
      window.open(registerUrl, '_blank');
      return;
    }

    if (isFeatured) {
      ReactGA.event({
        category: 'featured_registration_link',
        action: 'click',
        label: registerUrl,
      });
    }
    window.open(registerUrl, '_blank');
  };

  const registerUrl = getCourseRegistrationHierarchy(course);
  const isNowCourse = checkIfIsNowCourse(course);
  if (!registerUrl || isNowCourse) {
    return <React.Fragment />;
  }
  return (
    <div className="floating-content register-url-link-container">
      <div className="register-url-link">
        <span className="course-details-title">Course Registration</span>
        <a href={registerUrl} onClick={handleWebsiteClick}>
          {registerUrl}
        </a>
      </div>
    </div>
  );
};

CourseRegistrationLink.propTypes = {
  course: PropTypes.object,
  isFeatured: PropTypes.bool,
  offeringId: PropTypes.any,
  isOutOfCourseSearch: PropTypes.bool,
  categoryGA: PropTypes.string,
};

export default CourseRegistrationLink;
