import at from './types';

export const fetch = (filters, meta) => ({
  type: at.FETCH_REQUEST,
  filters,
  meta,
});

export const fetchQuestions = (exemptionId, lpExemptionId, meta) => ({
  type: at.FETCH_QUESTIONS_REQUEST,
  exemptionId,
  lpExemptionId,
  meta,
});

export const fetchAttachments = (lpExemptionId, meta) => ({
  type: at.FETCH_ATTACHMENTS_REQUEST,
  lpExemptionId,
  meta,
});

export const setPageFetchStatus = (page, status) => ({
  type: at.SET_PAGE_FETCH_STATUS,
  page,
  status,
});

export const revokeExemption = (lpExemptionId, pkLicense, meta) => ({
  type: at.REVOKE_EXEMPTION_REQUEST,
  lpExemptionId,
  pkLicense,
  meta,
});

export const clearEntities = () => ({
  type: at.CLEAR_ENTITIES,
});
