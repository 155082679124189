import React from 'react';
import PropTypes from 'prop-types';
import './CourseDetailSubjectAreas.css';

class CourseDetailSubjectAreas extends React.Component {
  render() {
    const { course, professionId } = this.props;

    const courseDetail = course.course || {};

    let { components = [] } = courseDetail;

    if (professionId) {
      components = components.filter(c => c.profession.id === professionId);
    }

    const boards = components.reduce((prev, next) => {
      const board = next.profession.board;
      const profession = next.profession;

      if (!prev[board.id]) {
        prev[board.id] = {
          ...board,
          professions: {
            [profession.id]: {
              ...next.profession,
              subjects: [next]
            }
          }
        };
      } else {
        if (!prev[board.id].professions[profession.id]) {
          prev[board.id].professions[profession.id] = {
            ...next.profession,
            subjects: [next]
          };
        } else {
          prev[board.id].professions[profession.id].subjects.push(next);
        }
      }
      return prev;
    }, {});

    return (
      <div className="course-detail-subject-areas-content course-detail-content">
        <span className="course-detail-subject-areas-title">Subject areas</span>
        {Object.keys(boards).map(key => {
          const board = boards[key];
          const professions = board.professions;
          return (
            <div
              className="course-detail-subject-areas-top"
              key={`board-${board.id}`}
            >
              <div className="course-detail-subject-areas-descr-field board">
                <i
                  className="fas fa-university"
                  style={{ marginRight: 10 }}
                ></i>
                <span>{board.name}</span>
                <span />
              </div>

              {Object.keys(professions).map(key => {
                const profession = professions[key];
                const subjects = profession.subjectAreas;
                return (
                  <React.Fragment
                    key={`board-${board.id}-profession-${profession.id}`}
                  >
                    <div className="course-detail-subject-areas-descr-field profession">
                      <i className="fas fa-briefcase"></i>
                      <span style={{ marginLeft: 10 }}>{profession.name}</span>
                    </div>
                    {subjects.map(subject => {
                      return (
                        <div
                          className="course-detail-subject-areas-sa"
                          key={`board-${board.id}-profession-${profession.id}-subject-${subject.id}`}
                        >
                          <div className="course-detail-subject-areas-descr-field subject-area">
                            <span>{subject.name}</span>
                          </div>
                          <div className="course-detail-subject-areas-value-field">
                            <span>{subject.approvedHours} hours</span>
                          </div>
                        </div>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

CourseDetailSubjectAreas.propTypes = {
  course: PropTypes.object,
  isCourseDetailModal: PropTypes.bool,
  professionId: PropTypes.number
};

CourseDetailSubjectAreas.defaultProps = {
  course: {},
  isCourseDetailModal: false
};

export default CourseDetailSubjectAreas;
