import { combineReducers } from 'redux';
import at from '../types';

export const coursesRecommended = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_REQUEST:
      return { ...state };
    case at.FETCH_SUCCESS: {
      const { cycleId = 0, pkLicense } = action;
      const id = `${pkLicense}-${cycleId}`;
      return {
        ...state,
        [id]: action.data,
      };
    }
    case at.FETCH_FAILURE:
      return { ...state };
    default:
      return { ...state };
  }
};

export const coursesRecommendedStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      const { cycleId = 0, pkLicense } = action.params;
      const id = `${pkLicense}-${cycleId}`;
      return { ...state, [id]: 'loading' };
    }
    case at.FETCH_SUCCESS: {
      const { cycleId = 0, pkLicense } = action;

      const id = `${pkLicense}-${cycleId}`;
      return { ...state, [id]: 'loaded' };
    }
    case at.FETCH_FAILURE: {
      const { cycleId = 0, pkLicense } = action;

      const id = `${pkLicense}-${cycleId}`;
      return { ...state, [id]: 'failed' };
    }
    default:
      return state;
  }
};

export default combineReducers({
  coursesRecommended,
  coursesRecommendedStatus,
});
