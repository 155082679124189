import { takeEvery, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import at from '../types';
import * as actions from '../actions';

export function* watchAdd({ notification }) {
  const { id, timeout = 0 } = notification;
  if (id && timeout) {
    yield delay(timeout + 2000);
    yield put(actions.remove(id));
  }
}

export default function* root() {
  yield takeEvery(at.ADD, watchAdd);
}
