export default (data) => {
  const { checkoutStripeId, amountTotal, tax = 0, discount = 0, couponId, items: courses = [] } = data;

  return {
    transaction_id: checkoutStripeId,
    value: amountTotal,
    tax,
    discount,
    currency: 'USD',
    coupon: couponId,
    items: courses.map((item) => ({
      ...(item.discount && { discount: item.discount }),
      item_id: item.courseId,
      name: item.name,
      price: item.amount,
      quantity: item.quantity || 1,
      category: item.type,
    })),
  };
};
