import { connect } from 'react-redux';
import ReportedExemptionDetail from '../components/ReportedExemptionDetail';
import * as reportedExemptionsStore from '../store/reportedExemptions';

import * as authenticationStore from '../store/authentication';

function mapStateToProps(state) {
  const authenticationSelectors = authenticationStore.selectors(state);
  const licenseeAccount = authenticationSelectors.getLicenseeAccount();

  const { subscriptions = [] } = licenseeAccount;

  let subscriptionType = '';
  if (subscriptions.length > 0) {
    subscriptionType = subscriptions[0].type;
  }

  return {
    subscriptionType,
  };
}

export default connect(mapStateToProps, {
  fetchQuestions: reportedExemptionsStore.actions.fetchQuestions,
})(ReportedExemptionDetail);
