export default function (globalState) {
  const state = globalState.users;

  const getUserProfile = () => state.profile || {};
  const getEmailSettings = () => state.emailSettings || {};
  const getProfileStatus = () => state.fetchStatus || '';
  const getEmailSettingsStatus = () => state.emailSettingsStatus || '';
  const getUpdateStatus = () => state.updateStatus || '';
  const getUpdateUsernameStatus = () => state.updateUsernameStatus || '';
  const getUpdateUsernameErrorMessage = () => state.updateErrorMessage || '';
  const getUpdatePasswordStatus = () => state.updatePasswordStatus || '';
  const getUpdateEmailSettingsStatus = () => state.updateEmailSettingsStatus || '';
  const getConciergeManager = () => state.conciergeManager || {};
  const getConciergeManagerStatus = () => state.conciergeManagerStatus || '';
  const getCommunicationSettings = () => state.communicationSettings || {};
  const getCommunicationSettingsStatus = () => state.communicationSettingsStatus || '';
  const getUpdateCommunicationSettingsStatus = () => state.updateCommunicationSettingsStatus || '';
  const getUserFlag = (key) => (state.userFlag ? state.userFlag[key] === 'true' : false);
  const getUserFlagStatus = () => state.userFlagStatus || '';

  return {
    getUserProfile,
    getProfileStatus,
    getUpdateStatus,
    getEmailSettings,
    getEmailSettingsStatus,
    getUpdateEmailSettingsStatus,
    getConciergeManager,
    getConciergeManagerStatus,
    getCommunicationSettings,
    getCommunicationSettingsStatus,
    getUpdateCommunicationSettingsStatus,
    getUpdateUsernameStatus,
    getUpdatePasswordStatus,
    getUpdateUsernameErrorMessage,
    getUserFlag,
    getUserFlagStatus,
  };
}
