import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Loader = ({ color = 'info' }) => {
  return <div className={`loader ${color}`} />;
};

Loader.propTypes = {
  color: PropTypes.string,
};

export default Loader;
