import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { refineQuery } from '../../utils';
import debounce from 'lodash/debounce';
import { withRouter } from 'react-router-dom';
import { Toolbar, SearchForm, Button } from 'emerald-ui/lib';

import './ListToolbar.css';
import { Icon } from 'emerald-ui';

class ListToolbar extends React.Component {
  constructor(props) {
    super(props);
    const { params } = props;
    const { q = '' } = params;
    this.state = { q, showFiltersModal: false };
  }

  updateUrl = (pathname, params) => {
    const {
      history: { replace },
    } = this.props;
    replace(`${pathname}?${queryString.stringify(params)}`);
  };

  updateUrlParams = debounce((params) => {
    const {
      location: { pathname },
    } = this.props;
    this.updateUrl(pathname, params);
  }, 250);

  handleOpenFiltersModal = () => {
    this.setState({ showFiltersModal: true });
  };

  handleCloseFiltersModal = () => {
    this.setState({ showFiltersModal: false });
  };

  handleQueryChange = (event) => {
    const { params, fetch } = this.props;
    const q = event.target.value;
    this.setState({ q });
    const newParams = refineQuery({ ...params, q });
    this.updateUrlParams(newParams);
    fetch(newParams, { clearEntities: true });
  };

  handleClearQuery = () => {
    const { params, fetch } = this.props;
    this.setState({ q: '' });
    const newParams = refineQuery({ ...params, q: '' });
    this.updateUrlParams(newParams);
    fetch(newParams, { clearEntities: true });
  };

  handleResetFilters = () => {
    const { params, fetch, filters } = this.props;
    const restFilters = {};
    for (const f of filters) {
      restFilters[f] = null;
    }
    const newParams = refineQuery({
      ...params,
      ...restFilters,
    });
    this.setState({ showFiltersModal: false });
    this.updateUrlParams(newParams);
    fetch(newParams, { clearEntities: true });
  };

  handleApplyFilters = (newFilters) => {
    const { params, fetch, filters } = this.props;
    const restFilters = {};
    for (const f of filters) {
      restFilters[f] = newFilters[f];
    }
    const newParams = refineQuery({
      ...params,
      ...restFilters,
    });
    this.setState({ showFiltersModal: false });
    this.updateUrlParams(newParams);
    fetch(newParams, { clearEntities: true });
  };

  getFiltersApplied = (newFilters) => {
    const { filters } = this.props;
    let count = 0;

    for (const f of filters) {
      if (newFilters[f]) {
        count++;
      }
    }
    return count;
  };

  render() {
    const { params, FiltersModal, filters } = this.props;
    const restFilters = {};
    for (const f of filters) {
      restFilters[f] = params[f];
    }
    const { q, showFiltersModal } = this.state;
    const filtersApplied = this.getFiltersApplied(params);
    return (
      <div className="toolbar-wrapper">
        <FiltersModal
          {...{
            ...restFilters,
            show: showFiltersModal,
            onHide: this.handleCloseFiltersModal,
            onResetFilters: this.handleResetFilters,
            onApplyFilters: this.handleApplyFilters,
          }}
        />
        <Toolbar>
          <SearchForm
            clearable
            placeholder="Search course history"
            className="course-history-list-search-form"
            value={q}
            onChange={this.handleQueryChange}
            onClear={this.handleClearQuery}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          />
          <Button onClick={this.handleOpenFiltersModal}>
            <div className="toolbar-button-wrapper">
              <Icon name="filter_list" />
              <span className="hide-mobile">Filter</span>
              <span className="filter-number">({filtersApplied})</span>
            </div>
          </Button>
        </Toolbar>
      </div>
    );
  }
}

ListToolbar.propTypes = {
  params: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  fetch: PropTypes.func,
  FiltersModal: PropTypes.func,
  filters: PropTypes.array,
};

ListToolbar.defaultProps = {
  params: {},
  history: {},
  location: {},
  filters: [],
};

export default withRouter(ListToolbar);
