import { put, call, select } from 'redux-saga/effects';
import at from '../types';
import selectors from '../selectors';
import * as api from '../../../api/courseHistory';

export default function* fetchSubjectAreas({ professionId }) {
  const state = yield select();
  const courseHistorySelectors = selectors(state);
  const subjectAreas = courseHistorySelectors.getSubjectAreasByProfession(professionId);

  if (subjectAreas.length > 0) {
    return;
  }

  const userId = state.authentication.licenseeAccount.user.id;
  try {
    const { data } = yield call(api.fetchSubjectAreas, professionId, userId);
    const { subjectAreas: { items = [] } = {} } = data;
    yield put({
      type: at.FETCH_SUCCESS_SUBJECT_AREAS_FILTER,
      professionId,
      data: items,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({
      type: at.FETCH_FAILURE_SUBJECT_AREAS_FILTER,
      professionId,
      data: null,
    });
  }
}
