import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import { refineQuery } from '../utils';

import * as courseHistory from '../store/courseHistory';

import CourseHistoryList from '../components/CourseHistoryList';

import * as authenticationStore from '../store/authentication';

function mapStateToProps(state, ownProps) {
  const authenticationSelectors = authenticationStore.selectors(state);
  const user = authenticationSelectors.getLicenseeAccount();

  const previousPage = state.courseHistory.previousPage;
  const courseHistorySelectors = courseHistory.selectors(state);
  const params = refineQuery(queryString.parse(ownProps.location.search));

  const page = params.page || 1;

  const { licenses = [], subscriptions = [] } = user;

  const showCreditCourseType = licenses.some((license) => license.profession.showCreditCourseType);

  const subscriptionType = subscriptions[0] ? subscriptions[0].type : '';

  const courseHistoryEntities = courseHistorySelectors.getEntitiesByPage(page);

  const { pkLicense } = ownProps.match.params;
  const selectedLicense = licenses.find((l) => l.id.toString() === pkLicense);
  return {
    subscriptionType,
    showCreditCourseType,
    courseHistory: courseHistoryEntities,
    previousCourseHistory: courseHistorySelectors.getEntitiesByPage(previousPage),
    courseHistoryFetchStatus: courseHistorySelectors.getEntitiesFetchStatus(),
    getAttachmentsStatus: courseHistorySelectors.getAttachmentStatusById,
    getDeleteAttachmentStatus: courseHistorySelectors.getDeleteAttachmentStatus,
    selectedLicense,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    deleteCourseHistoryAttachment: courseHistory.actions.deleteAttachment,
    fetchSubjectAreas: courseHistory.actions.fetchSubjectAreas,
  })(CourseHistoryList)
);
