import axios from 'axios';

import { licenseesApiInternal } from '../config';

const instanceLicenseesInternalApi = axios.create({
  baseURL: licenseesApiInternal,
});

export const getTotalOfferingsCount = (filters = {}) => {
  return instanceLicenseesInternalApi.request({
    method: 'get',
    url: '/offerings',
    headers: {},
    params: {
      ...filters,
    },
  });
};

export function fetchCourseReviews(params = {}) {
  return instanceLicenseesInternalApi.get(`/offerings/reviews`, { params });
}

export function fetchAllSavedOfferings({ userId, pageIndex = 1, pageSize = 100 }) {
  return instanceLicenseesInternalApi.get(`/saved-courses/${userId}`, {
    params: { pageIndex, pageSize },
  });
}

export function saveOffering(data) {
  return instanceLicenseesInternalApi.post(`/saved-courses`, data);
}

export function deleteFromSavedOfferings(data) {
  return instanceLicenseesInternalApi.delete(`/saved-courses`, {
    data,
  });
}

export function fetchCourseRatingNumbers(offeringId) {
  return instanceLicenseesInternalApi.get(`/offerings/${offeringId}/rating-numbers`);
}
