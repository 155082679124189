import React, { Component } from 'react';
import { Panel, Col, Row, SkeletonLoader } from 'emerald-ui';
import './SettingsProfileEmails.css';

class SettingsProfileEmails extends Component {
  render() {
    return (
      <div id="settings-profile">
        <Panel>
          <Panel.Body>
            <Row>
              <Col md={4} xs={12}>
                <div className="settings-panel-title">
                  <h3>Personal info</h3>
                  <p>View and edit your personal information</p>
                </div>
              </Col>
              <Col md={8} xs={12}>
                <div className="settings-section">
                  <SkeletonLoader loading style={{ marginBottom: '15px' }} />
                  <SkeletonLoader loading style={{ height: '50px' }} />
                </div>
                <div className="settings-section with-subsection">
                  <SkeletonLoader loading style={{ marginBottom: '15px' }} />
                  <SkeletonLoader loading style={{ marginBottom: '15px' }} />
                  <SkeletonLoader loading style={{ height: '30px', marginBottom: '15px' }} />
                  <SkeletonLoader loading style={{ height: '30px', marginBottom: '15px' }} />
                  <SkeletonLoader loading style={{ height: '30px', marginBottom: '15px' }} />
                </div>
              </Col>
            </Row>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}

export default SettingsProfileEmails;
