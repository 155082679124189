import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Button } from 'emerald-ui';

import './CourseHistoryEmptyState.css';
import icon from './icon.svg';

import { legacyReportCEUrl } from '../../config';

class CourseHistoryEmptyState extends React.Component {
  render() {
    const { subscriptionType } = this.props;
    let { licenseId } = this.props;
    licenseId = !licenseId ? 0 : licenseId;
    return (
      <Panel>
        <div className="course-history-empty-state">
          <img src={icon} alt="icon" />
          <div>
            <p className="course-history-empty-state-title">No course history yet.</p>
            <p className="course-history-empty-state-description">
              Think something’s missing? Sometimes it takes educational providers a few days to report credit.
            </p>
            <Button href={`${legacyReportCEUrl(subscriptionType, licenseId)}`} color="info">
              Report CE
            </Button>
          </div>
        </div>
      </Panel>
    );
  }
}

CourseHistoryEmptyState.propTypes = {
  subscriptionType: PropTypes.string,
  licenseId: PropTypes.string,
};

CourseHistoryEmptyState.defaultProps = {
  subscriptionType: 'BAS',
  licenseId: '',
};

export default CourseHistoryEmptyState;
