import { combineReducers } from 'redux';
import at from '../types';

import fetchCart from './fetchCart';
import saveCartItem from './saveCartItem';
import removeCartItem from './removeCartItem';

const entities = (state = [], action) => {
  switch (action.type) {
    case at.ADD_ENTITIES:
    case at.FETCH_CART_SUCCESS:
    case at.DELETE_CART_ITEM_SUCCESS: {
      return {
        ...state,
        courses: action.entity,
        sessionUuid: action.sessionUuid,
        totalItems: action.totalItems,
        subscription: action.subscription,
      };
    }
    case at.SAVE_CART_ITEM_SUCCESS: {
      return {
        ...state,
        courses: action.entity || [],
        sessionUuid: action.sessionUuid,
        totalItems: action.totalItems,
        subscription: action.subscription,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  fetchCart,
  saveCartItem,
  removeCartItem,
});
