import { takeEvery } from 'redux-saga/effects';

import at from '../actions/types';
import fetch from './fetch';
import fetchBoardLogo from './fetchBoardLogo';

export default function* root() {
  yield takeEvery(at.FETCH_REQUEST, fetch);
  yield takeEvery(at.FETCH_BOARD_LOGO_REQUEST, fetchBoardLogo);
}
