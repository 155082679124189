import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { deleteFromSavedOfferings, fetchAllSavedOfferings, saveOffering } from 'api/offerings';
import { redirectTo, showToastNotification } from 'utils';
import { getCourseSearchUrl } from 'config';

export function useAllSavedOfferings({ userId }) {
  return useQuery({
    queryKey: ['allSavedOfferings', userId],
    queryFn: async () => {
      const { data = {} } = await fetchAllSavedOfferings({ userId });
      const { items = [] } = data;
      return items;
    },
    retry: 2,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}

export function useAddSavedOfferings() {
  const queryClient = useQueryClient();
  return useMutation({
    queryKey: ['addSavedOfferings'],
    mutationFn: async (offeringData) => {
      const { data = {} } = await saveOffering(offeringData);
      return data;
    },
    onSuccess: (newSavedOffering) => {
      queryClient.setQueryData(['allSavedOfferings'], (old) => [...old, newSavedOffering]);
    },
  });
}

export function useRemoveFromSavedOfferings() {
  return useMutation({
    queryKey: ['removeFromSavedOfferings'],
    mutationFn: async (offeringData) => {
      const { data = {} } = await deleteFromSavedOfferings(offeringData);
      return data;
    },
  });
}

export default function useSavedOfferings(userId, addNotification) {
  const [savedOfferings, setSavedOfferings] = useState([]);
  const [currentOfferingId, setCurrentOfferingId] = useState('');
  const { data: currentSavedOfferings = [] } = useAllSavedOfferings({ userId });
  const { mutate: removeFromSavedOffering, isLoading: isRemovingCourseLoading } = useRemoveFromSavedOfferings();
  const { mutate: addSavedOffering, isLoading: isSavingCourseLoading } = useAddSavedOfferings();

  useEffect(() => {
    setSavedOfferings(currentSavedOfferings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSavedOfferings.length]);

  const handleSaveOffering = (offering) => {
    const { isCourseSaved, userId, courseId, courseName, offeringId, professionId, providerId, providerName } =
      offering;
    let toastOptions;
    const dataForSaving = { userId, courseId, courseName, offeringId, professionId, providerId, providerName };
    const dataForRemoving = {
      courseId,
      userId,
      offeringId,
      professionId,
      providerId,
    };
    setCurrentOfferingId(offeringId);
    if (isCourseSaved) {
      removeFromSavedOffering(dataForRemoving);
      const savedOfferingsFiltered = savedOfferings.filter((savedOffering) => savedOffering.offeringId !== offeringId);
      setSavedOfferings(savedOfferingsFiltered);
      toastOptions = {
        message: 'Course removed',
        actionText: 'UNDO',
        action: () => {
          addSavedOffering(dataForSaving);
          setSavedOfferings([...savedOfferings, dataForSaving]);
        },
      };
    } else {
      addSavedOffering(dataForSaving);
      setSavedOfferings([...savedOfferings, dataForSaving]);
      toastOptions = {
        message: 'Item added',
        actionText: 'View course',
        action: () => {
          const queryParams = {
            redirect_to: 'saved-courses',
          };
          redirectTo(getCourseSearchUrl(queryParams, false));
        },
      };
    }

    showToastNotification({
      message: toastOptions.message,
      actionText: toastOptions.actionText,
      addNotification,
      onActionClick: toastOptions.action,
    });
  };

  const statuses = currentOfferingId ? { [currentOfferingId]: isRemovingCourseLoading || isSavingCourseLoading } : {};
  return { savedOfferings, handleSaveOffering, statuses };
}
