import { combineReducers } from 'redux';
import at from '../types';

const initialState = {
  statusRequest: 'loading',
  firstShow: false,
};

export const task = (state = { ...initialState }, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return {
        ...state,
        ...action.data,
        statusRequest: 'loaded',
        firstShow: true,
      };
    }
    case at.FETCH_REQUEST_LOADING: {
      return {
        statusRequest: 'loading',
        firstShow: state.firstShow,
      };
    }
    case at.FETCH_FAILURE: {
      return {
        ...action.data,
        statusRequest: 'failed',
      };
    }
    case at.SET_TASK: {
      return {
        ...state,
        ...action.data,
      };
    }
    case at.CLEAR_ENTITIES: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export const userFlagsAudit = (state = { statusRequest: 'loaded' }, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS_FLAG: {
      return {
        ...state,
        ...action.data,
        statusRequest: 'loaded',
      };
    }
    case at.FETCH_REQUEST_LOADING_FLAG: {
      return {
        ...state,
        statusRequest: 'loading',
      };
    }
    case at.FETCH_FAILURE_FLAG: {
      return {
        ...action.data,
        statusRequest: 'failed',
      };
    }
    case at.SET_FLAG: {
      return {
        ...state,
        ...action.data,
      };
    }
    default:
      return state;
  }
};

export const submitTaskStatus = (state = '', action) => {
  switch (action.type) {
    case at.SUBMIT_REQUEST: {
      return 'loading';
    }
    case at.SUBMIT_SUCCESS: {
      return 'loaded';
    }
    case at.SUBMIT_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export default combineReducers({
  task,
  userFlagsAudit,
  submitTaskStatus,
});
