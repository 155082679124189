import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import LogRocket from 'logrocket';
import throttle from 'lodash/throttle';

import { loadStateFromLocalStorage, saveStateToLocalStorage } from './localStorageUtils';

import reducers from './rootReducer';
import rootSaga from './rootSaga';

const persistedState = loadStateFromLocalStorage();

//this line allows to user redux dev tool on the browser
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const logRocketMiddleware = LogRocket.reduxMiddleware();

export const store = createStore(
  reducers,
  persistedState,
  composeEnhancers(applyMiddleware(sagaMiddleware, logRocketMiddleware))
);

//update de localStorage automatically when the redux state change
store.subscribe(
  throttle(() => {
    const state = store.getState();
    saveStateToLocalStorage({
      authentication: state.authentication,
    });
  }, 1000)
);

sagaMiddleware.run(rootSaga);
