import React from 'react';
import PropTypes from 'prop-types';

import ShowMore from 'react-show-more';
import { Icon } from 'emerald-ui/lib';

import './styles.css';

const InfoTranscript = ({ message }) => {
  return (
    <div className="info-transcript">
      <Icon name="info" />
      <ShowMore lines={2} more="Load more" less="Load less">
        <p dangerouslySetInnerHTML={{ __html: message }} />
      </ShowMore>
    </div>
  );
};

InfoTranscript.propTypes = {
  message: PropTypes.string,
};

export default InfoTranscript;
