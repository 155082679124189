import React from 'react';
import PropTypes from 'prop-types';

import ImageWithFallback from '../../../ImageWithFallback';
import defaultLogo from '../../../../static/images/default-provider-logo.png';
import './CourseDetailEducator.css';

class CourseDetailEducator extends React.PureComponent {
  handleViewProviderDetailClick = (e) => {
    e.preventDefault();
    const { courseDetail, onViewProviderDetail } = this.props;
    const { provider = {} } = courseDetail;
    const { id, name } = provider;
    if (onViewProviderDetail) {
      onViewProviderDetail({ id, name });
    }
  };

  render() {
    const { courseDetail } = this.props;

    const { provider = {} } = courseDetail;

    return (
      <div className="floating-content" id="course-details">
        <div className="course-detail-educator">
          <span className="course-detail-educator-title">Educator</span>
          <div className="course-detail-educator-wraper">
            <div className="course-detail-educator-logo-container">
              <ImageWithFallback
                alt="Provider logo"
                src={provider.logoUrl}
                defaultSrc={defaultLogo}
                style={{
                  width: '36px',
                  height: '36px',
                  maxWidth: '36px',
                  maxHeight: '36px',
                  marginRight: '27px',
                  borderRadius: '18px',
                }}
              />
            </div>

            <a
              href={`/providers/${provider.id}`}
              onClick={this.handleViewProviderDetailClick}
              className="course-detail-provider-name"
            >
              {provider.name}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

CourseDetailEducator.propTypes = {
  courseDetail: PropTypes.object,
  onViewProviderDetail: PropTypes.func,
  associationLabels: PropTypes.array,
};

CourseDetailEducator.defaultProps = {
  courseDetail: {},
  associationLabels: [],
};

export default CourseDetailEducator;
