import { fork } from 'redux-saga/effects';
import { rootSaga as ads } from './ads';
import { rootSaga as authentication } from './authentication';
import { rootSaga as courseHistory } from './courseHistory';
import { rootSaga as dashboardCards } from './dashboardCards';
import { rootSaga as licenses } from './licenses';
import { rootSaga as notifications } from './notifications';
import { rootSaga as transcript } from './transcript';
import { rootSaga as reportedExemptions } from './reportedExemptions';
import { rootSaga as users } from './users';
import { rootSaga as subscription } from './subscription';
import { rootSaga as subscriptions } from './subscriptions';
import { rootSaga as paymentHistory } from './paymentHistory';
import { rootSaga as inbox } from './inboxNotifications';
import { rootSaga as communications } from './communications';
import { rootSaga as nowCourses } from './nowCourses';
import { rootSaga as coursesRecommended } from './coursesRecommended';
import { rootSaga as addLicenseInfo } from './addLicenseInfo';
import { rootSaga as audits } from './audits';
import { rootSaga as discoveryTab } from './discoveryTab';
import { rootSaga as banner } from './banner';
import { rootSaga as cart } from './cart';

export default function* rootSaga() {
  yield fork(ads);
  yield fork(authentication);
  yield fork(courseHistory);
  yield fork(dashboardCards);
  yield fork(licenses);
  yield fork(notifications);
  yield fork(transcript);
  yield fork(reportedExemptions);
  yield fork(users);
  yield fork(subscription);
  yield fork(subscriptions);
  yield fork(inbox);
  yield fork(communications);
  yield fork(paymentHistory);
  yield fork(nowCourses);
  yield fork(coursesRecommended);
  yield fork(addLicenseInfo);
  yield fork(audits);
  yield fork(discoveryTab);
  yield fork(banner);
  yield fork(cart);
}
