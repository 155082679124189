import { combineReducers } from 'redux';
import at from '../types';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_RESPONSE_SUCCESS: {
      return { ...state, ...action.entities.items };
    }
    case at.FETCH_REQUEST_SUCCESS: {
      return { ...state, ...action.entities.items };
    }
    case at.FETCH_DETAIL_SUCCESS: {
      const { data, inboxId } = action;
      const { requestId = 0 } = data;
      const reduxId = `${inboxId}${requestId}`;
      return { ...state, [reduxId]: { ...state[reduxId], ...data } };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const responsePages = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_RESPONSE_SUCCESS: {
      const { filters, result } = action;
      return { ...state, [filters.responsePage]: result };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const requestPages = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_REQUEST_SUCCESS: {
      const { filters, result } = action;
      return { ...state, [filters.requestPage]: result };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const requestPageStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_REQUEST_SUCCESS: {
      const { filters } = action;
      if (filters.communicationsType === 'REQUEST') {
        return { ...state, [filters.requestPage]: 'loaded' };
      }
      return state;
    }
    case at.FETCH_REQUEST_FAILURE: {
      const { filters } = action;
      if (filters.communicationsType === 'REQUEST') {
        return { ...state, [filters.requestPage]: 'failed' };
      }
      return state;
    }
    case at.SET_PAGE_FETCH_STATUS: {
      const { filters, status } = action;
      if (filters.communicationsType === 'REQUEST') {
        return { ...state, [filters.requestPage]: status };
      }
      return state;
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const responsePageStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_RESPONSE_SUCCESS: {
      const { filters } = action;
      if (filters.communicationsType === 'RESPONSE') {
        return { ...state, [filters.responsePage]: 'loaded' };
      }
      return state;
    }
    case at.FETCH_RESPONSE_FAILURE: {
      const { filters } = action;
      if (filters.communicationsType === 'RESPONSE') {
        return { ...state, [filters.responsePage]: 'failed' };
      }
      return state;
    }
    case at.SET_PAGE_FETCH_STATUS: {
      const { filters, status } = action;
      if (filters.communicationsType === 'RESPONSE') {
        return { ...state, [filters.responsePage]: status };
      }
      return state;
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const requestDetailStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_DETAIL_REQUEST: {
      const { filters = {} } = action;
      if (filters.sectionCode === 'REQUEST') {
        return 'loading';
      }
      return state;
    }
    case at.FETCH_DETAIL_SUCCESS: {
      const { filters = {} } = action;
      if (filters.sectionCode === 'REQUEST') {
        return 'loaded';
      }
      return state;
    }
    case at.FETCH_DETAIL_FAILURE: {
      const { filters = {} } = action;
      if (filters.sectionCode === 'REQUEST') {
        return 'failed';
      }
      return state;
    }
    case at.CLEAR_ENTITIES: {
      return '';
    }
    default:
      return state;
  }
};

export const responseDetailStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_DETAIL_REQUEST: {
      const { filters = {} } = action;
      if (filters.sectionCode === 'RESPONSE') {
        return 'loading';
      }
      return state;
    }
    case at.FETCH_DETAIL_SUCCESS: {
      const { filters = {} } = action;
      if (filters.sectionCode === 'RESPONSE') {
        return 'loaded';
      }
      return state;
    }
    case at.FETCH_DETAIL_FAILURE: {
      const { filters = {} } = action;
      if (filters.sectionCode === 'RESPONSE') {
        return 'failed';
      }
      return state;
    }
    case at.CLEAR_ENTITIES: {
      return '';
    }
    default:
      return state;
  }
};

export const totalRequestCount = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_REQUEST_SUCCESS: {
      const { options } = action;
      return options.totalRecords;
    }
    case at.CLEAR_ENTITIES: {
      return 0;
    }
    default:
      return state;
  }
};

export const totalResponseCount = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_RESPONSE_SUCCESS: {
      const { options } = action;
      return options.totalRecords;
    }
    case at.CLEAR_ENTITIES: {
      return 0;
    }
    default:
      return state;
  }
};

export const auditRequestEntities = (state = [], action) => {
  switch (action.type) {
    case at.FETCH_AUDIT_REQUEST_SUCCESS: {
      const { messages } = action;
      return [...state, ...messages];
    }
    case at.CLEAR_AUDIT_ENTITIES: {
      return [];
    }
    default:
      return state;
  }
};

export const auditResponseEntities = (state = [], action) => {
  switch (action.type) {
    case at.FETCH_AUDIT_RESPONSE_SUCCESS: {
      const { messages } = action;
      return [...state, ...messages];
    }
    case at.CLEAR_AUDIT_ENTITIES: {
      return [];
    }
    default:
      return state;
  }
};

export const totalAuditRequestCount = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_AUDIT_REQUEST_SUCCESS: {
      return action.totalMessages;
    }
    case at.CLEAR_AUDIT_ENTITIES: {
      return 0;
    }
    default:
      return state;
  }
};

export const totalAuditResponseCount = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_AUDIT_RESPONSE_SUCCESS: {
      return action.totalMessages;
    }
    case at.CLEAR_AUDIT_ENTITIES: {
      return 0;
    }
    default:
      return state;
  }
};

export const auditRequestStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_AUDIT_REQUEST: {
      const { actionType } = action;
      if (actionType === 'REQUEST') {
        return 'loading';
      }
      return state;
    }
    case at.FETCH_AUDIT_REQUEST_SUCCESS: {
      const { actionType } = action;
      if (actionType === 'REQUEST') {
        return 'loaded';
      }
      return state;
    }
    case at.FETCH_AUDIT_REQUEST_FAILURE: {
      const { actionType } = action;
      if (actionType === 'REQUEST') {
        return 'failed';
      }
      return state;
    }
    case at.CLEAR_AUDIT_ENTITIES: {
      const { actionType } = action;
      if (actionType === 'REQUEST') {
        return '';
      }
      return state;
    }
    default:
      return state;
  }
};

export const auditResponseStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_AUDIT_REQUEST: {
      const { actionType } = action;
      if (actionType === 'RESPONSE') {
        return 'loading';
      }
      return state;
    }
    case at.FETCH_AUDIT_RESPONSE_SUCCESS: {
      const { actionType } = action;
      if (actionType === 'RESPONSE') {
        return 'loaded';
      }
      return state;
    }
    case at.FETCH_AUDIT_RESPONSE_FAILURE: {
      const { actionType } = action;
      if (actionType === 'RESPONSE') {
        return 'failed';
      }
      return state;
    }
    case at.CLEAR_AUDIT_ENTITIES: {
      const { actionType } = action;
      if (actionType === 'RESPONSE') {
        return '';
      }
      return state;
    }
    default:
      return state;
  }
};

export const saveAuditResponseStatus = (state = '', action) => {
  switch (action.type) {
    case at.SAVE_AUDIT_RESPONSE_REQUEST: {
      return 'loading';
    }
    case at.SAVE_AUDIT_RESPONSE_SUCCESS: {
      return 'loaded';
    }
    case at.SAVE_AUDIT_RESPONSE_FAILURE: {
      return 'failed';
    }
    case at.FETCH_AUDIT_REQUEST: {
      return '';
    }
    default:
      return state;
  }
};

export const saveRequestResponseStatus = (state = '', { type, message }) => {
  const response = {
    status: '',
    message: '',
  };

  switch (type) {
    case at.SAVE_REQUEST_RESPONSE_REQUEST: {
      response.status = 'loading';
      return response;
    }
    case at.SAVE_REQUEST_RESPONSE_SUCCESS: {
      response.status = 'loaded';
      return response;
    }
    case at.SAVE_REQUEST_RESPONSE_FAILURE: {
      response.status = 'failed';
      response.message = message;
      return response;
    }
    case at.FETCH_REQUEST: {
      return response;
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  responsePages,
  requestPages,
  requestPageStatus,
  responsePageStatus,
  requestDetailStatus,
  responseDetailStatus,
  totalRequestCount,
  totalResponseCount,
  auditRequestEntities,
  auditResponseEntities,
  auditRequestStatus,
  auditResponseStatus,
  totalAuditRequestCount,
  totalAuditResponseCount,
  saveAuditResponseStatus,
  saveRequestResponseStatus,
});
