import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

const PhoneOnly = (p) => <MediaQuery {...p} maxWidth={599} />;
const TabletPortraitUp = (p) => <MediaQuery {...p} minWidth={600} />;
const TabletLandscapeUp = (p) => <MediaQuery {...p} minWidth={900} />;
const TabletOnly = (p) => <MediaQuery {...p} minWidth={600} maxWidth={1200} />;
const DesktopUp = (p) => <MediaQuery {...p} minWidth={1200} />;
const BigDesktopUp = (p) => <MediaQuery {...p} minWidth={1800} />;

const Responsive = ({ size, ...props }) => {
  switch (size) {
    case 'phoneOnly':
      return <PhoneOnly {...props} />;
    case 'tabletPortraitUp':
      return <TabletPortraitUp {...props} />;
    case 'tabletLandscapeUp':
      return <TabletLandscapeUp {...props} />;
    case 'tabletOnly':
      return <TabletOnly {...props} />;
    case 'desktopUp':
      return <DesktopUp {...props} />;
    case 'bigDesktopUp':
      return <BigDesktopUp {...props} />;
    default:
      return <DesktopUp {...props} />;
  }
};

Responsive.propTypes = {
  size: PropTypes.string,
};

Responsive.defaultProps = {
  size: 'desktopUp',
};

export default Responsive;
