import axios from 'axios';
import config from '../config';

export function fetchDiscoveryTabPermissions({ professions }) {
  return axios.get(`${config.licenseesApiInternal}/discover/settings`, {
    params: { professions },
  });
}

export function fetchPopularCourses({ stateCode, professionId }) {
  return axios.get(
    `${config.licenseesApiInternal}/offerings?expand=totalItems&pageIndex=1&pageSize=12&sortField=POPULARITY&state=${stateCode}&profession=${professionId}&isNowCourses=true`,
    {}
  );
}

export function fetchKeepLearningCourses({ userId, stateCode, professionId }) {
  return axios.get(
    `${config.licenseesApiInternal}/discover/keep-learning/${userId}/${professionId}?expand=totalItems&sortField=RELEVANCE&state=${stateCode}`
  );
}

export function fetchCoursesRecentlyAdded({ stateCode, professionId }) {
  return axios.get(`${config.licenseesApiInternal}/discover/recently-added-courses/${professionId}?state=${stateCode}`);
}

export function fetchSpotlight({ stateId, professionId }) {
  return axios.get(`${config.licenseesApiInternal}/discover/spotlight?stateId=${stateId}&professionId=${professionId}`);
}
