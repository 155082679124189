import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import '../ListPager/ListPager.css';

import { Pager } from 'emerald-ui/lib';

class CourseHistoryListPager extends React.Component {
  componentDidMount() {
    const { resetCurrentPage, params } = this.props;
    if (resetCurrentPage) {
      const newParams = { ...params, page: 1 };
      this.updateUrlParams(newParams);
    }
  }

  updateUrl = (pathname, params) => {
    const {
      history: { replace },
    } = this.props;

    replace(`${pathname}?${queryString.stringify(params)}`);
  };

  updateUrlParams = (params) => {
    const {
      location: { pathname },
    } = this.props;
    this.updateUrl(pathname, params);
  };

  handlePageChange = (action) => {
    const { params, fetchCourseHistory } = this.props;
    let page = params.page;
    const oldPage = page;
    page = action === 'prev' ? page - 1 : page + 1;
    const newParams = { ...params, page };
    fetchCourseHistory({ ...newParams, oldPage });
    this.updateUrlParams(newParams);
  };

  handleLimitChage = (limit) => {
    const { params, fetchCourseHistory } = this.props;
    const newParams = { ...params, size: limit };
    fetchCourseHistory(newParams, { clearEntities: true });
    this.updateUrlParams(newParams);
  };

  render() {
    const { params, totalCount } = this.props;

    const { page, size } = params;

    return (
      <Pager
        id="list-pager"
        offset={(page - 1) * size}
        limit={size}
        total={totalCount}
        onPageChange={this.handlePageChange}
        onLimitChange={this.handleLimitChage}
      />
    );
  }
}

CourseHistoryListPager.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  params: PropTypes.object,
  totalCount: PropTypes.number,
  fetchCourseHistory: PropTypes.func,
  resetCurrentPage: PropTypes.bool,
};

export default CourseHistoryListPager;
