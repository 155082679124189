import React from 'react';
import PropTypes from 'prop-types';
import { SearchableSelect } from 'emerald-ui';

class SubjectAreaDropdown extends React.PureComponent {
  handleSelect = (subjectArea) => {
    const { onSubjectAreaChange } = this.props;
    if (typeof subjectArea === 'object') {
      return;
    }
    onSubjectAreaChange(subjectArea);
  };

  /* eslint-disable react/no-unknown-property */
  render() {
    const { className, subjectAreas, selectedSubjectArea } = this.props;

    const subjectAreaOptions = subjectAreas.map((s) => (
      <option value={s.id} key={s.id} selected={s.id.toString() === selectedSubjectArea} captionText={s.name}>
        <div data-subject-area-code={s.code}>{s.name}</div>
      </option>
    ));

    return (
      <SearchableSelect
        ariaLabel="subject area selector"
        className={`subject-area-dropdown ${className}`}
        disabled={!subjectAreas || subjectAreas.length === 0}
        onSelect={this.handleSelect}
        placeholder={'All subject areas'}
      >
        <option value={''} key={'-1'} selected={!selectedSubjectArea} captionText="All subject areas">
          <div>All subject areas</div>
        </option>
        {subjectAreaOptions}
      </SearchableSelect>
    );
  }
}

SubjectAreaDropdown.propTypes = {
  subjectAreas: PropTypes.array,
  selectedSubjectArea: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.string,
  onSubjectAreaChange: PropTypes.func,
};

SubjectAreaDropdown.defaultProps = {
  subjectAreas: [],
  onSubjectAreaChange: () => {},
};

export default SubjectAreaDropdown;
