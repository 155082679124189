import axios from 'axios';
import config from '../config';

export const saveClick = ({
  type,
  providerId,
  courseId,
  offeringId,
  clickedURL,
  courseSearchType,
  isFeaturedCourse,
}) => {
  return axios.request({
    method: 'post',
    url: `${config.courseSearchApiUrl}/clicks`,
    headers: {
      // Authorization: `Bearer ${authToken}`
    },
    data: {
      type,
      providerId,
      courseId,
      offeringId,
      clickedURL,
      courseSearchType,
      isFeaturedCourse,
    },
  });
};
