export default function (globalState) {
  const state = globalState.subscription;

  const getPaymentInfo = () => state.paymentInfo || {};
  const getPaymentInfoStatus = () => state.paymentInfoStatus || '';
  const getUpdatePaymentInfoStatus = () => state.updatePaymentInfoStatus || '';
  const getRenewFee = () => state.feeAmount || '';
  const getRenewFeeStatus = () => state.feeAmountStatus || '';
  const getHasActiveSubscription = () => state.hasActiveSubscription;
  const getHasActiveSubscriptionStatus = () => state.hasActiveSubscriptionStatus;
  const getDowngradeStatus = () => state.downgradeStatus || '';
  const getPromotionCodesDiscounts = () => state.promotionCodes || {};
  const getDefaultPriceOfSubscription = () => state.currentAmount || '';

  return {
    getPaymentInfo,
    getPaymentInfoStatus,
    getUpdatePaymentInfoStatus,
    getHasActiveSubscription,
    getHasActiveSubscriptionStatus,
    getRenewFee,
    getRenewFeeStatus,
    getDowngradeStatus,
    getPromotionCodesDiscounts,
    getDefaultPriceOfSubscription,
  };
}
