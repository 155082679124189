import axios from 'axios';
import config from '../config';
const { licenseesApiInternal } = config;

export const fetch = (userId, section) => {
  return axios.request({
    url: `${licenseesApiInternal}/transcripts/dashboard/${userId}?section=${section}`,
    headers: {
      Pragma: 'no-cache',
    },
    method: 'GET',
  });
};

export const fetchHeartCards = (userId) => {
  return axios.request({
    url: `${licenseesApiInternal}/heartcards?userId=${userId}`,
    method: 'GET',
  });
};

export const deleteHeartcard = (heartcardId, params = {}) => {
  return axios.request({
    url: `${licenseesApiInternal}/heartcards/remove/${heartcardId}`,
    headers: {},
    data: { loggedUserInfo: params },
    method: 'DELETE',
  });
};

export const addHeartcard = (heartcard, loggedUserInfo) => {
  return axios.request({
    url: `${licenseesApiInternal}/heartcards/add`,
    headers: {},
    data: { heartcard, loggedUserInfo },
    method: 'POST',
  });
};

export const editHeartcard = (heartcard, loggedUserInfo) => {
  return axios.request({
    url: `${licenseesApiInternal}/heartcards/edit`,
    headers: {},
    data: { heartcard, loggedUserInfo },
    method: 'PUT',
  });
};

export const getHeartcardAttachments = (heartcardId) => {
  return axios.request({
    url: `${licenseesApiInternal}/heartcards/${heartcardId}/attachments`,
    headers: {},
    method: 'GET',
  });
};

export const addAttachment = (file, heartcardId, userId) => {
  return axios.request({
    url: `${licenseesApiInternal}/heartcards/${heartcardId}/attachments`,
    headers: {},
    data: { file, userId },
    method: 'POST',
  });
};

export const deleteAttachment = (fileId, heartcardId, userId) => {
  return axios.request({
    url: `${licenseesApiInternal}/heartcards/${heartcardId}/attachments/${fileId}`,
    headers: {},
    data: { userId },
    method: 'DELETE',
  });
};
