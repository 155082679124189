import { put, call, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as api from '../../../api/communications';
import at from '../types';

export default function* fetchDetail({ filters }) {
  yield delay(400);
  const state = yield select();
  const user = state.authentication.licenseeAccount.user;

  let result;
  try {
    result = yield call(api.fetchDetail, user.id, filters);
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put({ type: at.FETCH_DETAIL_FAILURE, filters });
    return;
  }
  const { data } = result;
  yield put({
    type: at.FETCH_DETAIL_SUCCESS,
    filters,
    data,
    inboxId: filters.inboxId,
    options: data.options,
  });
}
