import { put, call } from 'redux-saga/effects';

import * as api from '../../../api/coursesRecommended';
import at from '../types';

export default function* fetch({ params }) {
  const { cycleId = 0, professionId, subjectsAreas = [], pkLicense } = params;

  const subjectsAreaIds = subjectsAreas.map(({ subjectAreaId }) => subjectAreaId);

  const coursesIdsTaken = subjectsAreas
    .map(({ courseIds: ids }) => ids)
    .reduce((prev, courseId) => [...prev, ...courseId], []);

  try {
    const { data } = yield call(api.fetchCoursesRecommended, {
      professionId,
      subjectAreas: subjectsAreaIds,
    });
    const subjectsRecommends = subjectsAreas.map((subjectRecommend) => {
      let { courses: coursesRecommended = [] } = data.items.find(
        ({ subjectAreaId: id }) => subjectRecommend.subjectAreaId === id
      );

      coursesRecommended = coursesRecommended.filter(({ course = {} }) => {
        const { id: courseId } = course;
        return !coursesIdsTaken.includes(courseId);
      });

      return {
        ...subjectRecommend,
        coursesRecommended,
      };
    });
    yield put({
      type: at.FETCH_SUCCESS,
      data: subjectsRecommends,
      cycleId,
      pkLicense,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({ type: at.FETCH_FAILURE, cycleId, pkLicense });
  }
}
