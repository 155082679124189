import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { refineQuery } from '../../utils';
import debounce from 'lodash/debounce';
import config from '../../config';
import { Toolbar, SearchForm, Button, Icon } from 'emerald-ui/lib';
import FiltersModal from './FiltersModal';
import DownloadPdfModal from './DownloadPdfModal';

import './styles.css';

const { REACT_APP_PDF_REQUEST_POLLING_INTERVAL_SECONDS = 5 } = process.env;

class CourseHistoryListToolbar extends React.Component {
  constructor(props) {
    super(props);
    const { params } = props;
    const { q = '' } = params;
    this.state = {
      q,
      showFiltersModal: false,
      showDownloadPdfModal: { triggeredOnHide: false, triggeredOnShow: false },
      pendingStatusReportPdf: false,
    };
  }

  updateUrl = (pathname, params) => {
    const {
      history: { replace },
    } = this.props;
    replace(`${pathname}?${queryString.stringify(params)}`);
  };

  updateUrlParams = debounce((params) => {
    const {
      location: { pathname },
    } = this.props;
    this.updateUrl(pathname, params);
  }, 250);

  handleOpenFiltersModal = () => {
    this.setState({ showFiltersModal: true });
  };

  handleCloseFiltersModal = () => {
    this.setState({ showFiltersModal: false });
  };

  handleQueryChange = (event) => {
    const { params, fetchCourseHistory } = this.props;
    const q = event.target.value;
    this.setState({ q });
    const newParams = refineQuery({ ...params, q });
    this.updateUrlParams(newParams);
    fetchCourseHistory(newParams, { clearEntities: true });
  };

  handleClearQuery = () => {
    const { params, fetchCourseHistory } = this.props;
    this.setState({ q: '' });
    const newParams = refineQuery({ ...params, q: '' });
    this.updateUrlParams(newParams);
    fetchCourseHistory(newParams, { clearEntities: true });
  };

  handleResetFilters = () => {
    const { params, fetchCourseHistory } = this.props;
    const newParams = refineQuery({
      ...params,
      startDate: '',
      endDate: '',
      subjectArea: '',
    });
    this.setState({ showFiltersModal: false });
    this.updateUrlParams(newParams);
    fetchCourseHistory(newParams, { clearEntities: true });
  };

  handleApplyFilters = (filters) => {
    const { params, fetchCourseHistory } = this.props;
    const newParams = refineQuery({
      ...params,
      startDate: filters.minCompletedDate,
      endDate: filters.maxCompletedDate,
      subjectArea: filters.subjectArea,
      page: 1,
    });
    this.setState({ showFiltersModal: false });
    this.updateUrlParams(newParams);
    fetchCourseHistory(newParams, { clearEntities: true });
  };

  getFiltersApplied = (filters) => {
    let count = 0;
    if (filters.startDate) {
      count++;
    }
    if (filters.endDate) {
      count++;
    }
    if (filters.subjectArea) {
      count++;
    }
    return count;
  };

  handleToggleDownloadPdfModal = () => {
    const { subscriberCredentials, downloadPdfCall, resetRequestStatus } = this.props;

    if (resetRequestStatus) {
      resetRequestStatus();
    }

    if (downloadPdfCall) {
      downloadPdfCall(subscriberCredentials);
    }

    this.resetModal();
  };

  componentWillUnmount() {
    this.stopPolling();
  }

  componentDidUpdate(prevProps) {
    const {
      getRequestStatus: currentPropsRequestStatus,
      requestDownloadPdfStatus: currentPropsDownloadStatus,
      navKey,
      params,
      subscriberCredentials,
      clearTimerDownload,
      getPdfStatusRequest,
      resetDownloadPdf,
      resetRequestStatus,
    } = this.props;

    const {
      downloadPdfStatuses: { initial, processing, finished },
    } = config;
    if (prevProps.params.pkLicense !== params.pkLicense) {
      clearTimerDownload();
      resetRequestStatus();
      resetDownloadPdf();
      this.setState({
        pendingStatusReportPdf: false,
        showDownloadPdfModal: { triggeredOnHide: false, triggeredOnShow: false },
      });
      getPdfStatusRequest(subscriberCredentials);
    } else {
      if (navKey === 'courses') {
        if (!this.state.pendingStatusReportPdf) {
          const isRequestStatusPending =
            currentPropsDownloadStatus.statusRequest === initial || currentPropsDownloadStatus.message;
          const wasRequestMade =
            currentPropsRequestStatus.statusRequest === processing ||
            currentPropsRequestStatus.statusRequest === initial;
          const shouldStartPolling = Object.keys(currentPropsRequestStatus).length
            ? wasRequestMade
            : isRequestStatusPending;

          const { showDownloadPdfModal } = this.state;
          const { triggeredOnHide, triggeredOnShow } = showDownloadPdfModal;

          if (shouldStartPolling) {
            this.setState({ pendingStatusReportPdf: true });
            this.polling();
          }
          if (
            Object.keys(currentPropsRequestStatus).length &&
            currentPropsRequestStatus.statusRequest === finished &&
            !triggeredOnShow &&
            !triggeredOnHide
          ) {
            this.stopPolling();
            this.setState((state) => ({
              showDownloadPdfModal: {
                ...state.showDownloadPdfModal,
                triggeredOnShow: true,
              },
            }));
          }
        } else {
          if (Object.keys(currentPropsRequestStatus).length && currentPropsRequestStatus.statusRequest === finished) {
            this.stopPolling();
            this.setState((state) => ({
              showDownloadPdfModal: {
                ...state.showDownloadPdfModal,
                triggeredOnShow: true,
              },
              pendingStatusReportPdf: false,
            }));
          }
        }
      }
    }
  }

  stopPolling = () => {
    const { timerDownload, resetTimerDownload } = this.props;
    if (timerDownload) {
      clearInterval(timerDownload);
      resetTimerDownload();
    }
  };

  componentDidMount() {
    const { subscriberCredentials, getPdfStatusRequest } = this.props;
    getPdfStatusRequest(subscriberCredentials);
  }

  polling() {
    const { timerDownload, getPdfStatusRequest, subscriberCredentials, setTimerDownload } = this.props;
    if (!timerDownload) {
      const time = REACT_APP_PDF_REQUEST_POLLING_INTERVAL_SECONDS * 1000;
      const timer = setInterval(() => getPdfStatusRequest(subscriberCredentials), time);
      setTimerDownload(timer);
    }
  }

  handleHidePdfModal = () => {
    this.setState(() => ({
      showDownloadPdfModal: { triggeredOnShow: false, triggeredOnHide: true },
    }));
  };

  resetModal = () => {
    this.setState(() => ({
      showDownloadPdfModal: { triggeredOnShow: false, triggeredOnHide: false },
    }));
  };

  render() {
    const {
      params,
      getRequestStatus,
      browserDownload,
      getBrowserDownload,
      updatePdfStatus,
      navKey,
      subjectAreaList,
      coursesDisplayed,
      subscriptionType,
      loggedOnBehalf,
    } = this.props;
    const { q, showFiltersModal, showDownloadPdfModal, pendingStatusReportPdf } = this.state;

    const showSubjectsFilter = (subscriptionType !== 'BAS' || loggedOnBehalf) && navKey === 'courses';

    const { startDate, endDate, subjectArea = '', pkLicense } = params;
    const filtersApplied = this.getFiltersApplied(params);
    return (
      <div className="toolbar-wrapper">
        <FiltersModal
          {...{
            minCompletedDate: startDate,
            maxCompletedDate: endDate,
            subjectArea: subjectArea.toString(),
            subjectAreaList,
            showSubjectsFilter,
            key: pkLicense,
            show: showFiltersModal,
            onHide: this.handleCloseFiltersModal,
            onResetFilters: this.handleResetFilters,
            onApplyFilters: this.handleApplyFilters,
          }}
        />
        {navKey === 'courses' && (
          <DownloadPdfModal
            {...{
              show: showDownloadPdfModal.triggeredOnShow,
              onHide: this.handleHidePdfModal,
              cchPdfData: getRequestStatus,
              browserDownload,
              getBrowserDownload,
              updatePdfStatus,
              onResetModal: this.resetModal,
            }}
          />
        )}
        <Toolbar className="course-history-list-toolbar">
          <SearchForm
            clearable
            className="course-history-list-search-form"
            value={q}
            onChange={this.handleQueryChange}
            onClear={this.handleClearQuery}
            onSubmit={(e) => {
              e.preventDefault();
            }}
            placeholder="Search course history"
          />
          <Button className="filter-btn" onClick={this.handleOpenFiltersModal}>
            <div className="toolbar-button-wrapper">
              <Icon name="filter_list" />
              <span className="hide-mobile">Filter</span>
              <span className="filter-number">({filtersApplied})</span>
            </div>
          </Button>
          {navKey === 'courses' && (
            <Button onClick={this.handleToggleDownloadPdfModal} disabled={pendingStatusReportPdf || !coursesDisplayed}>
              <div className="toolbar-button-wrapper">
                {pendingStatusReportPdf ? (
                  <div className="downloadPdfLoader"></div>
                ) : (
                  <Icon className="download-icon" name="file_download" />
                )}
                <span className="hide-mobile">Download PDF Report</span>
              </div>
            </Button>
          )}
        </Toolbar>
      </div>
    );
  }
}

CourseHistoryListToolbar.propTypes = {
  params: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  fetchCourseHistory: PropTypes.func,
  licenseeAccount: PropTypes.object,
  subscriberCredentials: PropTypes.object,
  downloadPdfCall: PropTypes.func,
  getPdfStatusRequest: PropTypes.func,
  requestDownloadPdfStatus: PropTypes.object,
  getRequestStatus: PropTypes.object,
  browserDownload: PropTypes.object,
  getBrowserDownload: PropTypes.func,
  updatePdfStatus: PropTypes.func,
  resetRequestStatus: PropTypes.func,
  navKey: PropTypes.string,
  coursesDisplayed: PropTypes.bool,
  timerDownload: PropTypes.any,
  subjectAreaList: PropTypes.array,
  subscriptionType: PropTypes.string,
  loggedOnBehalf: PropTypes.bool,
  setTimerDownload: PropTypes.func,
  resetTimerDownload: PropTypes.func,
  clearTimerDownload: PropTypes.func,
  resetDownloadPdf: PropTypes.func,
};

export default CourseHistoryListToolbar;
