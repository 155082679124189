import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { refineQuery } from '../utils';
import * as courseHistory from '../store/courseHistory';
import CourseHistoryDeletedPage from '../components/CourseHistoryDeletedPage';
import * as authenticationStore from '../store/authentication';

function mapStateToProps(state, ownProps) {
  const previousPage = state.courseHistory.previousPage;

  const authenticationSelectors = authenticationStore.selectors(state);
  const token = authenticationSelectors.getAuthenticationLegacyToken().token;
  const user = authenticationSelectors.getLicenseeAccount();
  const authenticatedAccount = authenticationSelectors.getAuthenticatedAccount();

  const courseHistorySelectors = courseHistory.selectors(state);
  const filters = refineQuery(queryString.parse(ownProps.location.search));
  const { pkLicense } = ownProps.match.params;
  filters.pkLicense = pkLicense;
  if (!filters.page) {
    filters.page = 1;
  }
  if (!filters.size) {
    filters.size = 10;
  }
  const { page } = filters;

  const { licenses = [], subscriptions = [] } = user;

  const showCreditCourseType = licenses.some((license) => license.profession.showCreditCourseType);

  const subscriptionType = subscriptions[0] ? subscriptions[0].type : '';

  return {
    authenticatedAccount,
    authToken: token,
    hasOneLicense: licenses.length === 1,
    user,
    subscriptionType,
    showCreditCourseType,
    filters,
    courseHistory: courseHistorySelectors.getEntitiesDeletedByPage(page),
    previousCourseHistory: courseHistorySelectors.getEntitiesDeletedByPage(previousPage),
    pageFetchStatus: courseHistorySelectors.getDeletedPageFetchStatus(page),
    courseHistoryFetchStatus: courseHistorySelectors.getEntitiesFetchStatus(),
    totalCount: courseHistorySelectors.getDeletedTotalCount(),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchCourseHistory: courseHistory.actions.fetch,
    fetchCourseHistoryDetail: courseHistory.actions.fetchDetail,
    fetchCourseHistoryAttachments: courseHistory.actions.fetchAttachments,
    addCourseHistoryAttachment: courseHistory.actions.addAttachment,
    deleteCourseHistoryAttachment: courseHistory.actions.deleteAttachment,
    rateCourse: courseHistory.actions.rateCourse,
    deleteCourse: courseHistory.actions.deleteCourse,
  })(CourseHistoryDeletedPage)
);
