import { put, call, select, cancelled } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';

import * as api from '../../../api/inbox';
import * as actions from '../actions';
import at from '../types';
import * as schema from '../schema';

export default function* fetch({ filters }) {
  yield put(actions.setPageFetchStatus(filters, 'loading'));
  try {
    yield delay(400);
    const state = yield select();
    const user = state.authentication.licenseeAccount.user;
    const { data } = yield call(api.fetch, user.id, filters);
    const normalizedData = normalize(data.items, schema.inboxNotificationsArray);
    yield put({
      type: at.FETCH_SUCCESS,
      filters,
      ...normalizedData,
      options: data.options,
    });
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put({ type: at.FETCH_FAILURE, filters });
  } finally {
    if (yield cancelled()) {
      yield put(actions.setPageFetchStatus(filters.page, 'cancelled'));
    }
  }
}
