import { takeEvery } from 'redux-saga/effects';

import at from '../types';
import fetch from './fetch';
import fetchDetail from './fetchDetail';

export default function* root() {
  yield takeEvery(at.FETCH_REQUEST, fetch);
  yield takeEvery(at.FETCH_DETAIL_REQUEST, fetchDetail);
}
