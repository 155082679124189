import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { SingleSelect, Modal, Button } from 'emerald-ui';
import './ReportedExemptionFiltersModal.css';

class ReportedExemptionFiltersModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: props.status || null,
      period: props.period || null,
    };
  }

  componentDidMount() {
    const { fetchLicensePeriods, pkLicense } = this.props;
    fetchLicensePeriods({ pkLicense });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { fetchLicensePeriods, pkLicense } = this.props;
    const { pkLicense: newPkLicense } = nextProps;
    if (pkLicense !== newPkLicense) {
      fetchLicensePeriods({ pkLicense: newPkLicense });
    }
  }

  handleResetFilters = () => {
    const { onResetFilters } = this.props;
    this.setState({
      period: null,
      status: null,
    });
    onResetFilters(this.state);
  };

  handleApplyFilters = () => {
    const { onApplyFilters } = this.props;
    onApplyFilters(this.state);
  };

  onSelectStatus = (value) => {
    this.setState({
      status: value,
    });
  };

  onSelectPeriod = (value) => {
    this.setState({
      period: value,
    });
  };

  render() {
    const { show, onHide, licenses = [], pkLicense } = this.props;
    const { status, period } = this.state;
    const selectedLicense = licenses.find((l) => l.id === parseInt(pkLicense, 10));
    let periods = [];
    if (selectedLicense && selectedLicense.periods) {
      periods = selectedLicense.periods;
    }
    return (
      <Modal id="reported-exemption-filters" hideOnOutsideClick={true} show={show} onHide={onHide} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="selectors-container">
            <div className="period-selector-container">
              <SingleSelect
                id="period-selector"
                label="Period"
                disabled={periods.length === 0}
                onSelect={this.onSelectPeriod}
                helpText={periods.length === 0 && 'No periods found'}
              >
                <option disabled>Select an option...</option>
                {periods.map((p) => {
                  return (
                    <option value={p.id} key={p.id} selected={p.id === period}>
                      {moment(p.renewalStartDate).format('DD MMM YYYY')} -{' '}
                      {moment(p.renewalEndDate).format('DD MMM YYYY')}
                    </option>
                  );
                })}
              </SingleSelect>
            </div>
            <div className="status-selector-container">
              <SingleSelect label="Status" id="status-selector" onSelect={this.onSelectStatus}>
                <option disabled>Select an option...</option>
                <option value="PENDING" selected={'PENDING' === status}>
                  PENDING
                </option>
                <option value="APPROVED" selected={'APPROVED' === status}>
                  APPROVED
                </option>
                <option value="REVOKE" selected={'REVOKE' === status}>
                  REVOKED
                </option>
              </SingleSelect>
            </div>
          </div>
          <div className="buttons-container">
            <Button color="info" shape="flat" onClick={this.handleResetFilters}>
              RESET FILTERS
            </Button>
            <Button color="info" onClick={this.handleApplyFilters}>
              APPLY
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

ReportedExemptionFiltersModal.propTypes = {
  show: PropTypes.bool,
  licenses: PropTypes.array,
  pkLicense: PropTypes.string,
  status: PropTypes.string,
  period: PropTypes.number,
  fetchLicensePeriods: PropTypes.func,
  onHide: PropTypes.func,
  onApplyFilters: PropTypes.func,
  onResetFilters: PropTypes.func,
};

export default ReportedExemptionFiltersModal;
