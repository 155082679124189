import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';

import LoginPage from '../components/LoginPage';
import * as authenticationStore from '../store/authentication';

import * as utils from '../utils';
import config from '../config';

const { secureCookie } = config;

function mapStateToProps(globalState, ownProps) {
  const { location } = ownProps;
  const query = queryString.parse(location.search);
  const token = utils.getCookie(secureCookie);
  const authenticationSelectors = authenticationStore.selectors(globalState);
  const licenseeAccount = authenticationSelectors.getLicenseeAccount();
  const { subscriptions = [], isBetaAvailable } = licenseeAccount;
  const subscriptionType = subscriptions[0] ? subscriptions[0].type : 'BAS';

  return {
    token,
    query,
    subscriptionType,
    isBetaAvailable,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    setAuthenticationToken: authenticationStore.actions.setAuthenticationToken,
    setAuthenticationLegacyToken: authenticationStore.actions.setAuthenticationLegacyToken,
    setAuthenticationStatus: authenticationStore.actions.setAuthenticationStatus,
    setAuthenticatedAccount: authenticationStore.actions.setAuthenticatedAccount,
    setLicenseeAccount: authenticationStore.actions.setLicenseeAccount,
    resetState: () => ({ type: 'RESET_STATE' }),
  })(LoginPage)
);
