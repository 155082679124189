import { takeLatest } from 'redux-saga/effects';

import at from '../types';
import { fetch } from './fetch';
import fetchQuestions from './fetchQuestions';
import fetchAttachments from './fetchAttachments';
import revokeExemption from './revokeExemption';

export default function* root() {
  yield takeLatest(at.FETCH_REQUEST, fetch);
  yield takeLatest(at.FETCH_QUESTIONS_REQUEST, fetchQuestions);
  yield takeLatest(at.FETCH_ATTACHMENTS_REQUEST, fetchAttachments);
  yield takeLatest(at.REVOKE_EXEMPTION_REQUEST, revokeExemption);
}
