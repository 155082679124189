import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MediaQuery from 'react-responsive';
import startCase from 'lodash/startCase';
import { Avatar, Navbar, Nav, Container, IconButton, Icon, RedIndicator } from 'emerald-ui';
import { IconDropdown, DropdownItem } from 'emerald-ui-3';
import ShoppingCart from './components/ShoppingCartIcon';
import ImageWithFallback from '../ImageWithFallback';
import utils from '../../utils';
import * as eventsApi from '../../api/events';
import * as statisticsApi from '../../api/statistics';
import config from '../../config';
import iconCEBrokerWhite from './assets/CEBroker-logo-white-full-color-lockup.svg';
import iconPoweredByCeb from './assets/powered-by-ceb.svg';
import './Navbar.css';

const {
  getCourseSearchUrl,
  courseSearchUrl,
  AnalyticsTrackingEntities: { actions, categories },
  SUBSCRIPTION_TYPES,
} = config;

const {
  REACT_APP_POLLING_INTERVAL_MINUTES = 30,
  REACT_APP_COURSE_SEARCH_LABEL,
  REACT_APP_ENABLE_CART: isEnabledCart,
  REACT_APP_SHOPPING_CART_LOCALSTORAGE_KEY: shoppingCartKey,
} = process.env;

const buildCourseSearchUrl = (user, loggedOnBehalf, firstProfession) => {
  const state = firstProfession.board && firstProfession.board.state ? firstProfession.board.state.code : '';
  const queryParams = {
    profession: firstProfession.id,
    state,
  };
  if (loggedOnBehalf) {
    queryParams.userId = user.id;
  }
  return getCourseSearchUrl(queryParams);
};

class LicenseeNavbar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showRedShoppingCartIndicator: false,
      totalCourses: 0,
    };

    this.navBarRef = React.createRef();
    this.myLearningTabRef = React.createRef();
    this.handleMenuToggle = this.handleMenuToggle.bind(this);
    this.handleClickCapture = this.handleClickCapture.bind(this);
  }

  forceLogin() {
    const { user, loggedOnBehalf, history } = this.props;
    const overview = loggedOnBehalf ? `&logged_on_behalf=true&user_id=${user.id}` : '';
    const url = `/login?redirect_to=overview${overview}`;
    history.push(url);
  }

  polling() {
    const time = REACT_APP_POLLING_INTERVAL_MINUTES * 1000 * 60;
    this.timer = setInterval(() => this.forceLogin(), time);
  }

  componentDidMount() {
    utils.addNewStyle(
      `.eui-navbar.eui-navbar-inverse {background-color: #${this.props.professionBrandColor} !important}`
    );
    const { autoRenewSettings, lastSubscription } = this.props;
    // inActiveSubscription -> sysdate between dt_renewal_start and dt_renewal_end
    const { inActiveSubscription } = lastSubscription;
    const { inStripeProcessing = 0 } = autoRenewSettings || {};
    if (inStripeProcessing === 1 || inActiveSubscription === 0) {
      this.polling();
    }
  }

  UNSAFE_componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  handleDashboardClick = (e) => {
    e.preventDefault();
    const { history = {} } = this.props;
    history.push('/dashboard');

    this.navBarRef.current.closeNavbar();
  };

  handleHeartcardPageClick = (e) => {
    e.preventDefault();
    const { history = {} } = this.props;
    history.push('/heartcard');

    this.navBarRef.current.closeNavbar();
  };

  handleCourseSearchLinkClick = (e) => {
    e.preventDefault();
    const { user, loggedOnBehalf, firstProfession } = this.props;
    const statisticCode = 'COURSE_SEARCH_CLICK';
    const type = 'TOPNAV';
    statisticsApi.saveStatistic(user.id, statisticCode, type);
    utils.redirectTo(buildCourseSearchUrl(user, loggedOnBehalf, firstProfession), false);
  };

  handleMenuToggle(toggled) {
    if (toggled) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }

  handleUpgradeClick = (e) => {
    e.preventDefault();
    const { user = {}, history = {}, subscriptionType, isFTOAvailable } = this.props;
    const statisticCode = 'UPGRADE';
    const type = 'TOPNAV';
    eventsApi.saveEvent(user.id, statisticCode, type);

    const cameFromDropdown = e.target.tagName.toUpperCase() === 'BUTTON';
    let action;
    if (cameFromDropdown) {
      action = actions.sectionUpgradeLinkMenu;
    } else {
      action = actions.sectionTopNavUpgrade;
      if (subscriptionType === SUBSCRIPTION_TYPES.BAS && !isFTOAvailable) {
        action = actions.sectionRenewSubsTopNav;
      }
    }

    utils.sendAnalyticsEvent({
      category: categories.upgrade,
      action,
      label: utils.getAccountTypeLabel(subscriptionType, isFTOAvailable),
    });

    history.push('/upgrade', { comeFrom: cameFromDropdown ? 'upgradeDropdown' : 'navbar' });

    this.navBarRef.current.closeNavbar();
  };

  handleSettingClick = (e) => {
    e.preventDefault();
    const { history = {} } = this.props;
    history.push('/settings/profile');

    this.navBarRef.current.closeNavbar();
  };

  handleInboxClick = (e) => {
    e.preventDefault();
    const { history = {}, subscriptionType } = this.props;
    const communicationsLink = '/inbox/communications';
    const notificationsLink = '/inbox/notifications';
    const link = subscriptionType === 'BAS' ? communicationsLink : notificationsLink;
    history.push(link);

    this.navBarRef.current.closeNavbar();
  };

  handleLogoutClick = () => {
    const { logout, associationUsersInfo, loggedOnBehalf } = this.props;
    logout({
      ...associationUsersInfo,
      loggedOnBehalf,
    });

    this.navBarRef.current.closeNavbar();
  };

  getMyLearningURL() {
    const { canSeeDiscover } = this.props;
    return `/my-learning/${canSeeDiscover ? 'discovery' : 'in-progress'}`;
  }

  handleMyLearningClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { history = {} } = this.props;

    history.push(this.getMyLearningURL());

    this.navBarRef.current.closeNavbar();
  };

  handleClickCapture() {
    this.navBarRef.current.closeNavbar();
  }

  handleViewCartClick = async (e) => {
    e.preventDefault();
    const { loggedOnBehalf, user } = this.props;
    const queryParams = {
      redirect_to: 'cart',
    };
    if (loggedOnBehalf) {
      queryParams.userId = user.id;
    }
    utils.redirectTo(getCourseSearchUrl(queryParams, false));
  };

  renderMyLearning = (desktopOnly) => {
    const { pathname } = this.props;
    const myLearningPath = this.getMyLearningURL();
    const className = `my-learning-tab desktop-only ${pathname.includes('my-learning') && 'active'}`;
    return (
      <React.Fragment>
        <a
          ref={this.myLearningTabRef}
          href={myLearningPath}
          onClick={this.handleMyLearningClick}
          title=""
          className={`${desktopOnly && className} menu-item`}
        >
          <Icon name="school" className="menu-icon" />
          <span>My learning</span>
        </a>
      </React.Fragment>
    );
  };

  render() {
    const {
      user = {},
      subscriptionType,
      isFTOAvailable,
      onClickCebClassic,
      showBrowseCourseLink,
      pathname,
      loggedOnBehalf,
      totalNotifications,
      autoRenewSettings,
      associationUsersInfo,
      isPaidByBoard,
      professionBrandColor,
      hasBanner,
      totalItemsInCart,
    } = this.props;

    const { inStripeProcessing = 0 } = autoRenewSettings || {};

    const { firstName, lastName } = user;

    const { associationLogo, isAssociationUser } = associationUsersInfo;

    const query = {
      userId: user.id,
      redirect_to: 'saved-courses',
    };

    const { showRedShoppingCartIndicator, totalCourses } = this.state;

    const isOverviewPage = /\/license\/*/.test(pathname);
    const isSettingsPage = /\/settings\/*/.test(pathname);

    const showNotificationsIndicator = totalNotifications > 0;

    const isUpgradeAvailable =
      ![SUBSCRIPTION_TYPES.FTO, SUBSCRIPTION_TYPES.CON, SUBSCRIPTION_TYPES.EMP, SUBSCRIPTION_TYPES.PRO_PLUS].includes(
        subscriptionType
      ) &&
      !isPaidByBoard &&
      !inStripeProcessing;

    const brandingStyles = {
      backgroundColor: `#${professionBrandColor}`,
    };

    const professionBrandColorValue = professionBrandColor === '' ? '' : 'navbar-branding-color';

    let upgradeLabel;
    if (subscriptionType === SUBSCRIPTION_TYPES.BAS) {
      upgradeLabel = isFTOAvailable ? '7 day trial' : 'Renew subscription';
    } else {
      upgradeLabel = 'Upgrade account';
    }

    const courseSearchLabel = REACT_APP_COURSE_SEARCH_LABEL || 'Course Search';

    const isEnabledCartLocalStorage = utils.getLocalStorage(shoppingCartKey);
    const showCartIcon = isEnabledCart === 'true' || isEnabledCartLocalStorage === true;

    if (totalItemsInCart > 0) {
      this.setState({
        showRedShoppingCartIndicator: true,
        totalCourses: totalItemsInCart,
      });
    }

    return (
      <Navbar
        breakAt="lg"
        fixedAtTop
        inverse={true}
        style={brandingStyles}
        className={`bar-brightness ${professionBrandColorValue} lic-navbar ${classnames({
          'lic-navbar-banner app-has-banner': hasBanner,
        })}`}
        onExpand={this.handleMenuToggle}
        ref={this.navBarRef}
      >
        <Container>
          <Navbar.Brand className={`${isAssociationUser ? 'brand-association-logo' : ''} navbar-brand`}>
            <a href={config.licenseesUrl} title="">
              <React.Fragment>
                {isAssociationUser && (
                  <React.Fragment>
                    <ImageWithFallback
                      width={'143'}
                      height={'66'}
                      alt="CE Broker logo"
                      src={associationLogo}
                      defaultSrc=""
                      className="association-logo"
                    />
                    <div className="vertical-divider" />
                  </React.Fragment>
                )}
                <ImageWithFallback
                  className="secondary-logo"
                  alt="CE Broker logo"
                  src={isAssociationUser ? iconPoweredByCeb : iconCEBrokerWhite}
                  defaultSrc=""
                />
              </React.Fragment>
            </a>
          </Navbar.Brand>

          {showCartIcon && (
            <ShoppingCart
              showRedIndicator={showRedShoppingCartIndicator}
              redIndicatorNumber={totalCourses}
              onClick={this.handleViewCartClick}
              hideForDesktop
            />
          )}

          <Nav className={professionBrandColorValue} style={brandingStyles} name="menu" grow collapsible>
            <a
              title=""
              href="/dashboard"
              onClick={this.handleDashboardClick}
              className={pathname.includes('license') ? 'active menu-item' : 'menu-item'}
            >
              <Icon name="home" className="menu-icon" />
              <span>Home</span>
            </a>
            {showBrowseCourseLink && (
              <a
                href={`${courseSearchUrl}/search`}
                onClick={this.handleCourseSearchLinkClick}
                className="menu-item"
                title=""
              >
                <Icon name="search" className="menu-icon" />
                <span>{courseSearchLabel}</span>
              </a>
            )}

            <MediaQuery maxWidth={1200}>
              {(match) => {
                return match ? (
                  <React.Fragment>
                    {this.renderMyLearning(false)}

                    {/* eslint-disable-next-line  jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    <DropdownItem title="" onClick={this.handleInboxClick}>
                      <Icon name="email" />
                      <span>Inbox</span>
                    </DropdownItem>
                    <DropdownItem
                      href={'/settings/profile'}
                      onClick={this.handleSettingClick}
                      className={pathname.startsWith(isSettingsPage).toString() ? 'active' : ''}
                      title=""
                    >
                      <Icon name="settings" />
                      <span>Account settings</span>
                    </DropdownItem>
                    {subscriptionType !== 'BAS' && (
                      <DropdownItem href={getCourseSearchUrl(query)} title="">
                        <Icon name="favorite" />
                        <span>Saved Courses</span>
                      </DropdownItem>
                    )}
                    {isUpgradeAvailable && (
                      <DropdownItem href="/upgrade" onClick={this.handleUpgradeClick} title="">
                        <Icon name="upgrade" />
                        <span>{upgradeLabel}</span>
                      </DropdownItem>
                    )}

                    <DropdownItem
                      href={'/heartcard'}
                      onClick={this.handleHeartcardPageClick}
                      title=""
                      data-testid="heartcard-status-item"
                    >
                      <Icon name="credit_card" />
                      <span>Heartcard status</span>
                    </DropdownItem>

                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    <DropdownItem useButton onClick={this.handleLogoutClick}>
                      <Icon name="exit_to_app" />
                      <span>Sign out</span>
                    </DropdownItem>

                    {(loggedOnBehalf || (!isPaidByBoard && !isAssociationUser)) && (
                      <DropdownItem useButton onClick={onClickCebClassic}>
                        <Icon name="replay" />
                        <span>CE Broker Classic</span>
                      </DropdownItem>
                    )}
                  </React.Fragment>
                ) : (
                  this.renderMyLearning(true)
                );
              }}
            </MediaQuery>
          </Nav>

          <Nav collapsible className="desktop-only lic-navbar-desktop">
            {isUpgradeAvailable && (
              <a href="/upgrade" onClick={this.handleUpgradeClick} className="upgrade-link nolink" title="">
                {upgradeLabel}
              </a>
            )}

            {showCartIcon && (
              <ShoppingCart
                showRedIndicator={showRedShoppingCartIndicator}
                redIndicatorNumber={totalCourses}
                onClick={this.handleViewCartClick}
              />
            )}

            <RedIndicator active={subscriptionType !== 'BAS' && showNotificationsIndicator}>
              <IconButton className="nav-inbox-link" icon="email" title="" onClick={this.handleInboxClick} />
            </RedIndicator>

            <IconDropdown
              fromRight
              icon="settings"
              id="nav-user-dropdown"
              className="avatar-dropdown"
              buttonProps={{
                'data-testid': 'settings-dropdown-button',
              }}
            >
              <DropdownItem
                eventKey={1}
                disabled={true}
                title={`${firstName} ${lastName}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '11px',
                  paddingBottom: '10px',
                  minWidth: '217px',
                  color: 'red',
                }}
              >
                <Avatar title={`${firstName} ${lastName}`} size="lg" />
                <div className="avatar-username text-color">
                  <p>{startCase(`${firstName} ${lastName}`.toLowerCase())}</p>
                  {isUpgradeAvailable && (
                    <button className="lic-navbar-nav-button-upgrade" onClick={this.handleUpgradeClick}>
                      Upgrade account
                    </button>
                  )}
                </div>
              </DropdownItem>
              <DropdownItem separator />
              <DropdownItem
                className="custom-menuitem"
                icon="settings"
                eventKey={3.1}
                href={'/settings/profile'}
                title=""
                onClick={this.handleSettingClick}
              >
                Account settings
              </DropdownItem>
              {subscriptionType !== 'BAS' && (
                <DropdownItem
                  className="custom-menuitem"
                  icon="favorite"
                  eventKey={3.3}
                  href={getCourseSearchUrl(query)}
                  title=""
                >
                  Saved Courses
                </DropdownItem>
              )}
              <DropdownItem
                className="custom-menuitem"
                icon="credit_card"
                eventKey={3.2}
                href={'/heartcard'}
                onClick={this.handleHeartcardPageClick}
                title=""
                data-testid="heartcard-status-item"
              >
                Heartcard status
              </DropdownItem>
              <DropdownItem
                className="custom-menuitem"
                icon="exit_to_app"
                eventKey={3.4}
                onClick={this.handleLogoutClick}
                useButton
              >
                Sign out
              </DropdownItem>
              {((isOverviewPage && loggedOnBehalf) || (!isAssociationUser && !isPaidByBoard)) && (
                <DropdownItem separator />
              )}
              {(loggedOnBehalf || (!isPaidByBoard && !isAssociationUser)) && (
                <React.Fragment>
                  <DropdownItem
                    className="custom-menuitem"
                    id="cebclassic-link"
                    icon="replay"
                    eventKey={3.5}
                    onClick={onClickCebClassic}
                    useButton
                  >
                    CE Broker Classic
                  </DropdownItem>
                </React.Fragment>
              )}
            </IconDropdown>
          </Nav>
        </Container>
      </Navbar>
    );
  }
}

LicenseeNavbar.propTypes = {
  fullScreen: PropTypes.bool,
  user: PropTypes.object,
  subscriptionType: PropTypes.string,
  isFTOAvailable: PropTypes.bool,
  loggedOnBehalf: PropTypes.bool,
  history: PropTypes.object,
  logout: PropTypes.func,
  onClickCebClassic: PropTypes.func,
  showBrowseCourseLink: PropTypes.bool,
  pathname: PropTypes.string,
  firstProfession: PropTypes.object,
  totalNotifications: PropTypes.number,
  autoRenewSettings: PropTypes.object,
  lastSubscription: PropTypes.object,
  associationUsersInfo: PropTypes.object,
  isPaidByBoard: PropTypes.bool,
  professionBrandColor: PropTypes.string,
  hasBanner: PropTypes.bool,
  canSeeDiscover: PropTypes.bool,
  totalItemsInCart: PropTypes.number,
};

export default LicenseeNavbar;
