import React from 'react';
import PropTypes from 'prop-types';
import { Card, Modal } from 'emerald-ui';
import get from 'lodash/get';

import CoursesListItems from '../CoursesListItems';
import './CourseRecommendationCard.css';
import SubjectAreaThumbnail from '../SubjectAreaThumbnail';
import CourseDetail from '../CourseDetail';
import { saveStatistic } from '../../api/statistics';
import utils from '../../utils';
import config from '../../config';

const { getCourseSearchUrl } = config;

class CourseRecommendationCard extends React.Component {
  state = {
    subjectSelected: 1,
    courses: [],
    showCourseDetail: false,
    course: {},
  };

  componentDidMount() {
    const { recommendations } = this.props;
    if (recommendations.length > 0) {
      this.setState({
        subjectSelected: recommendations[0].subjectAreaId,
        courses: recommendations[0].coursesRecommended,
        hasMore: recommendations[0].hasMore,
      });
    }
  }

  onSubjectClick = ({ subjectAreaId }) => {
    const { coursesRecommended = [], hasMore } = this.props.recommendations.find(
      (subject) => subject.subjectAreaId === subjectAreaId
    );

    this.setState({
      subjectSelected: subjectAreaId,
      courses: coursesRecommended,
      hasMore,
    });
  };

  onSeeMoreClick = (event) => {
    event.preventDefault();
    const { professionId = 0, stateCode = '', user = {}, loggedOnBehalf = false } = this.props;

    const statisticCode = 'COURSE_SEARCH_CLICK';
    const type = 'COURSE_RECOMMENDATIONS';
    saveStatistic(user.id, statisticCode, type);

    const queryParams = {
      profession: professionId,
      state: stateCode,
      subjectArea: this.state.subjectSelected,
    };

    if (loggedOnBehalf) {
      queryParams.userId = user.id;
    }
    const courseSearchUrl = getCourseSearchUrl(queryParams);
    utils.redirectTo(courseSearchUrl);
  };

  handleOnCourseClick = (params) => {
    const { offeringId } = params;
    const course = this.state.courses.find(({ id }) => offeringId === id) || {};

    this.setState({ showCourseDetail: true, course });
  };

  handleCloseCourseDetail = () => {
    this.setState({ showCourseDetail: false });
    utils.handleDisplayAdaComponent(true);
  };

  render() {
    const {
      professionId = 0,
      stateCode = '',
      user = {},
      loggedOnBehalf = false,
      seeCheckoutPage,
      customerId,
      addNotification,
      location,
      saveCartItem,
      coursesInCart,
      saveCartItemStatus,
      clearStatusCart,
      savedOfferingsList,
      handleSaveOffering,
      savingStatuses,
      showFavoriteIcon,
      totalItemsInCart,
      shouldShowProPlusBadge,
    } = this.props;

    const queryParams = {
      profession: professionId,
      state: stateCode,
      subjectArea: this.state.subjectSelected,
    };

    if (loggedOnBehalf) {
      queryParams.userId = user.id;
    }

    const courseSearchUrl = getCourseSearchUrl(queryParams);
    const inCoursescriptionEnrolled = get(this.state, 'course.course.provider.inCoursescriptionEnrolled', false);
    const showProPlusBadge = inCoursescriptionEnrolled && shouldShowProPlusBadge;

    return (
      <Card className="course-recommendation-card">
        <div className="subject-areas-container">
          <div className="subject-areas-panel">
            {this.props.recommendations.map((subjects) => (
              <SubjectAreaThumbnail
                key={subjects.subjectAreaId}
                subjectAreaId={subjects.subjectAreaId}
                subjectAreaName={subjects.subjectAreaName}
                hoursNeeded={subjects.hoursNeeded}
                isYesNotRequirement={subjects.isYesNotRequirement}
                selected={subjects.subjectAreaId === this.state.subjectSelected}
                onSelect={this.onSubjectClick}
              />
            ))}
          </div>
        </div>
        <div className="courses-list">
          <CoursesListItems
            className="course-list-panel"
            courses={this.state.courses}
            professionId={professionId}
            onCourseClick={this.handleOnCourseClick}
            savedOfferingsObject={{}}
            userId={user.id}
            saveCartItem={saveCartItem}
            coursesInCart={coursesInCart}
            loggedOnBehalf={loggedOnBehalf}
            savedOfferingsList={savedOfferingsList}
            handleSaveOffering={handleSaveOffering}
            savingStatuses={savingStatuses}
            showFavoriteIcon={showFavoriteIcon}
            shouldShowProPlusBadge={shouldShowProPlusBadge}
          />
          {this.state.hasMore && (
            <div className="see-more-courses">
              <a href={courseSearchUrl} onClick={this.onSeeMoreClick}>
                See More
              </a>
            </div>
          )}
        </div>
        <Modal
          show={this.state.showCourseDetail}
          onHide={this.handleCloseCourseDetail}
          size="lg"
          style={{ paddingLeft: 0 }}
          hideOnOutsideClick
          className="lic-detail-modal"
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <CourseDetail
              stateFetchStatus="loaded"
              course={this.state.course}
              userId={user.id}
              loggedOnBehalf={loggedOnBehalf}
              courseDetailFetchStatus={'loaded'}
              onCourseDetailClose={this.handleCloseCourseDetail}
              professionId={professionId}
              stateCode={stateCode}
              showFavoriteIcon={showFavoriteIcon}
              showShareIcon={true}
              professionIds={[professionId]}
              isOutOfCourseSearch={true}
              splitHours={true}
              getCourseSearchUrl={config.getCourseSearchUrl}
              categoryGA="lic_beta_register_recommended_course"
              seeCheckoutPage={seeCheckoutPage}
              customerId={customerId}
              addNotification={addNotification}
              location={location}
              saveCartItem={saveCartItem}
              coursesInCart={coursesInCart}
              saveCartItemStatus={saveCartItemStatus}
              clearStatusCart={clearStatusCart}
              savedOfferingsList={savedOfferingsList}
              handleSaveOffering={handleSaveOffering}
              savingStatuses={savingStatuses}
              totalItemsInCart={totalItemsInCart}
              shouldShowProPlusBadge={showProPlusBadge}
            />
          </Modal.Body>
        </Modal>
      </Card>
    );
  }
}

CourseRecommendationCard.propTypes = {
  recommendations: PropTypes.array,
  professionId: PropTypes.number,
  stateCode: PropTypes.string,
  user: PropTypes.object,
  loggedOnBehalf: PropTypes.bool,
  seeCheckoutPage: PropTypes.bool,
  customerId: PropTypes.string,
  addNotification: PropTypes.func,
  location: PropTypes.object,
  coursesInCart: PropTypes.array,
  saveCartItem: PropTypes.func,
  saveCartItemStatus: PropTypes.string,
  clearStatusCart: PropTypes.func,
  savedOfferingsList: PropTypes.array,
  handleSaveOffering: PropTypes.func,
  savingStatuses: PropTypes.object,
  showFavoriteIcon: PropTypes.bool,
  totalItemsInCart: PropTypes.number,
  shouldShowProPlusBadge: PropTypes.bool,
};

CourseRecommendationCard.defaultProps = {
  recommendations: [],
  loggedOnBehalf: false,
  coursesInCart: [],
  saveCartItem: () => {},
  clearStatusCart: () => {},
  shouldShowProPlusBadge: false,
};

export default CourseRecommendationCard;
