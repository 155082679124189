import React, { Component } from 'react';
import SettingsProfileInfo from '../SettingsProfileInfo/SettingsProfileInfoLoading';
import SettingsProfileEmails from '../SettingsProfileEmails/SettingsProfileEmailsLoading';
import './SettingsProfile.css';

class SettingsProfile extends Component {
  render() {
    return (
      <div id="settings-profile">
        <SettingsProfileInfo />
        <SettingsProfileEmails />
      </div>
    );
  }
}

export default SettingsProfile;
