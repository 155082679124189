import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LoadingPage from '../LoadingPage';
import queryString from 'query-string';
import { launchpadUrl, licenseesUrl, SUBSCRIPTION_TYPES, getCourseSearchUrl } from '../../config';
import utils from '../../utils';

const ReactivateSubscription = ({
  params,
  fetchLastSubscription,
  getSubscriptionPlans,
  subscription,
  history,
  secureToken,
  subscriptionPlans,
  saveCartItem,
  saveCartItemStatus,
}) => {
  useEffect(() => {
    if (saveCartItemStatus === 'loaded') {
      const paramsToRedirect = {
        redirect_to: 'cart',
        userId: params.userId,
      };
      utils.redirectTo(getCourseSearchUrl(paramsToRedirect, false));
    }
  }, [params.userId, saveCartItemStatus]);

  useEffect(() => {
    if (fetchLastSubscription) {
      fetchLastSubscription(params.userId);
    }

    if (params.userId && getSubscriptionPlans) {
      getSubscriptionPlans();
    }
  }, [fetchLastSubscription, getSubscriptionPlans, params.userId]);

  useEffect(() => {
    const { type: lastSubscription, error: lastSubscriptionFailed } = subscription.fetchLastSubscription;

    // redirect to launchpad if user is unauthenticated
    const loginPage = `${licenseesUrl}/login?redirect_to=reactivate-subscription`;
    const recaptureUrl = encodeURIComponent(loginPage);
    const loginAccount = `${launchpadUrl}?continue_to=${recaptureUrl}`;

    if (params.status === 'unauthenticated' || (params.status === 'authenticated' && !secureToken)) {
      window.location.replace(loginAccount);
      return;
    }
    if (params.currentSubscriptionType !== SUBSCRIPTION_TYPES.BAS || lastSubscriptionFailed) {
      return history.push('/overview');
    }

    // validate where should we redirect to
    if (lastSubscription) {
      if (lastSubscription === params.currentSubscriptionType) {
        history.push('/overview');
      } else {
        switch (lastSubscription) {
          case SUBSCRIPTION_TYPES.PRO:
          case SUBSCRIPTION_TYPES.CON:
            history.push({
              pathname: '/upgrade/payment',
              search: queryString.stringify({
                upgradeTo: lastSubscription.toLowerCase(),
                user_id: params.userId,
              }),
              state: {
                comeFrom: 'reactivate-subscription',
              },
            });
            break;
          case SUBSCRIPTION_TYPES.PRO_PLUS:
          case SUBSCRIPTION_TYPES.CON_PLUS:
            if (subscriptionPlans.length > 0 && !['loading', 'loaded'].includes(saveCartItemStatus)) {
              const plan = subscriptionPlans.find((plan) => plan.subscriptionType === lastSubscription);

              if (plan.upgradeInShoppingCart) {
                const {
                  sku,
                  amount,
                  name,
                  productId,
                  priceId,
                  bannerDescription,
                  bannerImageUrl,
                  subscriptionType,
                  salesTaxProductId,
                  label,
                } = plan;
                saveCartItem({
                  subscription: {
                    sku,
                    amount,
                    name,
                    productId,
                    priceId,
                    bannerDescription,
                    bannerImageUrl,
                    subscriptionType,
                    salesTaxProductId,
                    label,
                  },
                });
              }
            }
            break;
          default:
            history.push('/overview');
            break;
        }
      }
    }
  }, [
    saveCartItemStatus,
    params,
    secureToken,
    subscription.fetchLastSubscription,
    subscriptionPlans,
    saveCartItem,
    history,
  ]);

  return <LoadingPage />;
};

ReactivateSubscription.propTypes = {
  params: PropTypes.object,
  fetchLastSubscription: PropTypes.func,
  subscription: PropTypes.object,
  history: PropTypes.object,
  secureToken: PropTypes.string,
  subscriptionPlans: PropTypes.array,
  saveCartItem: PropTypes.func,
  saveCartItemStatus: PropTypes.string,
};

ReactivateSubscription.defaultProps = {
  params: {},
  fetchLastSubscription: () => {},
  getSubscriptionPlans: () => {},
  subscription: { fetchLastSubscription: {} },
  history: {},
  secureToken: '',
  subscriptionPlans: [],
  saveCartItem: () => {},
  saveCartItemStatus: '',
};

export default ReactivateSubscription;
