import React from 'react';
import PropTypes from 'prop-types';
import { professionColorStyles, convertHexToRGB, addNewStyle } from '../../utils';
import { PageHeader, Nav } from 'emerald-ui';
import AssociationReturnButton from '../AssociationReturnButton';

export default class SettingsHeader extends React.Component {
  componentDidMount() {
    addNewStyle(
      `.page-header-container {background-color: ${convertHexToRGB(this.props.professionBrandColor, 100)} !important}`
    );
  }

  render() {
    const { subscriptionType = 'BAS', professionBrandColor } = this.props;
    const brandingStyles = professionColorStyles(professionBrandColor);
    return (
      <PageHeader className="page-header-container" style={brandingStyles}>
        <AssociationReturnButton />
        <h2 className="text-left" style={{ marginTop: '35px', marginBottom: '25px', fontWeight: '500' }}>
          Settings
        </h2>
        <Nav
          style={{
            marginTop: '-5px',
            background: professionBrandColor ? 'none' : '',
          }}
          noWrap={true}
        >
          {/* eslint-disable jsx-a11y/anchor-is-valid */}
          <a>Profile</a>
          <a>Subscription</a>
          {/* DISCLAIMER: `subscriptionType` is receiving the old type property from the BE which only recognize the orginal plans BAS,FTO,PRO,CON
              It is not necessary to user the new `code` property which supports additionally the coursescription plans. 
              If for some reason this is changed, you need to be sure that the following option is available for CON_PLUS too. */}
          {subscriptionType === 'CON' && <a>Concierge manager</a>}
          <a>Payment history</a>
          {/* eslint-enable jsx-a11y/anchor-is-valid */}
        </Nav>
      </PageHeader>
    );
  }
}

SettingsHeader.propTypes = {
  subscriptionType: PropTypes.string,
  professionBrandColor: PropTypes.string,
};
