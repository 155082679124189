import { put, call, select } from 'redux-saga/effects';
import * as api from '../../../api/users';
import at from '../types';

export default function* updateEmailSettings({ emailSettings, meta = {} }) {
  const state = yield select();
  const user = state.authentication.licenseeAccount.user;
  try {
    yield call(api.updateEmailSettings, user.id, emailSettings);
    yield put({
      type: at.UPDATE_EMAIL_SETTINGS_SUCCESS,
      userId: user.id,
      emailSettings,
      meta,
    });
  } catch (error) {
    console.error(error); //eslint-disable-line
    yield put({
      type: at.UPDATE_EMAIL_SETTINGS_FAILURE,
      userId: user.id,
      emailSettings,
      meta,
    });
  } finally {
    //eslint-disable-line
  }
}
