import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { checkIfIsNowCourse } from '../../../../utils';
import { Icon } from 'emerald-ui';
import DeliveryMethodIcon from '../../../DeliveryMethodIcon';
import cebrokerIcon from './assets/cebroker.svg';
import './CourseDetailInfo.css';

const NowCourseItems = () => (
  <React.Fragment>
    <span>
      <Icon name="computer" />
      Online Learning
    </span>
    <span>
      <Icon name="brightness_low" />
      Certificate of Achievement
    </span>
    <span>
      <Icon name="phone_android" />
      Access Anywhere
    </span>
    <span>
      <img src={cebrokerIcon} alt="cebroker icon" />
      Reports Automatically
    </span>
  </React.Fragment>
);

const AnytimeCourseItems = (deliveryMethod) => {
  const { code: deliveryMethodCode, description: deliveryMethodDescription } = deliveryMethod;

  return (
    <React.Fragment>
      <span>
        <DeliveryMethodIcon deliveryMethodCode={deliveryMethodCode} courseType={'CD_ANYTIME'} />
        {deliveryMethodDescription}
      </span>
      {['CBT', 'CTRNPT', 'HOMESTUDY'].includes(deliveryMethodCode) && (
        <span>
          <Icon name="computer" />
          Online Learning
        </span>
      )}
      <span>
        <Icon name="brightness_low" />
        Certificate of Achievement
      </span>
      <span>
        <img src={cebrokerIcon} alt="cebroker icon" />
        Reports Automatically
      </span>
    </React.Fragment>
  );
};

const liveCourseItems = (deliveryMethod, location, startDate, endDate) => {
  const { code: deliveryMethodCode, description: deliveryMethodDescription } = deliveryMethod;

  const stringLocation = `${location.address} | ${location.city}, ${location.state} ${location.zipCode}`;
  const startDateString =
    startDate && startDate !== '01/01/0001' ? moment(startDate, 'MM/DD/YYYY').format('MMM DD YYYY') : '';
  const endDateString = endDate && endDate !== '01/01/0001' ? moment(endDate, 'MM/DD/YYYY').format('MMM DD YYYY') : '';
  const isLiveCourseStandard = startDateString && endDateString;
  const isLiveCourseWithoutDates = !startDateString && !endDateString;

  return (
    <React.Fragment>
      <span>
        <DeliveryMethodIcon deliveryMethodCode={deliveryMethodCode} courseType={'CD_LIVE'} />
        {deliveryMethodDescription}
      </span>
      {(isLiveCourseStandard || isLiveCourseWithoutDates) && (
        <span>
          <Icon name="date_range" />
          {!isLiveCourseWithoutDates ? `${startDateString} - ${endDateString}` : 'Contact provider'}
        </span>
      )}
      {location.address && (
        <span>
          <i className="material-icons-outlined">location_on</i>
          {stringLocation}
        </span>
      )}
      <span>
        <Icon name="brightness_low" />
        Certificate of Achievement
      </span>
      <span>
        <img src={cebrokerIcon} alt="cebroker icon" />
        Reports Automatically
      </span>
    </React.Fragment>
  );
};

class CourseDetailInfo extends React.Component {
  getItemsFromCourse(course) {
    const { course: courseDetail = {}, location = {}, startDate, endDate } = course;
    const { type: courseType, deliveryMethod = {} } = courseDetail;

    const isNowCourse = checkIfIsNowCourse(courseDetail);
    if (isNowCourse && courseType !== 'CD_LIVE') {
      return NowCourseItems();
    } else if (courseType === 'CD_ANYTIME') {
      return AnytimeCourseItems(deliveryMethod);
    } else if (courseType === 'CD_LIVE') {
      return liveCourseItems(deliveryMethod, location, startDate, endDate);
    }
  }

  render() {
    const { course } = this.props;
    const items = this.getItemsFromCourse(course);
    return (
      <div className="floating-content" id="course-details">
        <div className="course-details-panel">
          <span className="course-details-title">Details</span>
          <div className="course-details-items">{items}</div>
        </div>
      </div>
    );
  }
}

CourseDetailInfo.propTypes = {
  course: PropTypes.object,
};

CourseDetailInfo.defaultProps = {
  course: {},
};

export default CourseDetailInfo;
