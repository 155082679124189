import { useQuery } from '@tanstack/react-query';
import { fetchCourseRatingNumbers } from 'api/offerings';

function useCourseRatingNumbers(offeringId) {
  return useQuery({
    queryKey: ['rating-numbers', offeringId],
    queryFn: async () => {
      const { data } = await fetchCourseRatingNumbers(offeringId);
      return data;
    },
    enabled: !!offeringId,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 2,
  });
}

export default useCourseRatingNumbers;
