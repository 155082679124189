import { put, call } from 'redux-saga/effects';

import * as api from '../../../api/states';
import at from '../types';

export default function* fetch() {
  try {
    const { data } = yield call(api.getStatesAndProfessions);
    yield put({ type: at.FETCH_SUCCESS, data });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({ type: at.FETCH_FAILURE });
  }
}
