import { combineReducers } from 'redux';
import at from '../types';

export const general = (state = [], action) => {
  switch (action.type) {
    case at.ADD: {
      const { notification } = action;
      return [notification, ...state];
    }
    case at.REMOVE: {
      const { id } = action;
      return state.filter((n) => n.id !== id);
    }
    default:
      return state;
  }
};

export default combineReducers({
  general,
});
