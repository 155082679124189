import React from 'react';
import PropTypes from 'prop-types';
import './CourseLearningObjectives.css';
import SanitizeHTML from '../../../SanitizeHTML';

const { REACT_APP_ENABLE_LEARNING_OBJECTIVES: showLearningObjectives = 'false' } = process.env;

class CourseLearningObjectives extends React.Component {
  render() {
    const { learningObjectives } = this.props;
    if (showLearningObjectives !== 'true' || !learningObjectives) {
      return <React.Fragment />;
    }
    return (
      <div id="learning-objectives" className="course-detail-content">
        <span className="course-detail-title">What you&#39;ll learn</span>
        <SanitizeHTML className="learning-objectives-content" html={learningObjectives} />
      </div>
    );
  }
}

CourseLearningObjectives.propTypes = {
  learningObjectives: PropTypes.string,
};

export default CourseLearningObjectives;
