import { combineReducers } from 'redux';
import at from '../types';

export const paymentInfo = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_PAYMENT_INFO_SUCCESS: {
      return action.data.autoRenewSettings;
    }
    case at.UPDATE_PAYMENT_INFO_SUCCESS: {
      const { cardExpirationDate, cardNumber, cardHolder, cardType, stripeCustomerToken } = action.creditCard;
      const { comarketerId, discountPercentage, discountValue, promoCodeError, promotionCodeId, promotionCode } =
        action.promoCodeResult;

      let newCreditCard = {
        comarketerId,
        discountPercentage,
        discountValue,
        promoCodeError,
        promotionCodeId,
        promotionCode,
        address: { ...action.address },
      };

      if (cardNumber) {
        newCreditCard = {
          ...newCreditCard,
          ...{
            cardExpirationDate,
            cardNumber,
            cardHolder,
            cardType,
          },
        };
      }

      if (stripeCustomerToken) {
        newCreditCard.stripeCustomerToken = stripeCustomerToken;
      }

      return {
        ...state,
        ...newCreditCard,
      };
    }
    case at.UPDATE_PAYMENT_INFO_FAILURE: {
      return state;
    }
    case at.DOWNGRADE_ACCOUNT_SUCCESS: {
      return { ...state, ...{ isDowngradeNextRenewal: true } };
    }
    default:
      return state;
  }
};

export const paymentInfoStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_PAYMENT_INFO_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_PAYMENT_INFO_REQUEST: {
      return 'loading';
    }
    case at.FETCH_PAYMENT_INFO_FAILURE: {
      return 'failed';
    }
    case at.UPDATE_PAYMENT_INFO_SUCCESS: {
      return 'loaded';
    }
    default:
      return state;
  }
};

export const updatePaymentInfoStatus = (state = '', action) => {
  switch (action.type) {
    case at.UPDATE_PAYMENT_INFO_SUCCESS: {
      return 'loaded';
    }
    case at.UPDATE_PAYMENT_INFO_REQUEST: {
      return 'loading';
    }
    case at.UPDATE_PAYMENT_INFO_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const feeAmount = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_RENEW_FEE_SUCCESS: {
      return action.data.feeAmount;
    }
    default:
      return state;
  }
};

export const promotionCodes = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_RENEW_FEE_SUCCESS: {
      const { promotionCodes = {} } = action.data;
      return promotionCodes;
    }
    default:
      return state;
  }
};

export const currentAmount = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_RENEW_FEE_SUCCESS: {
      return action.data.currentAmount;
    }
    default:
      return state;
  }
};

export const feeAmountStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_RENEW_FEE_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_RENEW_FEE_REQUEST: {
      return 'loading';
    }
    case at.FETCH_RENEW_FEE_FAILURE: {
      return 'failed';
    }
    case at.UPDATE_PAYMENT_INFO_SUCCESS: {
      return 'reload';
    }
    case at.DOWNGRADE_ACCOUNT_SUCCESS: {
      return 'reload';
    }
    default:
      return state;
  }
};

export const hasActiveSubscription = (state = false, action) => {
  switch (action.type) {
    case at.FETCH_ACTIVE_SUBSCRIPTION_SUCCESS: {
      const { data } = action;
      return data.hasActiveSubscription;
    }
    case at.FETCH_ACTIVE_SUBSCRIPTION_FAILURE: {
      return false;
    }
    case at.UPDATE_PAYMENT_INFO_SUCCESS: {
      return true;
    }
    case at.CANCEL_SUBSCRIPTION_SUCCESS: {
      return false;
    }
    default:
      return state;
  }
};

export const hasActiveSubscriptionStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_ACTIVE_SUBSCRIPTION_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_ACTIVE_SUBSCRIPTION_FAILURE: {
      return 'failed';
    }
    case at.FETCH_ACTIVE_SUBSCRIPTION_REQUEST: {
      return 'loading';
    }
    case at.CANCEL_SUBSCRIPTION_SUCCESS: {
      return 'loaded';
    }
    case at.CANCEL_SUBSCRIPTION_FAILURE: {
      return 'failed';
    }
    case at.CANCEL_SUBSCRIPTION_REQUEST: {
      return 'loading';
    }
    default:
      return state;
  }
};

export const downgradeStatus = (state = '', action) => {
  switch (action.type) {
    case at.DOWNGRADE_ACCOUNT_SUCCESS: {
      return 'loaded';
    }
    case at.DOWNGRADE_ACCOUNT_FAILURE: {
      return 'failed';
    }
    case at.DOWNGRADE_ACCOUNT_REQUEST: {
      return 'loading';
    }
    default:
      return state;
  }
};

export const fetchLastSubscription = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_LAST_SUBSCRIPTION_REQUEST: {
      return { ...state, ...action.userId };
    }
    case at.FETCH_LAST_SUBSCRIPTION_SUCCESS: {
      return { ...state, ...action.data };
    }
    case at.FETCH_LAST_SUBSCRIPTION_FAILURE: {
      return { ...state, error: true };
    }
    default:
      return state;
  }
};

export default combineReducers({
  paymentInfo,
  paymentInfoStatus,
  updatePaymentInfoStatus,
  feeAmount,
  promotionCodes,
  feeAmountStatus,
  hasActiveSubscription,
  hasActiveSubscriptionStatus,
  downgradeStatus,
  fetchLastSubscription,
  currentAmount,
});
