import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { fetchCourseReviews } from 'api/offerings';

export function useCourseReviews({ pageSize, offeringId, orderBy = 'MOST_RECENT', rating }) {
  const [shouldFetchData, setShouldFetchData] = useState(false);

  useEffect(() => {
    if (offeringId) {
      setShouldFetchData(true);
    }
  }, [offeringId, orderBy, pageSize, rating]);

  return useQuery({
    queryKey: ['reviews'],
    queryFn: async () => {
      setShouldFetchData(false);
      const { data = {} } = await fetchCourseReviews({ offeringId, pageSize, orderBy, rating });
      return { ...data, hasMore: data?.reviews?.length !== data?.totalItems };
    },
    enabled: shouldFetchData,
    keepPreviousData: true,
    retry: 2,
  });
}
