import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import moment from 'moment';
import classNames from 'classnames';
import get from 'lodash/get';

import { PageHeader, Nav, Card, Modal, Alert, SkeletonLoader, Button, Container } from 'emerald-ui';
import CourseHistoryPage from '../../containers/CourseHistoryPage';
import CourseHistoryDeletedPage from '../../containers/CourseHistoryDeletedPage';
import ReportedExemptionsList from '../../containers/ReportedExemptionsList';
import { saveEvent } from '../../api/events';
import CourseRecommendationSection from '../CourseRecommendationSection';
import Ad from '../Ad';
import RenewSubscriptionBanner from '../RenewSubscriptionBanner';
import CourseDetail from '../CourseDetail';
import RenewalRequirementBanner from '../RenewalRequirementBanner';
import './Overview.css';
import config from '../../config';
import utils from '../../utils';
import ComplianceStatusCard from './components/ComplianceStatusCard';
import RequestedLicenseCard from './components/RequestedLicenseCard';
import { Col, Row } from 'emerald-ui/lib';

const { Tabs } = config;

const defaultTab = Tabs.COURSES;

const { AnalyticsTrackingEntities, SUBSCRIPTION_TYPES } = config;

class Overview extends Component {
  constructor(props) {
    super(props);
    const previousTabSelected = utils.getSessionItem('selectedTab');
    const selectedTab = !previousTabSelected ? defaultTab : previousTabSelected;
    this.state = {
      selectedTab,
      showCourseDetail: false,
      resetCurrentPage: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { resetCoursesTab = false, restoreTab } = this.props;

    if (resetCoursesTab) {
      this.handleTabChange(defaultTab);
      this.setState({ resetCurrentPage: false });
      restoreTab();
    }
  }

  componentDidMount() {
    const {
      fetchTranscript,
      location = {},
      match = {},
      transcriptStatus,
      transcript: { licenseCycles: licensePeriodItems = [] } = {},
      fetchAdsStatus,
      fetchAds,
      isRequestedLicense,
      fetchAuditTask,
      auditTasks,
      subscriptionType,
    } = this.props;
    if (isRequestedLicense) {
      return;
    }
    if (fetchAdsStatus !== 'loaded' && fetchAdsStatus !== 'loading') {
      fetchAds({
        mediaCode: 'Dashboard',
        adType: 'course',
      });
    }

    const { params } = match;
    const query = queryString.parse(location.search);

    const selected = Array.isArray(licensePeriodItems)
      ? licensePeriodItems.find((p) => {
          const {
            transcriptFields: { isDefault },
          } = p;
          return isDefault;
        })
      : null;

    const { cycle = 0 } = query;

    const isEmployee = subscriptionType === SUBSCRIPTION_TYPES.EMP;

    if (fetchTranscript && params.pkLicense && transcriptStatus !== 'loaded') {
      fetchTranscript({
        pkLicense: params.pkLicense,
        cycleId: cycle,
      });
    }

    if (fetchAuditTask && params.pkLicense && !auditTasks.pkLicense && !isEmployee) {
      fetchAuditTask({ pkLicense: params.pkLicense });
    }

    if (selected) {
      this.setState({
        selectedPeriod: {
          text: selected.transcriptFields.name,
          id: selected.id,
        },
      });
    }
  }

  handleClickTranscript = (e) => {
    e.preventDefault();
    const { location, history, match, subscriptionType, user } = this.props;
    if (subscriptionType === SUBSCRIPTION_TYPES.BAS) {
      const statisticCode = 'UPGRADE';
      const type = 'COMPLIANCE_STATUS_BOX';
      saveEvent(user.id, statisticCode, type);
      const { actions } = AnalyticsTrackingEntities;
      this.handleTrackedClick(actions.sectionComplianceStatusUpgrade);
      return history.push('/upgrade', { comeFrom: 'complianceStatusCard' });
    }
    const query = location.search;
    const { pkLicense } = match.params;
    history.push(`/license/${pkLicense}/transcript${query}`);
  };

  handleTabChange = (tab) => {
    this.setState({ selectedTab: tab, resetCurrentPage: true });
    if (tab && tab !== 'undefined') {
      utils.setSessionItem('selectedTab', tab);
    }
  };

  handleCloseCourseDetail = () => {
    this.setState({
      showCourseDetail: false,
    });
    utils.handleDisplayAdaComponent(true);
  };

  handleClickViewCourseDetail = () => {
    this.setState((prevState) => ({
      showCourseDetail: !prevState.showCourseDetail,
    }));
  };

  handleTrackedClick = (action) => {
    const { categories } = AnalyticsTrackingEntities;
    const { subscriptionType, isFTOAvailable } = this.props;
    utils.sendAnalyticsEvent({
      category: categories.upgrade,
      action,
      label: utils.getAccountTypeLabel(subscriptionType, isFTOAvailable),
    });
  };

  render() {
    const {
      ad,
      user,
      loggedOnBehalf,
      license,
      transcript,
      subscriptionType,
      transcriptStatus,
      fetchAdsStatus,
      fetchAdsDetailStatus: courseDetailFetchStatus,
      professionIds,
      resetCoursesTab,
      isFTOAvailable,
      selectedRequestedLicense,
      isRequestedLicense,
      seeCheckoutPage,
      customerId,
      addNotification,
      location,
      saveCartItem,
      coursesInCart,
      saveCartItemStatus,
      clearStatusCart,
      savedOfferingsList,
      handleSaveOffering,
      savingStatuses,
      showFavoriteIcon,
      totalItemsInCart,
      shouldShowProPlusBadge,
    } = this.props;

    const inCoursescriptionEnrolled = get(ad, 'course.provider', false);
    const showProPlusBadgeInAd = inCoursescriptionEnrolled && shouldShowProPlusBadge;
    const { selectedTab } = this.state;

    const DEFAULT_MESSAGE =
      'Please contact CE BROKER Help Desk at 1-877-I-FIND-CE (1-877-434-6323). Monday – Friday, 8:00 am – 8:00 pm EST';
    const showErrorMessage = (!transcript || !transcript.transcriptDetail) && transcriptStatus === 'failed';
    const errorMessage = showErrorMessage
      ? `No transcripts were found for evaluation. ${DEFAULT_MESSAGE}`
      : DEFAULT_MESSAGE;

    const { transcriptDetail = {} } = transcript;
    const { licensePeriod = {}, status } = transcriptDetail;
    const { isCurrentCycle = false, renewalStartDate = '', renewalEndDate = '', percentageCompleted } = licensePeriod;
    const { profession: { board: { state = {} } = {} } = {} } = license;
    const { profession: { board: { state: stateRequestedLicense = {} } = {} } = {} } = selectedRequestedLicense;

    const statusActions = {
      IN_PROG: () => ({
        transcriptLabel: 'danger',
        transcriptTitle: 'Not completed',
        transcriptDescription: `We'll show you exactly what you still need in your custom compliance transcript.`,
        transcriptActionText:
          subscriptionType === 'BAS'
            ? isFTOAvailable
              ? 'Activate free trial'
              : 'Renew Subscription'
            : `Show me what I'm missing`,
      }),
      NONE: () => ({
        transcriptLabel: 'danger',
        transcriptTitle: 'Not completed',
        transcriptDescription: `We'll show you exactly what you still need in your custom compliance transcript.`,
        transcriptActionText:
          subscriptionType === 'BAS'
            ? isFTOAvailable
              ? 'Activate free trial'
              : 'Renew Subscription'
            : `Show me what I'm missing`,
      }),
      COMPL: () => ({
        transcriptLabel: 'success',
        transcriptTitle: 'Complete',
        transcriptDescription: 'Great job! It looks like you completed your requirements for this cycle.',
        transcriptActionText:
          subscriptionType === 'BAS'
            ? isFTOAvailable
              ? 'Activate free trial'
              : 'Renew Subscription'
            : 'View my Transcript',
      }),
      NO_CE_REQ: () => ({
        transcriptLabel: 'success',
        transcriptTitle: 'Complete',
        transcriptDescription: 'Great job! It looks like you completed your requirements for this cycle.',
        transcriptActionText:
          subscriptionType === 'BAS'
            ? isFTOAvailable
              ? 'Activate free trial'
              : 'Renew Subscription'
            : 'View my Transcript',
      }),
    };

    let transcriptLabel, transcriptTitle, transcriptDescription, transcriptActionText;

    const action = statusActions[status];

    if (action) {
      const actionResult = action();
      transcriptLabel = actionResult.transcriptLabel;
      transcriptTitle = actionResult.transcriptTitle;
      transcriptDescription = actionResult.transcriptDescription;
      transcriptActionText = actionResult.transcriptActionText;
    }

    const canSeeReportedExemptions = license.inAllowReportExemption;

    const tabComponents = {
      courses: CourseHistoryPage,
      exemptions: ReportedExemptionsList,
      removed: CourseHistoryDeletedPage,
    };

    const TabContentComponent = tabComponents[selectedTab];

    const bannerToRender = isFTOAvailable ? (
      <Ad ad={ad} onClick={this.handleClickViewCourseDetail} adStatus={fetchAdsStatus} />
    ) : (
      <RenewSubscriptionBanner handleTrackedClick={this.handleTrackedClick} />
    );

    const showRecommendations =
      transcriptStatus === 'loaded' &&
      license.profession.showCoursesRecommendations &&
      (status === 'IN_PROG' || status === 'NONE') &&
      isCurrentCycle &&
      renewalEndDate !== '' &&
      moment().isSameOrBefore(moment(renewalEndDate, config.INPUT_FORMAT_DATE), 'day');

    const selectedProfession = isRequestedLicense ? selectedRequestedLicense.profession : license.profession;
    const licenseLabelCap = selectedProfession.licenseLabelCap;
    const stateLabelCap = selectedProfession.stateLabelCap;

    return (
      <Container id="overview">
        <div>
          {subscriptionType === 'BAS' ? bannerToRender : <React.Fragment />}
          {this.state.showCourseDetail && (
            <Modal
              show={this.state.showCourseDetail}
              onHide={this.handleCloseCourseDetail}
              size="lg"
              className="lic-detail-modal"
              hideOnOutsideClick
            >
              <Modal.Header closeButton />
              <Modal.Body>
                <CourseDetail
                  stateFetchStatus="loaded"
                  course={ad}
                  loggedOnBehalf={loggedOnBehalf}
                  userId={user.id}
                  courseDetailFetchStatus={courseDetailFetchStatus}
                  onCourseDetailClose={this.handleCloseCourseDetail}
                  professionIds={professionIds}
                  isOutOfCourseSearch={true}
                  getCourseSearchUrl={config.getCourseSearchUrl}
                  showShareIcon={false}
                  seeCheckoutPage={seeCheckoutPage}
                  customerId={customerId}
                  addNotification={addNotification}
                  location={location}
                  saveCartItem={saveCartItem}
                  coursesInCart={coursesInCart}
                  saveCartItemStatus={saveCartItemStatus}
                  clearStatusCart={clearStatusCart}
                  totalItemsInCart={totalItemsInCart}
                  shouldShowProPlusBadge={showProPlusBadgeInAd}
                />
              </Modal.Body>
            </Modal>
          )}
        </div>
        {showErrorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <div className="overview-info">
          {!isRequestedLicense ? (
            <ComplianceStatusCard
              {...{
                transcriptLabel,
                transcriptStatus,
                transcriptTitle,
                transcriptDescription,
                subscriptionType,
                percentageCompleted,
                transcriptActionText,
                handleClickTranscript: this.handleClickTranscript,
              }}
            />
          ) : (
            <RequestedLicenseCard {...{ license, subscriptionType }} />
          )}
          <Card className="card-license-details">
            <Card.Header>
              <h1 className="card-header-title text-color">{licenseLabelCap} details</h1>
            </Card.Header>
            <div className="card-details-grid">
              <p className="text-lighter">{stateLabelCap}</p>
              <p className="text-color">{isRequestedLicense ? stateRequestedLicense.name : state.name}</p>
              <p className="text-lighter">{licenseLabelCap} #</p>
              <p className="text-color">{isRequestedLicense ? selectedRequestedLicense.number : license.number}</p>
              <p className="text-lighter">CE Cycle</p>
              {transcriptStatus === 'loading' ? (
                <p>
                  <SkeletonLoader className="detail-loader" width="150px" height="20px" loading style={{ margin: 0 }} />
                </p>
              ) : (
                <p className="text-color">
                  {!isRequestedLicense
                    ? `${moment(renewalStartDate, config.INPUT_FORMAT_DATE).format('L')} - ${moment(
                        renewalEndDate,
                        config.INPUT_FORMAT_DATE
                      ).format('L')}`
                    : 'N/A'}
                </p>
              )}
            </div>
          </Card>
        </div>
        {showRecommendations && (
          <CourseRecommendationSection
            handleTrackedClick={this.handleTrackedClick}
            savedOfferingsList={savedOfferingsList}
            handleSaveOffering={handleSaveOffering}
            savingStatuses={savingStatuses}
            showFavoriteIcon={showFavoriteIcon}
            shouldShowProPlusBadge={shouldShowProPlusBadge}
          />
        )}
        {!isRequestedLicense && (
          <React.Fragment>
            <div className="history-text">Course history</div>
            {subscriptionType === 'BAS' && <RenewalRequirementBanner handleTrackedClick={this.handleTrackedClick} />}
            <PageHeader className="history-tabs">
              <Row>
                <Col>
                  <Nav className="light" noWrap>
                    <Button
                      onClick={() => {
                        this.handleTabChange(Tabs.COURSES);
                      }}
                      className={classNames({ active: selectedTab === Tabs.COURSES }, Tabs.COURSES)}
                      shape="flat"
                    >
                      COURSES
                    </Button>
                    {canSeeReportedExemptions && (
                      <Button
                        onClick={() => {
                          this.handleTabChange(Tabs.EXEMPTIONS);
                        }}
                        className={classNames({ active: selectedTab === Tabs.EXEMPTIONS }, Tabs.EXEMPTIONS)}
                        shape="flat"
                      >
                        ADDITIONAL OPTIONS
                      </Button>
                    )}
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleTabChange(Tabs.REMOVED);
                      }}
                      className={classNames({ active: selectedTab === Tabs.REMOVED }, Tabs.REMOVED)}
                      shape="flat"
                    >
                      REMOVED
                    </Button>
                  </Nav>
                </Col>
              </Row>
            </PageHeader>
            {TabContentComponent ? (
              <TabContentComponent
                resetCurrentPage={this.state.resetCurrentPage || resetCoursesTab}
                navKey={selectedTab}
              />
            ) : null}
          </React.Fragment>
        )}
      </Container>
    );
  }
}

Overview.propTypes = {
  ad: PropTypes.object,
  license: PropTypes.object,
  transcript: PropTypes.object,
  fetchTranscript: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  subscriptionType: PropTypes.string,
  transcriptStatus: PropTypes.string,
  fetchAdsStatus: PropTypes.string,
  isDohUser: PropTypes.string,
  loggedOnBehalf: PropTypes.bool,
  resetCoursesTab: PropTypes.bool,
  fetchAds: PropTypes.func,
  fetchAdsDetailStatus: PropTypes.string,
  user: PropTypes.object,
  professionIds: PropTypes.array,
  restoreTab: PropTypes.func,
  isFTOAvailable: PropTypes.bool,
  selectedRequestedLicense: PropTypes.object,
  isRequestedLicense: PropTypes.bool,
  fetchAuditTask: PropTypes.func,
  seeCheckoutPage: PropTypes.bool,
  customerId: PropTypes.string,
  addNotification: PropTypes.func,
  coursesInCart: PropTypes.array,
  saveCartItem: PropTypes.func,
  saveCartItemStatus: PropTypes.string,
  clearStatusCart: PropTypes.func,
  auditTasks: PropTypes.object,
  onFavoriteClick: PropTypes.func,
  savedOfferingsList: PropTypes.array,
  handleSaveOffering: PropTypes.func,
  savingStatuses: PropTypes.object,
  showFavoriteIcon: PropTypes.bool,
  totalItemsInCart: PropTypes.number,
  shouldShowProPlusBadge: PropTypes.bool,
};

Overview.defaultProps = {
  license: {},
  ad: {},
  isFTOAvailable: false,
  selectedRequestedLicense: {},
  restoreTab: () => {},
  coursesInCart: [],
  saveCartItem: () => {},
  clearStatusCart: () => {},
  shouldShowProPlusBadge: false,
};

export default Overview;
