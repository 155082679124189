export default function (globalState) {
  const state = globalState.notifications;

  const getGeneralNotification = () => state.general;

  const getSingleNotification = () => state.single;

  return {
    getGeneralNotification,
    getSingleNotification,
  };
}
