import { put, call } from 'redux-saga/effects';
import * as api from '../../../api/communications';
import at from '../types';
import auditsTypes from '../../audits/types';

export default function* saveAuditResponse({ information }) {
  const { data, conversationId } = information;

  try {
    yield call(api.postSaveAuditResponse, conversationId, data);
    yield put({ type: at.SAVE_AUDIT_RESPONSE_SUCCESS });
    yield put({ type: at.CLEAR_AUDIT_ENTITIES });
    yield put({ type: auditsTypes.CLEAR_ENTITIES });
    yield put({ type: at.FETCH_AUDIT_REQUEST, actionType: 'REQUEST' });
    yield put({ type: at.FETCH_AUDIT_REQUEST, actionType: 'RESPONSE' });
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put({ type: at.SAVE_AUDIT_RESPONSE_FAILURE, conversationId, data });
  }
}
