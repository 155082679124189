export default function (globalState) {
  const state = globalState.coursesRecommended;

  const getCoursesRecommended = (id) => state.coursesRecommended[id] || [];

  const fetchCoursesRecommendedStatus = (id) => state.coursesRecommendedStatus[id] || '';

  return {
    getCoursesRecommended,
    fetchCoursesRecommendedStatus,
  };
}
