export default function (globalState) {
  const state = globalState.authentication;

  const getAuthenticationToken = () => state.token;

  const getAuthenticationLegacyToken = () => state.legacyToken;

  const getAuthenticationStatus = () => state.status;

  const getAuthenticatedAccount = () => state.authenticatedAccount;

  const getLicenseeAccount = () => state.licenseeAccount;

  const getAssociationUsersInfo = () => state.licenseeAccount.associationUsersInfo;

  const getDiscoverPermissions = () => state.licenseeAccount.discoverSettings;

  const getDiscoverPermissionsByProfession = (professionId) => state.licenseeAccount.discoverSettings[professionId];

  const getAutoDiscounts = () => state.licenseeAccount.autoDiscounts;

  const getLicenseeSubscription = () => {
    const licenseeAccount = getLicenseeAccount();
    if (licenseeAccount.subscriptions && licenseeAccount.subscriptions.length > 0) {
      return licenseeAccount.subscriptions[0];
    }
    return {};
  };

  const getLicenseeProfessions = (licenses = []) => licenses.map(({ profession = {} }) => profession);

  const getLicenseeProfessionsIds = (licenses = []) => licenses.map(({ profession = {} }) => profession.id);

  return {
    getAuthenticationToken,
    getAuthenticationLegacyToken,
    getAuthenticationStatus,
    getAuthenticatedAccount,
    getLicenseeAccount,
    getAssociationUsersInfo,
    getDiscoverPermissions,
    getDiscoverPermissionsByProfession,
    getAutoDiscounts,
    getLicenseeSubscription,
    getLicenseeProfessions,
    getLicenseeProfessionsIds,
  };
}
