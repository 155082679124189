import { schema } from 'normalizr';

export const reportedExemptions = new schema.Entity(
  'items',
  {},
  {
    idAttribute: (value) => value.lpExemptionId,
    processStrategy: (value) => ({
      ...value,
      reduxId: value.lpExemptionId,
    }),
  }
);

export const reportedExemptionsArray = new schema.Array(reportedExemptions);
