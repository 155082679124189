import React from 'react';
import PropTypes from 'prop-types';
import { FileUpload } from 'emerald-ui-3';

const excludeFromFilesToAdd = (filesToAdd, idExclude) => {
  const files = [];
  if (filesToAdd && filesToAdd.length > 0) {
    filesToAdd.forEach((file) => {
      if (file.id !== idExclude) {
        files.push(file);
      }
    });
  }
  return files;
};

class CourseHistoryAttachmentUploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: props.files || [],
      filesToAdd: [],
    };

    this.handleUploadStart = this.handleUploadStart.bind(this);
    this.handleUploadSuccess = this.handleUploadSuccess.bind(this);
    this.handleUploadError = this.handleUploadError.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { showAttachmentsModal, addNotification } = nextProps;
    this.setState({ files: nextProps.files });

    const filesToAdd = this.state.filesToAdd.length;

    if (!showAttachmentsModal && filesToAdd > 0) {
      addNotification({
        id: Math.random(),
        message: `${filesToAdd} ${filesToAdd > 1 ? 'files' : 'file'} attached`,
        timeout: 6000,
      });
      this.setState({ filesToAdd: [] });
    }
  }

  handleUploadStart(files) {
    const messages = {
      size: 'File is too large. Please choose a file of 5Mb max',
      format: 'File type not supported. We only support PNGs',
      single: 'Only one file upload is supported',
    };

    const filesToAdd = [];

    const filesWithRejectedMessages = files.map((file) => {
      if (file.rejected) {
        file.message = messages[file.rejected];
      } else {
        filesToAdd.push(file);
      }
      return file;
    });

    this.setState((prevState) => {
      const newFiles = [...prevState.files, ...filesWithRejectedMessages];
      const newFilesToAdd = [...prevState.filesToAdd, ...filesToAdd];
      return { files: newFiles, filesToAdd: newFilesToAdd };
    });
  }

  handleUploadSuccess(result, id) {
    const { onUploadSuccess } = this.props;
    const uploadedFile = JSON.parse(result.currentTarget.response)[0];
    if (onUploadSuccess) {
      onUploadSuccess(uploadedFile);
    }
    this.setState((prevState) => {
      const newFiles = prevState.files.map((prevFile) => {
        if (prevFile.id === id) {
          prevFile.message = 'Done';
        }
        return prevFile;
      });
      const filesToAdd = [];
      if (prevState.filesToAdd && prevState.filesToAdd.length > 0) {
        prevState.filesToAdd.forEach((file) => {
          if (file.id === id) {
            file.id = uploadedFile.token;
          }
          filesToAdd.push(file);
        });
      }
      return { files: newFiles, filesToAdd };
    });
  }

  handleUploadError(error, id) {
    this.setState((prevState) => {
      const newFiles = prevState.files.map((prevFile) => {
        if (prevFile.id === id) {
          prevFile.error = true;
          prevFile.message = 'There was an error uploading this attachment';
        }

        return prevFile;
      });

      let filesToAdd = [];
      filesToAdd = excludeFromFilesToAdd(prevState.filesToAdd, id);

      return { files: newFiles, filesToAdd };
    });
  }

  handleProgress(file) {
    this.setState((prevState) => {
      const newFiles = prevState.files.map((prevFile) => {
        if (prevFile.id === file.id) {
          prevFile.progress = file.progress;
          prevFile.speed = file.speed;
        }

        return prevFile;
      });

      return { files: newFiles };
    });
  }

  handleCancel(fileName, id, aborted) {
    const { onDelete } = this.props;
    if (!aborted) {
      onDelete(id);
    }

    // removes the aborted file from the state
    this.setState((prevState) => {
      const newFiles = [];
      let filesToAdd = [];

      prevState.files.forEach((prevFile) => {
        if (prevFile.id !== id) {
          newFiles.push(prevFile);
        }
      });

      filesToAdd = excludeFromFilesToAdd(prevState.filesToAdd, id);

      return { files: newFiles, filesToAdd };
    });
  }

  render() {
    const { hideAfterDropping, children, multiple, maxSize, accept, url, headers, ...rest } = this.props;

    delete rest.files;

    const dropzoneStyle = {
      display: hideAfterDropping && this.state.files.length > 0 ? 'none' : 'flex',
    };

    return (
      <FileUpload
        url={url}
        headers={headers}
        dropzoneStyle={dropzoneStyle}
        files={this.state.files}
        onUploadStart={this.handleUploadStart}
        onProgress={this.handleProgress}
        onUploadSuccess={this.handleUploadSuccess}
        onUploadError={this.handleUploadError}
        onCancel={this.handleCancel}
        accept={accept}
        maxSize={maxSize}
        multiple={multiple}
        dropzoneUnstyled={false}
        useOverlay={false}
      >
        {children}
      </FileUpload>
    );
  }
}

CourseHistoryAttachmentUploader.propTypes = {
  files: PropTypes.array,
  onDelete: PropTypes.func,
  hideAfterDropping: PropTypes.bool,
  showAttachmentsModal: PropTypes.bool,
  children: PropTypes.node,
  multiple: PropTypes.bool,
  maxSize: PropTypes.number,
  accept: PropTypes.string,
  url: PropTypes.string,
  headers: PropTypes.object,
  token: PropTypes.string,
  onUploadSuccess: PropTypes.func,
  addNotification: PropTypes.func,
};

export default CourseHistoryAttachmentUploader;
