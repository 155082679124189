import { combineReducers } from 'redux';
import at from '../types';

const initialState = {
  name: '',
  theme: '',
  title: '',
  content: '',
  isActive: 0,
  showBanner: false,
};

export const banner = (state = initialState, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        ...data,
      };
    }
    case at.FETCH_FAILURE: {
      return {
        ...state,
      };
    }
    case at.CHANGE_BANNER_VISIBILITY: {
      const { showBanner } = action.params;
      return {
        ...state,
        showBanner,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  banner,
});
