import React from 'react';
import PropTypes from 'prop-types';
import CourseLogo from '../CourseLogo';
import AssociationLabel from '../../../AssociationLabels';
import TakeItHereLabel from '../../../TakeItHereLabel';
import FavoriteButton from '../../../FavoriteButton';
import { ShareCourseContainer } from '../../../ShareCourse';
import { checkIfIsNowCourse } from '../../../../utils';
import './CourseDetailHeader.css';
import ProPlusBadge from 'components/common/ProPlusBadge';

class CourseDetailHeader extends React.PureComponent {
  render() {
    const {
      courseDetail,
      associationLabels,
      onFavoriteClick,
      showFavoriteIcon,
      showShareIcon,
      isFeatured,
      favorite,
      offeringId,
      savedOfferingsList,
      handleSaveOffering,
      savingStatuses,
      courseId,
      shouldShowProPlusBadge,
    } = this.props;

    const { type: courseType, name: courseName } = courseDetail;

    const isNowCourse = checkIfIsNowCourse(courseDetail);
    return (
      <div className="course-detail-header">
        <div className="course-detail-logo-container">
          <CourseLogo courseType={courseType} />
        </div>
        <h2 className="course-detail-name">{courseDetail.name}</h2>
        <div className="course-detail-header-section">
          <span className="course-detail-tracking">Tracking # 20-{courseDetail.id}</span>
          <div className="labels-section">
            <TakeItHereLabel active={isNowCourse} />
            <AssociationLabel labels={associationLabels} />
          </div>
          {shouldShowProPlusBadge && <ProPlusBadge />}
          <div className="interaction-buttons-header-section">
            {showShareIcon && (
              <ShareCourseContainer
                isCourseDetail={true}
                isFeatured={isFeatured}
                offeringId={offeringId}
                courseName={courseName}
              />
            )}
            <FavoriteButton
              className="course-detail-favorite"
              onClick={onFavoriteClick}
              active={favorite}
              show={showFavoriteIcon}
              savedOfferingsList={savedOfferingsList}
              handleSaveOffering={handleSaveOffering}
              savingStatuses={savingStatuses}
              courseId={courseId}
            />
          </div>
        </div>
      </div>
    );
  }
}

CourseDetailHeader.propTypes = {
  courseDetail: PropTypes.object,
  associationLabels: PropTypes.array,
  onFavoriteClick: PropTypes.func,
  favorite: PropTypes.bool,
  offeringId: PropTypes.any,
  showFavoriteIcon: PropTypes.bool,
  showShareIcon: PropTypes.bool,
  isFeatured: PropTypes.bool,
  savedOfferingsList: PropTypes.array,
  handleSaveOffering: PropTypes.func,
  savingStatuses: PropTypes.object,
  isCourseSaved: PropTypes.bool,
  courseId: PropTypes.string,
};

CourseDetailHeader.defaultProps = {
  courseDetail: {},
  associationLabels: [],
  showShareIcon: true,
};

export default CourseDetailHeader;
