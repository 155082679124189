import React from 'react';
import PropTypes from 'prop-types';

/*
AB - Audiobridge via Telephone - `Home Icon`
AC - Audiocassette - `Home Icon`
HOMESTUDY - Home Study - `Home Icon`
IS - Independent Study - `Home Icon`
JN - Journal/Newsletter - `Home Icon`
NID - Non-Interactive-Distance - `Home Icon`    
PB - Prerecorded Broadcast - `Home Icon`
PJ - Professional Journal - `Home Icon` 
VIDEO_DVD - Videocassette/DVD - `Home Icon` 
CD - CD Rom - `Computer Icon`
CBT - Computer-Based Training (ie: online courses)- `Computer Icon`     
CTRNPT - Computer-Based Training NO POST TEST REQUIRED    - `Computer Icon`
EC - Electronic Course - `Computer Icon`
SOFTWAREPROG - Software Program F.A.C. 64B11-5.001(10) - `Computer Icon`
CORRESP - Correspondence - `Mail Icon`
MAILED - Mailed Material - `Mail Icon`  
 */

class DeliveryMethodIcon extends React.Component {
  render() {
    const { deliveryMethodCode, courseType } = this.props;
    switch (deliveryMethodCode) {
      case 'CD':
      case 'CBT':
      case 'CTRNPT':
      case 'EC':
      case 'SOFTWAREPROG': {
        return <i className="material-icons">computer</i>;
      }
      case 'AB':
      case 'AC':
      case 'HOMESTUDY':
      case 'IS':
      case 'JN':
      case 'NID':
      case 'PB':
      case 'PJ':
      case 'VIDEO_DVD': {
        return <i className="material-icons">home</i>;
      }
      case 'CORRESP':
      case 'MAILED': {
        return <i className="material-icons-outlined">markunread_mailbox</i>;
      }
      default: {
        return courseType === 'CD_ANYTIME' ? (
          <i className="material-icons">home</i>
        ) : (
          <i className="material-icons">business</i>
        );
      }
    }
  }
}

DeliveryMethodIcon.propTypes = {
  deliveryMethodCode: PropTypes.string.isRequired,
  courseType: PropTypes.string.isRequired,
};

export default DeliveryMethodIcon;
