import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReportedExemptionsList from '../components/ReportedExemptionsList';
import queryString from 'query-string';
import { refineQuery } from '../utils';

import * as authenticationStore from '../store/authentication';
import * as reportedExemptionsStore from '../store/reportedExemptions';

function mapStateToProps(state, ownProps) {
  const authenticationSelectors = authenticationStore.selectors(state);
  const licenseeAccount = authenticationSelectors.getLicenseeAccount();

  const { subscriptions = [], licenses = [] } = licenseeAccount;

  let subscriptionType = '';
  if (subscriptions.length > 0) {
    subscriptionType = subscriptions[0].type;
  }
  const { match, location } = ownProps;
  const reportedExemptionsSelectors = reportedExemptionsStore.selectors(state);
  const params = refineQuery(queryString.parse(location.search));
  const {
    params: { pkLicense },
  } = match;

  if (!params.page) {
    params.page = 1;
  }
  if (!params.size) {
    params.size = 10;
  }
  if (!params.sortBy) {
    params.sortBy = 'dateAdded';
    params.sortDirection = 'DESC';
  }
  return {
    licenses,
    subscriptionType,
    params,
    reportedExemptions: reportedExemptionsSelectors.getEntitiesByPage(params.page),
    pageStatus: reportedExemptionsSelectors.getPageFetchStatus(params.page),
    totalCount: reportedExemptionsSelectors.getTotalCount(),
    revokeReqStatus: reportedExemptionsSelectors.getRevokeReqStatus(),
    pkLicense,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchReportedExemptions: reportedExemptionsStore.actions.fetch,
    revokeExemption: reportedExemptionsStore.actions.revokeExemption,
  })(ReportedExemptionsList)
);
