import { put, call, select } from 'redux-saga/effects';

import * as api from '../../../api/courseHistory';
import at from '../types';

export function* fetchAttachments({ reduxId, attachmentDocumentNumber, attachmentCode }) {
  const state = yield select();
  const token = state.authentication.legacyToken.token;

  try {
    const { data } = yield call(api.fetchAttachments, attachmentDocumentNumber, attachmentCode, token);
    yield put({ type: at.FETCH_ATTACHMENTS_SUCCESS, reduxId, data });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({ type: at.FETCH_ATTACHMENTS_FAILURE, reduxId });
  }
}

export function* deleteAttachment({ reduxId, attachmentId, attachmentCode, attachmentDocumentNumber }) {
  const state = yield select();
  const token = state.authentication.legacyToken.token;
  try {
    yield call(api.deleteAttachment, attachmentId, token, attachmentCode, attachmentDocumentNumber);
    yield put({ type: at.DELETE_ATTACHMENT_SUCCESS, reduxId, attachmentId });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({ type: at.DELETE_ATTACHMENT_FAILURE, reduxId, attachmentId });
  }
}
