export default function (globalState) {
  const state = globalState.audits;

  const getTask = () => state.task;

  const getUserFlagsAudit = () => state.userFlagsAudit;

  const getSubmitTaskStatus = () => state.submitTaskStatus;

  return {
    getTask,
    getUserFlagsAudit,
    getSubmitTaskStatus,
  };
}
