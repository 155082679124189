import axios from 'axios';
import qs from 'query-string';
import config from '../config';
const { licenseesApiInternal } = config;

export const fetch = (userId, filters) => {
  filters.userId = userId;
  const query = qs.stringify(filters);
  return axios.request({
    url: `${licenseesApiInternal}/reported-exemptions?${query}`,
    headers: {},
    method: 'GET',
  });
};

export const fetchQuestions = (exemptionId, lpExemptionId) => {
  const query = qs.stringify({ exemptionId });
  return axios.request({
    url: `${licenseesApiInternal}/reported-exemptions/${lpExemptionId}/questions?${query}`,
    headers: {},
    method: 'GET',
  });
};

export const fetchAttachments = (lpExemptionId) => {
  return axios.request({
    url: `${licenseesApiInternal}/reported-exemptions/${lpExemptionId}/attachments`,
    headers: {},
    method: 'GET',
  });
};

export const revokeExemption = (lpExemptionId, pkLicense, userLogon, ownerId) => {
  return axios.request({
    method: 'POST',
    url: `${licenseesApiInternal}/reported-exemptions/${lpExemptionId}/revoke`,
    data: {
      pkLicense,
      userLogon,
      ownerId,
    },
  });
};
