import { combineReducers } from 'redux';
import at from '../types';

export const entities = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return { ...state, ...action.entities.items };
    }
    case at.FETCH_QUESTIONS_SUCCESS: {
      const id = action.lpExemptionId;
      const entity = { ...state[id] };
      entity.questions = action.questions;
      return {
        ...state,
        [id]: { ...entity },
      };
    }
    case at.FETCH_ATTACHMENTS_SUCCESS: {
      const id = action.lpExemptionId;
      const entity = { ...state[id] };
      entity.attachments = action.attachments;
      return {
        ...state,
        [id]: { ...entity },
      };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const entitiesFetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_DETAIL_REQUEST: {
      const { reduxId } = action;
      return { ...state, [reduxId]: 'loading' };
    }
    case at.FETCH_DETAIL_SUCCESS: {
      const { reduxId } = action;
      return { ...state, [reduxId]: 'loaded' };
    }
    case at.FETCH_DETAIL_FAILURE: {
      const { reduxId } = action;
      return { ...state, [reduxId]: 'failed' };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const pages = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { filters, result } = action;
      return { ...state, [filters.page]: result };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    case at.DELETE_COURSE_SUCCESS: {
      const { reduxId, page } = action;
      const pageData = state[page].filter((i) => {
        return i !== reduxId;
      });
      return { ...state, [page]: pageData };
    }
    default:
      return state;
  }
};

export const pagesFetchStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { filters } = action;
      return { ...state, [filters.page]: 'loaded' };
    }
    case at.FETCH_FAILURE: {
      const { filters } = action;
      return { ...state, [filters.page]: 'failed' };
    }
    case at.SET_PAGE_FETCH_STATUS: {
      const { page, status } = action;
      return { ...state, [page]: status };
    }
    case at.CLEAR_ENTITIES: {
      return {};
    }
    default:
      return state;
  }
};

export const totalCount = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { options } = action;
      return options.totalRecords;
    }
    case at.CLEAR_ENTITIES: {
      return 0;
    }
    default:
      return state;
  }
};

export const revokeReqStatus = (state = 'loaded', action) => {
  switch (action.type) {
    case at.REVOKE_EXEMPTION_REQUEST: {
      return 'loading';
    }
    case at.REVOKE_EXEMPTION_SUCCESS: {
      return 'loaded';
    }
    case at.REVOKE_EXEMPTION_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export default combineReducers({
  entities,
  entitiesFetchStatus,
  pages,
  pagesFetchStatus,
  totalCount,
  revokeReqStatus,
});
